<template>
  <div>
    <div class="container-fluid p-0 friends-list">
      <div class="row no-gutters">
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-1">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "friendsShim",

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {},
  mounted() {},
};
</script>
<style scoped>
</style>