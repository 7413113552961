var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar'),_c('div',{staticClass:"setting-main-wrapper"},[_c('div',{staticClass:"main-left-menu"},[_c('LeftMenu')],1),_c('Banner'),_c('div',{staticClass:"shop-side p-md-0"},[_c('div',{staticClass:"shop-left-menu p-0"},[_c('LeftSearch',{attrs:{"openTab":_vm.openTab,"searchText":_vm.searchTerm}})],1),_c('div',{staticClass:"shop-page-products pb-0 mt-3 pl-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 col-lg-8 pr-lg-3"},[(_vm.showLoader == 0 && _vm.showLoaderPeople == 0)?_c('All',{attrs:{"openTab":_vm.openTab,"searchProfiles":_vm.searchProfiles,"searchText":_vm.searchTerm,"posts":_vm.searchFetchPost,"searchFetchProfile":_vm.searchFetchProfile}}):_vm._e(),(_vm.showLoaderPeople == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}})],1):_vm._e(),(_vm.showLoaderPeople == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}})],1):_vm._e(),(_vm.showLoaderPeople == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}})],1):_vm._e(),(_vm.showLoaderPeople == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}})],1):_vm._e(),(
                (_vm.openTab == 1 || _vm.openTab == 3) &&
                _vm.showLoaderPeople == 0 &&
                _vm.showLoader == 0
              )?_c('div',_vm._l((_vm.searchFetchPost),function(post){return _c('post',{key:post.id,attrs:{"posts":post,"openTab":_vm.openTab}})}),1):_vm._e(),(
                _vm.searchFetchPost.length == 0 &&
                (_vm.openTab == 1 || _vm.openTab == 3) &&
                _vm.showLoaderPeople == 0 &&
                _vm.showLoader == 0
              )?_c('div',{staticClass:"see-all-seen-post-wrapper"},[_c('p',[_vm._v("No posts found.")])]):_vm._e(),(_vm.showLoader == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}}),_c('content-placeholders-text',{attrs:{"lines":3}})],1):_vm._e(),(_vm.showLoader == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}}),_c('content-placeholders-text',{attrs:{"lines":3}})],1):_vm._e(),(_vm.showLoader == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}}),_c('content-placeholders-text',{attrs:{"lines":3}})],1):_vm._e(),(_vm.showLoaderPost == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}}),_c('content-placeholders-text',{attrs:{"lines":3}})],1):_vm._e(),(_vm.showLoaderPost == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}}),_c('content-placeholders-text',{attrs:{"lines":3}})],1):_vm._e()],1),_c('div',{staticClass:"col-12 col-lg-4 d-none"})])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }