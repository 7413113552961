var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PinModal'),_c('div',{staticClass:"media-wrapper"},[(_vm.showDocShimmer == 1)?_c('div',{staticClass:"lds-dual-ring-media"}):_vm._e(),(_vm.messages.length != 0 && _vm.showDocShimmer == 0)?_c('div',_vm._l((_vm.messages),function(file,index){return _c('div',{key:index,staticClass:"link-content"},[_c('div',{staticClass:"content-sec",attrs:{"id":file.id}},[_vm._m(0,true),_c('div',[_c('div',{staticClass:"footer"},[_c('a',{attrs:{"href":file.url,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(file.name)+"."+_vm._s(file.extension)+" ")])])]),_c('div',{staticClass:"title"},[_c('h6',[_vm._v(_vm._s(file.username))]),_c('span',[_vm._v(_vm._s(_vm.messages.size))])])])]),(file.senderId === _vm.currentUserId)?_c('div',{staticClass:"add-to-pin"},[_c('img',{attrs:{"src":require("../../../../../assets/images/add-to-pin-2.png"),"alt":""},on:{"click":function($event){return _vm.callModal(file)}}}),_c('img',{attrs:{"src":require("../../../../../assets/images/cross.png"),"alt":""},on:{"click":function($event){return _vm.deleteMessage({
                  message: file,
                  roomId: _vm.selectedRoomTeam.roomId,
                })}}})]):_vm._e()])}),0):(_vm.messages.length == 0 && _vm.showDocShimmer == 0)?_c('div',{staticClass:"no-content"},[(_vm.showTeams == 0)?_c('div',[_vm._m(1),_c('p',{staticClass:"header"},[_vm._v("No Documents")]),_c('p',{staticClass:"text"},[_vm._v(" All file documents shared with this team will appear here. ")])]):_vm._e(),(_vm.showIndividual == 0)?_c('div',[_vm._m(2),_c('p',{staticClass:"header"},[_vm._v("No Documents")]),_c('p',{staticClass:"text"},[_vm._v(" All file documents shared with this chat will appear here. ")])]):_vm._e()]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-content-img"},[_c('img',{attrs:{"src":require("../../../../../assets/images/shared-files.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../../assets/images/documents.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../../assets/images/documents.png"),"alt":""}})])
}]

export { render, staticRenderFns }