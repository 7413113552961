<template>
<div>
  <div class="shop-products-img-loader" v-if="showLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
  <div class="cover-photo-wrapper" v-if="dataCover">
    <img
    @load="onImageLoad"
      v-if="dataCover.coverphoto == 'yes'"
      :src="API_URL + dataCover.user_id + '/cover_photo.jpg?v='+time"
      alt=""
      class="cursor-pointer"
      @click="OpenCoverPhotoPreview"
    />
    <img
    @load="onImageLoad"
      v-else
      src="../../assets/cover-default.png"
      alt=""
      class="cover-default"
    />
    <div class="cover-photo-modal" v-if="openCoverPhoto">
      <button
        class="close-icon btn btn-outline-danger btn-sm lb-modal-close"
        @click="CloseCoverPhotoPreview"
      >
        <i class="fa fa-times"></i>
      </button>
      <div class="cover-img-full-preview" @click="CloseCoverPhotoPreview">
        <img
          v-if="dataCover.coverphoto == 'yes'"
          :src="API_URL + dataCover.user_id + '/cover_photo.jpg?v='+time"
          alt=""
        />
      </div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  name: "CoverPhoto",
  props: {
    dataCover: {
      type: Object,
    },
  },
  data() {
    return {
      time:this.$time,
      API_URL: this.$userProfileStorageURL,
      openCoverPhoto: false,
      showLoader: 1,
    };
  },
  methods: {
    OpenCoverPhotoPreview() {
      this.openCoverPhoto = true;
    },
    CloseCoverPhotoPreview() {
      this.openCoverPhoto = false;
    },
    onImageLoad() {
      this.showLoader = 0
    },
  },
};
</script>
<style></style>
