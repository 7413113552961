<template>
  <div class="followers-wrapper">
    <div class="follower-count text-center">
      <span>Followers</span>
      <h3>{{ followerCount }}</h3>
    </div>
    <div class="following-count text-center">
      <span>Following</span>
      <h3>{{ followingCount }}</h3>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Followers",
    props: {
    followersUpdated: {
      type: Boolean
    }
  },
    watch: {
    followersUpdated: {
      handler(newVal) {
        if (newVal == true) {
          this.allOthersCounts();
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      followerCount: "0",
      followingCount: "0",
      user_link: "",
    };
  },
  methods: {
    async allOthersCounts() {
      this.showLoader = 1;
      let allCounts = await axios.get(
        `allOtherCount?user_link=${this.user_link}`
      );

      if (allCounts.data.statusCode == 200) {
        this.followerCount = allCounts.data.data.followerCount;
        this.followingCount = allCounts.data.data.followingCount;
        this.showLoader = 0;
      } else {

        this.$router.push({ name: "PageNotFound" });
        this.showLoader = 0;
      }
    },
    getuserLink() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.user_link = segment_array.pop();
      if (this.user_link !== "profileOther") {
        this.allOthersCounts();
      }
    },
  },
  mounted() {
    this.getuserLink();
  },
};
</script>
