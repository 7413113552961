<template>
  <div>
    <div>
      <Navbar />
    </div>
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side">
        <div class="privacy-policy-page mb-3">
          <PolicyTop />
          <Definitions />
          <CollectingInformation />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import PolicyTop from "../components/PrivacyPolicy/PolicyTop.vue";
import Definitions from "../components/PrivacyPolicy/Definitions.vue";
import CollectingInformation from "../components/PrivacyPolicy/CollectingInformation.vue";
import Banner from "../components/BannerNotify/BannerPage.vue"

export default {
  components: {
    Navbar,
    LeftMenu,
    PolicyTop,
    Definitions,
    CollectingInformation,
    Banner
  },
  name: "PrivacyPolicy",
};
</script>
