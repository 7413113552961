<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#editSponsor"
      ref="openModalEditSponsor"
      @click="dataReset1()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="editSponsor">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Edit Sponsor</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeEditSponsor"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <form @keyup.enter="editSponsor(0)" @submit.prevent>
              <div class="profile-preview">
                <div class="profile-photo-edit" @click="openinput()">
                  <img
                      v-if="
                        this.eventAvatar.length == 0 &&
                          this.eventFinalAvatar == ''
                      "
                      src="../../../assets/images/sponsor.png"
                      alt=""
                      class="default-image"
                    />
                    <img
                      v-else-if="this.eventAvatar.length == 0"
                      :src="this.$eventSponserStorageURL + this.eventFinalAvatar"
                      alt=""
                    />

                  <form>
                    <input
                    type="file"
                    id="vue-file-upload-input-sponsor-photo-edit"
                    @change="onFileChange"
                    class="d-none"
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <div id="preview" class="new-profile-photo">
                    <clipper-basic
                      v-if="this.eventAvatar.length > 0"
                      :src="this.rawData"
                      preview="preview"
                      :border="2"
                      :outline="0"
                      :corner="false"
                      :ratio="1"
                      :wrap-ratio="1"
                      ref="clipper"
                    ></clipper-basic>
                    </div>

                    <div class="profile-photo-edit-icon-wrapper">
                      <img src="../../../assets/images/edit.png" alt="" />
                    </div>
                  </form>
                </div>
                <div class="preview-image">
                  <div v-if="this.eventAvatar.length > 0">
                    <clipper-preview name="preview"></clipper-preview>
                  </div>
                  <div
                      v-else
                      class="no-image-wrapper"
                    >
                      <div slot="placeholder">No image</div>
                    </div>
                </div>
              </div>
              <div class="form-group">
                <label for="name">Sponsor Name</label>
                <input
                  id="name"
                  class="form-control"
                  v-model.trim="$v.details.name.$model"
                  spellcheck="false"
                  autocomplete="off"
                  :class="{
                    'is-invalid': validationStatus($v.details.name),
                  }"
                  trim
                  type="text"
                  required
                  v-on:keyup="errorNull"
                />
                <div v-if="!$v.details.name.required" class="invalid-tooltip">
                  Sponsor name is required
                </div>
                <div v-if="!$v.details.name.maxLength" class="invalid-tooltip">
                  Maximum 18 characters allowed.
                </div>
              </div>
              <div class="form-group">
                <label for="url">Website URL</label>
                <input
                  type="text"
                  class="form-control"
                  id="url"
                  v-model="url"
                  @keyup="errorNull"
                  @input="change($event)"
                  @change="change($event)"
                  required
                />

                <div class="error-sponser" v-if="!isValid && url">
                  URL is Invalid
                </div>
                <div class="error-sponser" v-if="!url">
                  URL is required
                </div>

              </div>
            </form>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-between">
            <button
              type="button"
              class="btn btn-danger w-25"
              block
              @click="deleteSponsor(0)"
              :disabled="isDeleteButtonDisabled || isLoadingArrayDelete[0]"
            >
              <div v-if="isLoadingArrayDelete[0]" class="lds-dual-ring"></div>
              <span v-else>Delete</span>
            </button>
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="editSponsor(0)"
              :disabled="isUpdateButtonDisabled || isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isUpdateButtonDisabled || isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import { required, maxLength } from "vuelidate/lib/validators";
import { clipperBasic, clipperPreview } from "vuejs-clipper";
import axios from "axios";
export default {
  name: "EditSponsor",
  components: {
    clipperBasic,
    clipperPreview,
  },
  props: {
    eventId: {
      type: Number,
    },
  },
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      isUpdateButtonDisabled: false,
      isDeleteButtonDisabled: false,
      eventAvatar : [],
      eventFinalAvatar : "",
      rawData: "",
      sponserURL: this.$eventSponserStorageURL,
      sponsor:{},
      isLoadingArrayDelete: [],
      details: {
        name: "",
      },
      url: "",
      photoUrl: null,
      isValid: true,
      sponsorPhoto: null,
    };
  },
  validations: {
    details: {
      name: { required, maxLength: maxLength(18) },
    },
  },

  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    errorNull: function () {
      if (this.details.name == ""|| this.url =="" || !this.isValid || this.$v.$invalid) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    dataReset1: function() {
     this.isValid =true;
    },
    dataReset: function() {
      this.details.name = "";
      this.url = "";
      this.$v.$reset();
      this.eventAvatar = [];
      this.rawData = "";
      this.eventFinalAvatar = "";
      this.isDisabled = true;
      this.sponsorPhoto = null;
      this.photoUrl = null;
      document.getElementById("vue-file-upload-input-sponsor-photo").value = "";
    },
    change(e) {
      const url = e.target.value;
      this.isURLValid(url);
      this.errorNull(); 
    },
    isURLValid(inputUrl) {
      var regx = new RegExp(
        "^" +
          // protocol identifier
          "(?:(?:https?|http)://)" +
          // user:pass authentication
          "(?:\\S+(?::\\S*)?@)?" +
          "(?:" +
          // IP address exclusion
          // private & local networks
          "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
          "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
          "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
          // IP address dotted notation octets
          // excludes loopback network 0.0.0.0
          // excludes reserved space >= 224.0.0.0
          // excludes network & broacast addresses
          // (first & last IP address of each class)
          "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
          "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
          "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
          "|" +
          // host name
          "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
          // domain name
          "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
          // TLD identifier
          "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
          ")" +
          // port number
          "(?::\\d{2,5})?" +
          // resource path
          "(?:/\\S*)?" +
          "$",
        "i"
      );
      this.isValid = regx.test(inputUrl);
    },
    openinput: function () {
      document.getElementById("vue-file-upload-input-sponsor-photo-edit").click();
    },
    onFileChange: function(event) {
      const self = this;
      var fileInput = document.getElementById("vue-file-upload-input-sponsor-photo-edit");
      var file_ = fileInput.value;
      var files = event.target.files;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file_)) {
        alert(
          "Please upload file having extensions .jpeg/.jpg/.png/.gif only."
        );
        fileInput.value = "";
        return false;
      } else {
        const file_ = files[0];
        this.eventAvatar.push({
          target: event.target.files[0],
          fileName: file_.name,
          link: URL.createObjectURL(file_),
        });
        let reader = new FileReader();
        reader.onload = (e) => {
          self.rawData = e.target.result;
        };
        reader.readAsDataURL(file_);
        this.errorNull();
      }
    },
    dataURItoBlob: function(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },

    // dataURItoBlob: function (dataURI) {
    //   // convert base64/URLEncoded data component to raw binary data held in a string
    //   var byteString;
    //   if (dataURI.split(",")[0].indexOf("base64") >= 0)
    //     byteString = atob(dataURI.split(",")[1]);
    //   else byteString = unescape(dataURI.split(",")[1]);

    //   // separate out the mime component
    //   var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    //   // write the bytes of the string to a typed array
    //   var ia = new Uint8Array(byteString.length);
    //   for (var i = 0; i < byteString.length; i++) {
    //     ia[i] = byteString.charCodeAt(i);
    //   }

    //   return new Blob([ia], { type: mimeString });
    // },
    editSponsor(index) {
  console.log('Edit Sponsor button clicked');
  try {
    this.isLoadingArray[index] = true;
    this.isUpdateButtonDisabled = true;
    this.isDeleteButtonDisabled = true;

    const formData = new FormData();
    formData.append('id', this.sponsor.id);
    formData.append('name', this.details.name);
    formData.append('website_url', this.url);
    
    if (this.eventAvatar.length > 0) {
        // this.eventAvatar.forEach((value, index) => {
        //   formData.append("avatar[" + index + "]", value.target);
        // });

        const canvas = this.$refs.clipper.clip();
        var dataURL = canvas.toDataURL("image/jpeg", 0.95);
        var blob = this.dataURItoBlob(dataURL);
        this.eventAvatar.forEach((value, index) => {
          formData.append("image_url[" + index + "]", blob);
        });
      }

    axios.post('/associationEvent/editSponsor', formData)
      .then(response => {
        console.log(response.data);
        
        this.$root.$emit('sponsorUpdated');
        this.dataReset();
        var elem = this.$refs.closeEditSponsor;
        if (elem) {
          elem.click();
        }
      })
      .catch(error => {
        console.error('Error updating sponsor:', error);
      }) 
      .finally(() => {
        this.isLoadingArray[index] = false;
        this.isUpdateButtonDisabled = false;
        this.isDeleteButtonDisabled = false;
      });
  } catch (error) {
    console.error('Error updating sponsor:', error);
    this.isLoadingArray[index] = false;
    this.isUpdateButtonDisabled = false;
    this.isDeleteButtonDisabled = false;
  }
},

deleteSponsor(index) {
      try {
        this.isLoadingArrayDelete[index] = true;
        this.isUpdateButtonDisabled = true;
        this.isDeleteButtonDisabled = true;

        axios.post('/associationEvent/deleteSponsor', { id: this.sponsor.id })
          .then(response => {
            console.log(response.data);
            this.$root.$emit('sponsorUpdated');
            var elem = this.$refs.closeEditSponsor;
            if (elem) {
              elem.click();
            }
          })
          .catch(error => {
            console.error('Error deleting sponsor:', error);
          })
          .finally(() => {
            this.isLoadingArrayDelete[index] = false;
            this.isUpdateButtonDisabled = false;
            this.isDeleteButtonDisabled = false;
          });
      } catch (error) {
        console.error('Error deleting sponsor:', error);
        this.isLoadingArrayDelete[index] = false;
        this.isUpdateButtonDisabled = false;
        this.isDeleteButtonDisabled = false;
      }
    },
  

  },

  mounted() {
    this.$root.$on("openEditSponsorModal", (sponsor) => {
    this.sponsor=sponsor;
    this.details.name = sponsor.name;
    this.url = sponsor.website_url;
    if (sponsor.image_url != null) {
        this.eventFinalAvatar = sponsor.image_url;
        this.rawData = this.$eventSponserStorageURL + this.eventFinalAvatar; 
      }
    this.photoUrl = sponsor.image_url;
    
    // Trigger click event on the modal trigger button
    var elem = this.$refs.openModalEditSponsor;
    if (elem) {
      elem.click();
    }
  });
  },
};
</script>
  
  <style scoped>
.profile-preview {
  display: flex;
  gap: 10px;
  align-items: end;
}

.profile-photo-edit {
  width: 200px;
  height: 200px;
  position: relative;
  text-align: center;
  border: 2px solid #f1f3f6;
  cursor: pointer;
}
.profile-photo-edit img{
  width: 200px;
  height: 200px;
  /* position: relative;
  text-align: center;
  border: 2px solid #f1f3f6;
  cursor: pointer; */
}
.profile-photo-edit .user-default {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.profile-photo-edit img,
.new-profile-photo img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.error-sponser{
        left: auto;
        margin-top: 15px;
color: white;
border-radius: 5px;
        right: 5%;
        background-color: #ff5b66 !important;
        transform: translate(-5%, -50%);
        -webkit-transform: translate(-5%, -50%);
        -moz-transform: translate(-5%, -50%);
        -ms-transform: translate(-5%, -50%);
        -o-transform: translate(-5%, -50%);
        width: max-content;
}
.profile-photo-edit-icon-wrapper {
  position: absolute;
  top: 76.5%;
  background-color: #e5f2fe;
  right: 3.8%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.profile-photo-edit-icon-wrapper img {
  width: 20px;
  height: 20px;
}
</style>
  
    