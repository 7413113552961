<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side">
        <div class="privacy-policy-page mb-3">
          <TermsTop />
          <Definitions />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import TermsTop from "../components/TermsAndConditions/TermsTop.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import Definitions from "../components/TermsAndConditions/Definitions.vue";

export default {
  components: {
    Navbar,
    LeftMenu,
    TermsTop,
    Definitions,
    Banner
  },
  name: "TermsAndConditions",
};
</script>
