<template>
  <div>
    <div class="team-info-wrap">
      <div class="row m-0">
            <div class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end">
                <input
                    class=""
                    id="eventType"
                    v-model="filter.name"
                    @input="getFilters()"
                    placeholder="Enter Team Name"
                    />
                <span :style="{ display: isLoading ? 'none' : 'block' }" v-if="filter.name" class="clear-icon" @click="clearName()"><img src="../../assets/cross-icon.png"/></span>
            </div>
            <div  class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end">
                <select
                class=""
                id="ageGroup"
                v-model="filter.age"
                @change="getFilters()"
                >
                  <option value="" selected > Select Age Group </option>
                  <option v-for="option in ageOptions" :key="option.id" :value="option.id">
                    {{ option.name }}
                  </option>
                </select>
                <span v-if="filter.age" class="clear-icon" @click="clearAge()"><img src="../../assets/cross-icon.png"/></span>

            </div>
            <div  class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end">
                <select
                class=""
                id="gender"
                v-model="filter.gender"
                @change="getFilters()"
                >
                  <option value="" selected>Select Gender</option>
                  <option v-for="option in genderOptions" :key="option.id" :value="option.name" >
                    {{ option.name }}
                  </option>
                </select>
                <span v-if="filter.gender" class="clear-icon" @click="clearGender()"><img src="../../assets/cross-icon.png"/></span>

            </div>
            <div  class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end">
                <select
                class=""
                v-model="filter.rating"
                @change="getFilters()"
                >
                <option value="" selected>Select Rating</option>
                <option v-for="option in ratingOptions" :value="option.id" :key="option.id">
                    {{ option.rate }}
                </option>
                </select>
                <span v-if="filter.rating" class="clear-icon" @click="clearRating()"><img src="../../assets/cross-icon.png"/></span>

            </div>
            
            
        </div>

      <b-row class="no-gutters" v-if="AllTeam.length > 0">
        <div class="col-12 products-column mt-3">
          <b-card>
            <b-table
              :items="AllTeam"
              :fields="fields"
              hover
              responsive
              class="mt-3 b-datatable"
            >
              <!-- Custom slot for the exactStartDate column -->

              <!-- Custom slot for the location column -->
              <template v-slot:cell(location)="data">
                <div v-if="showLocation(data.item)">
                  <img
                    class="mr-2"
                    src="../../assets/images/location.png"
                    @click="openGoogleMap(data.item)"
                  />
                  <span>{{ getLocation(data.item) }}</span>
                </div>
              </template>
            </b-table>
          </b-card>
        </div>
      </b-row>
      <div
        v-if="AllTeam.length"
        v-observe-visibility="handleScrolledToButtom"
      ></div>
    </div>
    <div
      class="no-products-in-shop"
      v-if="AllTeam.length === 0 && showLoader === 0"
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No teams found.</p>
      <p class="info">
        There are no teams to display in the selected category.
      </p>
    </div>
    <div
      class="products-list-shim mt-3"
      v-if="AllTeam.length == 0 && showLoader == 1"
    >
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AllTeamList",

  data() {
    return {
      reverse: false,
      sortKey: "",

      filter: {
        name: "",
        age: "",
        rating: "",
        gender: "",
      },
      fields: [
        { key: "team_name", label: "Team Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "phone_number", label: "Phone", sortable: true },
        { key: "city", label: "City", sortable: true },
        { key: "state", label: "State", sortable: true },
        { key: "ratings.rate", label: "Rating", sortable: true },
        { key: "age_group.name", label: "Age", sortable: true },
        { key: "gender", label: "Gender", sortable: true },
        {
          key: "updated_at",
          label: "Last Update",
          sortable: true,
          formatter: (value) => {
            if (!value) return "";
            const date = new Date(value);
            return date.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
          },
        },
      ],
      teamsAll: [],
      messages: [],
      page: 1,
      lastPage: 1,
      AllTeam: [],
      showLoader: 0,
      showLoader1: false,
      ageOptions: [],
      genderOptions: [
        { name: "Male", id: "Male" },
        { name: "Female", id: "Female" },
        { name: "Coed", id: "Coed" },
      ],
      ratingOptions: [],
      isLoading : false,

    };
  },
  computed: {
    sortedTeam() {
      const teams = this.AllTeam.slice();

      if (this.sortKey) {
        teams.sort((a, b) => {
          const fieldA = a[this.sortKey];
          const fieldB = b[this.sortKey];

          if (this.sortKey === "age_group") {
            const numericPartA = fieldA
              ? parseInt(fieldA.name.match(/\d+/), 10) || 0
              : 0;
            const numericPartB = fieldB
              ? parseInt(fieldB.name.match(/\d+/), 10) || 0
              : 0;

            return this.reverse
              ? numericPartB - numericPartA
              : numericPartA - numericPartB;
          } else if (this.sortKey === "updated_at") {
            const dateA = new Date(fieldA);
            const dateB = new Date(fieldB);

            if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
              return 0; // Handle invalid dates
            }

            return this.reverse
              ? dateB.getTime() - dateA.getTime()
              : dateA.getTime() - dateB.getTime();
          } else if (this.sortKey === "ratings") {
            const rateA = a.ratings ? a.ratings.rate : "";
            const rateB = b.ratings ? b.ratings.rate : "";

            // Implement custom sorting logic for string values
            return this.reverse
              ? rateB.localeCompare(rateA)
              : rateA.localeCompare(rateB);
          } else if (this.sortKey === "email") {
            const valueA =
              a.email && a.email[0]
                ? a.email[0].charAt(0).toLowerCase()
                : "N/A";
            const valueB =
              b.email && b.email[0]
                ? b.email[0].charAt(0).toLowerCase()
                : "N/A";

            return this.reverse
              ? valueB.localeCompare(valueA)
              : valueA.localeCompare(valueB);
          } else if (this.sortKey === "phone_number") {
            const valueA = a.phone_number
              ? a.phone_number[0].charAt(0).toLowerCase()
              : "N/A";
            const valueB = b.phone_number
              ? b.phone_number[0].charAt(0).toLowerCase()
              : "N/A";

            return this.reverse
              ? valueB.localeCompare(valueA)
              : valueA.localeCompare(valueB);
          } else if (typeof fieldA === "number" && typeof fieldB === "number") {
            return this.reverse ? fieldB - fieldA : fieldA - fieldB;
          } else if (typeof fieldA === "string" && typeof fieldB === "string") {
            return this.reverse
              ? fieldB.localeCompare(fieldA)
              : fieldA.localeCompare(fieldB);
          } else if (fieldA instanceof Date && fieldB instanceof Date) {
            return this.reverse
              ? fieldB.getTime() - fieldA.getTime()
              : fieldA.getTime() - fieldB.getTime();
          } else {
            // Handle other types of sorting if needed
            return 0;
          }
        });
      }

      return teams;
    },
  },

  methods: {
    clearName() {
      this.filter.name = "";
      this.getFilters();
    },
    clearAge() {
      this.filter.age = "";
      this.getFilters();
    },
    clearGender() {
      this.filter.gender = "";
      this.getFilters();
    },
    clearRating() {
      this.filter.rating = "";
      this.getFilters();
    },
    sortBy(sortKey) {
      if (this.sortKey === sortKey) {
        this.reverse = !this.reverse;
      } else {
        this.sortKey = sortKey;
        this.reverse = false;
      }
    },
    async getFilters() {
      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;
      this.AllTeam = [];
      try {
        const filteredteams = await axios.post(
          `${process.env.VUE_APP_API_URL}adminTeam/getTeams`,
          {
            name: this.filter.name,
            age: this.filter.age,
            gender: this.filter.gender,
            rating: this.filter.rating,
          }
        );

        if (filteredteams.data.statusCode === 200) {
          const newEventData = filteredteams.data.data.data;
          if (this.page === 1) {
            this.AllTeam = [];
          }
          this.AllTeam = this.AllTeam.concat(newEventData);

          // this.AllTeam = filteredteams.data.data.data;
          this.lastPage = filteredteams.data.data.last_page;
          this.showLoader = 0;
          this.showLoader1 = false;
          this.filterOption = false;
          this.isLoading = false; // Close the popup after successful filtering
        } else {
          this.errorMessage = filteredteams.data.message;
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoading = false;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
        this.showLoader1 = false;
      } 
    },

    getAgeGroup() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAgeGroup`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ageOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    getAllRating() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAllRatings`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ratingOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },

    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.getAllTeam();
      }
    },

    async getAllTeam() {
      this.showLoader = 1;
      this.showLoader1 = true;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}adminTeam/getTeams?page=${this.page}`
        );

        if (response.data.statusCode === 200) {
          this.AllTeam.push(...response.data.data.data);
          this.lastPage = response.data.data.last_page;
          this.showLoader = 0;
          this.showLoader1 = false;
        } else {
          this.errorMessage = response.data.message;
          this.showLoader = 0;
          this.showLoader1 = false;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
        this.showLoader1 = false;
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
      }
    },
  },
  mounted() {
    this.getAgeGroup();
    this.getAllRating();
    this.getAllTeam();
  },
};
</script>

<style scoped>

.table thead th {
  min-width: 150px;
}
.chat-filter-options {
  background: #cce6fd;
  border-radius: 5px;
  height: 35px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter-counter {
  background: #254c72;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  margin: 0 5px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.blocker {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  bottom: 0;
  right: 0;
  content: " ";
  background: rgba(0, 0, 0, 0.5);
}
.pinned-options {
  top: 9%;
  left: inherit;
  margin-right: -13%;
}
.sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.form-group select {
  border: 1px solid #ced4da !important;
}
</style>
