<template>
  <div class="edit-profile-main">
    <UpdateBio v-if="user" />
    <update-contact v-if="user" />
    <update-name v-if="user" />
    <updateEmergencyInfo v-if="user"  />
    <div
      class="edit-profile-wrapper"
      data-toggle="modal"
      ref="openModalXl"
      data-target="#editProfileModal"
    >
      <div class="change-icon">
        <img src="../../assets/images/edit.png" alt="" />
        <div class="edit-profile-text">
          <span>Edit Profile</span>
        </div>
      </div>
    </div>

    <div
      class="modal fade bd-example-modal-lg"
      id="editProfileModal"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        v-show="seen"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Edit Profile</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalPost"
            >
              <img src="../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body" v-if="user">
            <div class="info-heading">
              <p>Edit Name</p>

              <div class="edit-profile-wrapper">
                <div
                  class="change-icon"
                  data-toggle="modal"
                  data-target="#editProfileName"
                  @click="closeMain"
                >
                  <img src="../../assets/images/edit.png" alt="" />
                  <div class="edit-profile-text">
                    <span>Edit</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="personal-info">
              <div class="personal-info-heading">
                <span>Name:</span>
                <span>Nick:</span>
              </div>
              <div class="personal-info-content">
                <span v-if="user.fname || user.lname || user.suffix">
                  {{ user.suffix }} {{ user.fname }} {{ user.middle_name }}
                  {{ user.lname }}
                </span>
                <span v-else>N/A</span>
                <span v-if="user.nick_name">
                  {{ user.nick_name }}
                </span>
                <span v-else>N/A</span>
              </div>
            </div>

            <div class="info-heading">
              <p>Edit Bio</p>
              <div class="edit-profile-wrapper">
                <div
                  class="change-icon"
                  data-toggle="modal"
                  data-target="#editProfilebio"
                  @click="closeMain"
                >
                  <img src="../../assets/images/edit.png" alt="" />
                  <div class="edit-profile-text">
                    <span>Edit</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="personal-info">
              <div class="personal-address">
                <span v-if="user.bio">{{ user.bio }}</span>
                <span v-else>Please Update Your Bio</span>
              </div>
            </div>
            <div class="info-heading">
              <p>Edit Contact Info</p>
              <div class="edit-profile-wrapper">
                <div
                  class="change-icon"
                  data-toggle="modal"
                  data-target="#editProfileContact"
                  @click="closeMain"
                >
                  <img src="../../assets/images/edit.png" alt="" />
                  <div class="edit-profile-text">
                    <span>Edit</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="personal-info d-block">
              <div class="personal-info-heading flex-row mb-3">
                <span>Date of Birth:</span>
                <span v-if="user.dob">
                  {{ user.dob | formatDateOfBirth }}
                </span>
                <span v-else>N/A</span>
              </div>

              <div class="personal-info-heading flex-row mb-3">
                <span>Gender:</span>
                <span v-if="user.gender">
                  {{ user.gender }}
                </span>
                <span v-else>N/A</span>
              </div>
              <div class="personal-info-heading flex-row mb-3">
                <span>Address:</span>
                <span class="profile-address" v-if="user.address">
                  {{ user.address }}
                </span>
                <span v-else>N/A</span>
              </div>
              <div class="personal-info-heading flex-row">
                <span>City:</span>
                <span v-if="user.city">{{ user.city }}</span>
                <span v-else>N/A</span>
              </div>
            </div>
            <!-- Edit Emergency Contact Info -->
            <div class="info-heading">
              <p>Edit Emergency Contact Info</p>
              <div class="edit-profile-wrapper">
                <div
                  class="change-icon"
                  data-toggle="modal"
                  data-target="#editEmergencyInfo"
                  @click="closeMain"
                >
                  <img src="../../assets/images/edit.png" alt="" />
                  <div class="edit-profile-text">
                    <span>Edit</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="personal-info d-block">
              <div class="personal-info-heading flex-row mb-3">
                <span>Name:</span>
                <span v-if="user.emergency_name">
                  {{ user.emergency_name }}
                </span>
                <span v-else>N/A</span>
              </div>

              <div class="personal-info-heading flex-row mb-3">
                <span>Email:</span>
                <span v-if="user.emergency_email">
                  {{ user.emergency_email }}
                </span>
                <span v-else>N/A</span>
              </div>
              <div class="personal-info-heading flex-row mb-3">
                <span>Phone:</span>
                <span class="profile-address" v-if="user.emergency_phone">
                  {{ user.emergency_phone }}
                </span>
                <span v-else>N/A</span>
              </div>
              <div class="personal-info-heading flex-row">
                <span>Relationship:</span>
                <span v-if="user.emergency_relationship">{{ user.emergency_relationship }}</span>
                <span v-else>N/A</span>
              </div>
            </div>


            <div class="event-btns-wrapper justify-content-end">
              <button
                type="button"
                class="page-back w-25"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeEditProfile"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import UpdateBio from "./updateBio.vue";
import UpdateContact from "./updateContact.vue";
import updateName from "./updateName.vue";
import updateEmergencyInfo from "./UpdateEmergencyInfo.vue";
export default {
  name: "EditProfile",
  components: {
    UpdateBio,
    UpdateContact,
    updateName,
    updateEmergencyInfo
  },
  data() {
    return {
      seen: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth", ["getUserData"]),
    closeMain() {
      this.getUserData();
      this.$root.$emit("updateContact");
      this.$root.$emit("updateBio");
      this.$root.$emit("updateProfileName");
      this.$root.$emit("updateEmergencyInfo");
      var elem = this.$refs.closeModalPost;
      elem.click();
    },
    claseModal() {},
  },
  mounted() {
    this.$root.$on("closeEditProfileModal", () => {
      var elem = this.$refs.openModalXl;
      elem.click();
    });
  },
};
</script>