<template>
  <div>
    <div
      data-target="#AddScoresModal"
      data-toggle="modal"
      ref="openAddScores"
    ></div>
    <div
      class="modal fade"
      id="AddScoresModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Scores</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeAddScoresModal"
              @click="addScore(0)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="game-name">
                         <span  style="font-size: 15px;">
                          {{teamName}} 
                          <b>({{(teamType==1)?'Visiting Team':'Home Team'}})</b>  
                          </span>
            </div>
            <div class="game-name">
                        <span  style="font-size: 15px;">
                          Inning<b> ({{inningNo}})</b>
                          </span>
            </div><br/>
         
             <table v-if="showLoader==0" class="table table-bordered">
              <div class="lds-dual-ring-table"></div>
            </table>

            <form @keyup.enter="saveScore(0)" @submit.prevent  v-if="showLoader==1" >
              <div class="form-group" id="fieldset-1" label-for="score">
                <input
                  id="score"
                  autocomplete="off"
                  type="text"
                  v-model="score"
                  maxlength="4"
                  required
                  @keyup="isNumber()"
                />

                <span class="placeholder">Enter Score</span>
              </div>
              <div class="form-group" id="fieldset-1" label-for="hr">
                <input
                  id="hr"
                  autocomplete="off"
                  type="text"
                  v-model="hr"
                  maxlength="4"
                  required
                  @keyup="isNumber()"
                />

                <span class="placeholder">Enter HR</span>
              </div>
            </form>
          </div>
          <div class="modal-footer sign-in"  v-if="showLoader==1" >
            <b-button
              class="auth-btn font-weight-normal" style="width: 100px;"
              v-if="isDisabled == true"
              :disabled="true"
            >
             <span v-if="endInningStatus==1"> End & Save</span>
             <span v-else>Save </span>
            </b-button>
            <b-button
              v-else
              class="auth-btn font-weight-normal" style="width: 100px;"
              :disabled="isLoadingArrayScore[0]"
              @click="saveScore(0)"
            >
              <div v-if="isLoadingArrayScore[0]" class="lds-dual-ring"></div>
              <span v-else-if="endInningStatus==1"> End & Save </span>
             <span v-else> Save </span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ScoreModal",
  data: function() {
    return {
      isLoadingArrayScore: [],
      score: 0,
      hr:0,
      oldScore: 0,
      teamName:'',
      inningNo:0,
      teamType:0,
      isDisabled: true,
      showLoader:0,
      endInningStatus:1,
      currentBattingInning:0,
      currentBattingTeamType:0,
    };
  },
  methods: {
    addScore() {
      this.$root.$emit(
        "keepScoresModalBackFromScoreModal");
    },
    isNumber: function() {
      if (/^[0-9]\d*$/.test(this.score) && /^[0-9]\d*$/.test(this.hr)) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    async saveScore(index) {

      if((parseInt(this.currentBattingInning)>parseInt(this.inningNo) && this.endInningStatus!=1) || (parseInt(this.currentBattingInning)==parseInt(this.inningNo) && this.currentBattingTeamType==1))
      {
          if (this.isDisabled != true) {
            this.$set(this.isLoadingArrayScore, index, true);
            var formData = new FormData();
            formData.append("game_id", this.game_id);
            formData.append("inning_no", this.inningNo);
            formData.append("team_type", this.teamType);
            formData.append("update_score", (this.endInningStatus==1)?1:0);
            formData.append("s_score", this.score);
            formData.append("hr_score", this.hr);
            await axios
              .post(process.env.VUE_APP_API_URL + "game/addScore", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                if (response.data.statusCode == 404) {
                  alert(response.data.message);
                } else {
                  this.oldScore = this.score;
                }
                this.$set(this.isLoadingArrayScore, 0, false);
                var elem = this.$refs.closeAddScoresModal;
                if (elem) {
                  elem.click();
                }
              });
        }
      }
      else
      {
        alert('Please add pending inning Score first.');
      }
    },
  },
  mounted() {
    this.$root.$on("openAddScoresModal", (singleGame,inningNo, teamName,teamType) => {
      this.game_id = singleGame.id;
      this.inningNo = inningNo;
      this.teamName = teamName;
      this.teamType = teamType;
      this.showLoader=0;
      var formData = new FormData();
      formData.append("game_id", this.game_id);
      formData.append("team_type", teamType);
      formData.append("inning_no", inningNo);
      axios
        .post(process.env.VUE_APP_API_URL + "game/getInningScores", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
            this.hr = response.data.data.hr;
            this.score = response.data.data.score;
            this.endInningStatus = response.data.data.endInningStatus;
            this.currentBattingInning = response.data.data.currentBattingInning;
            this.currentBattingTeamType = response.data.data.currentBattingTeamType;
            var elem = this.$refs.openAddScores;
             this.showLoader=1;
            if (elem) {
              elem.click();
            }
            this.isNumber();
        });
    });
  },
};
</script>

<style>


@media screen and (min-width: 768px) {
  #AddScoresModal .modal-content {
    max-width: 300px;
  }
}
</style>
