<template>
  <div>
    <TournamentTeamsModal />
    <div class="d-flex justify-content-end"  v-if="currentIsAdmin == '1'">
      <button class="btn btn-primary" @click="callModal()">Manage Teams</button>
    </div>
    <div class="table-responsive mb-0" v-if="teamData.length > 0">
      <b-table
        :fields="fields"
        :items="teamData"
        hover
        responsive
        class="mt-3 b-datatable jointeames_table cursor-pointer"
      >
      <template v-slot:cell(team_sr_no)="data">
        <div class="d-flex align-items-center">
          <div class="user-img">
            <div class="users-notification" v-if="imgLoader == 1">
              <!-- <div class="lds-dual-ring-refresh"></div> -->
              <img src="../../assets/loader.gif" alt="" />
            </div>
            <img @load="onImageLoad"
              v-if="data.item.team.team_avatar"
              :src="url + data.item.team.team_avatar"
              alt=""
              style="border: 2px solid white"
            />
            <img
              @load="onImageLoad"
              v-else
              alt=""
              src="../../assets/team-avatar.png"
            />
          </div>
          <div class="group-table-name pl-2">
            <p>#{{data.item.team_sr_no || '-'}}: {{data.item.team.team_name || '-'}}</p>
          </div>
        </div>
      </template>

      </b-table>
      <content-placeholders :rounded="true" v-if="showLoader1 == 1">
            <content-placeholders-heading :img="false" />
          </content-placeholders>
    </div>
    <div
      v-if="teamData.length"
      v-observe-visibility="handleScrolledToButtom"
    ></div>
    <div
      class="no-products-in-shop"
      v-if="(!teamData || teamData.length === 0) && showLoader === 0"
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No tournament teams found.</p>
      <p class="info">
        There are no tournament teams to display.
      </p>
    </div>
    <div class="products-list-shim p-0 pt-3" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
import TournamentTeamsModal from "./Modals/TournamentTeamsModal.vue";
import axios from "axios";

export default {
  name: "TournamentTeams",
  components: {
    TournamentTeamsModal,
  },
  props: {
    tournamentData: {
      typeof: Object,
    },
    currentIsUmpire: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  data() {
    return {
      url: this.$groupAvatarStorageURL,
      imgLoader: 1,
      teamData: [],
      showLoader1: 1,
      showLoader: 1,
      page: 1,
      teamLastPage: 1,
      fields: [
        { key: "win_count", label: "Win", sortable: true },
        { key: "loss_count", label: "Loss", sortable: true },
        { key: "team_sr_no", label: "Team", sortable: true, 
          formatter: (value, key, item) => {
            const teamName = item.team.team_name || "-";
            return `#${value || "-"}: ${teamName}`; }
        },
        { key: "team.age_group.name", label: "Age Group", sortable: true },
        { key: "team.ratings.rate", label: "Rating", sortable: true },
        { key: "team.state", label: "Location", sortable: true },
      ],
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.teamLastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.getTournamentTeams(); // Emit event to notify parent component
      }
    },
    callModal() {
      this.$root.$emit("openTournamentTeamsModal", this.tournamentData);
    },
    async getTournamentTeams() {
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const tournamentGuid = segment_array.pop();
      this.showLoader = 1;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}tournaments/teams/getTournamentTeams`,
          { tournament_guid: tournamentGuid, page: this.page } // Include the page parameter
        );

        if (response.data.statusCode === 200) {
          const newData = response.data.data.data;
          // Sort newData by team_sr_no in ascending order
          newData.sort((a, b) => a.team_sr_no - b.team_sr_no);
          this.teamData = [...this.teamData, ...newData]; // Concatenate the new data with existing data
          this.teamLastPage = response.data.data.last_page;
          this.showLoader1 = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
      } finally {
        this.showLoader = 0;
      }
    },
  },
  mounted() {
    this.getTournamentTeams();
    this.$root.$on("showTournamentForSwitch", () => {
      this.teamData = [];
      this.getTournamentTeams();
    });
    this.$root.$on("selectedTournamentTeamEmit", () => {
      this.teamData = [];
      this.getTournamentTeams();
    });
  },
};
</script>
<style scoped>
.user-img {
    min-width: 40px;
    max-width: 40px!important;
    min-height: 40px;
    max-height: 40px!important;
}
</style>
