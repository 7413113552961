<template>
  <div>
    <div>
      <div class="post-view" v-if="user">
        <div class="user-info" style="margin-bottom: 16px">
          <div class="user-img overflow-hidden">
            <span v-if="user.user_link == posts.user_link">
            <router-link
              to="/profile"
              exact
              style="color: black"
              class="m-0"
            >
              <img
                v-if="posts.propicture == 'yes'"
                :src="Storage_URL + posts.user_id + '/profile_picture.jpg?v='+time"
                alt=""
              />
              <img v-else src="../../../assets/images/user-default-new.png" alt="" />
            </router-link>
            </span>
            <span v-else>
             <router-link
              :to="/profile/ + posts.user_link"
              exact
              style="color: black"
              class="m-0"
            >
              <img
                v-if="posts.propicture == 'yes'"
                :src="Storage_URL + posts.user_id + '/profile_picture.jpg?v='+time"
                alt=""
              />
              <img v-else src="../../../assets/images/user-default-new.png" alt=""  />
            </router-link>
            </span>
          </div>
          <div class="user-details mr-auto">
            <span v-if="user.user_link == posts.user_link">
            <router-link
              to="/profile"
              exact
              style="color: black"
              class="m-0"
              >{{ posts.user_name}}</router-link
            >
            </span>
            <span v-else>
            <router-link
              :to="/profile/ + posts.user_link"
             exact
              style="color: black"
              class="m-0"
              >{{ posts.user_name }}</router-link
            >
            </span>
            <p>{{ posts.created_at | formatDate }}</p>
          </div>
          <PostEdit
            :items="posts"
            v-if="user.id == posts.user_id"
            :openTab="openTab"
          ></PostEdit>
        </div>
        <div class="post-content">
          <PostContent :items="posts.caption"></PostContent>
          <div class="post-images">
            <div class="gallery-container">
              <lightbox :items="posts.post_images" class="newPostC"></lightbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import lightbox from "../../ImageGrid/lightbox";
import "../../ImageGrid/lightbox";
import "../../ImageGrid/lightbox.css";
import PostContent from "../../Posts/PostContent";
import PostEdit from "../../Posts/PostEdit";
export default {
  props: {
    posts: {
      type: Object,
    },
    openTab: {
      type: Number,
    },
  },
  name: "All",
  components: {
    lightbox,
    PostContent,
    PostEdit,
  },
  data() {
    return {
      time:this.$time,
      Storage_URL: this.$userProfileStorageURL,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>