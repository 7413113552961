<template>
  <div>
    <ManageUmpires :eventId="eventId" 
    :association_id = "association_id"/>
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary" @click="manageUmpires()" v-if="currentIsAdmin == '1'">Manage Umpires</button>
    </div>
    <div v-if="umpireData.length > 0">
      <b-row class="no-gutters">
        <div class="col-12 products-column">
          <div class="event-card">
            <div class="table-responsive mb-0">
              <b-table
                :fields="fields"
                :items="umpireData"
                hover
                responsive
                class="mt-3 b-datatable cursor-pointer"
              >
              <template v-slot:cell(umpire.email)="data">
                <p v-if="currentIsAdmin == '1' && data.item.umpire && data.item.umpire.email">
                  <span>{{ data.item.umpire.email }}</span>
                </p>
                <p v-else>
                  <span>N/A</span>
                </p>
              </template>

              </b-table>

              <content-placeholders
                :rounded="true"
                v-if="showLoaderUmpire == 1"
              >
                <content-placeholders-heading :img="false" />
              </content-placeholders>
            </div>
          </div>
        </div>
      </b-row>
    </div>
    <div
      v-if="umpireData.length"
      v-observe-visibility="handleScrolledToButtom"
    ></div>
    <div
      class="no-products-in-shop"
      v-if="(!umpireData || umpireData.length === 0) && showLoaderUmpire == 0"
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No Umpire found.</p>
      <p class="info">
        There are no Umpires to display.
      </p>
    </div>

    <div class="products-list-shim p-0 mt-3" v-if="showLoaderUmpire == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
import ManageUmpires from './Modals/ManageUmpires.vue';
// import axios from "axios";
export default {
  components: { ManageUmpires },
  name: "AssociationUmpiresInfo",
  props: {
    eventId: {
      // Define umpires prop to receive the umpire data
      type: Number,
    },
    association_id: {
      // Define umpires prop to receive the umpire data
      type: Number,
    },
    umpireData: {
      type: Array,
    },
    showLoaderUmpire: {
      type: Number,
    },
    umpirelastPage: {
      type: Number,
    },
    umpirepage: {
      type: Number,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  data() {
    return {
      // showLoader: 1,
      // showLoader1: 1,
      // umpirepage : 1,
      // msgLastPageUmpire : 1,
      // umpireData : [],
      fields: [
        { key: "umpire.name", label: "Name", sortable: true },
        { key: "umpire.email", label: "Email", sortable: true },
      ],
    };
  },
  methods: {
    handleScrolledToButtom(isVisible) {
      if (this.umpirepage >= this.umpirelastPage) {
        return;
      }
      if (isVisible) {
        this.$root.$emit("getUmpires"); // Emit event to notify parent component
      }
    },
    manageUmpires() {
      this.$root.$emit("openManageUmpiresModal");
    }
  },
};
</script>

<style></style>
