<template>
  <div class="container-fluid no-pdd">
    <div class="row no-gutters" v-if="products">
      <div
        class="col-12 col-sm-6 col-lg-4 col-xl-3 p-0 pr-sm-3 products-column"
        v-for="product in products"
        :key="product.id"
      >
        <div class="products-wrapper">
          <div class="badge-overlay" v-if="product.avail_status == '1'">
            <span class="top-right badge red">Out of Stock</span>
          </div>
          <!-- <div class="like">
            <img src="../../assets/like-red.png" alt="" />
          </div> -->

          <router-link :to="/product-detail/ + product.guid" exact>
            <div class="product-image">
              <img
                @load="onImageLoad"
                v-if="product.image != null"
                :src="API_URL + product.image"
                alt=""
              />
              <img
                class="product-default-img"
                v-else
                src="../../assets/images/gallery.png"
                alt=""
              />
              <div class="shop-products-img-loader" v-if="showLoader == 1">
                <div class="lds-dual-ring-media"></div>
              </div>
            </div>
            <div class="product-info">
              <h6>{{ product.name }}</h6>
              <span>${{ product.price }}</span>
            </div>
            <div v-if="product.avail_status == null" class="cart-link">
              <div>Add To Cart</div>
            </div>
            <div v-if="product.avail_status == '1'" class="cart-link">
              <div>Notify Me</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- <FlashMessage :position="'right bottom'"></FlashMessage> -->
  </div>
</template>
<script>
// import axios from "axios";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "Products",
  props: {
    products: {
      type: Array,
    },
  },
  data() {
    return {
      API_URL: this.$productImageStorageURL,
      errorMessage: "",
      productQuantity: 1,
      selectedSize: "",
      showLoader: 1,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapState("cart", ["cart"]),
  },
  methods: {
    onImageLoad() {
      this.showLoader = 0;
    },
    ...mapActions({
      addProductToCart: "cart/addProductToCart",
    }),
    // addToCart(item) {
    //   const product = {};
    //   product.products = item;
    //   product.catgories = item.category.parent;
    //   product.subcatgories = item.category;

    //   const formData = new FormData();
    //   formData.append("product_id", product.products.id);
    //   formData.append("product_quantity", this.productQuantity);
    //   formData.append("product_size", this.selectedSize);
    //   axios
    //     .post(process.env.VUE_APP_API_URL + "shop/addToCart", formData)
    //     .then((response) => {
    //       if (response.data.statusCode == 200) {
    //         this.addProductToCart({
    //           product: product,
    //           quantity: this.productQuantity,
    //           size: this.selectedSize,
    //         });
    //         this.flashMessage.success({
    //           title: "Success",
    //           message: response.data.message,
    //           time: 5000,
    //           blockClass: "custom-block-class",
    //         });
    //       } else {
    //         this.flashMessage.error({
    //           title: "Error",
    //           message: response.data.message,
    //           time: 5000,
    //           blockClass: "custom-block-class",
    //         });
    //       }
    //     });
    // },
    // getMeNotify(product) {
    //   axios
    //     .get(
    //       `${process.env.VUE_APP_API_URL}shop/notifyMe?id=${product.id}&user_id=${this.user.id}`,
    //       {}
    //     )
    //     .then((response) => {
    //       if (response.data.statusCode == 200) {
    //         this.flashMessage.success({
    //           title: product.name,
    //           message: response.data.message,
    //           time: 5000,
    //           blockClass: "custom-block-class",
    //         });
    //       } else {
    //         this.flashMessage.error({
    //           title: "Error" || "Error",
    //           message: response.data.message,
    //         });
    //       }
    //     });
    // },
  },
};
</script>
