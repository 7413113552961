<template>
  <div>
    <!-- Edit Model -->
    <div
      class="modal fade bd-example-modal-lg"
      id="editProfileName"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Edit Profile Name</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalNameEdit"
              @click="closeOldDiv"
            >
              <img src="../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="team-name-type">
                <div class="row no-gutters">
                  <div class="form-group col-sm-3 pl-0 pr-sm-3">
                    <select
                      class="form-control form-control-lg"
                      id="exampleFormControlSelect1"
                      v-model="details.suffix"
                      @change="errorNull()"
                    >
                      <option value="" selected disabled>Select Suffix</option>
                      <option value="Mr.">Mr</option>
                      <option value="Mrs.">Mrs</option>
                      <option value="Miss.">Miss</option>
                      <option value="Ms.">Ms</option>
                      <option value="Dr.">Dr</option>
                      <option value="Sr.">Sr</option>
                      <option value="Jr.">Jr</option>
                    </select>
                  </div>
                  <div class="form-group col-sm-9 p-0">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      required
                      v-model.trim="$v.details.firstName.$model"
                      :class="{
                        'is-invalid': validationStatus($v.details.firstName),
                      }"
                      v-on:keyup="errorNull()"
                    />
                    <span class="placeholder">First Name</span>
                    <div
                      v-if="!$v.details.firstName.required"
                      class="invalid-tooltip"
                    >
                      First name is required
                    </div>
                    <div
                      v-if="!$v.details.firstName.maxLength"
                      class="invalid-tooltip"
                    >
                      Maximum 18 characters allowed
                    </div>
                  </div>
                  <div class="form-group col-sm-12 p-0">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      required
                      v-model="details.middleName"
                      v-on:keyup="errorNull()"
                    />
                    <span class="placeholder">Middle Name</span>
                  </div>
                  <div class="form-group col-sm-12 p-0">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      required
                      v-model.trim="$v.details.lastName.$model"
                      :class="{
                        'is-invalid': validationStatus($v.details.lastName),
                      }"
                      v-on:keyup="errorNull()"
                    />
                    <span class="placeholder">Last Name</span>
                    <div
                      v-if="!$v.details.lastName.required"
                      class="invalid-tooltip"
                    >
                      Last name is required
                    </div>
                    <div
                      v-if="!$v.details.lastName.maxLength"
                      class="invalid-tooltip"
                    >
                      Maximum 18 characters allowed
                    </div>
                  </div>
                  <div class="form-group col-sm-12 p-0">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      required
                      v-model="details.NickName"
                      v-on:keyup="errorNull()"
                    />
                    <span class="placeholder">Nick Name</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="event-btns-wrapper justify-content-end p-3">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              @click="updateNameFunction(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { required, maxLength } from "vuelidate/lib/validators";
import * as firestoreService from "../../database/firestore";
export default {
  name: "updateName",
  props: {
    items: {
      type: Object,
    },
  },

  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      details: {
        firstName: "",
        lastName: "",
        middleName: "",
        NickName: "",
        suffix: "",
      },
    };
  },
  validations: {
    details: {
      firstName: { required, maxLength: maxLength(18) },
      lastName: { required, maxLength: maxLength(18) },
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    submitSuffix() {
      return this.details.suffix === "Select Suffix" ? "" : this.details.suffix;
    },
  },

  methods: {
    closeOldDiv: function () {
      this.$root.$emit("closeEditProfileModal");
      if (this.user) {
        this.details.firstName = this.user.fname;
        this.details.lastName = this.user.lname;
        this.details.middleName = this.user.middle_name;
        this.details.suffix = this.user.suffix;
        this.details.NickName = this.user.nick_name;
      }
    },
    getValues() {
      if (this.user) {
        this.details.firstName = this.user.fname;
        this.details.lastName = this.user.lname;
        this.details.middleName = this.user.middle_name;
        this.details.suffix = this.user.suffix
          ? this.user.suffix
          : "Select Suffix";
        this.details.NickName = this.user.nick_name;
      }
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    errorNull: function () {
      if (this.$v.$invalid) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    ...mapActions("auth", ["getUserData"]),
    updateNameFunction: function (index) {
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append(
        "firstName",
        this.details.firstName ? this.details.firstName : ""
      );
      formData.append(
        "lastName",
        this.details.lastName ? this.details.lastName : ""
      );
      formData.append("suffix", this.submitSuffix);
      formData.append(
        "middleName",
        this.details.middleName ? this.details.middleName : ""
      );
      formData.append(
        "NickName",
        this.details.NickName ? this.details.NickName : ""
      );
      formData.append(
        "zip_code",
        this.details.zip_code ? this.details.zip_code : ""
      );
      return axios
        .post(process.env.VUE_APP_API_URL + "user/nameUpdate", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (response) => {
          if (response.data.statusCode == 200) {
            var fame = this.details.firstName ? this.details.firstName : "";
            var lame = " " + this.details.lastName ? this.details.lastName : "";
            var user_Name = fame + " " + lame;
            if (this.user && this.user.id) {
              const promiseUser = await firestoreService.getUserCid(
                this.user.id.toString()
              );
              firestoreService.updateUser(promiseUser.data[0].id, {
                username: user_Name,
              });
              var elem = this.$refs.closeModalNameEdit;
              elem.click();
              this.getUserData();
              this.$set(this.isLoadingArray, index, false);
            } else {
              setTimeout(() => {
                this.$set(this.isLoadingArray, index, false);
                this.errorMessage = response.data.message;
              }, 500);
            }
          } else {
            console.error("User or user id is undefined.");
          }
        });
    },
  },
  mounted: function () {
    if (this.user) {
      this.details.dob = this.user.dob;
      this.details.firstName = this.user.fname;
      this.details.lastName = this.user.lname;
      this.details.suffix = this.user.suffix;
      this.details.mobCode = this.details.mobCode
        ? this.details.mobCode
        : this.user.mob_code;
      this.details.dob = this.user.dob;
      this.details.city = this.user.city;
      this.details.hometown = this.user.hometown;
      this.details.mob = this.user.mob;
      if (this.user.gender != null) {
        this.details.gender = this.user.gender;
      } else {
        this.details.gender = "";
      }
      if (this.user.country != null) {
        this.details.country = this.user.country;
      } else {
        this.details.country = "";
      }
      this.details.zip_code = this.user.zip_code;
      this.details.state = this.user.state;
      this.details.address = this.user.address;
    }
    this.$root.$on("updateProfileName", () => {
      this.getValues(1);
      this.$v.details.$reset();
    });
  },
};
</script>
<style scoped>
/* Important part */
.modal {
  overflow-y: initial !important;
}
</style>
