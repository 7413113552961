<template>
  <div>
    <PinModal />
    <div class="media-wrapper">
      <div v-if="showDocShimmer == 1" class="lds-dual-ring-media"></div>
      <div v-if="messages.length != 0 && showDocShimmer == 0">
        <div
          class="link-content"
          v-for="(file, index) in messages"
          :key="index"
        >
          <div class="content-sec" :id="file.id">
            <div class="link-content-img">
              <img src="../../../../../assets/images/shared-files.png" alt="" />
            </div>
            <div>
              <div class="footer">
                <a :href="file.url" target="_blank"
                  ><span>{{ file.name }}.{{ file.extension }} </span></a
                >
                <!-- <span>{{ file.date }}</span> -->
              </div>
              <div class="title">
                <h6>{{ file.username }}</h6>
                <span>{{ messages.size }}</span>
              </div>
            </div>
          </div>
          <div class="add-to-pin" v-if="file.senderId === currentUserId">
            <img
              src="../../../../../assets/images/add-to-pin-2.png"
              alt=""
              @click="callModal(file)"
            />
            <img
                src="../../../../../assets/images/cross.png"
                alt=""
                @click="
                  deleteMessage({
                    message: file,
                    roomId: selectedRoomTeam.roomId,
                  })
                "
              />
          </div>
        </div>
      </div>
      <div
        class="no-content"
        v-else-if="messages.length == 0 && showDocShimmer == 0"
      >
        <div v-if="showTeams == 0">
          <div class="img">
            <img src="../../../../../assets/images/documents.png" alt="" />
          </div>
          <p class="header">No Documents</p>
          <p class="text">
            All file documents shared with this team will appear here.
          </p>
        </div>
        <div v-if="showIndividual == 0">
          <div class="img">
            <img src="../../../../../assets/images/documents.png" alt="" />
          </div>
          <p class="header">No Documents</p>
          <p class="text">
            All file documents shared with this chat will appear here.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as firestoreService from "../../../../../database/firestore";
import { parseTimestamp } from "../../../../../utils/dates";
import PinModal from "./PinModal.vue";
import axios from 'axios';
export default {
  name: "ChatMedia",

  data() {
    return {
      showDocShimmer: 1,
      messagesPerPage: 80,
      messages: [],
      count: 0,
      messagesLoaded: false,
      lastLoadedMessage: null,
      previousLastLoadedMessage: null,
      listeners: [],
      showTeams: 0,
      showIndividual: 1,
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
  },
  components: {
    PinModal,
  },
  methods: {
    async deleteMessage({ message, roomId }) {
      await this.$confirm(
        "Are you sure you want to delete this message, Continue?",
        "Delete Message"
      ).then(() => {
        firestoreService.updateMessage(roomId, message._id, {
          deleted: new Date(),
          
        });

        this.$root.$emit("ChatDocuments");
        if (this.selectedRoomTeam.lastMessage.id == message._id) {
          firestoreService.updateRoom(roomId, { last_message_sent: "" });
        }

        var deleteChatFiles = true;

        var deleteChatLinks = true;

        const { files } = message;

        if (files) {
          files.forEach((file) => {
            if (
              file.type == "image/jpeg" ||
              file.type == "audio/mpeg" ||
              file.type == "video/mp4"
            ) {
              deleteChatFiles = true;
            }

            if (
              file.type == "application/pdf" ||
              file.extension == "docx" ||
              file.type == "text/plain" ||
              file.extension == "sql"
            ) {
              deleteChatFiles = false;
            }
            var formData = new FormData();
            formData.append("files", file.url);
            axios
              .post(
                process.env.VUE_APP_API_URL + "chat/deleteMedia",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(() => {
               
              });
          });
          if (deleteChatFiles == true) {
            this.$root.$emit("ChatMedia");
            this.$root.$emit("ChatPinned");
          } else if (deleteChatFiles == false) {
            this.$root.$emit("ChatDocuments");
            this.$root.$emit("ChatPinned");
          }
        }
        if (deleteChatLinks == true) {
          if (this.validURL(message.content) == true)
            this.$root.$emit("ChatLinks");
          this.$root.$emit("ChatPinned");
        }
      });
    },
    async fetchMessages() {
      firestoreService
        .getMediaMessages(
          this.selectedRoomTeam.roomId,
          this.messagesPerPage,
          this.lastLoadedMessage
        )
        .then(({ data, docs }) => {
          data.forEach((message) => {
            if(message.files && !message.deleted){
            if (message.pinFile == false || message.pinFile == undefined) {
              let imageExt = [
                "jpg",
                "png",
                "PNG",
                "gif",
                "jpeg",
                "mp3",
                "mpeg",
                "mp4",
              ];
              message.files.forEach((file) => {
                const foundExt = imageExt.find(
                  (ext) => ext === file["extension"]
                );
                if (!foundExt && !file.audio) {
                  const formattedMessage = this.formatMessage(
                    this.selectedRoomTeam,
                    this.count,
                    file,
                    message
                  );
                  this.messages[this.count] = formattedMessage;
                  this.messages = [...this.messages];
                  this.count++;
                }
                const formatBytes = (bytes, decimals = 2) => {
                  if (bytes === 0) {
                    return "0 Bytes";
                  }
                  const k = 1024;
                  const dm = decimals < 0 ? 0 : decimals;
                  const sizes = [
                    "Bytes",
                    "KB",
                    "MB",
                    "GB",
                    "TB",
                    "PB",
                    "EB",
                    "ZB",
                    "YB",
                  ];
                  const i = Math.floor(Math.log(bytes) / Math.log(k));
                  return (
                    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
                    " " +
                    sizes[i]
                  );
                };

                this.messages.size = formatBytes(file.size);
              });
            }
          }
          });
          this.showDocShimmer = 0;
          if (this.lastLoadedMessage) {
            this.previousLastLoadedMessage = this.lastLoadedMessage;
          }
          this.lastLoadedMessage = docs[docs.length - 1];
        });
    },

    formatMessage(room, count, file, message) {
      const formattedMessage = {
        ...file,
        ...{
          c_id: count,
          senderId: message.sender_id,
          _id: message.id,
          seconds: message.timestamp.seconds,
          timestamp: parseTimestamp(message.timestamp, "HH:mm"),
          date: parseTimestamp(message.timestamp, "DD MMMM YYYY"),
          username: room.users.find((user) => message.sender_id === user._id)
            ?.username,
          avatar: room.users.find((user) => message.sender_id === user._id)
            ?.avatar,
          distributed: true,
        },
      };
      return formattedMessage;
    },

    callModal: function (data_info) {
      const file_name = data_info.name;
      const file_extension = data_info.extension;
      this.$root.$emit("openPinModal", file_name, file_extension,null,this.selectedRoomTeam, data_info._id);
    },
  },
  mounted() {
    this.fetchMessages();
    this.$root.$on("ChatDocuments", () => {
      this.messages = [];
      this.count = 0;
      this.messagesLoaded = false;
      this.lastLoadedMessage = null;
      this.previousLastLoadedMessage = null;
      this.listeners = [];
      this.showDocShimmer = 1;
      this.fetchMessages();
    });
    if (localStorage.getItem("openTabChat") == "Individual") {
      this.showIndividual = 0;
      this.showTeams = 1;
    } else {
      this.showIndividual = 1;
      this.showTeams = 0;
    }
  },
};
</script>
<style scoped>
.add-to-pin-icons[data-v-4336a3e0] {
display: flex;
    align-items: center;
   
}

.add-to-pin-icons img {
  margin-right: 10px; /* Adjust as needed */
  /* background-color: white; */
}

</style>

