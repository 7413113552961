<template>
  <div class="teams-sec">
    <ul class="chat-selection">
      <li>
        <a
          class="text-capitalize active"
          >{{ selectedRoomTeam.teamName }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TeamSelect",
  data: function () {
    return {
      homeActiveTeam: "active",
      visitorActiveTeam: "",
    };
  },
  props: {
    visitTeamName: {
        type: String
    },
    homeTeamName: {
        type: String
    },
    selectedRoomTeam: {
      type: Object,
    },
  },
  methods: {
    showHomeTeam: function () {
      this.homeActiveTeam = "active";
      this.visitorActiveTeam = "";
    },
    showVisitorTeam: function () {
      this.visitorActiveTeam = "active";
      this.homeActiveTeam = "";
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 900px) {
.chat-selection {
    min-width: 100%!important;
}
}
</style>