<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#proAndSuperInvites"
      ref="openModalUserInvite"
      @click="dataReset()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="proAndSuperInvites">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">
              Invite Friend to join Who I Follow as
              <span
                class=""
                v-if="subscribedInfo && subscribedInfo.pack_type == 1"
                >Pro/Super Users</span
              >
              <span
                class=""
                v-else-if="subscribedInfo && subscribedInfo.pack_type == 3"
                >Pro Users</span
              >
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalUserInvite"
              @click="showscroll()"
            >
              <img src="../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <p v-if="errorMessage != null" class="tag-line" style="color: red">
              {{ errorMessage }}
            </p>
            <p v-else class="tag-line mb-3">Let's invite your friends.</p>
            <div class="col-12 col-md-12 px-0 pl-lg-0 pb-2">
              <div class="form-group">
                <label class="input-wrapper" for="ajaxsingle"
                  >Select Package</label
                >
                <select
                  class="form-control form-control-lg"
                  id="singlePackage"
                  v-model="singlePackage"
                  @change="errorNull"
                >
                  <option value="" selected disabled>Select Package </option>
                  <option
                    class="text-capitalize"
                    :value="pack"
                    v-for="pack in packagesList"
                    :key="pack.id"
                  >
                    {{ pack.pack_name }} (${{ pack.price }})
                  </option>
                </select>
              </div>
            </div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="home-tab"
                  ref="mydetailTab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  @click="selectMethod('phoneOrEmail')"
                  >Via Mobile/Email</a
                >
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  id="friends-tab"
                  data-toggle="tab"
                  href="#friends"
                  ref="friends"
                  role="tab"
                  aria-controls="friends"
                  aria-selected="false"
                  @click="selectMethod('addFriend')"
                  >Add Friends</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active mt-3 text-center"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div class="add-link">
                  <form>
                    <div class="d-md-flex align-items-center mt-2">
                      <div class="phone-code mb-2">
                        <b-form-select v-model="code" :options="codeList">
                          <template #first> </template>
                        </b-form-select>
                      </div>

                      <div class="form-group phone-number">
                        <div class="input-wrapper">
                          <input
                            id="mob"
                            class="form-control"
                            v-model.trim="$v.details.mob.$model"
                            spellcheck="false"
                            autocomplete="off"
                            :class="{
                              'is-invalid': validationStatus($v.details.mob),
                            }"
                            trim
                            type="text"
                            v-on:keyup="errorNull"
                            required
                          />

                          <span class="placeholder">Mobile Number</span>
                          <div
                            v-if="!$v.details.mob.integer"
                            class="invalid-tooltip"
                          >
                            Mobile number should be numbers.
                          </div>
                          <div
                            v-else-if="!$v.details.mob.minLength"
                            class="invalid-tooltip"
                          >
                            Minimum length should be 10.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center mb-2">OR</div>

                    <div class="form-group">
                      <div class="input-wrapper">
                        <input
                          id="email"
                          v-model.trim="$v.details.email.$model"
                          spellcheck="false"
                          autocomplete="off"
                          :class="{
                            'is-invalid': validationStatus($v.details.email),
                          }"
                          trim
                          required
                          class="form-control"
                          type="text"
                          v-on:keyup="errorNull"
                        />

                        <span class="placeholder">Email</span>
                        <div
                          v-if="!$v.details.email.email"
                          class="invalid-tooltip"
                        >
                          Please provide valid email
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="event-btns-wrapper justify-content-end">
                  <button
                    v-if="isDisabled == false"
                    type="button"
                    class="btn btn-primary w-100"
                    :disabled="isLoadingArray[0]"
                    @click="sendInvitesFriends(0)"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Send</span>
                  </button>
                  <button
                    v-else-if="isDisabled == true"
                    type="button"
                    class="btn btn-primary-disabled w-100"
                    :disabled="(true, isLoadingArray[0])"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Send</span>
                  </button>
                </div>
              </div>
              <div
                class="tab-pane fade mt-3"
                id="friends"
                role="tabpanel"
                aria-labelledby="friends-tab"
              >
                <form class="add-link">
                  <div class="member-select mt-2">
                    <label class="typo__label" for="ajax">Select friends</label>
                    <multiselect
                      v-model="selectedUsers"
                      id="ajax"
                      label="user_name"
                      track-by="id"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="addMultipleUsers"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="false"
                      :clear-on-select="false"
                      :options-limit="300"
                      :limit="100"
                      :limit-text="limitText"
                      :max-height="600"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="asyncFind"
                      @input="errorNullMember"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <div
                            class="custom__content"
                            @click="deselectFirstOption"
                          >
                            <div class="user-image">
                              <img
                                v-if="option.picture !== 'yes'"
                                src="../../assets/images/user-default-new.png"
                                alt=""
                              />
                              <img
                                v-else
                                :src="
                                  API_URL +
                                    option.id +
                                    '/profile_picture.jpg?v=' +
                                    time
                                "
                                alt=""
                                style="
                                  width: 40px;
                                  height: 40px;
                                  background-size: cover;
                                  background-repeat: no-repeat;
                                  background-position: center;
                                  object-fit: cover;
                                  border-radius: 100px;
                                "
                              />
                            </div>
                            <span class="user__name">
                              {{ option.user_name }}</span
                            >
                          </div>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          ></span
                        ></template
                      >
                      <template slot="clear" slot-scope="props">
                        <div
                          class="multiselect__clear"
                          v-if="selectedUsers"
                          @mousedown.prevent.stop="clearAll(props.search)"
                        ></div> </template
                      ><span slot="noResult"
                        >Oops! No elements found. Consider changing the search
                        query.</span
                      >
                      <span slot="noOptions"> No User Found </span>
                      <template slot="option" slot-scope="{ option }">
                        <div class="option__desc">
                          <div class="user-image">
                            <img
                              v-if="option.picture !== 'yes'"
                              src="../../assets/images/user-default-new.png"
                              alt=""
                            />
                            <img
                              v-else
                              :src="
                                API_URL +
                                  option.id +
                                  '/profile_picture.jpg?v=' +
                                  time
                              "
                              alt=""
                              style="
                                width: 40px;
                                height: 40px;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                object-fit: cover;
                                border-radius: 100px;
                              "
                            />
                          </div>
                          &nbsp;<span class="text-capitalize">
                            {{ option.user_name }}</span
                          >
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </form>
                <div class="event-btns-wrapper justify-content-end">
                  <button
                    v-if="isDisabledMmeber == false"
                    type="button"
                    class="btn btn-primary w-100 mt-3"
                    :disabled="isLoadingArrayMember[0]"
                    @click="sendInvitesFriends(0)"
                  >
                    <div
                      v-if="isLoadingArrayMember[0]"
                      class="lds-dual-ring"
                    ></div>
                    <span v-else>Send Invite</span>
                  </button>
                  <button
                    v-else-if="isDisabledMmeber == true"
                    type="button"
                    class="btn btn-primary-disabled w-100 mt-3"
                    :disabled="(true, isLoadingArrayMember[0])"
                  >
                    <div
                      v-if="isLoadingArrayMember[0]"
                      class="lds-dual-ring"
                    ></div>
                    <span v-else>Send Invite</span>
                  </button>
                </div>
              </div>
              <div class="join-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { email, minLength, integer, required } from "vuelidate/lib/validators";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "InviteModal",
  props: {
    packagesList: Array,
  },
  data() {
    return {
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      selectedMethod: "phoneOrEmail",
      singlePackage: "",
      isLoading: false,
      isDisabledMmeber: true,
      selectedUsers: [],
      isDisabled: true,
      errorMessage: null,
      errorMessageEmail: null,
      successMessage: null,
      isLoadingArray: [],
      isLoadingArrayMember: [],
      addMultipleUsers: [],
      code: "+1",
      details: {
        mob: "",
        email: "",
      },
      codeList: [
        { value: "+92", text: "Pak (+92)" },
        { value: "+1", text: "USA (+1)" },
      ],
    };
  },
  validations: {
    singlePackage: required,
    details: {
      email: { email },
      mob: { integer, minLength: minLength(10) },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("auth", ["subscribedInfo"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
    this.$root.$on("openInviteUserModal", () => {
      var elem = this.$refs.openModalUserInvite;
      elem.click();
      this.asyncFind();
    });
  },
  components: {
    Multiselect,
  },
  methods: {
    ...mapActions("auth", ["sendRegisterRequest"]),
    deselectFirstOption() {
      // Assuming there is at least one option selected
      if (this.selectedUsers.length > 0) {
        // Deselect the first option
        this.deselectOption(this.selectedUsers[0]);
      }
    },
    validationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    limitText(count) {
      return `and ${count} others`;
    },
    asyncFind(searchQuery) {
      this.isLoading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}friends/friendList?search=${
            searchQuery ? searchQuery : ""
          }`,
          this.details,
          {}
        )
        .then((response) => {
          this.addMultipleUsers = response.data.data;
          this.isLoading = false;
        });
    },
    selectMethod(method) {
      this.selectedMethod = method;
    },

    errorNullMember() {
      if (this.selectedUsers) {
        this.isDisabledMmeber = false;
      } else {
        this.isDisabledMmeber = true;
      }
    },
    sendInvitesFriends: function(index) {
      // Disabling button and validating form
      this.$set(this.isLoadingArray, index, true);
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return;
      if (!this.singlePackage) {
        const errorMessage = "Please select a package.";
        this.$alert(errorMessage);
        return;
      }

      // Setting loading state and preparing form data
      this.$set(this.isLoadingArrayMember, index, true);
      var formData = new FormData();
      var UrlCall = "";

      if (this.selectedMethod === "addFriend") {
        // Logic for adding friends scenario
        if (
          this.subscribedInfo &&
          this.subscribedInfo.pack_type == 3 &&
          this.singlePackage.pack_type == 2
        ) {
          formData.append("pro_user_id", this.selectedUsers.id);
          formData.append("package_id", this.singlePackage.id);
          UrlCall = "invite/inviteProUser";
        } else if (
          this.subscribedInfo &&
          this.subscribedInfo.pack_type == 1 &&
          this.singlePackage.pack_type == 3
        ) {
          formData.append("super_user_id", this.selectedUsers.id);
          formData.append("package_id", this.singlePackage.id);
          UrlCall = "invite/inviteSuperUser";
        } else if (
          this.subscribedInfo &&
          this.subscribedInfo.pack_type == 1 &&
          this.singlePackage.pack_type == 2
        ) {
          formData.append("pro_user_id", this.selectedUsers.id);
          formData.append("package_id", this.singlePackage.id);
          UrlCall = "invite/inviteProUser";
        }
      } else if (this.selectedMethod === "phoneOrEmail") {
        // Logic for phone/email scenario
        let target = "";
        if (this.details.mob == "" && this.details.email == "") {
          const errorMessage = "Please provide an email or Mobile number.";
          this.$alert(errorMessage);
        }
        if (this.details.email !== "") {
          if (
            this.subscribedInfo &&
            this.subscribedInfo.pack_type == 3 &&
            this.singlePackage.pack_type == 2
          ) {
            target = this.details.email.toLowerCase();
            formData.append("target", target);
            formData.append("package_id", this.singlePackage.id);
            UrlCall = "invite/inviteProUser";
          } else if (
            this.subscribedInfo &&
            this.subscribedInfo.pack_type == 1 &&
            this.singlePackage.pack_type == 3
          ) {
            target = this.details.email.toLowerCase();
            formData.append("target", target);
            formData.append("package_id", this.singlePackage.id);
            UrlCall = "invite/inviteSuperUser";
          } else if (
            this.subscribedInfo &&
            this.subscribedInfo.pack_type == 1 &&
            this.singlePackage.pack_type == 2
          ) {
            target = this.details.email.toLowerCase();
            formData.append("target", target);
            formData.append("package_id", this.singlePackage.id);
            UrlCall = "invite/inviteProUser";
          }
        } else if (this.details.mob !== "" && this.code !== "") {
          if (
            this.subscribedInfo &&
            this.subscribedInfo.pack_type == 3 &&
            this.singlePackage.pack_type == 2
          ) {
            target = this.code + this.details.mob;
            formData.append("target", target);
            formData.append("package_id", this.singlePackage.id);
            UrlCall = "invite/inviteProUser";
          } else if (
            this.subscribedInfo &&
            this.subscribedInfo.pack_type == 1 &&
            this.singlePackage.pack_type == 3
          ) {
            target = this.code + this.details.mob;
            formData.append("target", target);
            formData.append("package_id", this.singlePackage.id);
            UrlCall = "invite/inviteSuperUser";
          } else if (
            this.subscribedInfo &&
            this.subscribedInfo.pack_type == 1 &&
            this.singlePackage.pack_type == 2
          ) {
            target = this.code + this.details.mob;
            formData.append("target", target);
            formData.append("package_id", this.singlePackage.id);
            UrlCall = "invite/inviteProUser";
          }
        } else {
          return; // Exit if no valid scenario found
        }
      }

      // Sending API request
      return axios
        .post(process.env.VUE_APP_API_URL + UrlCall, formData, {})
        .then((response) => {
          // Handling API response
          if (response.data.statusCode == 200) {
            // Successful response
            this.details.mob = "";
            this.details.email = "";
            this.singlePackage = "";
            this.code = "+1";
            this.successMessage = response.data.message;
            this.$alert(this.successMessage);
            var elem = this.$refs.closeModalUserInvite;
            elem.click();
            this.$root.$emit("callInvitesApi");
            this.isDisabled = false;
          } else {
            this.$set(this.isLoadingArray, index, false);
            // Error response
            if (response.data.message == "Email Already Registered") {
              this.errorMessageEmail = response.data.message;
            } else {
              this.errorMessage = response.data.message;
            }
          }

          // Resetting loading state
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
          }, 500);
        })
        .catch(() => {
          this.details.email = "";
          // this.showLoaderSend = 0;
          this.$alert("Failed to send the invitation.Please try again later.");
          this.$set(this.isLoadingArray, index, false);
        })
        .finally(() => {
          this.$set(this.isLoadingArray, index, false);
        });
    },

    errorNull: function() {
      this.errorMessage = null;
      this.errorMessageEmail = null;
      if (
        (this.details.mob == "" &&
          this.details.email == "" &&
          this.singlePackage == null) ||
        this.$v.$invalid
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },

    dataReset: function() {
      this.errorMessage = null;
      this.errorMessageEmail = null;
      this.details.mob = "";
      this.details.email = "";
      this.code = "+1";
      this.isDisabled = true;
      this.singlePackage = "";
      this.isLoadingArrayMember = [];
      this.selectedUsers = [];
    },

    showscroll: function() {
      // document.querySelector("body").style.overflow = "visible";
    },
  },
};
</script>
