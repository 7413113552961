<template>
  <div class="see-all-seen-post-wrapper">
    <p>
      See all post of <span>'{{ searchText }}'</span>
    </p>
  </div>
</template>
<script>
export default {
  name: "AllSeenPost",
  props: {
    searchText: {
      type: String,
    },
  },
};
</script>
<style scoped>
</style>