<template>
  <div>
    <Navbar />
    <InviteFriendsModal />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side p-3">
        <div class="row no-gutters">
          <div class="col-12">
            <CoverPhoto />
            <ChangeCover />
          </div>
        </div>
        <div class="row align-items-center cover-bottom">
          <div class="col-12 col-md-3 col-lg-3">
            <div class="profile-photo-section">
              <ProfilePhoto />
            </div>
          </div>
          <div class="col-6 col-md-5 col-lg-7">
            <div class="profile-name-section">
              <Username />
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-2">
            <div class="profile-edit-section">
              <EditProfile />
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-12">
            <FriendsOptions
              :friendsList="friendsList"
              :followerListCount="followerListCount"
              :followingListCount="followingListCount"
              :friendsRequestscount="friendsRequestscount"
              :friendsRequestsSendcount="friendsRequestsSendcount"
              :showLoader="showLoader"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-observe-visibility="handleScrolledToButtom"
      v-if="friendsList.length"
    ></div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import CoverPhoto from "../components/ProfilePage/CoverPhoto.vue";
import ChangeCover from "../components/ProfilePage/ChangeCover.vue";
import ProfilePhoto from "../components/ProfilePage/ProfilePhoto.vue";
import Username from "../components/ProfilePage/Username.vue";
import EditProfile from "../components/ProfilePage/EditProfile.vue";
import FriendsOptions from "../components/Friends/FriendsOptions.vue";
import InviteFriendsModal from "../components/Home/InviteFriendsModal.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import axios from "axios";
// import $ from "jquery";

export default {
  name: "FriendsPage",
  components: {
    Navbar,
    LeftMenu,
    CoverPhoto,
    ChangeCover,
    ProfilePhoto,
    Username,
    EditProfile,
    FriendsOptions,
    InviteFriendsModal,
    Banner
  },
  data() {
    return {
      friendsList: [],
      page: 1,
      lastPage: 1,
      followingListCount: 0,
      followerListCount: 0,
      friendsRequestscount: 0,
      showLoader: 0,
      friendsRequestsSendcount: 0,
      user_link: "",
    };
  },
  methods: {
    async allCounts() {
      this.user_link = localStorage.getItem("user_link");
      this.showLoader = 1;
      let allCounts = await axios.get(`allCount?user_link=${this.user_link}`);

      if (allCounts.data.statusCode == 200) {
        this.friendsRequestscount = allCounts.data.data.friendReqcount;
        this.friendsRequestsSendcount = allCounts.data.data.requestSentcount;
        this.followerListCount = allCounts.data.data.followerCount;
        this.followingListCount = allCounts.data.data.followingCount;
        this.showLoader = 0;
      } else {
        this.showLoader = 0;
      }
    },
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.friendShort();
    },

    async friendShort() {
      this.showLoader = 1
      this.user_link = localStorage.getItem("user_link");
      axios
        .get(
          `${process.env.VUE_APP_API_URL}friends/friendList?page=${this.page}&user_link=${this.user_link}`
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.friendsList.push(...response.data.data);
            this.$root.$emit("enableFriendsButton");
            // this.lastPage = response.data.data.last_page;
            // this.friendsList = response.data.data;
            this.showLoader = 0
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },

  mounted() {
    // window.scrollTo(0, 0);
    window.scrollTo(0, document.body.scrollHeight);
    // $(document).scrollTop($(document).height());
    this.allCounts(1);
    this.friendShort();
    this.$root.$on("FriendsPage", () => {
      this.friendsList = [];
      this.friendShort(1);
      this.allCounts(1);
    });

    this.$root.$on("FriendsPageCount", () => {
      this.allCounts(1);
    });
  },
};
</script>
<style>
.navbar-expand-lg .navbar-nav .nav-link {
  padding-bottom: 20px;
}
</style>
