<template>
  <div class="all-event-wrapper">
    <div class="event-top">
      <div class="event-top-content">
        <div @click="closeDiv" class="back-arrow">
          <img src="../../../../assets/images/arrow-left.png" />
        </div>
        <div class="event-title">
          <div class="image-wrapper">
            <div class="users-notification" v-if="imgLoader == 1">
        <img src="../../../../assets/loader.gif" alt="">
    </div>
            <img
            @load="onImageLoad"
              v-if="selectedRoomTeam.teamAvatar == ''"
              src="../../../../assets/images/main-team-avatar.png"
              alt=""
            />
            <img
            @load="onImageLoad"
              v-if="selectedRoomTeam.teamAvatar != ''"
              :src="this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar"
              alt=""
            />
          </div>
          <div class="user-details">
            <p class="team-name" :id="selectedRoomTeam.id">
              {{ selectedRoomTeam.teamName }}
            </p>
            <span>
              Events
            </span>
          </div>
        </div>
      </div>
      <div
        class="create-new-event"
        v-if="currentIsAdmin == '1' && currentTeamIsActive == 1"
      >
        <CreateEventDetailModal
          :selectedRoomTeam="selectedRoomTeam"
          :currentUserId="currentUserId"
          :currentIsAdmin="currentIsAdmin"
        />
      </div>
    </div>
    <div class="all-event-category">
      <ul>
        <li :class="pointActiveUpcomingEvents" @click="showUpcomingEventsList">
          Upnext
          <span class="nav-counter-team">{{
           upComingCounter
          }}</span>
        </li>
        <li :class="pointActivePastEvents" @click="showPastEventsList">
          Past
          <span class="nav-counter-team">{{
            pastCounter
          }}</span>
        </li>
      </ul>
    </div>
    <UpcomingEvents
      :selectedRoomTeam="selectedRoomTeam"
      v-if="showUpcomingEvents == 0"
    />
    <PastEvents
      :selectedRoomTeam="selectedRoomTeam"
      v-if="showPastEvents == 0"
    />
  </div>
</template>
<script>
import PastEvents from "./PastEvents.vue";
import UpcomingEvents from "./UpcomingEvents.vue";
import CreateEventDetailModal from "./CreateEventDetailModal.vue";

export default {
  components: {
    UpcomingEvents,
    PastEvents,
    CreateEventDetailModal,
  },
  name: "EventMain",

  data() {
    return {
      showUpcomingEvents: 0,
      showPastEvents: 1,
      pointActiveUpcomingEvents: "active",
      pointActivePastEvents: "",
      imgLoader: 1,
      upComingCounter:0,
      pastCounter:0
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    showUpcomingEventsList: function() {
      this.showPastEvents = 1;
      this.showUpcomingEvents = 0;
      this.pointActivePastEvents = "";
      this.pointActiveUpcomingEvents = "active";
    },
    showPastEventsList: function() {
      this.showPastEvents = 0;
      this.showUpcomingEvents = 1;
      this.pointActivePastEvents = "active";
      this.pointActiveUpcomingEvents = "";
    },
    closeDiv: function() {
      this.$root.$emit("ChatSettings", 0);
      this.$root.$emit("fetchEvents");
    },
  },

  mounted() {
     this.upComingCounter= this.selectedRoomTeam.upcomingEventsList.length;
     this.pastCounter= this.selectedRoomTeam.pastEventsList.length;
     this.$root.$on("updateCounterEvent", (upComingCounter,pastCounter) => {
     this.upComingCounter=upComingCounter;
     this.pastCounter=pastCounter;
    });
  },
};
</script>
