<template>
  <div>
    <!-- loader -->
    <div class="play-loader" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="2" />
      </content-placeholders>
    </div>

    <div class="play-info" v-if="showLoader == 0">
      <div class="game-play">
        <span>G</span>
        <span>{{ totalGame }}</span>
      </div>
      <div class="game-win">
        <span>W</span>
        <span>{{ win }}</span>
      </div>
      <div class="game-loss">
        <span>L</span>
        <span>{{ loss }}</span>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  name: "Play",
  data() {
    return {
      showLoader: 1,
      totalGame: 0,
      win: 0,
      loss: 0,
    };
  },
  props: {
    singleEvent: {
      type: Object,
    },
  },

  methods: {
    async playRecord() {
      this.showLoader = 1;
      const formData = new FormData();

      if (this.singleEvent.id) {
        formData.append("event_id", this.singleEvent.id);
      }
      let report = await axios.post("game/statsAgainstEvent", formData);

      if (report.data.statusCode == 200) {
        this.totalGame = report.data.data.total_games;
        this.win = report.data.data.win;
        this.loss = report.data.data.lost;
        this.showLoader = 0;
      } else {
        this.showLoader = 0;
      }
    },
  },
  mounted() {
    this.playRecord();
  },
};
</script>