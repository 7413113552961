<template>
  <div>
    <GoPayment />
    <div class="card-row" v-if="SubFee">
      <div
        class="card-col"
        v-for="product in SubFee"
        :key="product.id"
      >
        <div class="pro-wrapper unlock-features">
          <div class="mt-1">
            <h2>{{ product.pack_name }}</h2>
          </div>
          <div class="go-pro-img">
            <img src="../../assets/go-pro.png" alt="Go Pro" />
          </div>
          <div class="mb-1 amount-per-year">
            <span>${{ product.price }}</span
            ><span>/ year</span>
          </div>
          <h6 class="text-center mb-4 description">
            {{ product.description }}
          </h6>
          <div
            v-if="showLoader == 0 && SubFee == 0"
            class="amount-per-year info-heading"
          >
            <p>No subscription plan added yet.</p>
          </div>
          <!-- <div class="add-to-cart"> -->
          <button v-if="SubFee != 0" type="button" @click="callModal(product)">
            Subscribe Now
          </button>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="row" v-if="showLoader == 0 && SubFee == 0">
      <div class="col-lg-4"></div>
      <div
        class="col-12 col-md-6 col-lg-4 p-3 unlock-features unlock-features-1 col-md-offset-2"
      >
        <div class="pro-wrapper">
          <div class="go-pro-img">
            <img src="../../assets/go-pro.png" alt="Go Pro" />
          </div>
          <h6 class="text-center">No subscription added yet.</h6>
        </div>
      </div>
      <div class="col-lg-4"></div>
    </div>

    <div class="row no-gutters" v-if="showLoader == 1">
      <div class="col-12 col-md-6 pr-md-1">
        <div class="products-list-shim">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
            <content-placeholders-text :lines="3" />
          </content-placeholders>
        </div>
      </div>
      <div class="col-12 col-md-6 pl-md-2">
        <div class="products-list-shim">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
            <content-placeholders-text :lines="3" />
          </content-placeholders>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoPayment from "./GoPayment.vue";
import axios from "axios";

export default {
  name: "GoSubscribe",
  components: {
    GoPayment,
  },
  data() {
    return {
      SubFee: {},
      timeSpan: 0,
      showLoader: 0,
    };
  },
  methods: {
    callModal(product) {
      this.$root.$emit("openGoPayment", product);
    },
    getSubFee() {
      this.showLoader = 1;
      axios
        .get(`${process.env.VUE_APP_API_URL}getSubscriptionFee`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.showLoader = 0;
            this.SubFee = response.data.data.prosub;
            this.timeSpan = response.data.data.prosub[0].time_span;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
  },
  mounted() {
    this.getSubFee();
  },
};
</script>

<style></style>
