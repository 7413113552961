<template>
  <div>
    <EditStats />
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active position-relative teams-tables"
        id="visitorBatting"
        role="tabpanel"
        aria-labelledby="home-batting-tab"
      >
        <div
          class="history-table position-relative"
          v-if="gameLineUps.length > 0 && showLoader == 0"
        >
          <table class=" table table-sm">
            <thead>
              <tr>
                <th scope="col" class="text-left">Lineup</th>

                <th scope="col" v-if="!showBase">On Base %</th>
                <th scope="col">Average</th>
                <th scope="col">AB</th>
                <th scope="col">H</th>
                <th scope="col">1B</th>
                <th scope="col">2B</th>
                <th scope="col">3B</th>
                <th scope="col">HR</th>
                <th scope="col">RBI</th>
                <th scope="col">R</th>
                <th scope="col">BB</th>
                <th scope="col">SAC</th>
                <!-- <th scope="col">Solo HR</th> -->
                <th scope="col">E</th>
                <th scope="col" v-if="currentIsAdmin == '1'"></th>
              </tr>
            </thead>
            <tbody> 
              <tr v-for="(user) in sortedGameLineUps" :key="user.user_id">
                <td>
                  <div>
                    <small>{{
                      user.position[0] ? user.position[0].position_name : ""
                    }}</small>
                    <div class="shirt-no">
                      <img src="../../../assets/images/shirt.png" alt="Image" />
                      <span>{{
                        user.userRole ? user.userRole.uniform : 0
                      }}</span>
                    </div>
                    <span class="gameline_user">{{ user.username }}</span>
                  </div>
                </td>
                <td v-if="showBase">
                  {{ user.on_base_avg !== null ? user.on_base_avg : "-" }}
                </td>
                <td v-if="!showBase">
                  {{ user.on_base_avg !== null ? user.on_base_avg : "-" }}
                </td>
                <td v-if="!showBase">
                  {{ user.average !== null ? user.average : "-" }}
                </td>
                <td>{{ user.ab }}</td>
                <td>{{ user.h }}</td>
                <td>{{ user.one_b }}</td>
                <td>{{ user.two_b }}</td>
                <td>{{ user.three_b }}</td>
                <td>{{ user.hr }}</td>
                <td>{{ user.rbi }}</td>
                <td>{{ user.r }}</td>
                <td>{{ user.bb }}</td>
                <td>{{ user.sac }}</td>
                <!-- <td>{{ user.solo_hr }}</td> -->
                <td>{{ user.e }}</td>
                <td v-if="currentIsAdmin == '1'">
                  <div>
                    <div
                      class="shirt-no cursor-pointer"
                      @click="callModal(user)"
                    >
                      <img src="../../../assets/images/edit.png" alt="Image" />
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="text-left text-bold bold-make">
                  <div>Game Total</div>
                </td>
                <td class="bold-make" v-if="showBase">
                  {{ totalStats.on_base_avg }}
                </td>
                <td class="bold-make" v-if="!showBase">
                  {{ totalStats.on_base_avg }}
                </td>
                <td class="bold-make" v-if="!showBase">
                  {{ totalStats.average }}
                </td>
                <td class="bold-make">{{ totalStats.ab }}</td>
                <td class="bold-make">{{ totalStats.h }}</td>
                <td class="bold-make">{{ totalStats.one_b }}</td>
                <td class="bold-make">{{ totalStats.two_b }}</td>
                <td class="bold-make">{{ totalStats.three_b }}</td>
                <td class="bold-make">{{ totalStats.hr }}</td>
                <td class="bold-make">{{ totalStats.rbi }}</td>
                <td class="bold-make">{{ totalStats.r }}</td>
                <td class="bold-make">{{ totalStats.bb }}</td>
                <!-- <td class="bold-make">{{ getTotal("solo_hr") }}</td> -->
                <td class="bold-make">{{ totalStats.sac }}</td>
                <td class="bold-make">{{ totalStats.e }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="lds-dual-ring-media" v-if="showLoader == 1"></div>
        <!-- <div
          class="single-box-score-wrapper teams-tables"
          v-if="showLoader == 1"
        >
          <div class="">
            <br />
            <div class="container-fluid p-0">
              <content-placeholders :rounded="true">
                <content-placeholders-heading />
              </content-placeholders>
              <br />
              <br />
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="true" />
              </content-placeholders>
              <br />
              <br />
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="true" />
              </content-placeholders>
              <br />
              <br />
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="true" />
              </content-placeholders>
              <br />
              <br />
              <content-placeholders :rounded="true">
                <content-placeholders-heading />
              </content-placeholders>
            </div>
          </div>
        </div> -->
        <!-- <div
          class="teams-tables teams-tables-2 mt-1"
          v-if="gameLineUps.length > 0 && showLoader == 0"
        >
          <table class="table table-sm">
            <tbody>
              <tr>
                <td class="text-left text-bold"><div>Game Total</div></td>
                <td class="">{{ getTotal("ab") }}</td>
                <td class="">{{ getTotal("h") }}</td>
                <td class="">{{ getTotal("one_b") }}</td>
                <td class="">{{ getTotal("two_b") }}</td>
                <td class="">{{ getTotal("three_b") }}</td>
                <td class="">{{ getTotal("hr") }}</td>
                <td class="">{{ getTotal("rbi") }}</td>
                <td class="">{{ getTotal("r") }}</td>
                <td class="">{{ getTotal("bb") }}</td>
                <td class="">{{ getTotal("sac") }}</td>
                <td class="">{{ getTotal("e") }}</td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <div
          class="no-line-up"
          v-if="showLoader == 0 && gameLineUps.length == 0"
        >
          <div class="img">
            <img src="../../../assets/images/games.png" alt="" />
          </div>
          <p class="text">No lineup found.</p>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="visitorBatting"
        role="tabpanel"
        aria-labelledby="home-pitching-tab"
      >
        home Pitching
      </div>
      <div
        class="tab-pane fade"
        id="homeFielding"
        role="tabpanel"
        aria-labelledby="home-fielding-tab"
      >
        home Fielding
      </div>
    </div>

    <!-- <div class="tab-content" id="myTabContent" v-if="visitorTeam == 0">
      <div
        class="tab-pane fade show active"
        id="visitorBatting"
        role="tabpanel"
        aria-labelledby="visitor-batting-tab"
      >
        <div class="teams-tables">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col" class="text-left">Lineup</th>
                <th scope="col">PA</th>
                <th scope="col">AB</th>
                <th scope="col">H</th>
                <th scope="col">1B</th>
                <th scope="col">2B</th>
                <th scope="col">3B</th>
                <th scope="col">H</th>
                <th scope="col">HR</th>
                <th scope="col">RBI</th>
                <th scope="col">R</th>
                <th scope="col">BB</th>
                <th scope="col">SO</th>
                <th scope="col">K-L</th>
                <th scope="col">HBP</th>
                <th scope="col">SAC</th>
                <th scope="col">SF</th>
                <th scope="col">ROE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>CF</div>
                  <span>Player 1, #1</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>LF</div>
                  <span> Player 2, #63 </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>RF</div>
                  <span> Player 3, #19</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>1B</div>
                  <span>Player 4, #21</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>2B</div>
                  <span>Player 5, #16</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>3B</div>
                  <span>Player 6, #13</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>SS</div>
                  <span>Player 7, #2</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>P</div>
                  <span>Player 8, #4</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>C</div>
                  <span>Player 9, #54</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>EH</div>
                  <span>Player 10, #6</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>EH</div>
                  Player 11, #11
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="visitorPitching"
        role="tabpanel"
        aria-labelledby="visitor-pitching-tab"
      >
        Visitor Pitching
      </div>
      <div
        class="tab-pane fade"
        id="visitorFielding"
        role="tabpanel"
        aria-labelledby="visitor-fielding-tab"
      >
        Visitor Fielding
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import * as firestoreService from "../../../database/firestore";
import EditStats from "./EditStats.vue";
export default {
  name: "TeamsHistory",
  components: { EditStats },
  data: function() {
    return {
      homeTeam: 0,
      visitorTeam: 1,
      gameLineUps: [],
      totalStats: [],
      showLoader: 1,
      showBase: false,
      teamData: {},
      totalNumberOfPlayers: 0,
    };
  },
  props: {
    singleGame: {
      type: Object,
    },
    selectedRoomTeam: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  computed: {
  sortedGameLineUps() {
    return this.gameLineUps.slice().sort((a, b) => a.position_index - b.position_index);
  },
},
  methods: {
    getDivs() {
      this.showBase = !this.showBase;
    },
    getTotal(columnName) {
      // Use reduce to calculate the sum of a specific column
      return this.gameLineUps.reduce(
        (total, item) => total + item[columnName],
        0
      );
    },
    callModal(singleLineup) {
      this.$root.$emit("openeditBattingModal", singleLineup);
    },
    async fetchLineups() {
  this.showLoader = 1;
  var formData = new FormData();
  formData.append("gameId", this.singleGame.id);
  this.$root.$emit("showCanvasLoader", this.showLoader);
  await axios
    .post(process.env.VUE_APP_API_URL + "game/getGameLineups", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      // Populate gameLineUps array with data from the API
      this.gameLineUps = response.data.data.gameLineUps;
      this.totalStats = response.data.data.stats_sums;
      this.totalNumberOfPlayers = response.data.data.gameLineUps.length;
      const userRoles = this.selectedRoomTeam.userRole;
      const rawUsers = [];
      this.getTeamData();

      // Define a function to fetch user data asynchronously
      const fetchUserData = async (userId) => {
        const userFireBase = await firestoreService.getUser(userId);
        rawUsers.push(userFireBase);
      };

      // Fetch user data and merge it with gameLineUps
      (async () => {
        const gameLineUpsWithUserRole = [];

        for (const user of this.gameLineUps) {
          await fetchUserData(user.player_id);

          const userRole = userRoles.find(
            (userRole) => userRole.id === user.player_id
          );
          const userData = rawUsers[rawUsers.length - 1];

          gameLineUpsWithUserRole.push({
            ...user,
            userRole,
            username: userData.username,
          });
        }
        this.gameLineUps = gameLineUpsWithUserRole;
        this.showLoader = 0;
      })();
    });
},

    async fetchLineupsUponemit(game_id) {
      this.showLoader = 1;
      var formData = new FormData();
      this.$root.$emit("showCanvasLoader", this.showLoader);
      formData.append("gameId", game_id);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/getGameLineups", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameLineUps = response.data.data.gameLineUps;

          this.totalStats = response.data.data.stats_sums;
          this.totalNumberOfPlayers = response.data.data.gameLineUps.length;
          const userRoles = this.selectedRoomTeam.userRole;
          const rawUsers = [];
          this.getTeamData();
          // Define a function to fetch user data asynchronously
          const fetchUserData = async (userId) => {
            const userFireBase = await firestoreService.getUser(userId);
            rawUsers.push(userFireBase);
          };

          (async () => {
            const gameLineUpsWithUserRole = [];

            for (const user of this.gameLineUps) {
              await fetchUserData(user.player_id);

              const userRole = userRoles.find(
                (userRole) => userRole.id === user.player_id
              );
              const userData = rawUsers[rawUsers.length - 1];

              gameLineUpsWithUserRole.push({
                ...user,
                userRole,
                username: userData.username,
              });
            }

            this.gameLineUps = gameLineUpsWithUserRole;
            // this.gameLineUps.sort((a, b) => a.position_index - b.position_index);
            this.showLoader = 0;
          })();
        });
    },
    getTeamData() {
      this.showLoader = 1;
      const formData = new FormData();
      formData.append("team_id", this.selectedRoomTeam.id);
      axios
        .post(process.env.VUE_APP_API_URL + "chat/getTeamData", formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.teamData = response.data.data;
            if (
              this.teamData.team &&
              this.teamData.team.show_on_base_avg == 1
            ) {
              this.showBase = true;
            } else if (
              this.teamData.team &&
              this.teamData.team.show_on_base_avg == 0
            ) {
              this.showBase = false;
            }
            this.showLoader1 = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },
  mounted() {
    this.fetchLineups();
    this.$root.$on("HitTeamsHistory", () => {
      this.fetchLineups();
    });
    this.$root.$on("HitEmitTeamsHistory", (id) => {
      this.fetchLineupsUponemit(id);
    });
    this.$root.$on("callLineupAfterUpdate", () => {
      this.fetchLineups();
    });
  },
};
</script>

<style scoped>
.gameline_user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table thead tr th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-info {
  margin: 0px;
  justify-content: space-between !important;
  border-bottom: 1px solid #bfcbd9;
}
@media screen and (max-width: 1465px) and (min-width: 992px) {
  .history-table {
    position: absolute;
    overflow: auto;
  }
}
</style>
