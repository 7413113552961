<template>
    <div>
      <Navbar />
      <div class="setting-main-wrapper">
        <div class="main-left-menu">
          <LeftMenu />
        </div>
        <Banner />
        <div class="shop-side pb-3 pr-3"> 
          <div class="event-card">
                <AllTournamentLiveGame/>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Navbar from "../components/Header/Navbar.vue";
  import Banner from "../components/BannerNotify/BannerPage.vue";
  import LeftMenu from "../components/Home/LeftMenu.vue";
  import AllTournamentLiveGame from "../components/GameTime/AllTournamentLiveGames.vue";
  
  export default {
    components: {
      Navbar,
      LeftMenu,
      Banner,
      AllTournamentLiveGame
      
    },
  
    data: function () {
      return {
       
      };
    },

    methods: {
      
    },
  
    mounted() {
     
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  