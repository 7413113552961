<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#newSponsor"
      ref="openModalNewSponsor"
      @click="dataReset()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="newSponsor">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">New Sponsor</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeNewSponsor"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <form @keyup.enter="saveSponsor(0)" @submit.prevent>
              <div class="profile-preview">
                <div class="profile-photo-edit" @click="openinput()">
                  <!-- <img
                  v-if="user.picture == 'yes'"
                  :src="API_URL + user.id + '/profile_picture.jpg?v=' + time"
                  alt=""
                /> -->
                  <img
                    src="../../../assets/images/sponsor.png"
                    class="user-default"
                    alt=""
                  />

                  <form>
                    <input
                      type="file"
                      id="vue-file-upload-input-sponsor-photo"
                      @change="onFileChange"
                      class="d-none"
                      accept="image/png, image/gif, image/jpeg"
                    />

                    <div id="preview" class="new-profile-photo">
                      <clipper-basic
                        :src="photoUrl"
                        preview="preview"
                        :border="2"
                        :outline="0"
                        :corner="false"
                        :ratio="1"
                        :wrap-ratio="1"
                        ref="clipper"
                      ></clipper-basic>
                    </div>

                    <div class="profile-photo-edit-icon-wrapper">
                      <img src="../../../assets/images/edit.png" alt="" />
                    </div>
                  </form>
                </div>
                <div class="preview-image" >
                  <div v-if="photoUrl">
                    <clipper-preview name="preview"></clipper-preview>
                  </div>
                  <div class="no-image-wrapper" v-else>
                    <div slot="placeholder">No image</div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="name">Sponsor Name</label>
                <input
                  id="name"
                  class="form-control"
                  v-model.trim="$v.details.name.$model"
                  spellcheck="false"
                  autocomplete="off"
                  :class="{
                    'is-invalid': validationStatus($v.details.name),
                  }"
                  trim
                  type="text"
                  required
                  v-on:keyup="errorNull"
                />
                <div v-if="!$v.details.name.required" class="invalid-tooltip">
                  Sponsor name is required
                </div>
                <div v-if="!$v.details.name.maxLength" class="invalid-tooltip">
                  Maximum 18 characters allowed.
                </div>
              </div>
              <div class="form-group">
                <label for="url">Website URL</label>
                <input
                  type="url"
                  class="form-control"
                  id="url"
                  v-model="url"
                  @keyup="errorNull"
                  @input="change($event)"
                  @change="change($event)"
                  @paste="onPaste($event)" 
                  required
                />

                <div class="error-sponser p-1" v-if="!isValid && url">URL is Invalid</div>
                <!-- <div class="error-sponser" v-if="!url">URL is required</div> -->
              </div>
            </form>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="saveSponsor(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "NewSponsor",
  props: {
    eventId: {
      type: Number,
    },
  },
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      details: {
        name: "",
      },
      url: "",
      photoUrl: null,
      isValid: true,
      sponsorPhoto: null,
    };
  },
  validations: {
    details: {
      name: { required, maxLength: maxLength(18) },
    },
  },
  methods: {
    // validationStatus: function(validation) {
    //   return typeof validation != "undefined" ? validation.$invalid : false;
    // },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    errorNull: function () {
      if (
        this.details.name &&
        !this.$v.details.name.$invalid &&
        this.url &&
        this.isValid &&
        this.photoUrl
      ) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    dataReset: function () {
      this.details.name = "";
      this.url = "";
      this.$v.$reset();
      this.isDisabled = true;
      this.sponsorPhoto = null;
      this.photoUrl = null;
      document.getElementById("vue-file-upload-input-sponsor-photo").value = "";
    },
    onPaste(e) {
      const url = e.clipboardData.getData("text");
      this.url = url;
      this.isURLValid(url);
      this.errorNull();
    },
    change(e) {
      const url = e.target.value;
      this.isURLValid(url);
      this.errorNull();
    },
    isURLValid(inputUrl) {
      var regx = new RegExp(
        "^" +
          "(?:(?:https?|http)://)" +
          "(?:\\S+(?::\\S*)?@)?" +
          "(?:" +
          "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
          "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
          "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
          "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
          "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
          "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
          "|" +
          "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
          "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
          "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
          ")" +
          "(?::\\d{2,5})?" +
          "(?:/\\S*)?" +
          "$",
        "i"
      );
      this.isValid = regx.test(inputUrl);
    },
    openinput: function () {
      document.getElementById("vue-file-upload-input-sponsor-photo").click();
    },
    onFileChange(e) {
      var fileInput = document.getElementById(
        "vue-file-upload-input-sponsor-photo"
      );
      var file_ = fileInput.value;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file_)) {
        alert(
          "Please upload file having extensions .jpeg/.jpg/.png/.gif only."
        );
        fileInput.value = "";
        return false;
      } else {
        this.sponsorPhoto = e.target.files[0];
        console.log("Original file:", this.sponsorPhoto);
        this.compressImage(this.sponsorPhoto, 0.7, (compressedImage) => {
          console.log("Compressed file:", compressedImage);
          this.photoUrl = URL.createObjectURL(compressedImage);
          this.sponsorPhoto = compressedImage;
          this.errorNull();
        });
      }
      this.errorNull();
    },
    compressImage(file, quality, callback) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const maxWidth = 800;
          const maxHeight = 800;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              console.log("Blob created:", blob);
              callback(blob);
            },
            "image/jpeg",
            quality
          );
        };
      };
    },
    saveSponsor(index) {
      try {
        this.$set(this.isLoadingArray, index, true);
        const formData = new FormData();
        formData.append("event_id", this.eventId);
        formData.append("name", this.details.name);
        formData.append("website_url", this.url);
        if (this.sponsorPhoto) {
          formData.append("image_url", this.sponsorPhoto, this.sponsorPhoto.name);
        }

        axios
          .post("/associationEvent/addSponsor", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            this.$root.$emit("sponsorAdded");
            this.dataReset();
            var elem = this.$refs.closeNewSponsor;
            if (elem) {
              elem.click();
            }
          })
          .catch((error) => {
            console.error("Error adding sponsor:", error);
          })
          .finally(() => {
            this.$set(this.isLoadingArray, index, false);
          });
      } catch (error) {
        console.error("Error adding sponsor:", error);
      }
    },
  },
  mounted() {
    this.$root.$on("openNewSponsorModal", () => {
      var elem = this.$refs.openModalNewSponsor;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>

<style scoped>
.profile-preview {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.profile-photo-edit {
  width: 200px;
  height: 200px;
  position: relative;
  text-align: center;
  border: 2px solid #f1f3f6;
  cursor: pointer;
}

.profile-photo-edit .user-default {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.profile-photo-edit img,
.new-profile-photo img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.profile-photo-edit-icon-wrapper {
  position: absolute;
  top: 76.5%;
  background-color: #e5f2fe;
  right: 3.8%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.error-sponser {
  left: auto;
  margin-top: 15px;
  color: white;
  border-radius: 5px;
  right: 5%;
  background-color: #ff5b66 !important;
  transform: translate(-5%, -50%);
  -webkit-transform: translate(-5%, -50%);
  -moz-transform: translate(-5%, -50%);
  -ms-transform: translate(-5%, -50%);
  -o-transform: translate(-5%, -50%);
  width: max-content;
}

.profile-photo-edit-icon-wrapper img {
  width: 20px;
}
</style>

