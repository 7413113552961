<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#addConfiguration"
      ref="openModalConfiguration"
    ></button>
    <div
      class="modal fade"
      id="addConfiguration"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Add Field Configuration
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalConfiguration"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <select
                class="form-control form-control-lg"
                id="exampleFormControlSelect1"
                v-model="configField"
              >
                <option value="" disabled selected>Select Field</option>
                <option
                  :value="field"
                  v-for="field in fieldsForConfig"
                  :key="field.id"
                >
                  {{ field.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="event-btns-wrapper justify-content-end p-3">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              @click="updateField(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  name: "AddConfiguration",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleGame: {
      type: Object,
    },
  },
  data: function () {
    return {
      configField: "",
      fieldsForConfig: [],
      isDisabled: false,
      isLoadingArray: [],
      currentUrl: "",
      config: [],
      fetchConfig: [],
    };
  },
  validations: {
    configField: { required },
  },
  methods: {
    getFields() {
      if (
        this.selectedRoomTeam.teamType == "sports" ||
        !this.selectedRoomTeam.sportType
      ) {
        axios
          .post(
            process.env.VUE_APP_API_URL +
              `game/getFieldConfig?sport_type=${this.selectedRoomTeam.sportType}&gameID=${this.gameToGet.id}`
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.fieldsForConfig = response.data.data.allFields;
              this.configField = response.data.data.oldField
                ? response.data.data.oldField
                : "";
            } else {
              this.errorMessage = response.data.message;
            }
          });
      } else {
        var elem = this.$refs.closeModalConfiguration;
        if (elem) {
          elem.click();
        }
        this.isDisabled = false;
        this.$set(this.isLoadingArray, 1, false);
        this.$alert("Sport type is not selected yet.");
      }
    },
    updateField(index) {
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      if (
        this.selectedRoomTeam.teamType == "sports" ||
        !this.selectedRoomTeam.sportType
      ) {
        axios
          .post(
            process.env.VUE_APP_API_URL +
              `game/saveFieldConfig?configField_id=${this.configField.id}&gameID=${this.gameToGet.id}`
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.currentUrl = window.location.pathname;
              
              if (this.currentUrl == "/teams") {
                this.$root.$emit("showGameList");
               
              } else {
                this.$root.$emit("HitTeamsHistory");
                this.$root.$emit("showSingleBoxScore");
              }
              var elem = this.$refs.closeModalConfiguration;
              if (elem) {
                elem.click();
              }
              this.isDisabled = false;
              this.$set(this.isLoadingArray, index, false);
              this.config = response.data.data.GamePositionConfigType;
              this.$emit('config-updated', this.config);
            } else {
              this.errorMessage = response.data.message;
              this.isDisabled = false;
              this.$set(this.isLoadingArray, index, false);
            }
          });
      } else {
        this.isDisabled = false;
        this.$set(this.isLoadingArray, index, false);
        this.$alert("Sport type is not selected yet.");
      }
    },
  },
  mounted() {
    this.$root.$on("AddConfiguration", (clickedGame) => {
      this.gameToGet = clickedGame;
      var elem = this.$refs.openModalConfiguration;
      if (elem) {
        this.getFields();
        elem.click();
      }
    });
  },
};
</script>

<style>
</style>
