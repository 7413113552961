<template>
  <div>
    <div class="p-0">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "chatRightSims",

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {},
  mounted() {},
};
</script>
<style scoped></style>
