<template>
    <div class="no-chat-wrapper">
      <div class="no-chat-image">
        <img src="../../assets/Placeholder.png" alt="" />
        <div class="no-chat-text">
          <p>There are no archived teams to display at the moment. </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "NoArchives",
  };
  </script>