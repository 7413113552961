<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <div class="shop-side pr-3">
        <!-- <Banner /> -->
        <div class="row no-gutters">
          <div class="col-12 px-0">
            <div class="calendar-top">
              <h6>Payment Details</h6>
            </div>
          </div>
        </div>
        <div class="order-wrapper">
          <div class="order-info">
            <div class="order-heading mt-lg-3">
              <img src="../assets/images/card.png" alt />
              <h6>How would you like to pay ?</h6>
            </div>
            <div class="order-form">
              <div
                class="account-wrapper-content my-0 px-0"
                style="border-radius: 0; box-shadow: none"
                id="accordionExample"
              >
                <div class="account-content-info pt-0 d-block">
                  <div
                    id="headingOne"
                    data-toggle="collapse"
                    data-target="#collapseOe"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  ></div>

                  <div
                    id="collapseOne"
                    class="in"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body p-0 d-flex flex-column">
                      <form onsubmit="return false;" id="form1" class="m-0">
                        <div class="form-row">
                          <div class="form-group col-12 col-md-6">
                            <label for="first_name">First Name *</label>
                            <input
                              name="cardholder-first-name"
                              class="field input-box"
                              placeholder="First Name"
                              v-model="firstname"
                              @keyup="validateCrud"
                            />
                          </div>
                          <div class="form-group col-12 col-md-6">
                            <label for="last_name">Last Name *</label>
                            <input
                              name="cardholder-last-name"
                              class="field input-box"
                              placeholder="Last Name"
                              v-model="lastname"
                              @keyup="validateCrud"
                            />
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-12 col-md-12">
                            <label>Mobile *</label>
                            <input
                              name="phone"
                              class="field input-box w-100"
                              placeholder="+1000000000000"
                              @keyup="validateCrud"
                              v-model="phone"
                            />
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-12 col-md-6">
                            <label for="card_no">Card No *</label>
                            <div
                              class="last-fields"
                              id="staxjs-number"
                              style="
                              height: 50px;
                              padding-top: 15px;
                              border-radius: 5px;
                              border: 1px solid #ced4da;
                              padding-left: 10px !important;
                            "
                            ></div>
                          </div>
                          <div class="form-group col-12 col-md-2">
                            <label for="cvv">Cvv</label>
                            <div
                              class="last-fields"
                              id="staxjs-cvv"
                              style="
                              height: 50px;
                              padding-top: 15px;
                              padding-left: 10px !important;
                              border-radius: 5px;
                              border: 1px solid #ced4da;
                            "
                            ></div>
                          </div>
                          <div class="form-group col-12 col-md-4">
                            <label for="expiry">Expiry Date *</label>
                            <div
                              class="card-element last-fields"
                              style="
                              height: 50px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              border-radius: 5px;
                              border: 1px solid #ced4da !important;
                            "
                            >
                              <input
                                name="month"
                                size="3"
                                maxlength="2"
                                placeholder="MM"
                                v-model="month"
                                @keyup="validateCrud"
                                style="
                                padding: 0 !important;
                                padding-left: 10px !important;
                                border: 1px solid #ced4da;
                                border-right: none !important;
                                border-left: none !important;
                                border-radius: 0 !important;
                                margin-bottom: 0 !important;
                                width: 45px;
                              "
                              />
                              <i>/</i>
                              <input
                                name="year"
                                size="5"
                                maxlength="4"
                                placeholder="YYYY"
                                v-model="year"
                                @keyup="validateCrud"
                                style="
                                padding: 0 !important;
                                padding-left: 10px !important;
                                border: 1px solid #ced4da;
                                border-right: none !important;
                                border-left: none !important;
                                border-radius: 0 !important;
                                margin-bottom: 0 !important;
                              "
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="form-group">
                  <div class="form-check my-2">
                    <input
                      class="form-check-input cursor-pointer"
                      type="checkbox"
                      id="gridCheck"
                      v-model="checkbox"
                      @change="validateCrud"
                    />
                    <label
                      class="form-check-label cursor-pointer"
                      for="gridCheck"
                      >I accept Terms & Conditions</label
                    >
                  </div>
                </div>
                <div class="event-btns-wrapper justify-content-end">
                  <button id="paybutton" type="submit" class="main-btn w-100">
                    <div v-if="showLoader == 1" class="lds-dual-ring"></div>
                    <span class="fee-price"
                      >Pay $ {{ singlePaymentForUser?.payment }}</span
                    >
                  </button>
                </div>
                <div class="outcome position-relative">
                  <div class="error"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://staxjs.staxpayments.com/stax.js?nocache=2"></script>
<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
// import Banner from "../components/BannerNotify/BannerPage.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FounderUserPayment",
  components: {
    Navbar,
    LeftMenu,
    // Banner,
  },
  data() {
    return {
      paymentObject: {},
      isLoadingArray: [],
      showLoaderOnPage: true,
      showLoader: 0,
      checkbox: "",
      firstname: "",
      lastname: "",
      month: "",
      phone: "",
      year: "",
      saveCard: false,
      address_1: "100 S Orange Ave",
      address_2: "Suite 400",
      address_city: "Orlando",
      address_state: "FL",
      address_zip: "32811",
      address_country: "USA",
      send_receipt: false,
      validate: false,
      StaxPayKey: this.$staxPayKey,
      product_ids: "",
      unAvailable: false,
      meta: {
        reference: "invoice-reference-num", // optional - will show up in emailed receipts
        memo: "notes about this transaction", // optional - will show up in emailed receipts
        otherField1: "other-value-1", // optional - we don't care
        otherField2: "other-value-2", // optional - we don't care
        subtotal: 0, // optional - will show up in emailed receipts
        tax: 0, // optional - will show up in emailed receipts, and used for L2 processing. Accepts the tax in dollar amount. To qualify for L2 processing rates, the tax dollar amount must be equivalent to 0.1% and 30% of the transaction's total.
        poNumber: "7649", // customer code used for L2 processing.
        shippingAmount: 0, // the shipping amount for the transaction used for L2 processing
        lineItems: [
          // optional - will show up in emailed receipts
          {
            id: "optional-fm-catalog-item-id",
            item: "Demo Item",
            details: "this is a regular demo item",
            quantity: 0,
            price: 0,
          },
        ],
        invoice_merchant_custom_fields: [
          {
            id: "dc4b-6c74-00dd-fab1-fe00", // Required, must be a unique string.
            name: "External ID", // The name of the custom field that will be displayed to your customers and users; this will appear above the field as a label.
            placeholder: "Ex. #123", // The placeholder for the field; this is the faint text that will appear in the entry box of your custom field to help guide your users before they enter in the value when creating an Invoice.
            required: true, // Determines if this field is required to be filled by the user (not customer) when first creating an Invoice.
            type: "text", // Input type. Only 'text' is supported.
            value: "123456789", // The value that will appear when viewing the Invoice or Invoice Email. For the merchant, this will also appear when viewing the Invoice via the Invoices or Edit Invoice pages.
            visible: true, // This determines if the field is visible when viewing the Invoice or Invoice Email. If false, will only appear in merchant-facing pages such as the Invoices or Edit Invoice pages.
          },
        ],
      },
    };
  },
  mounted() {
    this.crudClear();
    var payButton = document.querySelector("#paybutton");

    // Init StaxJs SDK
    var staxJs = new StaxJs(this.StaxPayKey, {
      number: {
        id: "staxjs-number",
        placeholder: "0000 0000 0000 0000",
      },
      cvv: {
        id: "staxjs-cvv",
        placeholder: "000",
      },
    });

    // tell staxJs to load in the card fields
    staxJs
      .showCardForm()
      .then((handler) => {
        // for testing!
        handler.setTestPan("");
        handler.setTestCvv("");
        var form = document.querySelector("#form1");
        form.querySelector("input[name=month]").value = "";
        form.querySelector("input[name=year]").value = "";
        form.querySelector("input[name=cardholder-first-name]").value;
        form.querySelector("input[name=cardholder-last-name]").value;
        form.querySelector("input[name=phone]").value = "";
      })
      .catch((err) => {
        console.log("error init form " + err);
        // reinit form
      });

    staxJs.on("card_form_complete", (message) => {
      // Check if all required fields are filled
      const form = document.querySelector("#form1");
      const expiryFilled =
        form.querySelector("input[name=month]").value !== "" &&
        form.querySelector("input[name=year]").value !== "";
      const termsAccepted = document.getElementById("gridCheck").checked;

      // Enable the Pay button only when all conditions are met
      if (expiryFilled && termsAccepted) {
        payButton.disabled = false;
        payButton.style.opacity = "1";
      } else {
        payButton.disabled = true;
        payButton.style.opacity = "0.4";
      }
    });

    staxJs.on("card_form_incomplete", (message) => {
      // deactivate pay button
      payButton.disabled = true;
      payButton.style.opacity = "0.4";
    });

    document.querySelector("#paybutton").onclick = () => {
      this.showLoader = 1;
      payButton.disabled = true;
      var errorElement = document.querySelector(".error");
      errorElement.classList.remove("visible");
      var form = document.querySelector("#form1");
      const getRandomId = (min = 1000, max = 500000) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        const num = Math.floor(Math.random() * (max - min + 1)) + min;
        return num;
      };
      var extraDetails = {
        total: this.singlePaymentForUser.payment, // 1$
        firstname: form.querySelector("input[name=cardholder-first-name]")
          .value,
        lastname: form.querySelector("input[name=cardholder-last-name]").value,
        company: "WHIIFOLLOW",
        email: this.user.email,
        month: form.querySelector("input[name=month]").value,
        year: form.querySelector("input[name=year]").value,
        phone: form.querySelector("input[name=phone]").value,
        address_1: "whoifollow",
        address_2: "",
        address_city: "California",
        address_state: "california",
        address_zip: "52000",
        address_country: "USA",
        url: "https://app.staxpayments.com/#/bill/",
        method: "card",
        // validate is optional and can be true or false.
        // determines whether or not stax.js does client-side validation.
        // the validation follows the sames rules as the api.
        // check the api documentation for more info:
        // https://staxpayments.com/api-documentation/
        validate: false,
        // meta is optional and each field within the POJO is optional also
        meta: {
          reference: getRandomId(), // optional - will show up in emailed receipts
          memo: "This is founder user invite", // optional - will show up in emailed receipts
          subtotal: this.singlePaymentForUser.price, // optional - will show up in emailed receipts
          tax: 0, // optional - will show up in emailed receipts
          lineItems: [
            // optional - will show up in emailed receipts
            {
              id: "optional-fm-catalog-item-id",
              item: "SUBSCRIPTION FEE",
              details: "This is Subscription fee for Year",
              quantity: 1,
              price: this.singlePaymentForUser.payment,
            },
          ],
        },
      };

      // call pay api
      staxJs
        .pay(extraDetails)
        .then((result) => {
          if (result.id) {
            result["first_name"] = this.firstname;
            result["last_name"] = this.lastname;
            result["mob"] = this.phone;
            result["email"] = this.user.email;
            result["total"] = this.singlePaymentForUser.payment;
            result["pack_id"] = this.singlePaymentForUser.package_id;
            result["sentBy"] = this.singlePaymentForUser.sentBy;
            return axios
              .post(
                process.env.VUE_APP_API_URL + "getFounderCharge",
                result,
                {}
              )
              .then((response) => {
                if (response.data.statusCode == 200) {
                  this.getUserData();
                  localStorage.setItem("paymentToGet", 0);
                  this.getSinglePayment(null);
                  this.$router.push({ name: "Teams" });
                } else {
                  this.errorMessage = response.data.message;
                  payButton.disabled = false;
                  payButton.style.opacity = "1";
                }
                this.showLoader = 0;
              });
          }
        })
        .catch((err) => {
          this.showLoader = 0;
          payButton.disabled = false;
          payButton.style.opacity = "1";
          // if a transaction did occur, but errored, the error will be in the message of the first child transactoin
          if (err.message) {
            errorElement.textContent = err.message;
          } else if (err.payment_attempt_message) {
            errorElement.textContent = err.payment_attempt_message;
          } else {
            // else, there may have been a validation error - and tokenization failed
            // err can contain an object where each key is a field name that points to an array of errors
            // such as {mobile_number: ['The mobile number is invalid']}
            if (err.total == "The total must be at least .01.") {
              errorElement.textContent = "Subscription Fee is Invalid!";
            } else if (
              err.phone ==
              "The mobile field is required when customer id is not present."
            ) {
              errorElement.textContent = "Mobile Number is Required!";
            } else if (err.status == 0) {
              errorElement.textContent =
                "Please Check Your Card Information and Try Again or Contact Support";
            } else {
              errorElement.textContent =
                typeof err === "object"
                  ? Object.keys(err)
                      .map((k) => err[k].join(" "))
                      .join(" ")
                  : JSON.stringify(err);
            }
          }

          errorElement.classList.add("visible");
        });
      errorElement.textContent = "";
    };
  },
  computed: {
    ...mapGetters("auth", ["group"]),
    ...mapGetters("auth", ["singlePaymentForUser"]),
    ...mapGetters("auth", ["founderPayment"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("notification", ["getUserNotificationData"]),
    ...mapActions("auth", ["getUserData"]),
    ...mapActions("auth", ["getFounderPayment"]),
    ...mapActions("auth", ["getSinglePayment"]),
    async crudClear() {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,

        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });

      this.firstname = "";
      this.lastname = "";
      this.phone = "";
      this.month = "";
      this.year = "";
      this.errorMessage = "";
      this.checkbox = "";
      var errorElement = document.querySelector(".error");
      errorElement.classList.remove("visible");
      await this.getFounderPayment();
      if (this.founderPayment.length != 0) {
        loader.hide();
      } else {
        loader.hide();
        this.$router.push({ name: "Teams" });
      }
    },
    validateCrud() {
      var payButton = document.querySelector("#paybutton");
      if (
        this.firstname == "" ||
        this.lastname == "" ||
        this.phone == "" ||
        this.month == "" ||
        this.year == ""
      ) {
        payButton.disabled = true;
        payButton.style.opacity = "0.4";
      }
      if (document.getElementById("gridCheck").checked) {
        payButton.disabled = false;
        payButton.style.opacity = "1";
      } else {
        payButton.disabled = true;
        payButton.style.opacity = "0.4";
      }
    },
  },
};
</script>
