var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{ref:"openGameModal",staticClass:"d-none",attrs:{"data-toggle":"modal","data-target":"#GameModal"}}),_c('div',{staticClass:"modal fade",attrs:{"id":"GameModal","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog product-cart-modal",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content d-block"},[_vm._m(0),_c('div',{staticClass:"modal-body overflow-auto"},[(_vm.showLoader === 1)?_c('div',{staticClass:"card-event mt-3 p-3"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-img'),_c('content-placeholders-heading')],1),_c('br'),_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-img'),_c('content-placeholders-heading')],1)],1):(_vm.gameList.length === 0 && _vm.showLoader === 0)?_c('div',[_vm._m(1)]):_vm._e(),(_vm.gameList.length > 0 && _vm.showLoader === 0)?_c('div',{staticClass:"live-league-wrap p-3"},_vm._l((_vm.gameList),function(schedule,index){return _c('div',{key:index},[_c('div',{staticClass:"cursor-pointer",attrs:{"data-dismiss":"modal"},on:{"click":function($event){return _vm.callSingleBoxModal(schedule)}}},[(schedule.game_live == 1)?_c('div',{staticClass:"live-league position-relative"},[_vm._m(2,true),_c('div',{staticClass:"league"},[_c('span',[_vm._v(_vm._s(schedule.name))])])]):_c('div',{staticClass:"live-league position-relative"},[_c('div',{staticClass:"inner-sec"}),_c('div',{staticClass:"league"},[_c('span',[_vm._v(_vm._s(schedule.name))])])]),_c('div',{staticClass:"schedule-info",class:{
                    'last-schedule-info': index === _vm.gameList.length - 1,
                  }},[_c('div',[_c('span',{staticClass:"day-date"},[_vm._v(_vm._s(schedule.exactDateFormat))]),_c('div',{staticClass:"location-info"},[_c('img',{attrs:{"src":require("../../../assets/images/location.png"),"alt":""},on:{"click":function($event){return _vm.openGoogleMap($event, schedule)}}}),_c('div',{staticClass:"content"},[_vm._v(_vm._s(schedule.park_address))])]),_c('div',{staticClass:"opponent-info"},[_c('span',{staticClass:"vs"},[_vm._v("vs")]),_c('span',{staticClass:"oppo-name"},[_vm._v(_vm._s(schedule.opponent_name))])]),(
                        schedule.comment !== null && schedule.comment !== ''
                      )?_c('div',{staticClass:"oppo-para"},[_vm._v(" "+_vm._s(schedule.comment)+" ")]):_vm._e()]),_c('div',{staticClass:"played-data"},[(schedule.game_live == 1)?_c('span',{staticClass:"tie"},[_vm._v(_vm._s(schedule.team_score)+" - "+_vm._s(schedule.opponent_score))]):(schedule.win_status == 0)?_c('span',{staticClass:"tie"},[_vm._v(_vm._s(schedule.team_score)+" - "+_vm._s(schedule.opponent_score))]):(schedule.win_status == 1)?_c('span',{staticClass:"win-data"},[_vm._v("W"+_vm._s(schedule.team_score)+" - "+_vm._s(schedule.opponent_score))]):(schedule.win_status == 2)?_c('span',{staticClass:"loss-data"},[_vm._v("L"+_vm._s(schedule.team_score)+" - "+_vm._s(schedule.opponent_score))]):_c('span',{staticClass:"tie"},[_vm._v(" "+_vm._s(schedule.team_score)+" - "+_vm._s(schedule.opponent_score))])])])])])}),0):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v("Switch Game")]),_c('button',{staticClass:"close cursor-pointer",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no-chat-text"},[_c('p',[_vm._v("No game is scheduled yet")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner-sec"},[_c('div',{staticClass:"live-tag"},[_c('span',[_vm._v("Live")])])])
}]

export { render, staticRenderFns }