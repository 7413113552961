<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="photos-wrapper">
          <PhotosTop />
          <Photos v-if="showLoader == 0" :items="photos" />
          <photo-shims v-if="showLoader == 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import PhotosTop from "../components/Photos/PhotosTop.vue";
import Photos from "../components/Photos/Photos.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import axios from "axios";
import PhotoShims from "../components/Photos/photoShims.vue";

export default {
  components: {
    Navbar,
    LeftMenu,
    PhotosTop,
    Photos,
    PhotoShims,
    Banner
  },
  data() {
    return {
      photos: [],
      showLoader: 0,
    };
  },
  methods: {
    async fetchPhotos() {
      this.showLoader = 1;
      let posts = await axios.get(`post/profilePictures`);
      if (posts.data.statusCode == 200) {
        this.showLoader = 0;
        this.photos = posts.data.data.profileImages;
      } else {
        this.showLoader = 0;
        this.errorMessage = posts.data.message;
      }
    },
  },
  mounted() {
    this.fetchPhotos(1);
  },
};
</script>
<style>
.navbar-expand-lg .navbar-nav .nav-link {
  padding-bottom: 20px;
}
</style>
