<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="photos-wrapper">
          <top-other />
          <OtherPhotos v-if="showLoader == 0" :items="photos" />
          <photo-shims v-if="showLoader == 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import OtherPhotos from "../components/Photos/OtherPhotos.vue";
import axios from "axios";
import TopOther from "../components/Photos/topOther.vue";
import PhotoShims from "../components/Photos/photoShims.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";

export default {
  components: {
    Navbar,
    LeftMenu,
    OtherPhotos,
    TopOther,
    PhotoShims,
    Banner
  },
  data() {
    return {
      photos: [],
      lastSegment: "",
      showLoader: 1,
    };
  },
  methods: {
    async fetchPhotos() {
      this.showLoader = 1;
      let posts = await axios.get(
        `post/otherProfilePictures?userLink=${this.lastSegment}`
      );
      if (posts.data.statusCode == 200) {
        this.showLoader = 0;
        this.photos = posts.data.data.profileImages;
      } else {
        this.showLoader = 0;
        this.errorMessage = posts.data.message;
      }
    },
    getuserLink() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.lastSegment = segment_array.pop();
      if (this.lastSegment !== "otherPhotos") {
        this.fetchPhotos();
      }
    },
  },
  mounted() {
    this.getuserLink();
  },
};
</script>
<style>
.navbar-expand-lg .navbar-nav .nav-link {
  padding-bottom: 20px;
}
</style>
