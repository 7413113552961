<template>
  <div>
    <div class="container-fluid bg-white pt-3">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
        <br />
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
          </content-placeholders>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PhotosShims",
  components: {},
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      API_URL: this.$postStorageURL,
    };
  },
  methods: {},
};
</script>
<style></style>
