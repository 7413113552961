<template>
  <div>
    <div class="brackets-btn" v-if="currentIsAdmin == '1'" >
      <button class="btn btn-danger delete-bracket-btn"  @click="deleteBracket">Delete All Brackets</button>
      <button class="btn btn-primary" data-toggle="modal" data-target="#bracketModal" @click="clearData">Upload
        Brackets</button>
    </div>
    <form @keyup.enter="uploadBracket(0)">
      <div class="modal fade bd-example-modal-lg" id="bracketModal" ref="modal-xl" tabindex="-1" role="dialog"
        aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title">Upload Brackets</h6>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalBracket">
                <img src="../../assets/close.png" />
              </button>
            </div>
            <div class="modal-body">
              <div class="post-content">
                <div class="row no-gutters image-div">
                  <div v-for="(data, index) in rawData" :key="data" class="image-input image-input-active d-flex">
                    <div class="image-preview">
                      <img class="img-responsive h-100" :src="data" />
                      <button class="btn btn-xs remove-file" @click="removeFile(index)">
                        <img class="remove-Image" src="../../assets/cross-icon.png" alt="" />
                      </button>
                    </div>
                  </div>

                  <div class="image-input image-input-tbd d-flex" v-if="this.images.length < this.option.maxFileCount">
                    <div class="image-preview dropzone d-flex justify-content-center align-items-center"
                      @click="openinput">
                      <img class="image-plus" src="../../assets/plus.png" />
                    </div>
                  </div>
                  <input type="file" class="d-none" id="vue-file-upload-input" @change="addImageNew"
                    accept="image/png, image/gif, image/jpeg" multiple />
                </div>
              </div>
            </div>
            <div>
              <div class="event-btns-wrapper justify-content-end p-3">
                <button v-if="this.isDisabled == false" type="button" class="btn btn-primary w-25" block
                  @click="uploadBracket(0)" :disabled="isLoadingArray[0]">
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Upload</span>
                </button>
                <button v-else-if="this.isDisabled == true" type="button" class="btn btn-primary-disabled w-25"
                  :disabled="isLoadingArray[0]">
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Upload</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div v-if="brackets.length > 0">
      <div v-for="(bracket, index) in brackets" :key="index" class="brackets-images-div mt-3">
        <img :src="API_URL + bracket.image_url" alt="">
      </div>
    </div>
    <div class="no-products-in-shop" v-if="(!brackets || brackets.length === 0) && showLoader === 0">
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No tournament bracket found.</p>
      <p class="info">
        There are no tournament bracket to display.
      </p>

    </div>
    <div class="products-list-shim p-0 mt-3" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TournamentBrackets",
  data: function () {
    return {
      isDisabled: true,
      isLoadingArray: [],
      option: {
        maxFileCount: 100,
      },
      rawData: [],
      images: [],
      brackets: [],
      API_URL: this.$bracketStorageURL,
      showLoader: 1,
    };
  },
  props: {
    currentIsUmpire: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  methods: {
    removeFile: function (index) {
      this.images.splice(index, 1);
      this.rawData.splice(index, 1);
      document.getElementById("vue-file-upload-input").value = null;
      if (this.images.length == 0) {
        this.isDisabled = true;
      }
      else {
        this.isDisabled = false;
      }
    },
    openinput: function () {
      document.getElementById("vue-file-upload-input").click();
    },
    clearData: function (index) {
      this.isDisabled = true;
      this.$set(this.isLoadingArray, index, false);
      this.images = [];
      this.rawData = [];
      document.getElementById("vue-file-upload-input").value = null;
    },
    addImageNew: function (event) {
      const self = this;
      var files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        const file_ = files[i];
        this.images.push({
          target: event.target.files[i],
          fileName: file_.name,
          link: URL.createObjectURL(file_),
        });
        let reader = new FileReader();
        reader.onload = (e) => {
          self.rawData.push(e.target.result);
        };
        reader.readAsDataURL(file_);
      }
      this.isDisabled = false;
    },
    uploadBracket: function (index) {
      this.isDisabled = true;
      this.$set(this.isLoadingArray, index, true);
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const Guid = segment_array.pop();
      var formData = new FormData();
      formData.append("tournament_guid", Guid);
      this.images.forEach((value, index) => {
        formData.append("images[" + index + "]", value.target);
      });

      return axios
        .post(process.env.VUE_APP_API_URL + "tournaments/brackets/storeTournamentBrackets", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            var elem = this.$refs.closeModalBracket;
            elem.click();
            this.getAllBrackets();
            this.$set(this.isLoadingArray, index, false);
            this.images = [];
            this.rawData = [];
          } else {
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },
    getAllBrackets: function () {
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const Guid = segment_array.pop();
      var formData = new FormData();
      formData.append("tournament_guid", Guid);

      this.showLoader = 1;
      return axios
        .post(process.env.VUE_APP_API_URL + "tournaments/brackets/getTournamentBrackets", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.brackets = response.data.data;
            this.showLoader = 0;
          } else {
            this.showLoader = 0;
          }
        });
    },
    deleteBracket: function () {
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const Guid = segment_array.pop();
      var formData = new FormData();
      formData.append("tournament_guid", Guid);
      if (this.brackets.length === 0) {
        return;
      }

      this.$confirm(
        `About to delete the brackets. Continue ?`,
      ).then(() => {

        axios
          .post(process.env.VUE_APP_API_URL + "tournaments/brackets/deleteTournamentBrackets", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.getAllBrackets();
            } else {
              console.log(response.data.message);
            }
          });
      });
    },
  },
  mounted() {
    this.getAllBrackets();
    this.$root.$on("showTournamentForSwitch", () => {
      this.brackets = [];
      this.getAllBrackets();
    });
  },
}
</script>
