<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side">
        <div class="shop-left-menu d-none d-lg-block p-0 main-page-left-side">
          <Events />
          <FriendsRequests
            v-if="showfriendRequest == 1"
            :items="this.friendsRequestsCount"
          />
          <Terms :showInviteFriend="showInviteFriend" />
        </div>
        <div class="shop-page-products pt-lg-0 main-page-right-side">
          <div class="row no-gutters">
            <div class="col-12 col-lg-8 pl-0 pr-lg-3">
              <Specialists />
            </div>

            <div class="col-4 d-none d-lg-block">
              <div class="s-heading m-0">Sponsored AD</div>
              <div v-for="ad in proAd" :key="ad.id">
                <AdsOnPro :items="ad" />
              </div>
              <content-placeholders
                class="post-view"
                :rounded="true"
                v-if="showLoader == 1"
              >
                <content-placeholders-heading :img="true" />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FlashMessage />
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Events from "../components/Home/Events.vue";
import FriendsRequests from "../components/Home/FriendsRequests.vue";
import Terms from "../components/Home/Terms.vue";
import Specialists from "../components/Posts/Specialists.vue";
import Banner from "../components/BannerNotify/BannerPage.vue"
import AdsOnPro from "../components/Posts/AdsOnPro.vue";

import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    Navbar,
    LeftMenu,
    Events,
    Specialists,
    Terms,
    AdsOnPro,
    FriendsRequests,
    Banner
  },
  name: "Home",

  data() {
    return {
      showInviteFriend: 0,
      showfriendRequest: 0,
      friendsRequestsCount: 0,
      grpInfo: null,
      linkGetUrl: null,
      proAd: null,
      showLoader: 0,
      ADType: "News Feed",
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth", ["getGrpData"]),
    async sideLeftView() {
      this.showInviteFriend = 0;
      let friendReq = await axios.get(`friends/friendRequestCount`);

      if (friendReq.data.statusCode == 200) {
        this.friendsRequestsCount = friendReq.data.data.count;
        if (this.friendsRequestsCount > 0) {
          this.showFriendRequestList();
        } else {
          this.showInviteButton();
        }
      } else {
        this.showInviteButton();
      }
      this.$root.$emit("FriendRequestshowLoaderOff");
    },
    async proAds() {
      this.showLoader = 1;
      let ads = await axios.get(`getAd?type=${this.ADType}`);

      if (ads.data.statusCode == 200) {
        this.proAd = ads.data.data.ads;
        this.showLoader = 0;
      } else {
        this.proAd = ads.data.message;
      }
      this.$root.$emit("FriendRequestshowLoaderOff");
    },
    showInviteButton: function () {
      this.showInviteFriend = 1;
      this.showfriendRequest = 0;
    },

    showFriendRequestList: function () {
      this.showInviteFriend = 0;
      this.showfriendRequest = 1;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.sideLeftView(1);
    this.proAds();
    this.$root.$on("FriendRequestCountCheck", () => {
      this.sideLeftView(1);
    });
  },
};
</script>