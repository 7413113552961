<template>
  <div class="user-name-wrapper">
    <span class="name"  v-if="dataName" >{{ dataName.fname }} {{ dataName.middle_name }} {{ dataName.lname }}</span>
    <span class="email"  v-if="dataName" >@{{ dataName.user_link }}</span>
  </div>
</template>
<script>
export default {
  name: "Username",
  props: {
    dataName: {
      type: Object,
    },
  },
};
</script>