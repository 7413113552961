<template>
  <div>
    <People
      v-if="openTab == 1 || openTab == 2"
      :openTab="openTab"
      :searchProfiles="searchProfiles"
      :searchFetchProfile="searchFetchProfile"
    />

    <!-- <SearchEvents v-if="openTab == 1 || openTab == 4" :openTab="openTab" /> -->
    <AllSeenPost
      v-if="openTab == 1 && posts.length > 0"
      :searchText="searchText"
    />
    <div v-observe-visibility="handleScrolledToButtom"></div>
  </div>
</template>
<script>
import People from "../../Search/SearchRight/People.vue";
import AllSeenPost from "../../Search/SearchRight/AllSeenPost.vue";
// import SearchEvents from "../../Search/SearchRight/SearchEvents.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    posts: {
      type: Array,
    },
    openTab: {
      type: Number,
    },
    searchText: {
      type: String,
    },
    searchProfiles: {
      type: Array,
    },
    searchFetchProfile: {
      type: Array,
    },
  },
  name: "All",
  components: {
    People,
    // SearchEvents,
    AllSeenPost,
  },
  data() {
    return {
      Storage_URL: this.$userProfileStorageURL,
    };
  },
  methods: {
    handleScrolledToButtom() {
      this.$root.$emit("handleScrolledToButtomSearch");
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
