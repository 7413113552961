<template>
  <div class="head-main">
    <div class="team-info team-right-info">
      <span class="main-back-arrow" @click="showChat">
        <img src="../../../../assets/images/arrow-left.png" alt="" />
      </span>
      <div class="team-content-wrapper" v-if="showLoader == 0">
        <div class="image-wrapper">
          <div class="shop-products-img-loader" v-if="imgLoader == 1">
            <div class="lds-dual-ring-media"></div>
          </div>
          <img
          @load="onImageLoad"
            v-if="foundCurrentUser.avatar != ''"
            :src="foundCurrentUser.avatar"
            alt=""
          />
          <img @load="onImageLoad" v-else src="../../../../assets/images/chat-user.png" alt="" />
        </div>
        <div class="chat-user-info">
          <div>
            <p class="team-name" :id="selectedRoomTeam.id">
              {{ selectedRoomTeam.roomName }}
            </p>
            <p class="follow">@{{ user_link }}</p>
          </div>
          <div class="follow-wrap">
            <span class="follow">Followers</span>
            <span class="count"> {{ followerCount }}</span>

            <span class="follow"> Followings</span>
            <span class="count"> {{ followingCount }}</span>
          </div>
        </div>
      </div>
      <div v-if="showLoader == 1">
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "IndHead",
  props: ["selectedRoomTeam"],
  data() {
    return {
      followerCount: "0",
      followingCount: "0",
      userId: localStorage.getItem("userId"),
      foundCurrentUser: [],
      showLoader: 0,
      user_link: "",
      imgLoader: 1,
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    showChat() {
      let window_width = window.innerWidth;
      if (window_width <= 991.98) {
        $(".chat-right").hide();
        $(".vac-col-messages").show();
      } else if (window_width >= 992) {
        $(".chat-right").show();
        $(".vac-col-messages").show();
      }
    },
    async allCount() {
      this.showLoader = 1;
      let allCounts = await axios.get(
        `allCountChat?c_id=${this.foundCurrentUser.c_id}`
      );

      if (allCounts.data.statusCode == 200) {
        this.followerCount = allCounts.data.data.followerCount;
        this.followingCount = allCounts.data.data.followingCount;
        this.user_link = allCounts.data.data.user.user_link;
        this.showLoader = 0;
      }
    },
  },
  mounted() {
    this.selectedRoomTeam.users.forEach((dataU) => {
      if (dataU.c_id != this.userId) {
        this.foundCurrentUser = dataU;
        this.allCount();
      }
    });
    this.$root.$on("updateIndividualRightSide", (room) => {
      room.users.forEach((dataU) => {
        if (dataU.c_id != this.userId) {
          this.foundCurrentUser = dataU;
          this.allCount();
        }
      });
    });
  },
};
</script>
<style scoped>
.team-info .user-details .team-name {
  font-size: 16px;
  font-weight: 500;
  color: #333333 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.team-info .user-details p {
  font-size: 14px;
  font-weight: 400;
}

.team-info .user-details .type {
  color: #188ef5;
}
.team-info .user-details .created-by,
.team-info .user-details .date {
  color: #333333;
  font-size: 14px;
}
</style>
