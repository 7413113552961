<template>
  <div class="back-to-store-wrapper">
    <router-link to="/shop" exact class="back-to-store"
      >Back to Store</router-link
    >
  </div>
</template>
<script>
export default {
  name: "BackStore",
};
</script>