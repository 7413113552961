<template>
    <div>
      <!-- Select Home Team -->
      <input
        class="d-none"
        data-target="#delayedGameModal"
        data-toggle="modal"
        ref="openDelayedGameTournament"
      />
      <div
        class="modal fade"
        id="delayedGameModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title" id="exampleModalLabel">Game Delayed</h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeDelayedGameModal"
                @click="closeModal()"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" v-if="singleGame.team_one">
              <form @submit.prevent>
              <!-- Description Field -->
              <div class="form-group">
                <label for="description">Description</label>
                <input
                  type="text"
                  class="form-control"
                  id="description"
                  placeholder="Add description"
                  v-model="description"
                  @input="errorNull()"
                  maxlength="20"
                />
              </div>
            </form>
            </div>
  
            <div class="event-btns-wrapper px-3 pt-0 pb-3 justify-content-end">
              <button
                v-if="isDisabled == false"
                type="button"
                class="btn btn-primary w-100"
                @click="gameDelayed(0)"
                :disabled="isLoadingArraySelectTeam[0]"
              >
                <div
                  v-if="isLoadingArraySelectTeam[0]"
                  class="lds-dual-ring"
                ></div>
                <span v-else>Save</span>
              </button>
              <button
                v-else-if="this.isDisabled == true"
                type="button"
                class="btn btn-primary-disabled w-100"
                :disabled="true"
              >
                <div
                  v-if="isLoadingArraySelectTeam[0]"
                  class="lds-dual-ring"
                ></div>
                <span v-else>Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
   import axios from "axios";
  export default {
    name: "DelayedGameModal",
    data: function () {
      return {
        isDisabled: true,
        isLoadingArraySelectTeam: [],
        singleGame: [],
        selectHomeTeamRadio: 1,
        opponentFlag: 0,
        exactStartTime: "10:00 AM",
        startTime: "10:00 AM",
        timeLimit: "",
        description: "",
      };
    },
   
   
    methods: {
      closeModal() {
      this.description = "";
      this.errorNull();
    },

    errorNull() {
      if (this.description == "") {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
      // Select Home Team
      async gameDelayed(index) {
        this.isDisabled = true;
        this.$set(this.isLoadingArraySelectTeam, index, true);

     
      var formData = new FormData();
      formData.append("tournament_game_id", this.singleGame.id);
      formData.append("delayed_reason", this.description);
      
      await axios
        .post(process.env.VUE_APP_API_URL + "tournaments/game/gameDelayed", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if(response.data.statusCode === 404) {
              this.$alert(response.data.message).then(() => {
                  this.$set(this.isLoadingArraySelectTeam, index, false);
                  this.isDisabeld = false;
                  
                  window.location.reload();
              });
          }
          else {
            this.$set(this.isLoadingArraySelectTeam, index, false);
            var close = this.$refs.closeDelayedGameModal;
            if (close) {
              close.click();
              this.isDisabled = false;
              this.$root.$emit("showTounamentGameScore");
            }
          }
        });
      },
     
    },
    mounted() {
      this.$root.$on(
        "openDelayedGameTournamentModal", (data) => {

          this.singleGame = data;

          var elem = this.$refs.openDelayedGameTournament;
          if (elem) {
            elem.click();
          }
        }
      );
    },
  };
</script>  