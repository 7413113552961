<template>
  <div class="photos-content photo-content pr-3 mb-3">
    <!-- <div class="row no-gutters" v-if="items.length != 0">
        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 pl-0 pr-sm-3"
          v-for="photo in items"
          :key="photo.id"
        >
          <PhotosPreview :photos="photo" />
        </div>
      </div> -->
    <lightbox :cells="1000" :items="photos" :css="null" />
    <div class="row" v-if="items.length == 0 && showLoader == 1">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <p>No photos found.</p>
      </div>
    </div>
  </div>
</template>
  <script>
// import PhotosPreview from "./PhotosPreview.vue";
import lightbox from "../ImageGrid/lightbox";
import "../ImageGrid/lightbox";
import "../ImageGrid/lightbox.css";
import axios from "axios";
export default {
  name: "OtherPhotos",
  components: {
    // PhotosPreview,
    lightbox,
  },
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      API_URL: this.$postStorageURL,
      photos: [],
      showLoader: 1,
    };
  },
  methods: {
    async fetchPhotos() {
      this.showLoader = 1;
      // let posts = await axios.get(`post/profilePictures`);
      let posts = await axios.get(
        `post/otherProfilePictures?userLink=${this.lastSegment}`
      );
      if (posts.data.statusCode == 200) {
        this.photos = posts.data.data.profileImages;
        this.showLoader = 0;
      } else {
        this.errorMessage = posts.data.message;
        this.showLoader = 0;
      }
    },
    getuserLink() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.lastSegment = segment_array.pop();
      if (this.lastSegment !== "profileOther") {
        this.fetchPhotos();
      }
    },
  },
  mounted() {
    this.getuserLink();
    this.$root.$on("ProfilePhotos", () => {
      this.fetchPhotos(1);
    });
  },
};
</script>
  