<template>
  <div class="Product-size">
    <div class="category-heading">
      <span>Size:</span>
    </div>
    <div class="size-btns">
      <button
        class="btnSize"
        v-for="(size, index) in productSize"
        :key="index"
        @click="selectSize(size, index)"
        :class="{ active: activeIndex === index ,
          disable: size.status ===0
        }"
        :disabled="size.status === 0"
      >
        {{ size.description }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSize",
  props: {
    productSize: {
      type: Array,
    },
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  methods: {
    selectSize(size, index) {
      this.activeIndex = index;
      this.$root.$emit("selectedSize", size);
    },
  },
};
</script>
<style>
.btnSize.active {
  background-color: #188ef5;
  color: #ffffff;
}
.btnSize.disable {
  background-color: #e5f2fe;
  color: #000000;
  opacity: 0.5;
  cursor: not-allowed;
}
.btnSize.disable:hover {
  background-color: #e5f2fe;
  color: #000000;
}
.Product-size {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>