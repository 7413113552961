<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="row no-gutters">
          <div class="col-12">
            <div v-if="otherUserDetail">
              <CoverPhoto :dataCover="otherUserDetail" />
            </div>
            <div class="products-list-shim" v-else>
              <content-placeholders>
                <content-placeholders-img />
                <content-placeholders-heading />
              </content-placeholders>
            </div>
          </div>
        </div>
        <div class="row align-items-center cover-bottom position-relative">
          <div class="col-12 col-md-3">
            <div class="profile-photo-section" v-if="otherUserDetail">
              <ProfilePhoto :dataProfile="otherUserDetail" />
            </div>
            <div class="products-list-shim" v-else>
              <content-placeholders>
                <content-placeholders-img />
              </content-placeholders>
            </div>
          </div>
          <div
            class="col-12 col-md-9 d-sm-flex align-items-center justify-content-between"
            v-if="otherUserDetail"
          >
            <div class="profile-name-section">
              <Username :dataName="otherUserDetail" />
            </div>

            <ul class="profile-options">
              <li
                v-on:click="followActions()"
                v-if="followStatus == 0 && followLoader == false"
              >
                <div class="message-button-wrapper">
                  <img
                    v-if="followLoader == false"
                    class="message-icon"
                    src="../assets/friend-icon.png"
                    alt
                  />
                  <span v-if="followLoader == false">Follow</span>
                </div>
              </li>
              <li v-if="followLoader == true">
                <div class="message-button-wrapper lds-dual-ring-refresh"></div>
              </li>
              <li
                v-on:click="followActions()"
                v-if="followStatus == 1 && followLoader == false"
              >
                <div class="message-button-wrapper">
                  <img
                    v-if="followLoader == false"
                    class="message-icon"
                    src="../assets/friend-icon.png"
                    alt
                  />
                  <span v-if="followLoader == false">Unfollow</span>
                </div>
              </li>

              <li v-if="actionLoading == true">
                <div class="message-button-wrapper lds-dual-ring-refresh"></div>
              </li>

              <li
                v-if="status == 1 && actionLoading == false"
                v-on:click="buttonActions()"
              >
                <div class="message-button-wrapper">
                  <img
                    class="message-icon"
                    src="../assets/add-friend.png"
                    alt
                  />
                  <span>Send Request</span>
                </div>
              </li>
              <li
                v-if="status == 2 && actionLoading == false"
                v-on:click="buttonActions()"
              >
                <div class="message-button-wrapper">
                  <img
                    class="message-icon"
                    src="../assets/add-friend.png"
                    alt
                  />
                  <span>Accept Request</span>
                </div>
              </li>
              <li
                v-if="status == 3 && actionLoading == false"
                v-on:click="buttonActions()"
                :class="{ unclickable: isUnclickable }"
              >
                <div class="message-button-wrapper">
                  <img
                    class="message-icon"
                    src="../assets/friend-icon.png"
                    alt
                  />
                  <span>Cancel Request</span>
                </div>
              </li>
              <li
                v-if="status == 4 && actionLoading == false"
                v-on:click="buttonActions()"
              >
                <div class="message-button-wrapper">
                  <img
                    class="message-icon"
                    src="../assets/friend-icon.png"
                    alt
                  />
                  <span>Unfriend</span>
                </div>
              </li>

              <li
                data-toggle="modal"
                v-if="loading"
                data-target="#individualModalDirect"
                class
                v-on:click="fetchDirectMessageData(otherUserName, otherUserID)"
              >
                <div class="message-button-wrapper message-buttons">
                  <img
                    class="message-icon"
                    src="../assets/images/message-white.png"
                    alt
                  />
                  <span>Message</span>
                </div>
              </li>
            </ul>

            <!-- <p
                  class="team-members-functions cursor-pointer position-relative"
                  v-click-outside="onClickOutside"
                  @click="openOtherProfileOptions"
                >
                  <img src="../assets/images/ellipsis-h.png" alt />
                </p> -->
          </div>

          <div class="col-12 col-md-9 products-list-shim" v-else>
            <content-placeholders>
              <content-placeholders-heading />
              <content-placeholders-heading />
            </content-placeholders>
          </div>
          <!-- <div
                class="col-lg-2 col-md-4 col-sm-6 mt-md-5 mt-sm-0 pt-md-5 pt-sm-0"
              >
                <div class="profile-edit-section" v-if="otherUserDetail">
                  <FriendRequest :dataRequest="otherUserDetail" />
                </div>
              </div>-->
        </div>
        <div class="row no-gutters mt-3">
          <div class="col-lg-4 col-md-6 col-sm-12 pr-md-3">
            <Followers :followersUpdated="this.followersUpdated" />
            <Info v-if="otherUserDetail" :dataInfo="otherUserDetail" />
            <ProfileFriends />
            <ProfilePhotos />
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 pl-md-0">
            <div class="row">
              <div class="col-12">
                <ProfilePosts />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create Chat -->
    <DirectMessageModal :type="type" :userId="otherUserID" />
    <!-- FlashMessage -->
    <FlashMessage
      :position="'right bottom'"
      style="z-index: 99999999 !important"
    ></FlashMessage>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import CoverPhoto from "../components/OtherProfile/CoverPhoto.vue";
import ProfilePhoto from "../components/OtherProfile/ProfilePhoto.vue";
import Username from "../components/OtherProfile/Username.vue";
import Followers from "../components/OtherProfile/Followers.vue";
import Info from "../components/OtherProfile/Info.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import ProfileFriends from "../components/OtherProfile/ProfileFriends.vue";
import ProfilePhotos from "../components/OtherProfile/ProfilePhotos.vue";
import ProfilePosts from "../components/OtherProfile/ProfilePosts";
import DirectMessageModal from "../components/Teams/ChatRight/ChatSettingComponents/DirectMessageModal.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  components: {
    Navbar,
    LeftMenu,
    CoverPhoto,
    ProfilePosts,
    ProfilePhoto,
    Username,
    Followers,
    Info,
    ProfileFriends,
    ProfilePhotos,
    DirectMessageModal,
    Banner
  },
  data() {
    return {
      // OtherProfileOptions: false,
      type: "searchPage",
      lastSegment: "",
      errorMessage: "",
      otherUserID: "",
      otherUserName: "",
      loading: false,
      actionLoading: false,
      actionLoader: false,
      otherUserDetail: null,
      user_id: localStorage.getItem("userId"),
      user_link: localStorage.getItem("user_link"),
      status: "",
      followStatus: "",
      followLoader: false,
      loader: false,
      followersUpdated: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    // openOtherProfileOptions() {
    //   this.OtherProfileOptions = !this.OtherProfileOptions;
    // },

    onClickOutside() {
      // this.OtherProfileOptions = false;
    },
    async fetchOtherProfile() {
      let otherUser = await axios.get(
        `user/getOtherUserData?userLink=${this.lastSegment}`
      );
      if (otherUser.data.statusCode == 200) {
        this.otherUserDetail = otherUser.data.data.userData;
        this.otherUserID = otherUser.data.data.userData.user_id;
        this.otherUserName = otherUser.data.data.userData.fname.concat(
          " ",
          otherUser.data.data.userData.lname
        );
        this.getUserData();
      } else {
        this.errorMessage = otherUser.data.message;
      }
    },
    getuserLink() {
      this.getUserData();
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.lastSegment = segment_array.pop();
      if (this.lastSegment !== "otherProfile") {
        if (this.lastSegment == this?.user_link) {
          this.$router.push({ name: "Profile" });
        } else {
          this.fetchOtherProfile();
        }
      }
    },
    fetchDirectMessageData(otherUserName, otherUserID) {
      this.$root.$emit("fetchDirectMessageData", otherUserName, otherUserID);
    },
    yourCallBackFunction() {
    },
    buttonActions() {
      this.actionLoading = true;
      this.loader = 1;
      var formData = new FormData();
      formData.append("type", this.status);
      formData.append("friend_id", this.otherUserID);

      return axios
        .post(
          process.env.VUE_APP_API_URL + "friends/friendsAction",
          formData,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.loader = 0;
            this.status = response.data.data;
          } else {
            if (response.data.statusCode == 404)
              this.flashMessage.error({
                title: "Error",
                message: response.data.message,
                time: 5000,
                blockClass: "custom-block-class",
              });
          }
          this.actionLoading = false;
        });
    },
    followActions() {
      this.followersUpdated = true;
      this.loader = 1;
      this.followLoader = true;
      var formData = new FormData();
      formData.append("type", this.followStatus);
      formData.append("friend_id", this.otherUserID);

      return axios
        .post(
          process.env.VUE_APP_API_URL + "friends/followUnfollow",
          formData,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.loader = 0;
            this.followStatus = response.data.optional;
            this.followersUpdated = false;
          }
        });
    },
  },

  updated() {
    this.loading = true;
    return axios
      .get(process.env.VUE_APP_API_URL + "friends/friendStatus", {
        params: {
          user_id: this.user_id,
          friend_id: this.otherUserID,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.statusCode == 200) {
          this.status = response.data.data["status"];
          this.followStatus = response.data.data["follow"];
          this.actionLoader = true;
          this.followLoader = false;
        }
      });
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getuserLink();
    this.getUserData();
    this.followLoader = true;
  },
  beforeDestroy() {
    document.removeEventListener("backbutton", this.yourCallBackFunction);
  },
};
</script>
<style>
.navbar-expand-lg .navbar-nav .nav-link {
  padding-bottom: 20px;
}
.message-buttons {
  background-color: #188ef5 !important;
  color: white !important;
}
.request-button {
  /* position:absolute; */
  cursor: pointer;
  /* top:5px; */
  color: black;
  /* right:180px; */
  padding: 10px;
  background-color: #cce6fd;
  border-radius: 10px;
  width: max-content;
}
.message-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #e5f2fe;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.message-button-wrapper span {
  white-space: nowrap;
}
.message-button-wrapper.lds-dual-ring {
  cursor: not-allowed;
}
.message-icon {
  width: 20px;
}
.loading {
  cursor: wait;
  opacity: 0.6;
  top: 5px;
  color: black;
  right: 180px;
  padding: 10px;
  background-color: #cce6fd;
  border-radius: 10px;
  position: absolute;
}
</style>
