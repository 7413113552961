<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-md-3 pb-3">
        <div class="row no-gutters">
          <div class="col-12 col-md-8 px-0">
            <div class="order-history-page">
              <OrderHistoryLeftside />
            </div>
          </div>
          <div class="col-12 col-md-4 px-0">
            <div class="order-history-right-side mt-3 mt-md-0">
              <OrderDetailRightside />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import OrderHistoryLeftside from "../components/OrderHistory/OrderHistoryLeftside.vue";
import OrderDetailRightside from "../components/OrderHistory/OrderDetailRightside.vue";
export default {
  name: "OrderHistory",
  components: {
    Navbar,
    LeftMenu,
    OrderHistoryLeftside,
    OrderDetailRightside,
    Banner
  },
};
</script>

<style scoped>
body {
  background-color: #ffffff !important;
}
</style>
