<template>
  <div>
    <button
      type="button"
      class="edit-cart-btn"
      data-toggle="modal"
      data-target="#editProductCart"
    >
      <img src="../../../assets/images/edit.png" alt="" />
      <span>Edit Cart</span>
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="editProductCart"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog product-cart-modal" role="document">
        <div class="modal-content">
          <div class="modal-left-wrapper">
            <CartHeading />
            <ItemsHeading />
          </div>
          <div class="modal-right-wrapper">
            <Summary />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CartHeading from "../ShoppingCart/CartHeading.vue";
import ItemsHeading from "../ShoppingCart/ItemsHeading.vue";
import Summary from "../ShoppingCart/Summary.vue";

export default {
  name: "EditCart",
  components: {
    CartHeading,
    ItemsHeading,
    Summary,
  },
  computed: {
    ...mapGetters("cart", ["cartItemCount"]),
  },
  methods: {
    backToOrder: function() {
      var elem = this.$refs.closeModalShoppingCart;
      elem.click();
      this.$router.push({ name: "Order" });
    },
  },
};
</script>
