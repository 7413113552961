<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#editServiceRoasterModal"
      ref="openEditModalService"
    ></button>
    <div
      class="modal fade"
      id="editServiceRoasterModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Edit Event Travel Arrangements
            </h5>
            <button
              type="button"
              class="close text-primary"
              data-dismiss="modal"
              aria-label="Close"
              ref="openEditModalServiceClose"
              @click="resetForm()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="toggle-content">
              <div>
                <h6 class="toogle-header">Do you need travel arrangements?</h6>
                <span class="toggle-text" v-if="travelStatus == true"
                  >Please select the services you need while attending the
                  event</span
                >
              </div>
              <toggle-button
                class="toggle"
                v-model="travelStatus"
                @change="getDivs"
                :value="false"
                :sync="true"
                :width="34"
                ref="toggleBtn"
              />
            </div>
           <div v-if="travelStatus == true">
              <div id="accordion">
                <div v-for="(select, index) in selections" :key="index">
                  <div :id="'heading' + index">
                    <h6
                      class="py-2 border-bottom mb-3 d-flex align-items-center justify-content-between"
                      data-toggle="collapse"
                      :data-target="'#collapse' + index"
                      aria-expanded="true"
                      :aria-controls="'collapse' + index"
                    >
                      <span>
                        {{ select.text }}
                      </span>
                      <toggle-button
                        class="custom-control-toggle"
                        :id="'customCheck' + select.value"
                        @change="getValue(select, $event)"
                        :width="34"
                        :value="select.isChecked"
                      />
                    </h6>
                  </div>

                  <div v-if="select.value === 'hotel'">
                    <div
                      :id="'collapse' + index"
                      class="collapse"
                      :class="{ 'show': select.isChecked }"
                      aria-labelledby="'heading' + index"
                      data-parent="#accordion"
                    >
                      <div class="row no-gutters">
                        <div class="col-12 col-sm-6 pr-sm-3">
                          <div class="form-group control-group">
                            <label for="startDateCheck">Start Date</label>
                            <span
                              class="label-type error event-error ml-2"
                              v-if="startDateError != ''"
                            >
                              Please select Start Date.
                            </span>
                            <div
                              class="input-group date"
                              id="startDateCheck"
                              data-target-input="nearest"
                            >
                              <input
                                class="form-control"
                                required="required"
                                id="startDate"
                                type="date"
                                v-model="startDate"
                                v-on:change="checkdate"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6 mt-2 mt-sm-0">
                          <div class="form-group">
                            <label for="endDateCheck">End Date</label>
                            <span
                              class="label-type error event-error ml-2"
                              v-if="endDateError != ''"
                            >
                              Please select End Date.
                            </span>
                            <span
                              class="label-type error event-error ml-2"
                              v-if="endDateErrorGreater != ''"
                            >
                              End Date should be greater than start date
                            </span>
                            <div
                              class="input-group date"
                              id="endDateCheck"
                              data-target-input="nearest"
                            >
                              <input
                                class="form-control"
                                required="required"
                                id="endDate"
                                type="date"
                                v-model="endDate"
                                v-on:change="checkdate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-sm-6 mt-3">
                          <div class="product-actions">
                            <p>Adults</p>
                            <div class="product-inc-dec">
                              <div
                                class="product-decrement"
                                @click="adultdecrement()"
                              >
                                <img
                                  src="../../../../assets/images/minus.png"
                                  alt=""
                                />
                              </div>
                              <div class="product-value">
                                <span>{{ adultQuantity }}</span>
                              </div>
                              <div
                                class="product-increment"
                                @click="adultIncrement()"
                              >
                                <img
                                  src="../../../../assets/images/plus.png"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6 mt-3 mb-2">
                          <div class="product-actions">
                            <p>Rooms</p>
                            <div class="product-inc-dec">
                              <div
                                class="product-decrement"
                                @click="roomdecrement()"
                              >
                                <img
                                  src="../../../../assets/images/minus.png"
                                  alt=""
                                />
                              </div>
                              <div class="product-value">
                                <span>{{ room_count }}</span>
                              </div>
                              <div
                                class="product-increment"
                                @click="roomIncrement()"
                              >
                                <img
                                  src="../../../../assets/images/plus.png"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 mt-2 notes-div">
                          <div class="form-group">
                            <label for="note">Notes</label>
                            <textarea
                              type="text"
                              class="form-control pt-3 add-notes"
                              id="note"
                              rows="4"
                              :class="{
                                'is-invalid': validationStatus($v.note),
                              }"
                              v-model.trim="$v.note.$model"
                              @input="enableUpdateButton"
                            ></textarea>
                            <div
                              v-if="!$v.note.required && validationserror"
                              class="invalid-tooltip"
                            >
                              Notes is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer"> -->
          <div class="event-btns-wrapper justify-content-end p-3">
            <button
              type="button"
              v-if="this.isDisabledServices == false"
              id="servicesBtn"
              class="btn btn-primary w-25"
              block
              @click="getServices(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>

            <button
              type="button"
              v-if="this.isDisabledServices == true"
              id="servicesBtn"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { required } from "vuelidate/lib/validators";
import $ from "jquery";
export default {
  name: "EditServicesModal",
  data() {
    return {
      selections: [
        { value: "hotel", text: "Hotel" },
        { value: "bnb", text: "Bnb" },
        { value: "car_rental", text: "Car Rental" },
        { value: "airline_tickets", text: "Airline Tickets" },
      ],
      toggleData: "",
      selectedArray: [],
      isDisabledServices: true,
      isLoadingArray: [],
      showRoomInput: false,
      travelStatus: false,
      exactRoomStartDate: "",
      exactRoomEndDate: "",
      startDate: "",
      endDate: "",
      startDateError: "",
      endDateError: "",
      endDateErrorGreater: "",
      exactStartDateformat: "",
      exactEndDateformat: "",
      room_count: 1,
      adultQuantity: 1,
      note: "",
      validationserror: false,
      serviceString: "",
      user_id: "",
    };
  },
  validations: {
    startDate: { required },
    endDate: { required },
    note: { required },
  },

methods: {
  resetForm() {
    this.travelStatus = false;
    this.note = ""; 
    this.validationserror = false;
    this.$v.$reset();
    this.validationserror = false;
    this.endDateError = "";
    this.startDateError = "";

    this.selections.forEach((select) => {
    this.$set(select, "isChecked", false);
    });

    this.showRoomInput = false;
    this.selectedArray = [];
    this.toogleData = [];
    $("#servicesBtn").prop("disabled", true);
    this.isDisabledServices = true;
  },
  checkdate: function () {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      // Start Date
      if (this.startDate.length == 10) {

        const startDateArray = this.startDate.split("-");
        var startMonth = startDateArray[1];
        var startDay = startDateArray[2];
        var startYear = startDateArray[0];
        var varDate = startMonth + "-" + startDay + "-" + startYear; //dd-mm-YYYY
        var startDateConvert = new Date(varDate);
        this.exactStartDateformat = startDateConvert;
        this.exactRoomStartDate =
          month[startMonth - 1] + " " + startDay + ". " + startYear;
        if (this.startDateConvert == "") {
          this.startDateError = "on";
        } else {
          this.startDateError = "";
        }
      }
      // End Date-----
      if (this.endDate.length == 10) {

        const endDateArray = this.endDate.split("-");
        var endMonth = endDateArray[1];
        var endDay = endDateArray[2];
        var endYear = endDateArray[0];
        var varDatee = endMonth + "-" + endDay + "-" + endYear; //dd-mm-YYYY
        var endDateConvert = new Date(varDatee);
        if (endDateConvert < startDateConvert) {
          this.endDateError = "";
          this.endDateErrorGreater = "on";
          this.exactRoomEndDate = "";
          this.isDisabledServices = true;
        } else {
          this.exactEndDateformat = endDateConvert;
          this.endDateError = "";
          this.endDateErrorGreater = "";
          this.isDisabledServices = false;
          this.exactRoomEndDate =
            month[endMonth - 1] + " " + endDay + ". " + endYear;
        }
      }
    },
  roomIncrement() {
    this.room_count = parseInt(this.room_count, 10) + 1;
    this.isDisabledServices = false;
  },
  roomdecrement() {
    var newQuantity = this.room_count - 1;

    if (newQuantity > 0) {
      this.room_count = this.room_count - 1;
      this.isDisabledServices = false;
    }
  },
  adultIncrement() {
    this.adultQuantity = parseInt(this.adultQuantity, 10) + 1;
    this.isDisabledServices = false;
  },
  adultdecrement() {
    var newQuantity = this.adultQuantity - 1;

    if (newQuantity > 0) {
      this.adultQuantity = this.adultQuantity - 1;
      this.isDisabledServices = false;
    }
  },
  enableUpdateButton() {
    this.isDisabledServices = false;
  },
  getDivs() {
    const onlyHotelSelected =
        this.selectedArray.length === 1 && this.selectedArray[0] === "Hotel";

      if (onlyHotelSelected) {
        this.showRoomInput = true;
      } else {
        this.showRoomInput = false;
      }
    if (this.travelStatus == false) {
      this.selectedArray = [];
      this.showRoomInput = false;
      this.room_count = 1;
      this.travelStatus = false;
      this.adultQuantity = 1;
      this.note = "";
      this.exactStartDateformat = "";
      $("#servicesBtn").prop("disabled", false);
      this.isDisabledServices = false;
    }
    if ((!this.selectedArray || this.selectedArray.length === 0) && this.travelStatus == true) {
      $("#servicesBtn").prop("disabled", true);
      this.isDisabledServices = true;
    } else {
      $("#servicesBtn").prop("disabled", false);
      this.isDisabledServices = false;
    }
  },
  getValue(select, event) {
    const index = this.selections.findIndex(
      (item) => item.value === select.value
    );

    if (event.value === true) {
      if (select.value === "hotel") {
        this.showRoomInput = true;
      }
      this.$set(this.selections[index], "isChecked", true);
    } else {
      if (select.value === "hotel") {
        this.showRoomInput = false;
      }
      this.$set(this.selections[index], "isChecked", false);
    }

    this.selectedArray = this.selections
      .filter((item) => item.isChecked)
      .map((item) => item.text);
    this.toogleData = this.selectedArray;
    let anySelectionTrue = this.selections.some((item) => item.isChecked);
    this.travelStatus = anySelectionTrue;

    if (this.selectedArray.length === 0) {
      $("#servicesBtn").prop("disabled", true);
      this.isDisabledServices = true;
    } else {
      $("#servicesBtn").prop("disabled", false);
      this.isDisabledServices = false;
    }
  },
  validationStatus: function (validation) {
    return typeof validation != "undefined" ? validation.$error : false;
  },
  getServices(index) {
    if (this.travelStatus == true) {
      if(this.showRoomInput){
        if (this.startDate == null || "") {
          this.startDateError = "on";
        } else {
          this.startDateError = "";
        }
        if (this.endDate == null || "") {
          this.endDateError = "on";
        } else {
          this.endDateError = "";
        }
      }
      if (this.selectedArray && this.selectedArray.length === 0) {
        $("#servicesBtn").prop("disabled", true);
        this.isDisabledServices = true;
      } else {
        $("#servicesBtn").prop("disabled", false);
        this.isDisabledServices = false;
        this.$set(this.isLoadingArray, index, false);
        if (this.showRoomInput == true) {
          this.validationserror = true;
          this.$v.$touch();
          if (this.$v.$pendding || this.$v.$error) return;
          this.checkdate();
          var formattedHotelService = this.toogleData
          this.selectedArray = formattedHotelService
          // Emitting the event with updated data
          this.$root.$emit(
            "dataForservice",
            this.selectedArray,
            this.user_id,
            this.room_count,
            this.adultQuantity,
            this.note,
            this.exactRoomStartDate,
            this.exactRoomEndDate
          );
        } else {
          this.$root.$emit(
            "dataForservice",
            this.selectedArray,
            this.user_id,
            "",
            "",
            "",
            "",
            ""
          );
        }
      }
    } else {
      this.$root.$emit("dataForservice", "", "", "", "", "", "");
    }
    var elem = this.$refs.openEditModalServiceClose;
    if (elem) {
      elem.click();
      this.selectedArray = [];
      this.room_count = 1;
      this.travelStatus = false;
      this.adultQuantity = 1;
      this.note = "";
      this.exactStartDateformat = "";
      this.startDate = "";
      this.endDate = "";
      this.selections = [
        { value: "hotel", text: "Hotel" },
        { value: "bnb", text: "Bnb" },
        { value: "car_rental", text: "Car Rental" },
        { value: "airline_tickets", text: "Airline Tickets" },
      ];

      $("#servicesBtn").prop("disabled");
      this.isDisabledServices = true;
    }
  },
  getParseServices(servicesString, services) {
      const servicesArray = servicesString.split(",");
      const hasBnb = servicesArray.includes("Bnb");
      const hasCarRental = servicesArray.includes("Car Rental");
      const hasAirlineTickets = servicesArray.includes("Airline Tickets");
      const hotelService = servicesArray.find((service) =>
        service.includes("Hotel")
      );
      const parseDate = (dateString, endDateYear) => {
        const months = {
          Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
          Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
        };
        const [month, day, year] = dateString.split(" ");
        const formattedMonth = months[month.substr(0, 3)];
        const formattedDay = day.replace(".", ""); 

        const parsedYear = year ? year : endDateYear;

        return `${parsedYear}-${formattedMonth}-${formattedDay}`;
      };
      if (
        hotelService &&
        hotelService.includes("(") &&
        hotelService.includes(")")
      ) {
        const dates = hotelService.match(/\(([^)]+)\)/)[1].split(" - ");
        const [start, end] = dates.map(date => date.trim());
        const endDateYear = parseDate(end).split("-")[0];
        this.startDate = parseDate(start, endDateYear);
        this.endDate = parseDate(end);
        this.checkdate();
      } else if (
        services.exactEndDate &&
        services.exactStartDate
      ) {
        this.startDate = parseDate(services.exactStartDate, services.exactEndDate.split("-")[0]);
        this.endDate = parseDate(services.exactEndDate);
        this.checkdate();
      }


      this.selections.forEach((select) => {
        if (select.value === "bnb" && hasBnb) {
          this.$set(select, "isChecked", true);
        } else if (select.value === "car_rental" && hasCarRental) {
          this.$set(select, "isChecked", true);
        } else if (select.value === "airline_tickets" && hasAirlineTickets) {
          this.$set(select, "isChecked", true);
        } else if (select.value === "hotel" && hotelService) {
          this.$set(select, "isChecked", true);
          if (hotelService) {
            this.showRoomInput = true;
          }
        }
      });

      var elem = this.$refs.openEditModalService;
      if (elem) {
        elem.click();
        this.validationserror = false;
        this.room_count = services.room_count || 1;
        this.adultQuantity = services.adult_count || 1;
        this.note = services.note || "";
        this.exactRoomStartDate = this.exactStartDate || "";
        this.exactRoomEndDate = this.exactEndDate || "";
        this.travelStatus = true;
        if (this.travelStatus == true) {
          $("#servicesBtn").prop("disabled");
          this.isDisabledServices = true;
        } else {
          $("#servicesBtn").prop("disabled", false);
          this.isDisabledServices = false;
        }
      }
    },
  },

mounted() {
  this.$root.$on("openEditServicesModal", (data) => {
    var elem = this.$refs.openEditModalService;
    if (elem) {
      elem.click();
      if (this.travelStatus == true) {
        $("#servicesBtn").prop("disabled");
        this.isDisabledServices = true;
      } else {
        $("#servicesBtn").prop("disabled", false);
        this.isDisabledServices = false;
      }
      if (data) {
        this.user_id = data.member_id;
        const event = data.event;
        this.startDate = event.startDateForField;
        this.endDate = event.endDateForField;
        this.checkdate();
        const services = data.services;
        this.servicesString = data.services.services;
        this.selectedArray = data.services.services;
        if (this.selectedArray && this.selectedArray.length > 0) {
          this.getParseServices(this.selectedArray, services);
          this.travelStatus = true;
        }
      }
    }
  });
},
};
</script>
  
  <style>
.custom-checkbox {
  border-bottom: 1px solid #cfd7e0;
  padding: 16px 0;
}
.custom-checkbox:last-child {
  border: none;
  padding-bottom: 0;
}
.custom-checkbox .custom-control-label::before {
  display: none !important;
}
.custom-control {
  padding-left: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#startDate {
  position: relative;
}
.vue-js-switch {
  margin-bottom: 0;
}

.product-decrement {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .form-control.is-invalid {
    background-image: none !important;
  } */
</style>
