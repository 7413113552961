<template>
  <div>
    <div class="order-history" v-if="showLoader == 0">
      <InviteModal :packagesList="packagesList" />
      <button class="resend-Invite-btn float-right" @click="callModalInvites()">
        Invite
        <span class="" v-if="subscribedInfo && subscribedInfo.pack_type == 1"
          >Super Users/Pro Users</span
        >
        <span
          class=""
          v-else-if="subscribedInfo && subscribedInfo.pack_type == 3"
          >Pro Users</span
        >
      </button>
      <h1>Invites</h1>
      <div class="order-content container-fluid" v-if="displayedinvitesArray.length != 0">
        <div class="table-responsive-md">
          <table class="table" v-if="displayedinvitesArray">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Invite Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody v-for="(users, index) in displayedinvitesArray" :key="index">
              <tr>
                <td scope="row">
                  {{ users.fname }}
                  {{ users.lname }}
                </td>
                <td>{{ users.target }}</td>
                <td class="text-capitalize" v-if="users.status == 0">
                  <div class="order-status-top">
                    <p>Accepted</p>
                  </div>
                </td>

                <td class="text-capitalize" v-else-if="users.status == 1">
                  <div class="order-status-top">
                    <p>Pending</p>
                  </div>
                </td>
                <td class="text-capitalize" v-if="users.status == 1">
                  <div class="order-status-top">
                    <button
                      class="resend-Invite-btn"
                      @click="resendInvite(users)"
                    >
                      Resend Invite
                    </button>
                  </div>
                </td>
                <td class="text-capitalize" v-else>
                  <div class="order-status-top">
                    <p
                    >
                      Invite Accepted
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <nav class="orders-pagination-nav" aria-label="Page navigation example">
          <ul class="pagination m-0">
            <li class="page-item">
              <button
                type="button"
                class="page-link"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </button>
            </li>
            <li class="page-item" v-if="page != 1">
              <button
                type="button"
                class="page-link"
                v-for="pageNumber in displayedPages"
                :key="pageNumber"
                @click="page = pageNumber"
              >
                {{ pageNumber }}
              </button>
            </li>
            <li class="page-item">
              <select
                class="custom-select"
                v-model="page"
                v-if="pagesLength > 15"
              >
                <option
                  v-for="pageNumber in pages"
                  :key="pageNumber"
                  :value="pageNumber"
                  class="page-item"
                >
                  Page {{ pageNumber }}
                </option>
              </select>
            </li>
            <li class="page-item">
              <button
                type="button"
                @click="page++"
                v-if="pagesLength > 15 && page<pages.length"
                class="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div v-else class="media-wrapper">
        <div class="no-content" v-if="showLoader == 0">
          <div class="img">
            <img src="../../assets/images/order-grey.png" alt="" />
          </div>
          <p class="header">No invites yet</p>
          <p class="text">
            There are no invites to show in your <br />
            invites history
          </p>
        </div>
      </div>
    </div>
    <div class="order-history" v-if="showLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import InviteModal from "./InviteModal.vue";
export default {
  name: "InvitesTable",
  components: {
    InviteModal,
  },
  data() {
    return {
      errorMessage: null,
      invitesArray: [],
      showLoader: 0,
      page: 1,
      perPage: 15,
      pages: [],
      pagesLength: 0,
      packagesList: [],
    };
  },
  props: {},
  methods: {
    getPackages() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getPackagesForInvitation`)
        .then((response) => {
          this.packagesList = response.data.data;
        });
    },
    callModalInvites: function() {
      this.$root.$emit("openInviteUserModal");
    },

    resendInvite(users) {
      this.showLoader = 1;
      var formData = new FormData();
      var UrlCall = "";

      if (users.user_profile !== null) {
        if (this.subscribedInfo && this.subscribedInfo.pack_type == 3) {
          formData.append("pro_user_email", users.target);
          UrlCall = "invite/resendInviteToProUser";
        } else if (this.subscribedInfo && this.subscribedInfo.pack_type == 1) {
          formData.append("super_user_email", users.target);
          UrlCall = "invite/resendInviteToSuperUser";
        }
      } else {
        if (this.subscribedInfo && this.subscribedInfo.pack_type == 3) {
          formData.append("target", users.target);
          UrlCall = "invite/resendInviteToProUser";
        } else if (this.subscribedInfo && this.subscribedInfo.pack_type == 1) {
          formData.append("target", users.target);
          UrlCall = "invite/resendInviteToSuperUser";
        }
      }

      return axios
        .post(process.env.VUE_APP_API_URL + UrlCall, formData, {})
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.successMessage = response.data.message;
            this.$alert(this.successMessage);
            this.fetchInvites(1);
          } else {
            this.showLoader = 0;
            if (response.data.message == "Email Already Registered") {
              this.showLoader = 0;
              this.errorMessageEmail = response.data.message;
              this.$alert(this.errorMessageEmail);
            } else {
              this.showLoader = 0;
              this.errorMessage = response.data.message;
              this.$alert(this.errorMessage);
            }
          }
        });
    },
    async fetchInvites(page = 1) {
      this.pages = [];
      this.showLoader = 1;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}invite/getProSuperInviteList?page=${page}`
        );
        if (response.data.statusCode === 200) {
          this.invitesArray = response.data.data.data;
          this.pagesLength = response.data.data.total;
          this.showLoader = 0;
          this.setPages(); // Move this line here
        } else {
          this.errorMessage = response.data.message;
          this.showLoader = 0;
        }
      } catch (error) {
        this.errorMessage = error;
        this.showLoader = 0;
      }
    },
    setPages() {
      let numberOfPages = Math.ceil(this.pagesLength / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(invitesArray) {
      let page = this.page;
      let perPage = this.perPage;
      let from = 0;
      let to = page * perPage;
      const slicedInvites = invitesArray.slice(from, to);
      return slicedInvites;
    },
  },
  computed: {
    ...mapGetters("auth", ["subscribedInfo"]),
    displayedinvitesArray() {
      const displayed = this.paginate(this.invitesArray);
      return displayed;
    },
    displayedPages() {
      const currentPage = this.page;
      const totalPageCount = this.pages.length;
      if (totalPageCount <= 5) {
        return this.pages; 
      } else {
        const firstPage = Math.max(1, currentPage - 1);
        const lastPage = Math.min(currentPage + 1, totalPageCount);
        return this.pages.slice(firstPage - 1, lastPage);
      }
    },
  },
  mounted() {
    this.getPackages();
    this.$root.$on("callInvitesApi", () => {
      this.fetchInvites(1);
    });
  },
  watch: {
    page(newPage) {
      this.fetchInvites(newPage);
    },
    posts() {
      this.setPages();
    },
  },
  created() {
    this.fetchInvites();
  },
  filters: {
    trimWords(value) {
      return (
        value
          .split(" ")
          .splice(0, 20)
          .join(" ") + "..."
      );
    },
  },
};
</script>

<style>
.order-history th {
  padding: 0.75rem 0 !important;
  text-align: left;
}
.order-history td {
  padding: 0.75rem 0 !important;
  text-align: left;
}
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 14px;
  color: #29b3ed;
  font-weight: 500;
  padding: 7px 11px;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
.order-history .order-content {
width: 100%;
  height: calc(100vh - 172px);
  overflow: auto;
}

.order-history .order-content .table-repsonsive-md {
  width: 220%;
}
.payment-status {
  display: flex;
  align-items: center;
  gap: 6px;
}
.custom-select{
width: 10px;
height: 10px;
border: 1px solid white;
border-radius: 5px;
margin-right: 3px;
margin-left: 3px;
}
.order-status-top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.order-status-top img {
  width: 20px;
  height: 20px;
}
.order-status-top p {
  color: #254c72;
}
.order-status-end {
  font-size: 13px;
  color: #557491;
  margin-top: 1.5px;
}
.orders-pagination-nav {
  float: right;
  margin-right: 10px;
}
</style>
