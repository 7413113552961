<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="single-event-page bg-white p-3 mb-3 rounded">
          <TournamentTop
            :tournamentData="tournamentData"
            :showLoader="showLoader"
            :currentIsAdmin="currentIsAdmin"
            :currentIsUmpire="currentIsUmpire"
            :tournament_id="tournament_id"
             />
          <TournamentTabs 
            :tournamentData="tournamentData"
            :showLoader="showLoader"
            />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import TournamentTop from '../components/Tournaments/TournamentTop.vue';
import TournamentTabs from '../components/Tournaments/TournamentTabs.vue';

export default {
  name: "Tournament",
  data() {
    return {
      showLoader: 1,
      tournamentData: {},
      currentIsAdmin: "0",
      currentIsUmpire: "0",
      tournament_id: 0,
    };
  },
  components: {
    Navbar,
    Banner,
    LeftMenu,
    TournamentTop,
    TournamentTabs,
  },
  methods: {
      async getTournamentDetail() {
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const tournamentGuid = segment_array.pop();
      this.showLoader = 1;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}tournaments/getSingleTournament`,
          { tournament_guid: tournamentGuid }
        );

        if (response.data.statusCode === 200) {
            this.tournamentData = response.data.data;
            this.tournament_id=response.data.data.id;

            if (this.tournamentData) {
              this.currentIsAdmin = (this.tournamentData.user_association_role) ? (this.tournamentData.user_association_role.role_id == 1) ? '1' : '0' : '0';
              this.currentIsUmpire = (this.tournamentData.user_association_role) ? (this.tournamentData.user_association_role.role_id == 2) ? '1' : '0' : '0';
            }
            this.showLoader = 0;
        } else if (response.data.statusCode === 404) {
          this.showLoader = 0;
          this.$alert("Tournament not found").then(() => {
            window.history.back();
          });
          } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
      } finally {
        this.showLoader = 0;
      }
    },
  },
  mounted() {
    this.getTournamentDetail();
    this.$root.$on("showTournamentForSwitch", () => {
      this.getTournamentDetail();
    });
  },
};
</script>