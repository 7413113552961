var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-images",attrs:{"id":_vm.photo.id}},[(_vm.imgLoader == 1)?_c('div',{staticClass:"shop-products-img-loader"},[_c('div',{staticClass:"lds-dual-ring-media"})]):_vm._e(),(_vm.photo.type == 'video/mp4')?_c('video',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover","border-radius":"5px","cursor":"pointer"},on:{"loadeddata":_vm.onImageLoad,"click":_vm.OpenVideoPreview}},[_c('source',{attrs:{"src":_vm.photo.url,"type":"video/mp4"}})]):_vm._e(),(_vm.photo.type == 'audio/mpeg' || _vm.photo.type == 'audio/mp3')?_c('img',{attrs:{"src":_vm.mp3_icon,"alt":""},on:{"load":_vm.onImageLoad,"click":_vm.OpenAudioPreview}}):_vm._e(),(
      _vm.photo.type == 'image/jpeg' ||
      _vm.photo.type == 'image/png' ||
      _vm.photo.type == 'image/jpg'
    )?_c('img',{attrs:{"src":_vm.photo.url,"alt":""},on:{"load":_vm.onImageLoad,"click":_vm.OpenPhotosPreview}}):_vm._e(),(_vm.openVideos)?_c('div',{staticClass:"photos-modal"},[_c('button',{staticClass:"close-icon btn btn-outline-danger btn-sm lb-modal-close",on:{"click":_vm.CloseVideoPreview}},[_c('i',{staticClass:"fa fa-times"})]),(_vm.photo.type == 'video/mp4')?_c('div',{on:{"click":_vm.CloseVideoPreview}},[_c('video',{staticStyle:{"width":"80%","margin":"0 auto","display":"flex","height":"100vh"},attrs:{"controls":"","autoplay":""}},[_c('source',{attrs:{"src":_vm.photo.url,"type":"video/mp4"}})])]):_vm._e()]):_vm._e(),(_vm.openAudios)?_c('div',{staticClass:"photos-modal"},[_c('button',{staticClass:"close-icon btn btn-outline-danger btn-sm lb-modal-close",on:{"click":_vm.CloseAudioPreview}},[_c('i',{staticClass:"fa fa-times"})]),(
        _vm.photo.type == 'audio/mpeg' ||
        _vm.photo.type == 'audio/ogg' ||
        _vm.photo.type == 'audio/mp3'
      )?_c('div',{on:{"click":_vm.CloseAudioPreview}},[_c('audio',{staticStyle:{"width":"80%","margin":"0 auto","display":"flex","height":"90vh"},attrs:{"controls":"","autoplay":""}},[_c('source',{staticClass:"d-none",attrs:{"src":_vm.photo.url,"type":"audio/mp3"}})])]):_vm._e()]):_vm._e(),(_vm.openPhotos)?_c('div',{staticClass:"photos-modal"},[_c('button',{staticClass:"close-icon btn btn-outline-danger btn-sm lb-modal-close",on:{"click":_vm.ClosePhotosPreview}},[_c('i',{staticClass:"fa fa-times"})]),_c('div',{staticClass:"photos-full-preview",on:{"click":_vm.ClosePhotosPreview}},[_c('img',{attrs:{"src":_vm.photo.url,"alt":""}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }