<template>
  <div>
    <EditGame :singleGame="singleGame" :eventAlertOptions="eventAlertOptions" />
    <div class="opt mt-0 ml-2 position-relative cursor-pointer">
      <img
        src="../../assets/images/ellipsis-h.png"
        alt=""
        @click="openGameOption"
        v-click-outside="onClickOutside"
      />
      <div class="sub-setting-options edit-event-options" v-if="gameOption">
        <ul class="mb-0 border-bottom-0 pb-0 d-block">
          <li
            class="del-game"
            data-toggle="modal"
            data-target="#exampleModalEdit"
            @click="checkUpdate"
          >
            Edit Game
          </li>
          <li class="del-game" @click="callModalLineup()">Lineup</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import EditGame from "../Teams/Games/EditGame.vue";
export default {
  name: "GameOptions",
  components: {
    EditGame,
  },
  data() {
    return {
      gameOption: false,
    };
  },
  props:{
    singleGame: {
        type: Object
    },
    eventAlertOptions: {
      type: Array,
    },
  },
  methods: {
    openGameOption() {
      this.gameOption = !this.gameOption;
    },
    onClickOutside() {
      this.gameOption = false;
    },
    checkUpdate() {
      this.$root.$emit("checkUpdateGame", this.singleGame);
    },
    callModalLineup: function() {
      this.$root.$emit("openTeamLineUpModal", this.singleGame);
    },
  },
};
</script>

<style>
</style>