<template>
  <div>
    <div>
      <button
        class="d-none"
        data-toggle="modal"
        data-target="#GameModal"
        ref="openTournamentGameModal"
      ></button>
      <div
        class="modal fade"
        id="GameModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog product-cart-modal" role="document">
          <div class="modal-content d-block">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Switch Game</h5>
              <button
                type="button"
                class="close cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body overflow-auto">
              <div v-if="showLoader === 1" class="card-event mt-3 p-3">
                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                </content-placeholders>

                <br />

                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                </content-placeholders>
              </div>
              <div v-else-if="gameList.length === 0 && showLoader === 0">
                <div class="no-chat-text">
                  <p>No game is scheduled yet</p>
                </div>
              </div>
              <div
                v-if="gameList.length > 0 && showLoader === 0"
                class="live-league-wrap p-3"
              >
                <div v-for="(schedule, index) in gameList" :key="index">
                  <div
                    class="cursor-pointer"
                    data-dismiss="modal"
                    @click="callSingleBoxModal(schedule)"
                  >
                    <div
                      class="live-league position-relative"
                      v-if="schedule?.game_live == 1"
                    >
                      <div class="inner-sec d-flex align-items-center">
                        <div class="delayed-tag" v-if="schedule.game_delayed == 1">
                            <span>Delayed</span>
                          </div>
                          <div class="live-tag" v-else>
                            <span>Live</span>
                          </div>
                          <div class="ml-2 delay-reason" v-if="schedule.game_delayed == 1 && schedule.delayed_reason !== '' && schedule.delayed_reason !== null">
                            <span>{{ schedule.delayed_reason }}</span>
                          </div>  
                        <!-- <div class="video-icon">
                          <img src="../../assets/images/video.png" alt="" />
                        </div> -->
                      </div>

                      <div class="league">
                        <span>{{ schedule?.game_name }}</span>
                      </div>
                    </div>

                    <div class="live-league position-relative" v-else>
                      <div class="inner-sec"></div>

                      <div class="league">
                        <span>{{ schedule?.game_name }}</span>
                      </div>
                    </div>

                    <div
                      class="schedule-info"
                      :class="{
                        'last-schedule-info': index === gameList.length - 1,
                      }"
                    >
                      <div>
                        <span class="day-date">{{
                          formatStartTime(
                            schedule.start_date,
                            schedule.start_time
                          )
                        }}</span>

                        <div class="location-info">
                          <img src="../../../assets/images/maximize.png"/>

                          <div class="content">
                            <p class="mb-2 cursor-pointer" >
                              {{ schedule?.park?.park_name }}, {{ schedule?.field  ? "- " + schedule?.field?.field_name : "" }}  ({{ schedule?.park?.address }})
                            </p>
                          </div>
                        </div>
                        <div class="opponent-info">
                          <div class="team-name d-flex align-items-center">
                          <div class="user-img mr-2">
                            <div class="users-notification" v-if="imgLoader == 1">
                              <img src="../../../assets/loader.gif" alt="" />
                            </div>
                            <img @load="onImageLoad" v-if="schedule?.team_one?.team_data?.team_avatar"
                            :src="url + schedule?.team_one?.team_data?.team_avatar" alt="" style="border: 2px solid white;" />
                            <img @load="onImageLoad" v-else alt="" src="../../../assets/team-avatar.png" />
                          </div>
                          #{{ schedule?.team_one?.team_sr_no }} {{ schedule?.team_one?.team_data?.team_name || 'TBD' }}

                          <span class="vs px-2"> vs </span>

                          <div class="user-img mr-2">
                            <div class="users-notification" v-if="imgLoader == 1">
                              <img src="../../../assets/loader.gif" alt="" />
                            </div>
                            <img @load="onImageLoad" v-if="schedule?.team_two?.team_data?.team_avatar"
                            :src="url + schedule?.team_two?.team_data?.team_avatar" alt="" style="border: 2px solid white;" />
                            <img @load="onImageLoad" v-else alt="" src="../../../assets/team-avatar.png" />
                          </div>
                          #{{ schedule?.team_two?.team_sr_no }} {{ schedule?.team_two?.team_data?.team_name || 'TBD' }}
                        </div>
                        
                          <!-- <span class="oppo-name">#{{
                            schedule?.team_one?.team_sr_no
                          }} {{
                            schedule?.team_one?.team_data?.team_name || 'TBD'
                          }}</span>
                          <span class="vs">vs</span>
                          <span class="oppo-name">#{{
                            schedule?.team_two?.team_sr_no
                          }} {{
                            schedule?.team_two?.team_data?.team_name || 'TBD'
                          }}</span> -->
                        </div>
                        <div
                          class="oppo-para"
                          v-if="
                            schedule?.comments !== null &&
                              schedule?.comments !== ''
                          "
                        >
                          {{ schedule?.comments }}
                        </div>
                      </div>
                      <div class="played-data text-right">
                        <span class="tie" v-if="schedule?.game_live == 1"
                          >{{ schedule?.team1Runs }} -
                          {{ schedule?.team2Runs }}</span
                        >

                        <span v-else-if="schedule?.game_live == 0">
                          <span
                            class="tie"
                            v-if="schedule?.team1Runs > schedule?.team2Runs"
                          >
                            W{{ schedule?.team1Runs }}</span
                          >
                          <span
                            class="day-date"
                            v-else-if="
                              schedule?.team1Runs < schedule?.team2Runs
                            "
                          >
                            L{{ schedule?.team1Runs }}</span
                          >

                          <span
                            class="tie"
                            v-if="schedule?.team2Runs > schedule?.team1Runs"
                          >
                            - W{{ schedule?.team2Runs }}</span
                          >
                          <span
                            class="day-date"
                            v-else-if="
                              schedule?.team2Runs < schedule?.team1Runs
                            "
                          >
                            - L{{ schedule?.team2Runs }}</span
                          >

                          <span
                            class="tie"
                            v-if="schedule?.team2Runs == schedule?.team1Runs"
                          >
                            {{ schedule?.team1Runs }} -
                            {{ schedule?.team2Runs }}</span
                          >
                        </span>
                        <p class="umpire-name text-capitalize">
                              Umpire
                              <span
                                v-if="
                                  schedule?.umpire?.fname &&
                                  schedule?.umpire?.lname
                                "
                              >
                                ({{ schedule?.umpire?.fname }}
                                {{ schedule?.umpire?.lname }})
                              </span>
                              <span v-else>(Not Assigned)</span>
                            </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
import axios from "axios";
export default {
  components: {},
  name: "TournamentGameListModal",

  data() {
    return {
      url: this.$groupAvatarStorageURL,
      imgLoader: 1,
      gameList: [], // Dummy game list
      singleGame: {},
      showLoader: 1,
      page: 1,
      lastPage: 1,
      totalGames: null,
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    async getGames() {
      this.showLoader = 1;

      var formData = new FormData();
      formData.append("tournament_guid", this.singleGame.tournament.guid);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "tournaments/getTournamentGames",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.gameList = this.gameList.concat(response.data.data);
          this.lastPage = response.data.data.last_page;
          this.totalGames = response.data.data.total;

          this.showLoader = 0;
        });
    },
    openParkGoogleMap(item) {
      let urlSuffix;
      if (item["lat"] && item["lng"]) {
        const strlatLng = item["lat"] + "," + item["lng"];
        urlSuffix = strlatLng;
      } else {
        urlSuffix = encodeURIComponent(
          item["address"] + ", " + item["city"] + ", " + item["zipCode"]
        );
      }
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    formatStartTime(startDate, startTime) {
      // If startDate or startTime is not provided, return an empty string
      if (!startDate || !startTime) return "";

      // Split startTime into hours, minutes, and AM/PM
      const [hours, minutes] = startTime
        .match(/(\d+):(\d+) ([AaPp][Mm])/)
        .slice(1);

      // Split startDate into year, month, and day
      const [year, month, day] = startDate.split("-").map(Number);

      // Create a new Date object using the components
      const date = new Date(
        year,
        month - 1,
        day,
        parseInt(hours),
        parseInt(minutes)
      );

      // Format the date using the specified format
      const formattedDate = date.toLocaleString("en-US", {
        weekday: "short",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });

      return formattedDate;
    },
    callSingleBoxModal(schedule) {
      var combineUrl = `${schedule.guid}`;
      this.$router.push({
        name: "TournamentScores",
        params: { guid: combineUrl },
      });
      this.$root.$emit("showSingleBoxScoreTournamentForGameSwitch");
    },
  },
  mounted() {
    // $(window).on("popstate", function() {
    //   $(".modal-backdrop").remove();
    //   $("body").removeClass("modal-open");
    // });
    this.$store.commit("setErrors", {});
    this.$root.$on("openTournamentGamesDataModal", (game) => {
      this.singleGame = game;
      this.gameList = [];
      this.getGames();
      var elem = this.$refs.openTournamentGameModal;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>