<template>
  <div class="info-wrapper" v-if="user">
    <div class="info-heading">
      <p>Short Info</p>
      <div class="profile-edit-section">
        <EditProfile />
      </div>
    </div>
    <div v-if="user.bio" class="info-content">
      <p>
        {{ user.bio }}
      </p>
    </div>
    <div v-else class="info-content">
      <p class="text-muted">Please Update Your Bio.</p>
    </div>
    <div class="personal-info">
      <div class="personal-info-heading">
        <span>Date of Birth</span>
        <span>Age</span>
        <span>Gender</span>
      </div>
      <div class="personal-info-content">
        <span v-if="user.dob">{{ user.dob | formatDateOfBirth }}</span>
        <span v-else>-</span>
        
        <span v-if="calculatedAge >= 0 && user.dob">{{ calculatedAge }} Yrs</span>
        <span v-else>-</span>
        
        <span v-if="user.gender" class="text-capitalize">{{ user.gender }}</span>
        <span v-else>-</span>
      </div>

    </div>
    <div class="location mb-0" style="border: none !important">
      <div class="key">
        <span>Address</span>
        <span>City</span>
      </div>
      <div class="value text-capitalize">
        <span v-if="user.address">{{ user.address }}</span>
        <span v-else>-</span>
        <span v-if="user.city">{{ user.city }}</span>
        <span v-else>-</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EditProfile from "../ProfilePage/EditProfile.vue";
export default {
  name: "Info",
  components: {
    EditProfile
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    calculatedAge() {
      if (!this.user.dob) return null;
      const dob = new Date(this.user.dob);
      const today = new Date();
      let age = today.getFullYear() - dob.getFullYear();
      const monthDiff = today.getMonth() - dob.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      return age;
    }
  },
};
</script>