var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-main"},[_c('div',{staticClass:"team-info team-right-info"},[_c('span',{staticClass:"main-back-arrow",on:{"click":_vm.showChat}},[_c('img',{attrs:{"src":require("../../../../assets/images/arrow-left.png"),"alt":""}})]),_c('div',{staticClass:"team-content-wrapper"},[_c('div',{staticClass:"image-wrapper"},[(_vm.showLoader == 1)?_c('div',{staticClass:"shop-products-img-loader"},[_c('div',{staticClass:"lds-dual-ring-media"})]):_vm._e(),(_vm.selectedRoomTeam.teamAvatar == '')?_c('img',{attrs:{"src":require("../../../../assets/images/main-team-avatar.png"),"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e(),(_vm.selectedRoomTeam.teamAvatar != '')?_c('img',{attrs:{"src":this.$groupAvatarStorageURL + _vm.selectedRoomTeam.teamAvatar,"alt":""},on:{"load":_vm.onImageLoad,"click":_vm.OpenTeamPhotoPreview}}):_vm._e()]),(_vm.openTeamPhoto)?_c('div',{staticClass:"cover-photo-modal"},[_c('button',{staticClass:"close-icon btn btn-outline-danger btn-sm lb-modal-close",on:{"click":_vm.CloseTeamPhotoPreview}},[_c('i',{staticClass:"fa fa-times"})]),_c('div',{staticClass:"cover-img-full-preview",on:{"click":_vm.CloseTeamPhotoPreview}},[(_vm.selectedRoomTeam.teamAvatar != '')?_c('img',{attrs:{"src":this.$groupAvatarStorageURL + _vm.selectedRoomTeam.teamAvatar,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e()])]):_vm._e(),_c('div',{staticClass:"user-details"},[_c('span',{staticClass:"team-name",attrs:{"id":_vm.selectedRoomTeam.id}},[_vm._v(" "+_vm._s(_vm.selectedRoomTeam.teamName)+" ")]),_c('div',[(
              _vm.selectedRoomTeam.teamType == 'sports' &&
              _vm.selectedRoomTeam.sportType != undefined
            )?_c('p',{staticClass:"team-type"},[_c('span',[_vm._v(_vm._s(_vm.selectedRoomTeam.teamType)+" ")]),_c('span',[_vm._v("("+_vm._s(_vm.selectedRoomTeam.sportType)+")")])]):_c('p',{staticClass:"team-type"},[_c('span',[_vm._v(_vm._s(_vm.selectedRoomTeam.teamType))])]),_c('p',{staticClass:"gender-group"},[(
                _vm.selectedRoomTeam.teamType == 'sports' &&
                _vm.selectedRoomTeam.sportType != undefined
              )?_c('span',[_vm._v(" "+_vm._s(_vm.selectedRoomTeam.gender)+" - ")]):_vm._e(),(
                _vm.selectedRoomTeam.teamType == 'sports' &&
                _vm.selectedRoomTeam.sportType != undefined
              )?_c('span',[_vm._v(" "+_vm._s(_vm.selectedRoomTeam.ageGroup))]):_vm._e()]),_c('p',{staticClass:"gender-group"},[_vm._v(" Created By "),_c('span',[_vm._v(_vm._s(_vm.selectedRoomTeam.createdBy))]),_vm._v(" on "),_c('span',[_vm._v(_vm._s(_vm._f("formatDateChat")(_vm.selectedRoomTeam.createdAt)))])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }