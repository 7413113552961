var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PinModal'),_c('div',{staticClass:"media-wrapper"},[(_vm.showLinkShimmer == 1)?_c('div',{staticClass:"lds-dual-ring-media"}):_vm._e(),(_vm.messages.length != 0 && _vm.showLinkShimmer == 0)?_c('div',_vm._l((_vm.messages),function(link,index){return _c('div',{key:index,staticClass:"link-content"},[_c('div',{staticClass:"content-sec",attrs:{"id":link.id}},[_vm._m(0,true),_c('div',[_c('a',{attrs:{"href":link.content,"target":"_blank"}},[_vm._v(_vm._s(link.content))]),_c('h6',[_vm._v(_vm._s(link.username))])])]),(link.senderId === _vm.currentUserId)?_c('div',{staticClass:"add-to-pin"},[_c('img',{attrs:{"src":require("../../../../../assets/images/add-to-pin-2.png"),"alt":""},on:{"click":function($event){return _vm.callModal(link)}}}),_c('img',{attrs:{"src":require("../../../../../assets/images/cross.png"),"alt":""},on:{"click":function($event){return _vm.deleteMessage({
                  message: link,
                  roomId: _vm.selectedRoomTeam.roomId,
                })}}})]):_vm._e()])}),0):(_vm.messages.length == 0 && _vm.showLinkShimmer == 0)?_c('div',{staticClass:"no-content"},[(_vm.showTeams == 0)?_c('div',[_vm._m(1),_c('p',{staticClass:"header"},[_vm._v("No Links")]),_c('p',{staticClass:"text"},[_vm._v(" All external links shared with this team will appear here. ")])]):_vm._e(),(_vm.showIndividual == 0)?_c('div',[_vm._m(2),_c('p',{staticClass:"header"},[_vm._v("No Links")]),_c('p',{staticClass:"text"},[_vm._v(" All external links shared with this chat will appear here. ")])]):_vm._e()]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-content-img"},[_c('img',{attrs:{"src":require("../../../../../assets/images/link.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../../assets/images/media-link.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../../assets/images/media-link.png"),"alt":""}})])
}]

export { render, staticRenderFns }