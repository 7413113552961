<template>
  <div>
    <div class="friends-top mt-3">
      <div class="friends-left mb-3 mb-md-0">
        <router-link to="/profile" class="m-0">
          <img src="../../assets/images/arrow-left.png" alt="" />
        </router-link>
        <h3>Friends</h3>
      </div>
      <div class="form-inline">
        <button>
          <img
            class="search-image"
            src="../../assets/images/search.png"
            alt=""
          />
        </button>
        <input
          v-model="searchValue"
          class="form-control border-0"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
      </div>
    </div>
    <div class="friends-options pt-2">
      <ul class="options-list">
        <li>
          <a
            :class="{
              active: isActiveFriends,
              'disabled-button': isButtonExecuting,
            }"
            @click="switchTabs('friends')"
            data-tab="friends"
            class="m-0"
          >
            All Friends
            <span v-if="friendsList">({{ friendsList.length }})</span>
          </a>
        </li>
        <li>
          <a
            :class="{
              active: isActiveFollower,
              'disabled-button': isButtonExecuting,
            }"
            @click="switchTabs('followers')"
            data-tab="followers"
            class="m-0"
            >Followers
            <span>({{ followerListCount }})</span>
          </a>
        </li>
        <li>
          <a
            :class="{
              active: isActiveFollowing,
              'disabled-button': isButtonExecuting,
            }"
            @click="switchTabs('following')"
            data-tab="following"
            class="m-0"
            >Following
            <span>({{ followingListCount }})</span>
          </a>
        </li>
        <li v-if="friendsRequestsList">
          <a
            :class="{
              active: isActiveRequest,
              'disabled-button': isButtonExecuting,
            }"
            @click="switchTabs('request')"
            data-tab="request"
            class="m-0"
            >Requests Received
            <span>({{ friendsRequestscount }})</span>
          </a>
        </li>
        <li v-if="friendsRequestsList">
          <a
            :class="{
              active: isActiveRequestSent,
              'disabled-button': isButtonExecuting,
            }"
            @click="switchTabs('requestSent')"
            data-tab="requestSent"
            class="m-0"
            >Requests Sent
            <span>({{ friendsRequestsSendcount }})</span>
          </a>
        </li>
      </ul>

      <div
        class="ui bottom attached tab segment"
        data-tab="friends"
        :class="{ active: isActiveFriends }"
        v-if="isActiveFriends"
        ref="friendsTab"
      >
        <!-- <div v-if="showLoader == 0"> -->
          <friends-list
            ref="friendsListComponent"
            v-if="filteredFriends"
            :items="filteredFriends"
            :isButtonExecuting="isButtonExecuting"
            :showLoader="showLoader"
          />
        <!-- </div>
        <friends-shim v-if="showLoader == 1" /> -->
      </div>
      <div
        class="ui bottom attached tab segment"
        data-tab="followers"
        :class="{ active: isActiveFollower }"
        v-if="isActiveFollower"
      >
        <!-- <div v-if="shims == 0"> -->
          <followers-list v-if="filteredFollower" :items="filteredFollower" :loader="loader" />
        <!-- </div>
        <friends-shim v-if="shims == 1" /> -->
      </div>
      <div
        class="ui bottom attached tab segment"
        data-tab="following"
        :class="{ active: isActiveFollowing }"
        v-if="isActiveFollowing"
      >
        <!-- <div v-if="shims == 0"> -->
          <following v-if="filteredFollowing" :items="filteredFollowing" :loader="loader" />
        <!-- </div>
        <friends-shim v-if="shims == 1" /> -->
      </div>
      <div
        class="ui bottom attached tab segment"
        data-tab="request"
        :class="{ active: isActiveRequest }"
        v-if="isActiveRequest"
        ref="requestAdd"
      >
        <!-- <div v-if="showLoader == 0"> -->
          <friendRequest
            v-if="filteredRequestList"
            :items="filteredRequestList"
            :isButtonExecuting="isButtonExecuting"
            :showLoader="showLoader"
            :loader="loader"
          />
        <!-- </div>
        <friends-shim v-if="showLoader == 1" /> -->
      </div>
      <div
        class="ui bottom attached tab segment"
        data-tab="requestSent"
        :class="{ active: isActiveRequestSent }"
        v-if="isActiveRequestSent"
      >
        <!-- <div v-if="shims == 0"> -->
          <pending-request
            v-if="filteredSentList"
            :items="filteredSentList"
            :isButtonExecuting="isButtonExecuting"
            :loader="loader"
          />
        <!-- </div>
        <friends-shim v-if="shims == 1" /> -->
      </div>
      <div
        v-observe-visibility="handleScrolledToButtomFollowers"
        v-if="followerList.length"
      ></div>

      <div
        v-observe-visibility="handleScrolledToButtomFollowing"
        v-if="followingList.length"
      ></div>

      <div
        v-observe-visibility="handleScrolledToButtomFriendsRequestSent"
        v-if="friendsRequestsSendList.length"
      ></div>

      <div
        v-observe-visibility="handleScrolledToButtomFriendsRequestListing"
        v-if="friendsRequestsList.length"
      ></div>
    </div>
  </div>
</template>
<script>
import FollowersList from "./followersList.vue";
import Following from "./following.vue";
import FriendsList from "./FriendsList.vue";
import friendRequest from "./friendRequest.vue";
import PendingRequest from "./pendingRequest.vue";
import axios from "axios";
// import FriendsShim from "./friendsShim.vue";

export default {
  components: {
    FriendsList,
    FollowersList,
    Following,
    friendRequest,
    PendingRequest,
  },
  name: "FriendsOptions",
  props: {
    friendsList: Array,
    friendsRequestscount: Number,
    friendsRequestsSendcount: Number,
    followingListCount: Number,
    followerListCount: Number,
    showLoader: Number,
  },
  computed: {
    filteredFriends() {
      let tempProds = this.friendsList;

      // Process search input
      if (this.searchValue != "" && this.searchValue) {
        tempProds = tempProds.filter((item) => {
          return item.user_name
            .toUpperCase()
            .includes(
              this.searchValue.toUpperCase().trim().replace(/\s+/g, " ")
            );
        });
      }
      return tempProds;
    },
    filteredFollower() {
      let tempProds = this.followerList;

      // Process search input
      if (this.searchValue != "" && this.searchValue) {
        tempProds = tempProds.filter((item) => {
          return item.user_name
            .toUpperCase()
            .includes(
              this.searchValue.toUpperCase().trim().replace(/\s+/g, " ")
            );
        });
      }
      return tempProds;
    },
    filteredFollowing() {
      let tempProds = this.followingList;

      // Process search input
      if (this.searchValue != "" && this.searchValue) {
        tempProds = tempProds.filter((item) => {
          return item.user_name
            .toUpperCase()
            .includes(
              this.searchValue.toUpperCase().trim().replace(/\s+/g, " ")
            );
        });
      }
      return tempProds;
    },
    filteredRequestList() {
      let tempProds = this.friendsRequestsList;

      // Process search input
      if (this.searchValue != "" && this.searchValue) {
        tempProds = tempProds.filter((item) => {
          return item.name
            .toUpperCase()
            .includes(
              this.searchValue.toUpperCase().trim().replace(/\s+/g, " ")
            );
        });
      }
      return tempProds;
    },
    filteredSentList() {
      let tempProds = this.friendsRequestsSendList;

      // Process search input
      if (this.searchValue != "" && this.searchValue) {
        tempProds = tempProds.filter((item) => {
          return item.name
            .toUpperCase()
            .includes(
              this.searchValue.toUpperCase().trim().replace(/\s+/g, " ")
            );
        });
      }
      return tempProds;
    },
  },
  data() {
    return {
      isButtonExecuting: true,
      isActiveFriends: true,
      isActiveFollower: false,
      isActiveFollowing: false,
      isActiveRequest: false,
      isActiveRequestSent: false,
      friendsRequestsList: [],
      friendsRequestsSendList: [],
      user_link: "",
      followerList: [],
      followingList: [],
      shims: 0,
      searchValue: "",
      page: 1,
      lastPage: 1,
      size: 18,
      loader: 0,
    };
  },
  methods: {
    switchTabs(tab) {
      this.searchValue = "";
      this.isButtonExecuting = true;
      this.isActiveFriends = tab === "friends";
      this.isActiveFollower = tab === "followers";
      this.isActiveFollowing = tab === "following";
      this.isActiveRequest = tab === "request";
      this.isActiveRequestSent = tab === "requestSent";
      this.followerList = [];
      this.followingList = [];
      this.friendsRequestsList = [];
      this.friendsRequestsSendList = [];
      this.page = 1;
      this.lastPage = 1;
      if (tab === "friends") {
        this.isButtonExecuting = false;
        this.isActiveFriends = true;
        this.isActiveFollower = false;
        this.isActiveFollowing = false;
        this.isActiveRequestSent = false;
        this.isActiveRequest = false;
      } else if (tab === "followers") {
        this.followersGet(1);
      } else if (tab === "following") {
        this.followingGet(1);
      } else if (tab === "request") {
        this.friendRequestsListing(1);
      } else if (tab === "requestSent") {
        this.friendRequestSent(1);
      }
    },

    async friendRequestsListing() {
      this.loader = 1;
      this.isButtonExecuting = true;
      let friendReq = await axios.get(
        `friends/friendRequestReceivedListing?page=${this.page}&size=${this.size}`
      );

      if (friendReq.data.statusCode == 200) {
        this.friendsRequestsList.push(...friendReq.data.data.listing.data);
        this.lastPage = friendReq.data.data.listing.last_page;
        this.loader = 0;
        this.isButtonExecuting = false;
      } else {
        this.loader = 0;
        this.isButtonExecuting = false;
      }
    },
    async friendRequestSent() {
      this.loader = 1;
      this.isButtonExecuting = true;
      let friendReqsent = await axios.get(
        `friends/friendRequestSentListing?page=${this.page}&size=${this.size}`
      );

      if (friendReqsent.data.statusCode == 200) {
        this.loader = 0;
        this.friendsRequestsSendList.push(
          ...friendReqsent.data.data.listing.data
        );
        this.lastPage = friendReqsent.data.data.listing.last_page;
        this.isButtonExecuting = false;
      } else {
        this.loader = 0;
        this.isButtonExecuting = false;
      }
    },
    async followersGet() {
      this.loader = 1;
      this.isButtonExecuting = true;
      this.user_link = localStorage.getItem("user_link");
      var formData = new FormData();
      formData.append("user_link", this.user_link);
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            `friends/viewFollowers?page=${this.page}&user_link=${this.user_link}`,
          formData,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.loader = 0;
            this.followerList.push(...response.data.data.followers.data);
            this.lastPage = response.data.data.followers.last_page;
            this.isButtonExecuting = false;
          } else {
            this.loader = 0;
            this.errorMessage = response.data.message;
            this.isButtonExecuting = false;
          }
        });
    },
    async followingGet() {
      this.loader = 1;
      this.isButtonExecuting = true;
      this.user_link = localStorage.getItem("user_link");
      var formData = new FormData();
      formData.append("user_link", this.user_link);
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            `friends/viewFollowing?page=${this.page}&user_link=${this.user_link}`,
          formData,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.loader = 0;
            this.followingList.push(...response.data.data.followers.data);
            this.lastPage = response.data.data.followers.last_page;
            this.isButtonExecuting = false;
          } else {
            this.loader = 0;
            this.errorMessage = response.data.message;
            this.isButtonExecuting = false;
          }
        });
    },
    handleScrolledToButtomFollowers(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.followersGet();
    },

    handleScrolledToButtomFollowing(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.followingGet();
    },

    handleScrolledToButtomFriendsRequestSent(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.friendRequestSent();
    },

    handleScrolledToButtomFriendsRequestListing(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.friendRequestsListing();
    },
  },

  mounted() {
    if (localStorage.getItem("openFriends") == "request") {
      localStorage.setItem("openFriends", "");
      this.friendRequestsListing(1);
      this.isActiveFriends = false;
      this.isActiveFollower = false;
      this.isActiveFollowing = false;
      this.isActiveRequestSent = false;
      this.isActiveRequest = true;
    }
    this.$root.$on("FriendsOptions", () => {
      this.isButtonExecuting = true;
      if (this.isActiveFollower == true) {
        this.followerList = [];
        this.page = 1;
        this.lastPage = 1;
        this.followersGet(1);
      }
      if (this.isActiveFollowing == true) {
        this.followingList = [];
        this.page = 1;
        this.lastPage = 1;
        this.followingGet(1);
      }
      if (this.isActiveRequest == true) {
        this.friendsRequestsList = [];
        this.page = 1;
        this.lastPage = 1;
        this.friendRequestsListing(1);
      }
    });
    this.$root.$on("enableFriendsButton", () => {
      this.isButtonExecuting = false;
    });
  },
};
</script>
