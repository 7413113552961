<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side p-md-0 pb-3">
        <ProductsList
          v-if="showLoader == 0"
          :catProducts="catProducts"
          :products="shopProducts"
        />
        <div class="col-12 px-0 px-md-4 pb-md-4" v-if="showLoader == 1">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3 mt-md-4 pr-md-0">
              <div class="products-list-shim">
                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0">
              <div class="products-list-shim">
                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0">
              <div class="products-list-shim">
                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0 pr-lg-3">
              <div class="products-list-shim">
                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0">
              <div class="products-list-shim">
                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0">
              <div class="products-list-shim">
                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0">
              <div class="products-list-shim">
                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-4 pr-md-0 pr-lg-3">
              <div class="products-list-shim">
                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import ProductsList from "../components/Shop/ProductsList.vue";
import axios from "axios";
import { mapActions } from 'vuex';
import Banner from "../components/BannerNotify/BannerPage.vue";

export default {
  components: {
    Navbar,
    LeftMenu,
    ProductsList,
    Banner
  },
  data() {
    return {
      shopProducts: [],
      catProducts: [],
      errorMessage: "",
      showLoader: 0,
    };
  },
  methods: {
    ...mapActions({
      getTaxShippingData: "cart/getTaxShippingData",
    }),
    async fetchShop() {
      this.showLoader = 1;
      let shopResults = await axios.get(`shop/`);
      if (shopResults.data.statusCode == 200) {
        this.showLoader = 0;
        this.shopProducts = shopResults.data.data.products.data;
        this.catProducts = shopResults.data.data.allCatgories;
      } else {
        this.showLoader = 0;
        this.errorMessage = shopResults.data.message;
      }
    },
  },
  mounted() {
    this.getTaxShippingData();
    window.scrollTo(0, 0);
    this.fetchShop();
  },
};
</script>
<style scoped>
.products-wrapper {
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  padding: 10px 15px;
  /* margin-bottom: 5px; */
}
@media screen and (max-width: 767px) {
  .products-wrapper {
    margin-bottom: 10px;
  }
}
.name-for-product {
  margin-top: 40%;
}
.image-for-product {
  height: 240px;
}

@media screen and (max-width: 768px) {
  .shop-container {
    padding-right: 0 !important;
  }
}
</style>
