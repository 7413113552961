<template>
  <div>
    <FlashMessage
      :position="'right bottom'"
      style="z-index: 99999999 !important"
    ></FlashMessage>
    <div class="product-description-wrapper" v-if="productdetails">
      <div class="description-text">
        <router-link to="/shop" exact class="back-link d-none d-md-block">
          <img src="../../../assets/images/arrow-left.png" alt="" />
          <span>Back to products</span></router-link
        >
        <div
          class="badge-overlay"
          v-if="productProduct.products.avail_status == '1'"
        >
          <span class="top-right badge red">Out of Stock</span>
        </div>
        <!-- <div class="" v-if="productProduct">
          <div class="product-image-detail">
            <img
              v-if="productProduct.products.image != null"
              :src="API_URL + productProduct.products.image"
              alt=""
            />
            <img
              class="default-product-img"
              v-else
              src="../../../assets/images/gallery.png"
              alt=""
            />
          </div>
        </div> -->
        <div class="heading">
          <h6>{{ productdetails.products.name }}</h6>
        </div>
        <div class="description">
          <p>
            {{ productdetails.products.product_description }}
          </p>
        </div>
        <div class="price-category">
          <div class="category">
            <div class="category-heading">
              <span>Category:</span>
              <span>Sub Category:</span>
            </div>
            <div class="category-content">
              <span>{{ productdetails.catgories.name }}</span>
              <span>{{ productdetails.subcatgories.name }}</span>
            </div>
          </div>
        </div>
        <div class="price-category">
          <span>${{ productdetails.products.price }}</span>
        </div>
        <ProductSize
          v-if="productdetails.product_size.length > 0"
          :productSize="productdetails.product_size"
        />
      </div>
      <div class="product-actions">
        <div class="product-inc-dec">
          <img
            src="../../../assets/images/minus.png"
            alt=""
            @click="productdecrement()"
          />
          <div class="product-value">
            <span>{{ productQuantity }}</span>
          </div>

          <img
            src="../../../assets/images/plus.png"
            alt=""
            @click="productIncrement()"
          />
        </div>
        <div v-if="alreadyExist == 'no'" class="add-to-cart-btn">
          <div
            v-if="productdetails.products.avail_status == null"
            class="add-to-cart-wrapper"
            @click="addToCart()"
          >
            <div class="cart-btn">Add to cart</div>
          </div>
          <div
            v-if="productdetails.products.avail_status == '1'"
            class="add-to-cart-wrapper"
            @click="getMeNotify(productdetails.products)"
          >
            <div class="cart-btn">Notify Me</div>
          </div>
        </div>
        <div v-if="alreadyExist == 'yes'" class="add-to-cart-btn">
          <div class="add-to-cart-wrapper cursor-no-drop">
            <div class="cart-btn cursor-no-drop">Added to Cart.</div>
          </div>
        </div>
        <!-- <div class="col-2 col-sm-2 text-right my-2"> -->
        <!-- <div class="like">
              <img src="../../../assets/like-red.png" alt="" />
            </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import ProductSize from "./ProductSize.vue";
export default {
  name: "ProductDescription",
  components: {
    ProductSize,
  },
  props: {
    productdetails: {
      type: Object,
    },
    productProduct: {
      type: Object,
    },
  },
  computed: {
    ...mapState("cart", ["cart"]),
    ...mapState("cart", ["currentProduct"]),
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions({
      addProductToCart: "cart/addProductToCart",
      checkProductExists: "cart/checkProductExists",
      increaseQuantityFromCart: "cart/increaseQuantityFromCart",
      decreaseQuantityFromCart: "cart/decreaseQuantityFromCart",
    }),

    addToCart() {
      if (this.productdetails.product_size.length > 0 && this.selectedSize == "" || this.selectedSize == null) {
        this.flashMessage.error({
          title: "Error",
          message: "Please select size for this product",
          time: 5000,
          blockClass: "custom-block-class",
        });
        return;
      }
            
      const formData = new FormData();
      formData.append("product_id", this.productdetails.products.id);
      formData.append("product_quantity", this.productQuantity);
      formData.append("product_size", this.selectedSize.id);
      axios
        .post(process.env.VUE_APP_API_URL + "shop/addToCart", formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.addProductToCart({
              product: this.productdetails,
              quantity: this.productQuantity,
              size: this.selectedSize,
            });
            this.flashMessage.success({
              title: "Success",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
            this.alreadyExist = "yes";
          } else {
            this.flashMessage.error({
              title: "Error",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
          }
        });
    },
    productIncrement() {
      if (this.cart.length > 0) {
        if (this.alreadyExist == "yes") {
          return;
        }
        const formData = new FormData();
        formData.append("product_id", this.productdetails.products.id);
        formData.append("product_quantity", 1);
        // axios
        //   .post(process.env.VUE_APP_API_URL + "shop/addToCart", formData)
        //   .then((response) => {
        //     if (response.data.statusCode == 200) {
        this.productQuantity = this.productQuantity + 1;
        this.increaseQuantityFromCart({
          product: this.productdetails,
          size: this.selectedSize,
        });
        //     this.flashMessage.success({
        //       title: "Success",
        //       message: response.data.message,
        //       time: 5000,
        //       blockClass: "custom-block-class",
        //     });
        //   }  else {
        //     this.flashMessage.error({
        //       title: "Error",
        //       message: response.data.message,
        //       time: 5000,
        //       blockClass: "custom-block-class",
        //     });
        //   }
        // });
      } else {
        this.productQuantity = this.productQuantity + 1;
        this.increaseQuantityFromCart({
          product: this.productdetails,
          size: this.selectedSize,
        });
      }
    },
    getMeNotify(product) {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}shop/notifyMe?id=${product.id}&user_id=${this.user.id}`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.flashMessage.success({
              title: product.name,
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
          } else {
            this.flashMessage.error({
              title: "Error" || "Error",
              message: response.data.message,
            });
          }
        });
    },
    productdecrement() {
      var newQuantity = this.productQuantity - 1;
      if (this.alreadyExist == "yes") {
        return;
      }
      if (newQuantity > 0) {
        const formData = new FormData();
        formData.append("product_id", this.productdetails.products.id);
        formData.append("product_quantity", -1);
        // axios
        // .post(process.env.VUE_APP_API_URL + "shop/addToCart", formData)
        // .then((response) => {
        //   if (response.data.statusCode == 200) {
        this.productQuantity = this.productQuantity - 1;
        this.decreaseQuantityFromCart({
          product: this.productdetails,
        });
        //     this.flashMessage.success({
        //       title: "Success",
        //       // message: response.data.message,
        //       time: 5000,
        //       blockClass: "custom-block-class",
        //     });
        //   } else {
        //     this.flashMessage.error({
        //       title: "Error",
        //       // message: response.data.message,
        //       time: 5000,
        //       blockClass: "custom-block-class",
        //     });
        //   }
        // });
      }
    },
  },
  data() {
    return {
      API_URL: this.$productImageStorageURL,
      productQuantity: 1,
      alreadyExist: "no",
      selectedSize: "",
    };
  },

  watch: {
    productdetails: function () {
      this.checkProductExists({
        product: this.productdetails,
        size: this.selectedSize
      });
      if (this.currentProduct) {
        this.productQuantity = this.currentProduct.quantity;
        this.alreadyExist = "yes";
      }
    },
  },

  mounted() {
    this.$root.$on("selectedSize", (size) => {
      this.selectedSize = size;
      if (this.selectedSize) {
        this.alreadyExist = "no";
      } 
    });
    this.$root.$on("checkProductDetailsAgain", () => {
      this.checkProductExists({
        product: this.productdetails,
        size: this.selectedSize
      });

      if (this.currentProduct) {
        this.productQuantity = this.currentProduct.quantity;
        this.alreadyExist = "yes";
      } else {
        this.productQuantity = 1;
        this.alreadyExist = "no";
      }
    });
  },
};
</script>
