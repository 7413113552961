<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#editBatting_Modal"
      ref="openModaleditBatting"
    ></button>
    <div
      class="modal fade"
      id="editBatting_Modal"
      role="dialog"
      aria-labelledby="editBatting_Modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="editBatting_ModalLabel">
              Update Batting Stats
            </h6>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalEditBatting"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="invalid" v-if="errorMessage">
            {{ errorMessage }}
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="user-info col-md-6 mb-3 pr-md-0">
                <p>Name: {{ user?.name }}</p>
              </div>
              <div class="col-md-6 mb-3 pr-md-0">
                <p>Uniform Number : {{ userRole?.uniform }}</p>
              </div>
              <div class="col-md-6 pr-md-0">
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control message bg-white"
                    id="uniform"
                    v-model="battingStats.ab"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">AB</span>
                </div>
              </div>
              <div class="col-md-6 pl-md-2">
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control message bg-white"
                    id="bat_details"
                    v-model="battingStats.h"
                    required
                    v-limit-digits
                    readonly
                    disabled
                    style="padding-left: 20px;"
                    title="disabled"
                  
                  />
                  <span class="placeholder bg-white p-0" style="margin-left: -5px; top: -22.5px;">H</span>
                </div>
              </div>

              <div class="col-md-6 pr-md-0">
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control message bg-white"
                    id="bat_details"
                    v-model="battingStats.one_b"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">1B</span>
                </div>
              </div>
              <div class="col-md-6 pl-md-2">
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control message bg-white"
                    id="hat_size"
                    v-model="battingStats.two_b"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">2B</span>
                </div>
              </div>
              <div class="col-md-6 pr-md-0">
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control message bg-white"
                    id="shirt_size"
                    v-model="battingStats.three_b"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">3B</span>
                </div>
              </div>
              <div class="col-md-6 pl-md-2">
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control message bg-white"
                    id="pant_size"
                    v-model="battingStats.hr"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">HR</span>
                </div>
              </div>
              <div class="col-md-6 pr-md-0">
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control message bg-white"
                    id="shirt_size"
                    v-model="battingStats.rbi"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">RBI</span>
                </div>
              </div>
              <div class="col-md-6 pl-md-2">
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control message bg-white"
                    id="pant_size"
                    v-model="battingStats.r"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">R</span>
                </div>
              </div>
              <div class="col-md-6 pr-md-0">
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control message bg-white"
                    id="shirt_size"
                    v-model="battingStats.bb"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">BB</span>
                </div>
              </div>
              <div class="col-md-6 pl-md-2">
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control message bg-white"
                    id="pant_size"
                    v-model="battingStats.sac"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">SAC</span>
                </div>
              </div>
              <div class="col-md-6 pr-md-0">
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control message bg-white"
                    id="shirt_size"
                    v-model="battingStats.solo_hr"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">Solo HR</span>
                </div>
              </div>
              <div class="col-md-6 pl-md-2">
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control message bg-white"
                    id="shirt_size"
                    v-model="battingStats.e"
                    required
                    v-limit-digits
                  />
                  <span class="placeholder">E</span>
                </div>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              v-on:click="update(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="(true, isLoadingArray[0])"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "EditStats",
  data: function() {
    return {
      battingStats: {},
      isDisabled: true,
      userRole: {},
      user: {},
      errorMessage: null,
      hasChangedOnce: false,
      isLoadingArray: [],
    };
  },
  watch: {
    battingStats() {
      this.isDisabled = false;
    },
  },
  directives: {
    limitDigits: {
    bind(el, binding, vnode) {
      el.addEventListener("input", function(event) {
        const maxLength = 4; // Change this value to limit to a different number of digits
        const input = event.target;
        const inputValue = input.value.replace(/\D/g, ''); // Remove non-numeric characters
        if (inputValue.length > maxLength) {
          input.value = inputValue.slice(0, maxLength);
        } else {
          input.value = inputValue;
          vnode.context.battingStats[binding.expression] = parseInt(
            inputValue,
            10
          ); // Update the data model
        }
      });
    },
  },
  },
  methods: {
    clearToGetData(singleLineup, index) {
      if (singleLineup) {
        this.battingStats = {};
        this.battingStats.id = singleLineup.id;
        this.battingStats.ab = singleLineup.ab;
        this.battingStats.h = singleLineup.h;
        this.battingStats.one_b = singleLineup.one_b;
        this.battingStats.two_b = singleLineup.two_b;
        this.battingStats.three_b = singleLineup.three_b;
        this.battingStats.hr = singleLineup.hr;
        this.battingStats.rbi = singleLineup.rbi;
        this.battingStats.r = singleLineup.r;
        this.battingStats.bb = singleLineup.bb;
        this.battingStats.sac = singleLineup.sac;
        this.battingStats.e = singleLineup.e;
        this.battingStats.solo_hr = singleLineup.solo_hr;
        this.errorMessage = null;
        this.isDisabled = true;
        this.$set(this.isLoadingArray, index, false);
      } else {
        this.errorMessage = null;
        this.isDisabled = true;
        this.$set(this.isLoadingArray, index, false);
        this.battingStats = {};
      }
    },
    async update(index) {
      this.$set(this.isLoadingArray, index, true);
      this.isDisabled = true;
      await axios
        .post(
          process.env.VUE_APP_API_URL + "game/updateBattingStats",
          this.battingStats,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            var elem = this.$refs.closeModalEditBatting;
            elem.click();
            this.$root.$emit("callLineupAfterUpdate");
            this.$set(this.isLoadingArray, index, false);
            this.isDisabled = false;
          }
        })
        .catch((error) => {
          // Handle the error here
          if (error.response && error.response.status === 500) {
            this.$set(this.isLoadingArray, index, false);
            this.isDisabled = false;
            this.errorMessage =
              "Oops! Something went wrong on the server. Please try again later.";
          } else {
            this.errorMessage =
              "Oops! Something went wrong on the server. Please try again later.";
            this.$set(this.isLoadingArray, index, false);
            this.isDisabled = false;
          }
        });
    },
  },
  mounted() {
    this.$root.$on("openeditBattingModal", (singleLineup) => {
      console.log(singleLineup);
      this.clearToGetData(singleLineup);
      var elem = this.$refs.openModaleditBatting;
      if (elem) {
        elem.click();
      }
      this.userRole = singleLineup.userRole;
      this.user = singleLineup.user;
      console.log(this.user)
    });
  },
};
</script>

<style></style>
