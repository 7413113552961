<template>
  <div>
    <div
      class="change-cover-wrapper"
      data-toggle="modal"
      data-target="#changeCoverPhotoModal"
      v-on:click="resetForm"
    >
      <div class="change-icon">
        <img src="../../assets/images/cover-image.png" alt="" />
      </div>
      <div class="change-text ml-2">
        <span>Change Cover</span>
      </div>
    </div>

    <div
      class="modal fade bd-example-modal-lg"
      id="changeCoverPhotoModal"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Update Cover Photo</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalPost"
              v-on:click="resetForm"
            >
              <img src="../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="cover-preview">
              <div class="cover-photo-edit" v-if="user" @click="openinput()">
                <img
                  v-if="user.coverphoto == 'yes' && oldCoverPicture == true"
                  :src="API_URL + user.id + '/cover_photo.jpg?v=' + time"
                  alt=""
                />
                <img
                  v-else-if="oldCoverPicture == true"
                  src="../../assets/cover-default.png"
                  alt=""
                  class="default-image"
                />

                <form>
                  <input
                    type="file"
                    id="vue-file-upload-input-cover-photo"
                    @change="onFileChange"
                    class="d-none"
                    accept="image/png, image/gif, image/jpeg"
                  />

                  <div
                    id="preview"
                    v-if="oldCoverPicture == false"
                    class="new-cover-photo"
                  >
                    <clipper-basic
                      :src="url"
                      preview="preview"
                      :border="2"
                      :outline="0"
                      :corner="false"
                      :ratio="4 / 2"
                      :wrap-ratio="4 / 2"
                      ref="clipper"
                    ></clipper-basic>
                  </div>

                  <div
                    class="cover-photo-edit-icon-wrapper"
                    
                  >
                    <img src="../../assets/images/edit.png" alt="" />
                  </div>
                </form>
              </div>
              <div class="cover-preview-image">
                <div v-if="oldCoverPicture == false">
                  <clipper-preview name="preview"></clipper-preview>
                </div>
                <div
                  v-else-if="oldCoverPicture == true"
                  class="no-image-wrapper"
                >
                  <div slot="placeholder">No image</div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper justify-content-end p-3">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="updateCover(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { clipperBasic, clipperPreview } from "vuejs-clipper";
export default {
  name: "ChangeCover",
  components: {
    clipperBasic,
    clipperPreview,
  },
  data() {
    return {
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      url: null,
      isLoadingArray: [],
      coverphoto: null,
      oldCoverPicture: true,
      isDisabled: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    resetForm() {
      this.isDisabled = true;
      this.coverphoto = null;
      this.oldCoverPicture = true;
      this.url = null;
      document.getElementById("vue-file-upload-input-cover-photo").value = "";
    },

    onFileChange(e) {
      var fileInput = document.getElementById(
        "vue-file-upload-input-cover-photo"
      );
      var file_ = fileInput.value;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file_)) {
        alert(
          "Please upload file having extensions .jpeg/.jpg/.png/.gif only."
        );
        fileInput.value = "";
        return false;
      } else {
        this.isDisabled = false;
        this.oldCoverPicture = false;
        this.coverphoto = e.target.files[0];
        this.url = URL.createObjectURL(this.coverphoto);
      }
    },
    ...mapActions("auth", ["getUserData"]),

    CoverDataURItoBlob: function (dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },

    updateCover(index) {
      const canvas = this.$refs.clipper.clip();
      var dataURL = canvas.toDataURL("image/jpeg", 0.95);
      var blob = this.CoverDataURItoBlob(dataURL);
      var formData = new FormData(document.forms[0]);
      formData.append("coverphoto", blob);
      this.isDisabled = true;
      this.$set(this.isLoadingArray, index, true);

      return axios
        .post(process.env.VUE_APP_API_URL + "user/coverUpdate", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            var elem = this.$refs.closeModalPost;
            elem.click();
            this.$router.go("/profile");
            this.$set(this.isLoadingArray, index, false);
            this.coverphoto = null;
          } else {
            setTimeout(() => {
              this.$set(this.isLoadingArray, index, false);
              this.errorMessage = response.data.message;
            }, 500);
          }
        });
    },
    openinput: function () {
      document.getElementById("vue-file-upload-input-cover-photo").click();
    },
  },
};
</script>
<style scoped>
.cover-preview {
  display: flex;
  gap: 10px;
}

.cover-photo-edit {
  width: 450px;
  height: 225px;
  position: relative;
  text-align: center;
  border: 2px solid #f1f3f6;
  cursor: pointer;
}

.cover-photo-edit .default-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.cover-photo-edit img,
.new-cover-photo img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.cover-photo-edit-icon-wrapper {
  position: absolute;
  top: 80.5%;
  background-color: #e5f2fe;
  right: 2.6%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.cover-photo-edit-icon-wrapper img {
  width: 20px;
}
</style>
