var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TeamLineUpModal',{attrs:{"selectedRoomTeam":_vm.selectedRoomTeam}}),(_vm.games && _vm.games.length > 0)?_c('div',_vm._l((_vm.games),function(game){return _c('div',{key:game.id},[_c('div',{staticClass:"game-detail-wrapper align-items-stretch single-event-side-game p-2 mb-2"},[_c('div',{staticClass:"game-content"},[(game.game_live == 1)?_c('div',{staticClass:"inner-sec"},[_vm._m(0,true)]):_vm._e(),_c('router-link',{staticClass:"m-0 date-content",attrs:{"to":`/score-game/${game.team_id}-${game.guid}`}},[_c('p',[_vm._v(_vm._s(_vm.getFromDate(game.exactDateFormat)))])]),(game.park_name != null || game.park_name != '')?_c('span',{staticClass:"game-location cursor-pointer",on:{"click":function($event){return _vm.openGoogleMapforGamePark(game)}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../../assets/images/location.png")}}),_c('p',[_vm._v(_vm._s(game.park_name))])]):_vm._e(),(game.park_address != null || game.park_address != '')?_c('router-link',{staticClass:"m-0 game-location",attrs:{"to":`/score-game/${game.team_id}-${game.guid}`}},[_c('p',[_vm._v(_vm._s(game.park_address))])]):_vm._e(),(game.park_number != null || game.park_number != '')?_c('router-link',{staticClass:"m-0 game-location",attrs:{"to":`/score-game/${game.team_id}-${game.guid}`}},[_c('p',[_vm._v(_vm._s(game.park_number))])]):_vm._e(),_c('router-link',{staticClass:"m-0 opponent-name",attrs:{"to":`/score-game/${game.team_id}-${game.guid}`}},[_c('span',[_vm._v("vs")]),_c('h5',[_vm._v(_vm._s(game.opponent_name))])]),(
              game.comment != null &&
              game.comment != '' &&
              game.comment != 'null'
            )?_c('router-link',{staticClass:"m-0 game-description",attrs:{"to":`/score-game/${game.team_id}-${game.guid}`}},[_c('p',[_vm._v(_vm._s(game.comment))])]):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column justify-content-between align-items-end"},[_c('div',[_c('div',{staticClass:"game-name d-flex justify-content-end"},[_c('router-link',{staticClass:"m-0",attrs:{"to":`/score-game/${game.team_id}-${game.guid}`}},[_c('h6',[_vm._v(_vm._s(game.name))])])],1),_c('router-link',{staticClass:"m-0",attrs:{"to":`/score-game/${game.team_id}-${game.guid}`}},[_c('div',{staticClass:"played-data text-right"},[(game.game_live == 1)?_c('span',{staticClass:"tie"},[_vm._v(_vm._s(game.team_score)+" - "+_vm._s(game.opponent_score))]):(game.win_status == 0)?_c('span',{staticClass:"tie"},[_vm._v(_vm._s(game.team_score)+" - "+_vm._s(game.opponent_score))]):(game.win_status == 1)?_c('span',{staticClass:"win-data"},[_vm._v("W"+_vm._s(game.team_score)+" - "+_vm._s(game.opponent_score))]):(game.win_status == 2)?_c('span',{staticClass:"loss-data"},[_vm._v("L"+_vm._s(game.team_score)+" - "+_vm._s(game.opponent_score))]):_c('span',{staticClass:"tie text-dark"},[_vm._v(" "+_vm._s(_vm.getTimeFromDateFormat(game.exactDateFormat))+" ")])])])],1),(
              _vm.currentIsAdmin == '1' &&
              _vm.selectedRoomTeam.teamType == 'sports' &&
              _vm.currentTeamIsActive == 1
            )?_c('GameOptions',{attrs:{"singleGame":game,"eventAlertOptions":_vm.eventAlertOptions}}):_vm._e()],1)])])}),0):_vm._e(),(_vm.showGameLoader == true)?_c('div',{staticClass:"user-notification d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"lds-dual-ring-refresh"})]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"live-tag position-relative"},[_c('span',[_vm._v("Live")])])
}]

export { render, staticRenderFns }