<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <div class="shop-side pr-md-3 pb-3 pb-md-0">
        <div class="row no-gutters">
          <div class="col-12 col-md-12 px-0">
            <div class="order-history-page">
              <InvitesTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import InvitesTable from "../components/AllInvites/InvitesTable.vue";
//import Banner from "../components/BannerNotify/BannerPage.vue";

export default {
  components: {
    Navbar,
    LeftMenu,
    InvitesTable
  },
  name: "AllInvites",
  data() {
    return {
      errorMessage: "",
    };
  },
  methods: {
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.products-wrapper {
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  padding: 10px 15px;
  /* margin-bottom: 5px; */
}
@media screen and (max-width: 767px) {
  .products-wrapper {
    margin-bottom: 10px;
  }
}
.name-for-product {
  margin-top: 40%;
}
.image-for-product {
  height: 240px;
}

@media screen and (max-width: 768px) {
  .shop-container {
    padding-right: 0 !important;
  }
}
</style>
