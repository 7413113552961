<template>
  <div>
   <PostInput />
  </div>
</template>
<script>
import PostInput from "../Posts/PostInput";
export default {
  name: "Mew",
  components:{
    PostInput,
  }
};
</script>
<style>
</style>