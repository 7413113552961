<template>
  <div class="menuMain">
    <div v-if="showMain == 0" class="menu-items">
      <div v-if="showEventShimmerCounter == 1" class="member-list">
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/images/events.png" alt="" />
          </div>
          <content-placeholders :rounded="true">
            <content-placeholders-text
              :lines="1"
              class="content-margin-reaction"
            />
          </content-placeholders>
        </div>
      </div>

      <div v-else @click="showEventMainDiv()" class="member-list d-block pb-0">
        <div class="member-list border-0 pt-0">
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/events.png" alt="" />
            </div>
            <span class="counter-wrapper"
              >Events
              <span class="nav-counter-team">{{
                selectedRoomTeam.events.length
              }}</span></span
            >
          </div>
          <img src="../../../../assets/images/arrow-right.png" alt="" />
        </div>
      </div>

      <div v-if="selectedRoomTeam.upcomingEventsList.length > 0">
        <div
          class="main-page-event"
          v-for="(eventWeather,
          index) in selectedRoomTeam.upcomingEventsList.slice(0, 1)"
          :key="index"
        >
          <div @click="showDetails(eventWeather)">
            <div class="top-layer">
              <div class="left-sec">
                <p class="event-tag">Upnext</p>
                <p class="gateway text-capitalize">
                  {{
                    eventWeather.event_tournaments &&
                    eventWeather.event_tournaments.length > 0 &&
                    eventWeather.event_tournaments[0].tournamentName
                      ? eventWeather.eventName +
                        "/" +
                        eventWeather.event_tournaments[0].tournamentName
                      : eventWeather.eventName
                  }}
                </p>
              </div>
              <div class="right-sec">
                <img src="../../../../assets/images/info-circle.png" alt="" />
              </div>
            </div>
            <div class="event-list-details">
              <div class="event-date">
                <img src="../../../../assets/images/calendar-event.png" />
                <div class="content">
                  <span
                    v-if="
                      eventWeather.exactStartDate == eventWeather.exactEndDate
                    "
                  >
                    {{ eventWeather.exactStartDate }}</span
                  >

                  <span v-else>
                    {{ eventWeather.exactStartDate.split(",")[0] }} to
                    {{ eventWeather.exactEndDate }}
                  </span>

                  <span v-if="eventWeather.allDay == 'true'"> All Day.</span>
                  <span v-else>
                    From {{ eventWeather.exactStartTime }} to
                    {{ eventWeather.exactEndTime }}</span
                  >
                  <span v-if="eventWeather.time_zone !== null">
                    {{ eventWeather.time_zone.split(")").pop() }}
                  </span>
                </div>
              </div>
              <div
                class="location-content"
                v-if="
                  (eventWeather.status == 1 &&
                    eventWeather.address != null &&
                    eventWeather.address != '' &&
                    eventWeather.address != 'null') ||
                    (eventWeather.city != null &&
                      eventWeather.city != '' &&
                      eventWeather.city != 'null') ||
                    (eventWeather.state != null &&
                      eventWeather.state != '' &&
                      eventWeather.state != 'null')
                "
              >
                <img
                  @click="openGoogleMap(eventWeather)"
                  src="../../../../assets/images/location.png"
                />
                <!-- <span @click="openGoogleMap(eventWeather)" class="content">{{
              eventWeather.address != "" &&
              eventWeather.address != null &&
              eventWeather.address != "null"
                ? eventWeather.address
                : eventWeather.city
            }}</span> -->
                <span @click="openGoogleMap(eventWeather)" class="content"
                  >{{ eventWeather.address }} {{ eventWeather.city }} ,
                  {{ eventWeather.state }}</span
                >
              </div>
              <div class="location-content" v-if="eventWeather.status == 2">
                <img src="../../../../assets/images/link.png" />
                <a class="content" :href="eventWeather.Url" target="_blank">
                  {{ eventWeather.Url }}
                </a>
              </div>
            </div>

            <Weather
              v-if="
                selectedRoomTeam.upcomingEventsList.length > 0 &&
                  eventWeather.status == 1
              "
              :eventsForWeather="eventWeather"
            />

            <div class="images-overlap">
              <div class="team-members d-flex">
                <div
                  class="team-details mb-0"
                  v-for="user in membersAttendee.slice(0, 7)"
                  :key="user.id"
                >
                  <div class="team-image">
                    <abbr :title="user.username">
                      <img
                        v-if="user.avatar != ''"
                        :src="user.avatar"
                     onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                        alt=""
                        class="upload-image"
                      />
                      <img
                        v-else
                        src="../../../../assets/images/user-default-new.png"
                        alt=""
                      />
                    </abbr>
                  </div>
                </div>
              </div>
              <UserGoingList
                :selectedRoomTeam="selectedRoomTeam"
                :attendeeListing="eventWeather.event_attendee_all"
              />
            </div>
          </div>
        </div>
      </div>

      <div @click="showMembersDiv" class="member-list">
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/images/teammates.png" alt="" />
          </div>
          <span class="counter-wrapper"
            >Teammates
            <span class="nav-counter-team">{{
              selectedRoomTeam.users.length
            }}</span></span
          >
        </div>
        <img src="../../../../assets/images/arrow-right.png" alt="" />
      </div>
      <div
        @click="showSharedFilesDiv"
        v-if="currentIsFan != '1'"
        class="member-list"
      >
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/images/shared-files.png" alt="" />
          </div>
          <span>Shared Files</span>
        </div>
        <img src="../../../../assets/images/arrow-right.png" alt="" />
      </div>
      <div @click="showTeamOptionsDiv" class="member-list">
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/images/team-options.png" alt="" />
          </div>
          <span>Team Options</span>
        </div>
        <img src="../../../../assets/images/arrow-right.png" alt="" />
      </div>
    </div>

    <div v-else-if="showMain == 1">
      <transition name="slide" enter-active-class="slideInLeft">
        <Members
          v-if="showMembers == 0"
          :selectedRoomTeam="selectedRoomTeam"
          :currentUserId="currentUserId"
          :currentIsAdmin="currentIsAdmin"
          :currentIsFan="currentIsFan"
          :currentTeamIsActive="currentTeamIsActive"
        />

        <SharedFiles
          v-if="showSharedFiles == 0"
          :selectedRoomTeam="selectedRoomTeam"
          :currentUserId="currentUserId"
        />

        <TeamOptions
          v-if="showTeamOptions == 0"
          :selectedRoomTeam="selectedRoomTeam"
          :currentUserId="currentUserId"
          :currentIsAdmin="currentIsAdmin"
          :currentIsFan="currentIsFan"
          :currentTeamIsActive="currentTeamIsActive"
        />
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SharedFiles from "../ChatSettingComponents/SharedFiles.vue";
import TeamOptions from "../ChatSettingComponents/TeamOptions.vue";
import Members from "../ChatSettingComponents/Members.vue";
import Weather from "../ChatEvents/Weather.vue";
import UserGoingList from "../ChatEvents/UserGoingList.vue";

export default {
  components: {
    TeamOptions,
    SharedFiles,
    Members,
    Weather,
    UserGoingList,
  },
  name: "SettingMenu",
  data: function() {
    return {
      showEventShimmerCounter: 1,
      showMembers: 1,
      showSharedFiles: 1,
      showTeamOptions: 1,
      showMain: 0,
      selectAttendeeTab: 0,
      membersAttendeeComplete: [],
      membersAttendee: [],
      goingMemberCount: 0,
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    // filteredEvents() {
    //   // Replace the condition in the find() method with your specific condition
    //   return this.selectedRoomTeam.events.filter(event => Object.hasOwn(event, 'weatherData') && event.weatherData !== null);
    //   }
  },
  methods: {
    filterGoing: function(memberStatus) {
      this.selectAttendeeTab = 0;
      const filterGoing = memberStatus;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (attendee.member_status == 1 || attendee.member_status == "1") {
          this.membersAttendee.push(attendee);
        }
      }
      var count = this.membersAttendee.length;
      if (count > 7) {
        this.goingMemberCount = count - 7;
      }
    },
    showEventMainDiv: function() {
      // if (this.user.pro_status == null) {
      //   this.$router.push({ name: "GoPro" });
      // } else {
      this.$root.$emit("ChatSettingEvent", 1, 0);
      // }
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    showMembersDiv: function() {
      this.showMembers = 0;
      this.showSharedFiles = 1;
      this.showTeamOptions = 1;
      this.showMain = 1;
      this.$root.$emit("ChatSettings", 1);
    },
    showSharedFilesDiv: function() {
      this.showMembers = 1;
      this.showSharedFiles = 0;
      this.showTeamOptions = 1;
      this.showMain = 1;
      this.$root.$emit("ChatSettings", 1);
    },
    showTeamOptionsDiv: function() {
      this.showMembers = 1;
      this.showSharedFiles = 1;
      this.showTeamOptions = 0;
      this.showMain = 1;
      this.$root.$emit("ChatSettings", 1);
    },
    showDetails: function(event) {
      this.$root.$emit("showEventDetailPage", event);
    },
  },

  mounted() {
    this.$root.$on("MenuSettingsDiv", (id) => {
      this.showMain = id;
    });
    this.$root.$on("showEventShimmerCounter", (id) => {
      this.showEventShimmerCounter = id;
    });
  },
};
</script>
