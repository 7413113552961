<template>
  <div class="images-overlap">
    <div class="team-members d-flex">
        <div
          class="team-details mb-0"
          v-for="user in membersAttendee.slice(0, 7)"
          :key="user.id"
        >
          <div class="team-image">
            <abbr :title="user.username">
              <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../../../assets/loader.gif" alt="">
    </div>
              <img
              @load="onImageLoad"
                v-if="user.avatar != ''"
                :src="user.avatar"
                onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                alt=""
                class="upload-image"
              />
              <img
              @load="onImageLoad"
                v-else
                src="../../../../assets/images/user-default-new.png"
                alt=""
                
              />
            </abbr>
          </div>
        </div>
      </div>
     <span class="more-user" v-if="goingMemberCount > 0"
                >+{{ goingMemberCount }} more </span
              >
              <span class="more-user">going</span>
  </div>
</template>

<script>
export default {
  name: "UserGoingList",
  data() {
    return {
      membersAttendee: [],
      goingMemberCount: 0, 
      membersAttendeeComplete:[],
      imgLoader: 1,
    }
  },

  props: {
    selectedRoomTeam: {
      type: Object,
    },
    attendeeListing: {
      type: Array,
    },
  },

  methods:{
    onImageLoad() {
      this.imgLoader = 0;
    },
      allfilterMember: function () {
      const filterAll = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterAll) {
        this.membersAttendee.push(attendee);
      }
      var count = this.membersAttendee.length;
      if (count > 7) {
        this.goingMemberCount = count - 7;
      }
    },

     filterGoing: function () {
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 1 ||
          attendee.event_attendee_status == "1"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
      var count = this.membersAttendee.length;
      if (count > 7) {
        this.goingMemberCount = count - 7;
      }
    },
  },

  mounted()
  {
      this.membersAttendeeComplete = [];
      const eventAttendeeFromDB = this.attendeeListing;
       const tempMembers = this.selectedRoomTeam.users;
       const tempMembersRole = this.selectedRoomTeam.userRole;
      var $i = 0;

        for (const member of tempMembers) {
            for (const memberRole of tempMembersRole) {
              if (memberRole.id == member.id && memberRole.fan != "1") {
                this.membersAttendeeComplete.push(member);
                this.membersAttendeeComplete[$i].event_attendee_status = 0;
                for (const attendee of eventAttendeeFromDB) {
                  if (member.c_id == attendee.member_id) {
                    this.membersAttendeeComplete[$i].event_attendee_status =
                      attendee.member_status;
                  }
                }
                $i++;
              }
            }
          }
    this.filterGoing();
  }
};
</script>

<style>
</style>