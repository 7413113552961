<template>
  <div>
    <p class="policy-heading">Cookie Policy</p>
    <p class="policy-date">Last updated: March 26, 2022</p>
    <p class="policy-description">
      This Cookies Policy explains what Cookies are and how We use them. You
      should read this policy so You can understand what type of cookies We use,
      or the information We collect using Cookies and how that information is
      used.
    </p>
    <p class="policy-description">
      Cookies do not typically contain any information that personally identifies a user, but personal information that we store about You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.
    </p>
    <p class="policy-alert">
      We do not store sensitive personal information, such as mailing addresses,
      account passwords, etc. in the Cookies We use.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>