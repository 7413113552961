<template>
  <div class="teams-sec">
    <ul class="nav nav-tabs" id="homeTeam" role="tablist" v-if="homeTeam == 0">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="home-batting-tab"
          data-toggle="tab"
          data-target="#homeBatting"
          type="button"
          role="tab"
          aria-controls="homeBatting"
          aria-selected="true"
        >
          Batting
        </button>
      </li>
      <!-- <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="home-pitching-tab"
          data-toggle="tab"
          data-target="#homePitching"
          type="button"
          role="tab"
          aria-controls="homePitching"
          aria-selected="false"
        >
          Pitching
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="home-fielding-tab"
          data-toggle="tab"
          data-target="#homeFielding"
          type="button"
          role="tab"
          aria-controls="homeFielding"
          aria-selected="false"
        >
          Fielding
        </button>
      </li> -->
    </ul>
    <ul
      class="nav nav-tabs"
      id="visitorTeam"
      role="tablist"
      v-if="visitorTeam == 0"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="visitor-batting-tab"
          data-toggle="tab"
          data-target="#visitorBatting"
          type="button"
          role="tab"
          aria-controls="visitorBatting"
          aria-selected="true"
        >
          Batting
        </button>
      </li>
      <!-- <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="visitor-pitching-tab"
          data-toggle="tab"
          data-target="#visitorPitching"
          type="button"
          role="tab"
          aria-controls="visitorPitching"
          aria-selected="false"
        >
          Pitching
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="visitor-fielding-tab"
          data-toggle="tab"
          data-target="#visitorFielding"
          type="button"
          role="tab"
          aria-controls="visitorFielding"
          aria-selected="false"
        >
          Fielding
        </button>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
    name: "TeamActions",
    data: function () {
    return {
      homeTeam: 0,
      visitorTeam: 1,
    };
  },
};
</script>

<style>
</style>