<template>
  <div>
    <h1 class="name">{{ event_name }}</h1>
    <ul class="nav nav-tabs nav-pills nav-fill mt-4" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="home-tab"
          data-toggle="tab"
          href="#home"
          role="tab"
          ref="openOnGoing"
          aria-controls="home"
          aria-selected="true"
        >
          Ongoing
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="profile-tab"
          data-toggle="tab"
          href="#profile"
          role="tab"
          ref="openFinal"
          aria-controls="profile"
          aria-selected="false"
        >
          Final</a
        >
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <!-- No End Game -->
        <div class="row no-gutters">
          <div
            class="col-sm-12 products-column"
            v-for="gameScore in gameScores"
            :key="gameScore.id"
          >
            <div class="event-card" v-if="gameScore.game_status != 2">
              <div class="game_name11 mt-1">
                {{ gameScore.game_name }}
              </div>
              <div class="date-date">
                {{ gameScore.date }}
              </div>
              <div @click="callBoxViewModal(gameScore)" class="">
                <!-- Visiting Team -->
                <div v-for="score in gameScore.score" :key="score.id + 1000">
                  <div
                    class="row no-gutters align-items-center"
                    v-if="score.team_type == 1"
                  >
                    <div class="col-9 p-0">
                      <div class="user-info">
                        <div
                          class="user-img"
                          style="margin:3px!important; margin-left: 0!important;"
                        >
                          <div class="users-notification" v-if="imgLoader == 1">
                            <!-- <div class="lds-dual-ring-refresh"></div> -->
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="
                              score.team_flag == 1 && gameScore.team_avatar
                            "
                            :src="url + gameScore.team_avatar"
                            alt=""
                            style="border: 2px solid white"
                          />
                          <img
                            @load="onImageLoad"
                            v-else
                            alt=""
                            src="../../assets/team-avatar.png"
                          />
                        </div>
                        <div class="user-name-pro position-relative">
                          <span class="user-name ml-1" style="color: black">
                            {{ score.team_name }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-3 pr-0 pl-2 position-relative"
                      style="border-left:2px solid #e5f2fe;"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        {{ gameScore.visitingRuns }}
                        <span
                          class="arraw-inning"
                          v-if="gameScore.visitingBatting == 1"
                          >{{ gameScore.end_inning_flag }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Home Team -->
                <div v-for="score in gameScore.score" :key="score.id + 2000">
                  <div
                    class="row no-gutters align-items-center"
                    v-if="score.team_type == 2"
                  >
                    <div class="col-9 p-0">
                      <div class="user-info">
                        <div
                          class="user-img"
                          style="margin:3px; !important; margin-left: 0 !important;"
                        >
                          <div class="users-notification" v-if="imgLoader == 1">
                            <!-- <div class="lds-dual-ring-refresh"></div> -->
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="
                              score.team_flag == 1 && gameScore.team_avatar
                            "
                            :src="url + gameScore.team_avatar"
                            alt=""
                            style="border: 2px solid white;"
                          />
                          <img
                            @load="onImageLoad"
                            v-else
                            alt=""
                            src="../../assets/team-avatar.png"
                          />
                        </div>
                        <div class="user-name-pro position-relative">
                          <span class="user-name  ml-1" style="color: black">
                            {{ score.team_name }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-3 pl-2 pr-0 position-relative"
                      style="border-left:2px solid #e5f2fe;"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        {{ gameScore.homeRuns }}
                        <span
                          class="arraw-inning"
                          v-if="gameScore.homeBatting == 1"
                          >{{ gameScore.end_inning_flag }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <!--End Game -->
        <div class="row no-gutters">
          <div
            class="col-sm-12 products-column"
            v-for="gameScore in gameScores"
            :key="gameScore.id"
          >
            <div class="event-card" v-if="gameScore.game_status == 2">
              <div class="game_name11 mt-1">
                {{ gameScore.game_name }}
                <span v-if="gameScore.game_status == 2" class="float-right"
                  >F</span
                >
              </div>
              <div class="date-date">
                {{ gameScore.date }}
              </div>
              <div @click="callBoxViewModal(gameScore)" class="">
                <!-- Visiting Team -->
                <div v-for="score in gameScore.score" :key="score.id + 1000">
                  <div
                    class="row no-gutters align-items-center"
                    v-if="score.team_type == 1"
                  >
                    <div class="col-9 p-0">
                      <div class="user-info">
                        <div
                          class="user-img"
                          style="margin:3px!important; margin-left: 0!important;"
                        >
                          <div class="users-notification" v-if="imgLoader == 1">
                            <!-- <div class="lds-dual-ring-refresh"></div> -->
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="
                              score.team_flag == 1 && gameScore.team_avatar
                            "
                            :src="url + gameScore.team_avatar"
                            alt=""
                            style="border: 2px solid white"
                          />
                          <img
                            @load="onImageLoad"
                            v-else
                            alt=""
                            src="../../assets/team-avatar.png"
                          />
                        </div>
                        <div class="user-name-pro position-relative">
                          <span class="user-name ml-1" style="color: black">
                            {{ score.team_name }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-3 pr-0 pl-2 position-relative"
                      style="border-left:2px solid #e5f2fe;"
                    >
                      <div class="user-name">
                        {{ gameScore.visitingRuns }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Home Team -->
                <div v-for="score in gameScore.score" :key="score.id + 2000">
                  <div
                    class="row no-gutters align-items-center"
                    v-if="score.team_type == 2"
                  >
                    <div class="col-9 p-0">
                      <div class="user-info">
                        <div
                          class="user-img"
                          style="margin:3px!important; margin-left: 0px!important;"
                        >
                          <div class="users-notification" v-if="imgLoader == 1">
                            <!-- <div class="lds-dual-ring-refresh"></div> -->
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="
                              score.team_flag == 1 && gameScore.team_avatar
                            "
                            :src="url + gameScore.team_avatar"
                            alt=""
                            style="border: 2px solid white;"
                          />
                          <img
                            @load="onImageLoad"
                            v-else
                            alt=""
                            src="../../assets/team-avatar.png"
                          />
                        </div>
                        <div class="user-name-pro position-relative">
                          <span class="user-name  ml-1" style="color: black">
                            {{ score.team_name }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-3 pl-2 pr-0 position-relative"
                      style="border-left:2px solid #e5f2fe;"
                    >
                      <div class="user-name">
                        {{ gameScore.homeRuns }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import $ from "jquery";
export default {
  name: "MyBoxScores",
  components: {},
  data: function() {
    return {
      url: this.$groupAvatarStorageURL,
      count: 0,
      imgLoader: 1,
    };
  },
  props: {
    gameScores: {
      type: Array,
    },
    event_name: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    callBoxViewModal(score) {
      this.$root.$emit("openBoxViewScoresModal", score);
      this.$root.$emit("openBoxViewInningModal", score);
      document.getElementById("overlay").style.display = "none";
    },

    onImageLoad() {
      this.imgLoader = 0;
    },

    openTab() {
      if (this.count > 0) {
        var elemA = this.$refs.openOnGoing;
        if (elemA) elemA.click();
      } else {
        var elem = this.$refs.openFinal;
        if (elem) elem.click();
      }
    },
  },

  mounted() {
    this.count = 0;
    this.gameScores.forEach((gameScore) => {
      if (gameScore.game_status != 2) {
        this.count = this.count + 1;
      }
    });
    this.openTab();

    this.$root.$on("selectTapBoxScore", (count) => {
      this.count = count;
      this.openTab();
    });
  },
};
</script>

<style scoped>
.modal-tables {
  width: 100%;
  margin-bottom: 15px;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
}
.table {
  text-align: center;
  font-size: 13px;
  background-color: #ffffff;
}

.table-teams {
  width: 40%;
}

.table-teams {
  -webkit-border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -ms-border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
}

.table.table-teams tbody tr:nth-child(1) {
  height: 70px !important;
}

.table tbody + tbody {
  border: none !important;
}
.table.table-teams .team-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.game_name11 {
  border-right: none;
  font-size: 17px;
  color: #188ef5 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-align: left;
}

.date-date {
  font-size: 12px;
  text-align: left;
  color: #dc3545 !important;
  font-weight: 400;
}

@media screen and (max-width: 576px) {
  .table {
    font-size: 8px;
  }

  .table th {
    padding: 0.75rem 0.3rem;
  }

  .table-teams {
    width: 50%;
  }
}

@media screen and (max-width: 380px) {
  .date-date {
    font-size: 9px;
  }

  .table.table-teams thead tr:nth-child(1) {
    height: 70px !important;
  }
  .table.table-teams tbody tr:nth-child(1) {
    height: 70px !important;
  }
  .table.table-teams tbody tr:nth-child(2) {
    height: 70px !important;
  }
}

.event-card {
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #97ccfa;
  transition: all 0.7s;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
  padding: 15px;
}

.event-card:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.game_name11 {
  color: #000000 !important;
  font-size: 15px;
}

.arraw-inning {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  margin: 0 5px;
  text-align: center;
  font-size: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.game_name11 span {
  width: 18px;
  height: 18px;
  line-height: 10px;
  font-size: 12px;
  background: #188ef5;
  border-radius: 100px;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.nav-tabs {
  list-style-type: none;
  display: flex;
  margin-bottom: 0;
}
.nav-tabs .nav-item .nav-link {
  color: #188ef5;
  cursor: pointer;
  background-color: #e5f2fe;
  text-align: center;
}
.nav-tabs .nav-item:first-child .nav-link {
  border-radius: 5px 0 0 5px !important;
}

.nav-tabs .nav-item:last-child .nav-link {
  border-radius: 0 5px 5px 0 !important;
}

.nav-tabs {
  border-bottom: 0 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #188ef5 !important;
  border-radius: inherit !important;
  color: #ffffff !important;
}
.event-tab22 ul li a img {
  width: 17px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}
.nav-tabs .nav-link {
  border: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: 0 !important;
  font-size: 13px;
}
</style>
