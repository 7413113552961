<template>
  <div>
    <ManageEventSeedars  :eventId="eventId" />
    <div
      class="sec-top d-flex justify-content-between"
      v-if="events && showLoader == 0"
    >
      <div class="left-part">
        <h4>{{ events.eventName }}</h4>
        <p class="asso-type">
          {{ events.association }} - {{ events.eventType }}
        </p>
        <div class="event-list-details event-info-wrap">
          <div class="event-date">
            <img src="../../assets/images/calendar-event.png" />
            <div class="content" v-if="events && events.exactStartDate && events.exactEndDate">
              <div v-if="events.exactStartDate.split(',')[1] === events.exactEndDate.split(',')[1]">
                {{ events.exactStartDate.split(',')[0] }} - {{ events.exactEndDate }}
              </div>
              <div v-else>
                {{ events.exactStartDate }} - {{ events.exactEndDate }}
              </div>
            </div>
          </div>
          <p class="location-content"  v-if="
                  (events.status == 1 &&
                   events.address != null &&
                   events.address != '' &&
                   events.address != 'null') ||
                    (events.city != null &&
                     events.city != '' &&
                     events.address != 'null') ||
                    (events.state != null &&
                     events.state != '' &&
                     events.state != 'null')
                ">
            <img
              src="../../assets/images/location.png"
              @click="openGoogleMap(events)"
            />
            <span class="content" @click="openGoogleMap(events)">
              <span class="content"
                >{{ events.address }} {{ events.city }},
                {{ events.state }}</span
              >
            </span>
          </p>
        </div>
      </div>
      <div class="right-part asso-right-part">
        <div class="d-flex ">
          <!-- <div class="edit-event-div" v-if="currentIsAdmin == '1'">
            <a class="create-event btn btn-primary"  @click="callModalForSeedar()"><img src="../../assets/math.png"  alt=""><span>Seed Criteria</span></a>
          </div> -->

          <!-- <div class="opt edit-btn">
            <img
              src="../../assets/images/ellipsis-h.png"
              alt=""
              @click="openEditEventOption"
              v-click-outside="onClickOutside"
            />
            <div
              class="sub-setting-options edit-event-options"
              v-if="editEventOption"
            >
              <ul class="d-block pb-0">
                <li @click="callModal()" class="cursor-pointer">Edit Event</li>
              </ul>
            </div>
          </div> -->
        </div> 
        <p class="director" v-if="events.director_name">Director : {{ events.director_name }}</p>
        <p class="director" v-else>Director : N/A</p>

        <p class="num" v-if="events.director_phone">{{ events.director_phone }}</p>
        <p class="num" v-else> N/A</p>

        <p class="email" v-if="events.director_email">{{ events.director_email }}</p>
        <p class="email" v-else>N/A</p>

      </div>
    </div>
    <div class="products-list-shim p-0" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
import ManageEventSeedars from "./Modals/ManageEventSeedars.vue"
export default {
  name: "AssociationEventsTop",
  components: {
    ManageEventSeedars
  },
  data() {
    return {
      // editEventOption: false,
    };
  },
  methods: {
    // openEditEventOption() {
    //   this.editEventOption = !this.editEventOption;
    // },
    // onClickOutside() {
    //   this.editEventOption = false;
    // },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    callModalForSeedar(){
      console.log("hekek")
      this.$root.$emit("openManageEventSeedarsModal");
    }
  },
  props: {
    events: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currentIsAdmin: {
      type: String,
    },
    showLoader: {
      type: Number,
    },
    eventId: {
      type: Number,
    },
  },
  mounted() {
    console.log(this.eventId)
  },
};
</script>
<style scoped>
.create-event {
  background-color: #F0F0F0;
}
</style>