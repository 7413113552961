<template>
  <div>
    <div
      class="order-detail-wrapper"
      v-if="items.length != 0 && showLoader == 0"
    >
      <div>
        <h1>
          Order Details
          <span>Order # ({{ orders.pp_invoice_id }})</span>
        </h1>
        <p class="name text-capitalize " v-if="user">{{ user.name }}</p>
        <p class="phone" v-if="user">{{ user.mob_code }}{{ user.mob }}</p>
        <p class="address text-capitalize">
          {{ orders.billing_address }}, {{ orders.billing_city }},
          {{ orders.billing_state }}, {{ orders.billing_city }},
          {{ orders.billing_zip }}
        </p>
      </div>

      <div class="history-wrapper-main">
        <div class="history-wrapper-content">
          <div
            class="history-content"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="history-info">
              <div class="item-info-wrapper">
                <div class="item-image">
                  <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../assets/loader.gif" alt="">
    </div>
                  <img
                    @load="onImageLoad"
                    v-if="item.product.products.image != null"
                    :src="API_URL + item.product.products.image"
                    alt=""
                  />
                  <img
                    @load="onImageLoad"
                    v-else
                    src="../../assets/images/gallery.png"
                    alt=""
                  />
                </div>
                <div class="item-name">
                  <span class="category">{{ item.product.products.name }}</span>
                  <div class="item-quantity">
                    <span>{{ item.quantity }}x</span>
                    <span>${{ (item.product.products.price).toFixed(2) }} / Piece</span>
                  </div>
                </div>
              </div>
              <span
                >${{
                  (item.quantity * item.product.products.price).toFixed(2)
                }}</span
              >
            </div>
          </div>
        </div>

        <div class="history-total order-history-total d-block">
          <div class="total-heading order-total-heading">
            <div>Subtotal</div>
            <div>${{ (orders.total_amount).toFixed(2) }}</div>
          </div>

          <div class="total-heading order-total-heading">
            <div>
              Tax <span class="tax">({{ orders.tax_percentage }}%)</span>
            </div>
            <div>${{ (orders.tax_amount).toFixed(2) }}</div>
          </div>

          <div class="total-heading order-total-heading">
            <div>
              Shipping
              <span class="tax">({{ orders.shipping_percentage }}%)</span>
            </div>
            <div>${{ (orders.shipping_amount).toFixed(2) }}</div>
          </div>

          <div class="total-heading order-total-heading">
            <div>
              Discount <span class="tax">({{ orders.discount }}%)</span>
            </div>
            <div>${{ (orders.discounted_subtotal).toFixed(2) }}</div>
          </div>

          <div class="total-heading order-total-heading">
            <div>Total Payable</div>
            <div>${{ (orders.grand_total).toFixed(2) }}</div>
          </div>

          <div class="total-heading order-total-heading py-2">
            <div class="mb-0">Reference No: {{ orders.pp_invoice_id }}</div>
            <div>
              <button class="paid-btn">{{ orders.payment_status }}</button>
            </div>
          </div>

          <div class="total-heading order-total-heading">
            <!-- <div class="credit-card-number mb-0">Credit Card 5499 XXXX XXXX</div> -->
            <div class="credit-card-date">
              {{ orders.created_at | formatDateOfBirth }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-detail-wrapper-shim" v-if="showLoader == 1">
      <div class="products-list-shim">
        <br />
        <div class="container-fluid p-0">
          <content-placeholders :rounded="true">
            <content-placeholders-heading />
          </content-placeholders>
          <br />
          <br />
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
          <br />
          <br />
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
          <br />
          <br />
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
          <br />
          <br />
          <content-placeholders :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
          </content-placeholders>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
export default {
  name: "OrderDetailRightside",
  data() {
    return {
      time: this.$time,
      Storage_URL: this.$userProfileStorageURL,
      errorMessage: null,
      successMessage: null,
      orders: [],
      items: [],
      page: 1,
      noPost: 0,
      showLoader: 0,
      API_URL: this.$productImageStorageURL,
      imgLoader: 1,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    async fetch(order) {
      this.showLoader = 1;
      let OrderedProducts = await axios.get(
        `orders/getItems?item_id=${order.id}`
      );
      if (OrderedProducts.data.statusCode == 200) {
        this.items = OrderedProducts.data.data;
      } else {
        this.errorMessage = OrderedProducts.data.message;
      }
      this.showLoader = 0;
    },
  },
  computed: {
    ...mapState("cart", ["cart"]),
    ...mapGetters("cart", ["cartTotalPrice"]),
    ...mapGetters("cart", ["cartItemCount"]),
    ...mapGetters("cart", ["shopConfigData"]),
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    this.$root.$on("OrderedItems", (order) => {
      this.fetch(order);
      this.orders = order;
    });
  },
};
</script>
