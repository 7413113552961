<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="bg-white p-3 mb-3 rounded"><AsociationSingleEvent /></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import AsociationSingleEvent from "../components/Association/AsociationSingleEvent.vue";

export default {
  name: "AssociationSingleEventDetail",
  data() {
    return {};
  },
  components: {
    Navbar,
    LeftMenu,
    Banner,
    AsociationSingleEvent,
  },
  methods: {},
  mounted() {},
};
</script>