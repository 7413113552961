<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#AddMessage"
      ref="openModalMessage"
      @click="dataReset()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="AddMessage">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">New Message</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalMessage"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="subject">Subject</label>
              <input
                id="subject"
                class="form-control"
                v-model="subject"
                spellcheck="false"
                autocomplete="off"
                trim
                type="text"
                required
                @input="errorNull"
              />
            </div>
            <div class="form-group">
              <label for="topic">Topic</label>
              <select class="form-control" id="topic" v-model="selectedTopicId">
                <option value="">Select Topic</option>
                <option
                  v-for="topic in messageTopics"
                  :key="topic.id"
                  :value="topic.id"
                  >{{ topic.name }}</option
                >
              </select>
            </div>
            <div class="form-group">
              <label for="message">Message Body</label>
              <textarea
                type="text"
                class="form-control message"
                required
                rows="5"
                id="message"
                v-model="message"
                @input="errorNull"
                maxlength="1000"
              ></textarea>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <p>Turn on replies for this message</p>
                <toggle-button
                type="checkbox"
                  class="d-flex justify-content-end m-0 p-0"
                  id="allowReplies"
                  :width="34"
                  v-model="allowReplies"
          />
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-100"
              block
              @click="sendMessage(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AddMessage",
  props: {
    messageTopics: {
      type: Array,
    },
  },
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      subject: "",
      message: "",
      event_id: "",
      selectedTopicId: "",
      allowReplies: false,
      allowMessage: true,
    };
  },
  methods: {
    dataReset() {
      this.subject = "";
      this.message = "";
      this.isDisabled = true;
    },
    errorNull() {
      if (this.message === "" || this.subject === "") {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    async checkTimeDiscrepancy() {
      try {
        const response = await fetch('https://worldtimeapi.org/api/ip');
        if (!response.ok) {
          throw new Error('Failed to fetch current time');
        }
        const data = await response.json();
        const timestampFromAPI = new Date(data.utc_datetime);
        const localTimestamp = new Date();
        if (Math.abs(timestampFromAPI.getTime() - localTimestamp.getTime()) > 60000) {
          this.allowMessage = false;
          this.$alert("Your device's time and date settings appear to be incorrect. Please ensure your device's date and time settings are correct, then refresh the page.");
          var elem = this.$refs.closeModalMessage;
          elem.click();

        } else {
          this.allowMessage = true;
        }
      } catch (error) {
        console.error('Error fetching current time:', error);
      }
    },
    async sendMessage() {
      this.$set(this.isLoadingArray, 0, true);
      await this.checkTimeDiscrepancy();
      if (!this.allowMessage) {
        return;
      }

      try {
        const now = new Date();
        const offset = now.getTimezoneOffset();
        const localISOTime = new Date(now.getTime() - (offset * 60 * 1000)).toISOString().slice(0, -1);

        const data = {
          event_id: this.event_id,
          topic_id: this.selectedTopicId,
          parent_id: 0,
          message_subject: this.subject,
          message_body: this.message,
          message_date: localISOTime,
          allow_replies: this.allowReplies,
        };

        await axios.post(`${process.env.VUE_APP_API_URL}associationEvent/addMessage`, data);

        this.$root.$emit("fetchMesages");
        var elem = this.$refs.closeModalMessage;
        elem.click();
        this.dataReset();
        this.$set(this.isLoadingArray, 0, false);
      } catch (error) {
        console.error("Error sending message:", error);
        this.$set(this.isLoadingArray, 0, false);
      }
    }

  },
  mounted() {
    this.$root.$on("addMessageModal", (data) => {
      this.event_id = data.eventId;
      this.dataReset();
      var elem = this.$refs.openModalMessage;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
