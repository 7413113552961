<template>
    <div>
      <!-- Select Home Team -->
      <input
        class="d-none"
        data-target="#tournamentFormatModal"
        data-toggle="modal"
        ref="openTournamentFormatModal"
      />
      <div
        class="modal fade"
        id="tournamentFormatModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title" id="exampleModalLabel">Tournament Format</h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeDelayedGameModal"
                
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body tournament-format-modal">
                <vue-editor v-model="singleGame.format" :editorToolbar="customToolbar" :disabled="true"></vue-editor>
            </div>
  
            <div class="border-top event-btns-wrapper px-3 py-2 justify-content-end">
              
              <button type="button" class="btn btn-secondary" data-dismiss="modal"
                aria-label="Close"
                ref="closeDelayedGameModal"
               >
              Cancel
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { VueEditor } from 'vue2-editor';
  export default {
    name: "DelayedGameModal",
    data: function () {
      return {
        singleGame: [],
        customToolbar: [],
      };
      
    },
    components: {
      VueEditor
    },
    mounted() {
      this.$root.$on("openTournamentFormatModal", (data) => {

          this.singleGame = data;
          var elem = this.$refs.openTournamentFormatModal;
          if (elem) {
            elem.click();
          }
        }
      );
    },
  };
</script>