<template>
  <div class="profile-friends-wrapper">
    <div class="friends-heading">
      <h6 class="mb-0">
        Friends <span>({{ friendCount }})</span>
      </h6>
      <router-link
        v-if="friendCount > 0"
        :to="/otherFriends/ + lastSegment"
        exact
        >See All</router-link
      >
    </div>
    <div class="friends-content">
      <div class="container-fluid p-0" v-if="friendsShort">
        <div class="row no-gutters" v-if="friendsShort.length > 0">
          <div
            class="col-6 col-md-4 col-lg-4 mb-5 no-pdd text-center"
            v-for="value in friendsShort.slice(0, 6)"
            :key="value.id"
          >
            <a
              :href="
                $router.resolve({ path: '/profile/' + value.user_link })
                  .href
              "
              style="color: black"
              class="m-0"
            >
              <div class="friends-content-info">
                <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../assets/loader.gif" alt="">
    </div>
                <img
                @load="onImageLoad"
                  v-if="value.picture == 'yes'"
                  :src="API_URL + value.id + '/profile_picture.jpg?v=' + time"
                  alt=""
                />
                <img
                @load="onImageLoad"
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                />
              </div>
              <p class="mb-2 friend-name">{{ value.user_name }}</p>
            </a>
          </div>
        </div>
        <div class="info-heading" v-else>
          <p>No friends yet.</p>
        </div>
      </div>
      <content-placeholders :rounded="true" v-else>
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "ProfileFriends",
  data() {
    return {
      friendsShort: "",
      friendCount: "0",
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      lastSegment: "",
      imgLoader: 1,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    async friendShort() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}friends/friendOtherList?userLink=${this.lastSegment}`
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.friendsShort = response.data.data;
            this.friendCount = response.data.data.length;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    getuserLink() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.lastSegment = segment_array.pop();
      if (this.lastSegment !== "profileOther") {
        this.friendShort();
      }
    },
  },
  mounted() {
    this.getuserLink();
  },
};
</script>
