<template>
  <div class="photos-top-wrapper">
    <div class="photos-left">
      <router-link :to="/profile/ + userlink" exact class="m-0"
      >
      <img src="../../assets/images/arrow-left.png" alt="">
      </router-link
      >
      <h3>Photos</h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "topothers",
  data() {
    return {
      lastSegment: "",
      userlink: "",
    };
  },
  methods: {
    getuserLink() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.lastSegment = segment_array.pop();
      if (this.lastSegment !== "otherPhotos") {
        this.userlink = this.lastSegment;
      }
    },
  },
  mounted() {
    this.getuserLink();
  },
};
</script>
<style lang=""></style>
