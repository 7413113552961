<template>
    <div>
        <!-- Edit Model -->
        <div class="modal fade bd-example-modal-lg" id="editEmergencyInfo" ref="editEmergencyModal" tabindex="-1"
            role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Edit Emergency Info</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalNameEdit"
                            @click="closeOldDiv">
                            <img src="../../assets/close.png" />
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="team-name-type">
                                <div class="row no-gutters">
                                    <div class="form-group col-sm-12 p-0">
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" required
                                            v-model.trim="$v.details.emergencyName.$model" :class="{
                                                'is-invalid': validationStatus($v.details.emergencyName),
                                            }" v-on:keyup="errorNull()" />
                                        <span class="placeholder">Emergency Name</span>
                                        <div v-if="!$v.details.emergencyName.required" class="invalid-tooltip">
                                            Emergency Name is required
                                        </div>
                                        <div v-if="!$v.details.emergencyName.maxLength" class="invalid-tooltip">
                                            Maximum 18 characters allowed
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-12 p-0">
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" required
                                            v-model.trim="$v.details.emergencyEmail.$model" :class="{
                                                'is-invalid': validationStatus($v.details.emergencyEmail),
                                            }" v-on:keyup="errorNull()" />
                                        <span class="placeholder">Emergency Email</span>

                                        <div v-if="!$v.details.emergencyEmail.required" class="invalid-tooltip">
                                            Emergency Email is required
                                        </div>
                                        <div v-if="!$v.details.emergencyEmail.email" class="invalid-tooltip">
                                            Provide valid email
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-12 p-0">
                                        <input class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                            required v-model.trim="$v.details.emergencyPhone.$model" :class="{
                                                'is-invalid': validationStatus($v.details.emergencyPhone),
                                            }" v-on:keyup="errorNull()" />
                                        <span class="placeholder">Emergency Number</span>

                                        <div v-if="!$v.details.emergencyPhone.required" class="invalid-tooltip">
                                            Emergency number is required
                                        </div>
                                        <div v-if="!$v.details.emergencyPhone.minLength" class="invalid-tooltip">
                                            Minimum length should be 10
                                        </div>
                                        <div v-if="!$v.details.emergencyPhone.integer" class="invalid-tooltip">
                                            Emergency number should be a number
                                        </div>

                                    </div>
                                    <div class="form-group col-sm-12 p-0">
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" required
                                            v-model.trim="$v.details.emergencyRelation.$model" :class="{
                                                'is-invalid': validationStatus($v.details.emergencyRelation),
                                            }" v-on:keyup="errorNull()" />
                                        <span class="placeholder">Emergency Relationship</span>
                                        <div v-if="!$v.details.emergencyRelation.required" class="invalid-tooltip">
                                            Emergency Relationship is required
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="event-btns-wrapper justify-content-end p-3">
                        <button v-if="this.isDisabled == false" type="button" class="btn btn-primary w-25"
                            @click="updateEmergencyInfo(0)" :disabled="isLoadingArray[0]">
                            <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                            <span v-else>Save</span>
                        </button>
                        <button v-else-if="this.isDisabled == true" type="button" class="btn btn-primary-disabled w-25"
                            :disabled="isLoadingArray[0]">
                            <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                            <span v-else>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { required, email, maxLength, minLength, integer } from "vuelidate/lib/validators";
//   import * as firestoreService from "../../database/firestore";
export default {
    name: "UpdateEmergencyInfo",
    props: {
        items: {
            type: Object,
        },
    },

    data() {
        return {
            isDisabled: true,
            isLoadingArray: [],
            details: {
                emergencyName: "",
                emergencyEmail: "",
                emergencyPhone: "",
                emergencyRelation: "",
            },
        };
    },
    validations: {
        details: {
            emergencyName: { required, maxLength: maxLength(18) },
            emergencyEmail: { required, email },
            emergencyPhone: { required, minLength: minLength(10), integer },
            emergencyRelation: { required },
        },
    },

    computed: {
        ...mapGetters("auth", ["user"]),
    },

    methods: {
        closeOldDiv: function () {
            this.$root.$emit("closeEditProfileModal");
            if (this.user) {
                this.details.emergencyName = this.user.emergency_name;
                this.details.emergencyEmail = this.user.emergency_email;
                this.details.emergencyPhone = this.user.emergency_phone;
                this.details.emergencyRelation = this.user.emergency_relationship;
            }
        },
        getValues() {
            if (this.user) {
                this.details.emergencyName = this.user.emergency_name;
                this.details.emergencyEmail = this.user.emergency_email;
                this.details.emergencyPhone = this.user.emergency_phone;
                this.details.emergencyRelation = this.user.emergency_relationship;
            }
        },
        validationStatus: function (validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },
        errorNull: function () {
            if (this.$v.$invalid) {
                this.isDisabled = true;
            } else {
                this.isDisabled = false;
            }
        },
        ...mapActions("auth", ["getUserData"]),
        updateEmergencyInfo: function (index) {
            this.isDisabled = true;
            this.$v.$touch();
            if (this.$v.$pendding || this.$v.$error) return;
            this.$set(this.isLoadingArray, index, true);
            var formData = new FormData();
            formData.append(
                "emergency_name",
                this.details.emergencyName ? this.details.emergencyName : ""
            );
            formData.append(
                "emergency_email",
                this.details.emergencyEmail ? this.details.emergencyEmail : ""
            );
            formData.append("emergency_phone", this.details.emergencyPhone ? this.details.emergencyPhone : "");

            formData.append(
                "emergency_relationship",
                this.details.emergencyRelation ? this.details.emergencyRelation : ""
            );

            return axios
                .post(process.env.VUE_APP_API_URL + "user/emergencyContactUpdate", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(async (response) => {
                    if (response.data.statusCode == 200) {

                        var elem = this.$refs.closeModalNameEdit;
                        elem.click();
                        this.getUserData();
                        this.$set(this.isLoadingArray, index, false);
                    } else {
                        setTimeout(() => {
                            this.$set(this.isLoadingArray, index, false);
                            this.errorMessage = response.data.message;
                        }, 500);
                    }
                });
        },
    },
    mounted: function () {
        if (this.user) {
            this.details.emergencyName = this.user.emergency_name;
            this.details.emergencyEmail = this.user.emergency_email;
            this.details.emergencyPhone = this.user.emergency_phone;
            this.details.emergencyRelation = this.user.emergency_relationship;
        }
        this.$root.$on("updateEmergencyInfo", () => {

            this.getValues(1);
            this.$v.details.$reset();
        });
    },
};
</script>
<style scoped>
/* Important part */
.modal {
    overflow-y: initial !important;
}
</style>
  