<template>
  <div>
    <div class="profile-photo-wrapper">
      <div class="shop-products-img-loader" v-if="showLoader == 1">
        <div class="lds-dual-ring-media"></div>
      </div>
      <div class="profile-img">
        <img
          @load="onImageLoad"
          v-if="user.picture == 'yes'"
          :src="API_URL + user.id + '/profile_picture.jpg?v=' + time"
          alt=""
          class="cursor-pointer"
          @click="OpenProfilePhotoPreview"
        />
        <img
          @load="onImageLoad"
          v-else
          src="../../assets/images/user-default-new.png"
          alt=""
        />
      </div>
      <div class="products-list-shim" v-if="user">
        <content-placeholders>
          <content-placeholders-img />
        </content-placeholders>
      </div>
      <EditProfilePhoto />

      <div class="profile-photo-modal" v-if="openProfilePhoto">
        <button
          class="close-icon btn btn-outline-danger btn-sm lb-modal-close"
          @click="CloseProfilePhotoPreview"
        >
          <i class="fa fa-times"></i>
        </button>
        <div class="profile-img-full-preview" @click="CloseProfilePhotoPreview">
          <img
            v-if="user.picture == 'yes'"
            :src="API_URL + user.id + '/profile_picture.jpg?v=' + time"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EditProfilePhoto from "./EditProfilePhoto.vue";
export default {
  components: { EditProfilePhoto },
  name: "ProfilePhoto",
  data() {
    return {
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      openProfilePhoto: false,
      showLoader: 1,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    OpenProfilePhotoPreview() {
      this.openProfilePhoto = true;
      // document.querySelector("body").style.overflow = "hidden";
    },
    CloseProfilePhotoPreview() {
      this.openProfilePhoto = false;
      // document.querySelector("body").style.overflow = "visible";
    },
    onImageLoad() {
      this.showLoader = 0;
    },
  },
};
</script>
<style scoped>
.products-list-shim {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}
</style>
