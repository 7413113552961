<template>
  <div>
    <div class="friends-top mt-3">
      <div class="friends-left mb-3 mb-md-0">
        <router-link :to="/profile/ + userlink" exact class="m-0"
          ><img src="../../assets/images/arrow-left.png" alt=""
        /></router-link>
        <h3>Friends</h3>
      </div>
      <div class="form-inline">
        <button>
          <img
            class="search-image"
            src="../../assets/images/search.png"
            alt=""
          />
        </button>
        <input
          v-model="searchValue"
          class="form-control border-0"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
      </div>
    </div>
    <div class="friends-options">
      <ul class="options-list">
        <li>
          <a
            :class="{
              active: isActiveFriends,
              'disabled-button': isButtonExecuting,
            }"
            @click="switchTabs('friends')"
            data-tab="friends"
            class="m-0"
          >
            All Friends
            <span v-if="friendList">({{ friendList.length }})</span>
          </a>
        </li>
        <li>
          <a
            :class="{
              active: isActiveFollower,
              'disabled-button': isButtonExecuting,
            }"
            @click="switchTabs('followers')"
            data-tab="followers"
            class="m-0"
            >Followers
            <span>({{ followerListCount }})</span>
          </a>
        </li>
        <li>
          <a
            :class="{
              active: isActiveFollowing,
              'disabled-button': isButtonExecuting,
            }"
            @click="switchTabs('following')"
            data-tab="following"
            class="m-0"
            >Following
            <span>({{ followingListCount }})</span>
          </a>
        </li>
      </ul>

      <div
        class="ui bottom attached tab segment"
        data-tab="friends"
        :class="{ active: isActiveFriends }"
        v-if="isActiveFriends"
      >
        <div v-if="showLoader == 0">
          <friends-list
            v-if="filteredFriends"
            :items="filteredFriends"
            :isButtonExecuting="isButtonExecuting"
          />
        </div>
        <friends-shim v-if="showLoader == 1" />
      </div>
      <div
        class="ui bottom attached tab segment"
        data-tab="followers"
        :class="{ active: isActiveFollower }"
        v-if="isActiveFollower"
      >
        <!-- <div v-if="shims == 0"> -->
        <followers-list
          v-if="filteredFollower"
          :items="filteredFollower"
          :loader="loader"
        />
        <!-- </div>
        <friends-shim v-if="shims == 1" /> -->
      </div>
      <div
        class="ui bottom attached tab segment"
        data-tab="following"
        :class="{ active: isActiveFollowing }"
        v-if="isActiveFollowing"
      >
        <!-- <div v-if="shims == 0"> -->
        <following
          v-if="filteredFollowing"
          :items="filteredFollowing"
          :loader="loader"
        />
        <!-- </div>
        <friends-shim v-if="shims == 1" /> -->
      </div>
      <div
        v-observe-visibility="handleScrolledToButtomFollowers"
        v-if="followerList.length"
      ></div>
      <div
        v-observe-visibility="handleScrolledToButtomFollowing"
        v-if="followingList.length"
      ></div>
    </div>
  </div>
</template>
<script>
import FollowersList from "../OthersFriends/followersList";
import Following from "../OthersFriends/following.vue";
import FriendsList from "../OthersFriends/FriendsList.vue";
import axios from "axios";
import FriendsShim from "../OthersFriends/friendsShim.vue";

export default {
  components: { FriendsList, FollowersList, Following, FriendsShim },
  name: "FriendsOptions",
  props: {
    friendList: Array,
    followingListCount: Number,
    followerListCount: Number,
    showLoader: Number,
  },
  computed: {
    filteredFriends() {
      let tempProds = this.friendList;

      // Process search input
      if (this.searchValue != "" && this.searchValue) {
        tempProds = tempProds.filter((item) => {
          return item.user_name
            .toUpperCase()
            .includes(
              this.searchValue.toUpperCase().trim().replace(/\s+/g, " ")
            );
        });
      }
      return tempProds;
    },
    filteredFollower() {
      let tempProds = this.followerList;

      // Process search input
      if (this.searchValue != "" && this.searchValue) {
        tempProds = tempProds.filter((item) => {
          return item.user_name
            .toUpperCase()
            .includes(
              this.searchValue.toUpperCase().trim().replace(/\s+/g, " ")
            );
        });
      }
      return tempProds;
    },
    filteredFollowing() {
      let tempProds = this.followingList;

      // Process search input
      if (this.searchValue != "" && this.searchValue) {
        tempProds = tempProds.filter((item) => {
          return item.user_name
            .toUpperCase()
            .includes(
              this.searchValue.toUpperCase().trim().replace(/\s+/g, " ")
            );
        });
      }
      return tempProds;
    },
  },
  data() {
    return {
      isButtonExecuting: true,
      isActiveFriends: true,
      isActiveFollower: false,
      isActiveFollowing: false,
      user_link: "",
      pre_link: "",
      followerList: [],
      followingList: [],
      shims: 0,
      searchValue: "",
      userlink: "",
      lastSegment: "",
      page: 1,
      lastPage: 1,
      loader: 0,
    };
  },
  methods: {
    switchTabs(tab) {
      // if (tab === "friends") {
      //   this.searchValue = "";
      //   this.isActiveFriends = true;
      //   this.isActiveFollower = false;
      //   this.isActiveFollowing = false;
      // } else if (tab === "followers") {
      //   this.followerList = [];
      //   this.page = 1;
      //   this.lastPage = 1;
      //   this.followersGet(1);
      //   this.searchValue = "";
      //   this.isActiveFriends = false;
      //   this.isActiveFollower = true;
      //   this.isActiveFollowing = false;
      // } else if (tab === "following") {
      //   this.followingList = [];
      //   this.page = 1;
      //   this.lastPage = 1;
      //   this.followingGet(1);
      //   this.searchValue = "";
      //   this.isActiveFriends = false;
      //   this.isActiveFollower = false;
      //   this.isActiveFollowing = true;
      // }

      this.searchValue = "";
      this.isButtonExecuting = true;
      this.isActiveFriends = tab === "friends";
      this.isActiveFollower = tab === "followers";
      this.isActiveFollowing = tab === "following";
      this.followerList = [];
      this.followingList = [];
      this.page = 1;
      this.lastPage = 1;
      if (tab === "friends") {
        this.isButtonExecuting = false;
        this.isActiveFriends = true;
        this.isActiveFollower = false;
        this.isActiveFollowing = false;
        this.isActiveRequestSent = false;
        this.isActiveRequest = false;
      } else if (this.isActiveFollower) {
        this.followersGet(1);
      } else if (this.isActiveFollowing) {
        this.followingGet(1);
      }

      // this.$root.$emit("FriendsPageCount");
    },
    async followersGet() {
      this.loader = 1;
      this.isButtonExecuting = true;
      var formData = new FormData();
      formData.append("user_link", this.user_link);
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            `friends/viewOtherFollowers?page=${this.page}&user_link=${this.user_link}`,
          formData,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.loader = 0;
            this.followerList.push(...response.data.data.followers.data);
            this.lastPage = response.data.data.followers.last_page;
            this.isButtonExecuting = false;
          } else {
            this.loader = 0;
            this.errorMessage = response.data.message;
            this.isButtonExecuting = false;
          }
        });
    },
    async followingGet() {
      this.loader = 1;
      this.isButtonExecuting = true;
      var formData = new FormData();
      formData.append("user_link", this.user_link);
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            `friends/viewOtherFollowing?page=${this.page}&user_link=${this.user_link}`,
          formData,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.loader = 0;
            this.followingList.push(...response.data.data.followers.data);
            this.lastPage = response.data.data.followers.last_page;
            this.isButtonExecuting = false;
          } else {
            this.loader = 0;
            this.errorMessage = response.data.message;
            this.isButtonExecuting = true;
          }
        });
    },
    getuserLink() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.pre_link = segment_array.pop();
      if (this.user_link !== "otherFriends") {
        this.user_link = this.pre_link;
      }
    },
    getuserLinkOther() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.lastSegment = segment_array.pop();
      if (this.lastSegment !== "otherPhotos") {
        this.userlink = this.lastSegment;
      }
    },
    handleScrolledToButtomFollowers(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.followersGet(1);
    },
    handleScrolledToButtomFollowing(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.followingGet(1);
    },
  },

  mounted() {
    this.getuserLink();
    this.getuserLinkOther();
    this.$root.$on("enableOtherFriendsButton", () => {
      this.isButtonExecuting = false;
    });
  },
};
</script>
