<template>
  <div>
    <h2 class="main-heading">Interpretation and Definitions</h2>
    <p class="sub-heading">Interpretation</p>
    <p class="main-para">
      The words of which the initial letter is capitalized have meanings defined
      under the following conditions. The following definitions shall have the
      same meaning regardless of whether they appear in singular or in plural.
    </p>
    <p class="sub-heading">Definitions</p>
    <p class="main-para">For the purposes of this Return and Refund Policy:</p>
    <div class="tracking-technologies">
      <ul class="cookies-types">
        <li class="font-weight-normal">
          <div>
            <span>Application</span> means the software program provided by the
            Company downloaded by You on any electronic device, named WIF
          </div>
        </li>
        <li>
          <div>
            <span>Company</span> (referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to Who I Follow Corp, 12254
            Highland Rd.
          </div>
        </li>
        <li>
          <div>
            <span>Goods</span> refer to the items offered for sale on the
            Service.
          </div>
        </li>
        <li>
          <div>
            <span>Orders</span> mean a request by You to purchase Goods from Us.
          </div>
        </li>
        <li>
          <div>
            <span>Service</span> refers to the Application or the Website or
            both.
          </div>
        </li>
        <li>
          <div>
            <span>Website</span> refers to Who I Follow, accessible from
            <router-link to="">http://www.whoifollow.com </router-link>
          </div>
        </li>
        <li>
          <div>
            <span>You</span> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </div>
        </li>
      </ul>
    </div>
    <h2 class="main-heading mb-3">Your Order Cancellation Rights</h2>
    <p class="main-para mb-2">
      You are entitled to cancel Your Order within 14 days without giving any
      reason for doing so.
    </p>
    <p class="main-para mb-2">
      The deadline for cancelling an Order is 14 days from the date on which You
      received the Goods or on which a third party you have appointed, who is
      not the carrier, takes possession of the product delivered.
    </p>
    <p class="main-para mb-2">
      In order to exercise Your right of cancellation, You must inform Us of
      your decision by means of a clear statement. You can inform us of your
      decision by:
    </p>
    <div class="tracking-technologies">
      <ul class="cookies-types">
        <li class="font-weight-normal">
          <div>By email: refunds@whoifollow.com</div>
        </li>
        <li>
          <div>
            By visiting this page on our website:
            <router-link to="">http://www.whoifollow.com/refunds</router-link>
          </div>
        </li>
      </ul>
    </div>
    <p class="main-para mb-2">
      We will reimburse You no later than 14 days from the day on which We
      receive the returned Goods. We will use the same means of payment as You
      used for the Order, and You will not incur any fees for such
      reimbursement.
    </p>
    <h2 class="main-heading mb-3">Conditions for Returns</h2>
    <p class="main-para mb-2">
      In order for the Goods to be eligible for a return, please make sure that:
    </p>
    <div class="tracking-technologies">
      <ul>
        <li>
          <div>The Goods were purchased in the last 14 days</div>
        </li>
        <li>
          <div>The Goods are in the original packaging</div>
        </li>
        <li>
          <div>The Goods were not used or damaged</div>
        </li>
        <li>
          <div>You have the receipt or proof of purchase</div>
        </li>
      </ul>
    </div>
    <p class="main-para mb-2">The following Goods cannot be returned:</p>
    <div class="tracking-technologies">
      <ul>
        <li>
          <div>
            The supply of Goods made to Your specifications or clearly
            personalized.
          </div>
        </li>
        <li>
          <div>
            The supply of Goods which according to their nature are not suitable
            to be returned, deteriorate rapidly or where the date of expiry is
            over.
          </div>
        </li>
        <li>
          <div>
            The supply of Goods which are not suitable for return due to health
            protection or hygiene reasons and were unsealed after delivery.
          </div>
        </li>
        <li>
          <div>
            The supply of Goods which are, after delivery, according to their
            nature, inseparably mixed with other items.
          </div>
        </li>
      </ul>
    </div>
    <p class="main-para mb-2">
      We reserve the right to refuse returns of any merchandise that does not
      meet the above return conditions in our sole discretion.
    </p>
    <p class="main-para mb-2">
      Only regular priced Goods may be refunded. Unfortunately, Goods on sale
      cannot be refunded. This exclusion may not apply to You if it is not
      permitted by applicable law.
    </p>
    <h2 class="main-heading mb-3">Returning Goods</h2>
    <p class="main-para mb-2">
      You are responsible for the cost and risk of returning the Goods to Us.
      You should send the Goods at the following address:
    </p>
    <p class="main-para mb-2">12254 Highland Rd Highland, IL 62249</p>
    <p class="main-para mb-2">
      We cannot be held responsible for Goods damaged or lost in return
      shipment. Therefore, We recommend an insured and trackable mail service.
      We are unable to issue a refund without actual receipt of the Goods or
      proof of received return delivery.
    </p>
    <h2 class="main-heading mb-3">Gifts</h2>
    <p class="main-para mb-2">
      If the Goods were marked as a gift when purchased and then shipped
      directly to you, You'll receive a gift credit for the value of your
      return. Once the returned product is received, a gift certificate will be
      mailed to You.
    </p>
    <p class="main-para mb-2">
      If the Goods weren't marked as a gift when purchased, or the gift giver
      had the Order shipped to themselves to give it to You later, We will send
      the refund to the gift giver.
    </p>
    <p class="sub-heading">Contact Us</p>
    <p class="main=para mb-2">
      If you have any questions about our Returns and Refunds Policy, please
      contact us:
    </p>
    <div class="tracking-technologies">
      <ul class="cookies-types">
        <li class="font-weight-normal">
          <div>By email: refunds@whoifollow.com</div>
        </li>
        <li>
          <div>
            By visiting this page on our website:
            <router-link to="">http://www.whoifollow.com/refunds</router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>