<template>
  <div>
    <NewSponsor :eventId="eventId" />
    <EditSponsor  :eventId="eventId"/>
    <ManageParks :eventId="eventId"/>
    <NewHotel :eventId="eventId"/>
    <EditHotel :eventId="eventId"/>
    <div class="details-info" v-if="events && showLoader == 0">
      <div class="fee">
        <div class="row no-gutters align-items-cener">
          <div class="col-4 col-md-2">
            <h6 class="word-break">Entry Fee:</h6>
          </div>
          <div class="col-8 col-md-10">
            <div class="d-flex justify-content-between">
              <span class="pr-2 pr-md-0" v-if="events.entry_fee">${{ events.entry_fee }}</span>
              <span class="pr-2 pr-md-0" v-else>N/A</span>

              <h6 v-if="events.entry_fee_deadline">Entry Fee Deadline: <span>{{ events.entry_fee_deadline | formatDateOfBirth }}</span></h6>
              <h6 v-else>Entry Fee Deadline: <span>N/A</span></h6>
            </div>
          </div>
          <div class="col-4 col-md-2">
            <h6>Notes:</h6>
          </div>
          <div class="col-8 col-md-10">
            <p v-if="events.note">
              {{ events.note }}
            </p>
            <p v-else>
              N/A
            </p>
          </div>
          <div class="col-4 col-md-2">
            <h6 class="word-break">Reminder:</h6>
          </div>
          <div class="col-8 col-md-10">
           
            <p v-if="events.reminder">
              {{events.reminder}}
            </p>
            <p v-else>
              N/A
            </p>
          </div>
          <div class="col-4 col-md-2">
            <h6 class="word-break">Refund Policy:</h6>
          </div>
          <div class="col-8 col-md-10">
            <p v-if="events.refund_policy">
              {{events.refund_policy}}
            </p>
            <p v-else>
              N/A
            </p>
          </div>
          <div class="col-4 col-md-2">
            <h6 class="word-break">Tournament Format:</h6>
          </div>
          <div class="col-8 col-md-10">
            <p v-if="events.tournament_format">
              {{events.tournament_format}}
            </p>
            <p v-else>
              N/A
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="products-list-shim p-0" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-heading />
      </content-placeholders>
    </div>
    <div class="row no-gutters mt-4">
      <div class="col-12 col-md-6 col-xl-4 pr-0 pr-md-4">
        <div class="products-list-shim p-0" v-if="eventSponsors.length == 0 && sponsorLoader == 1">
          <content-placeholders :rounded="true">
              <content-placeholders-img />
              <content-placeholders-heading />
            </content-placeholders>
            
          </div>

          <div class="sponsor-wrapper" v-else-if="eventSponsors.length == 0 && sponsorLoader == 0">
            <div class="top">
              <h6>Sponsors</h6>
              <button class="btn btn-primary" @click="newSponsor()" v-if="currentIsAdmin == '1'">Add Sponsor</button>
            </div>
            <div class="row no-gutters">
              <div class="text-center w-100">
                <p class="">No sponsor found yet.</p>
              </div>

            </div>
          </div>

        <div class="sponsor-wrapper" v-else>
          <div class="top">
            <h6>Sponsors</h6>
            <button class="btn btn-primary" @click="newSponsor()" v-if="currentIsAdmin == '1'">Add Sponsor</button>
          </div>
          <div class="sponsor-wrap">
            <div class="sponsor-content"  v-for="(sponsor, index) in eventSponsors" :key="index" :style="{ cursor: sponsor.website_url ? 'pointer' : 'default' }">
              <div class="sponsor-info">
                <div class="sponsor-img" v-if="sponsor.image_url">
                  <img :src="sponserURL + sponsor.image_url" alt="Sponsor Image" />
                </div>
                <div class="sponsor-img" v-else>
                  <img src="../../assets/images/sponsor.png" alt="" />
                </div>
                <div class="edit-sponsor">
                  <img src="../../assets/images/edit.png" alt="" @click="editSponsor(sponsor)" v-if="currentIsAdmin == '1'" />
                </div>
              </div>
              <p class="sponsor-name" @click="openSponsorUrl(sponsor.website_url)">{{ sponsor.name }}</p>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-12 col-md-6 col-xl-4 pr-0 pr-xl-4">

        <div class="products-list-shim p-0" v-if="eventPlayingFacility.length == 0 && playingFacilityLoader == 1">
          <content-placeholders :rounded="true">
              <content-placeholders-img />
              <content-placeholders-heading />
            </content-placeholders>
            
          </div>

        <div class="playing-wrapper" v-else-if="eventPlayingFacility.length == 0 && playingFacilityLoader == 0">
          <div class="top">
            <h6>Playing Facilities</h6>
            <button class="btn btn-primary" @click="manageParks()" v-if="currentIsAdmin == '1'">Manage Parks</button>
          </div>
          <div class="ground-info">
            <div class="text-center w-100">
                <p class="">No playing facility found yet.</p>
              </div>
          </div>
          
        </div>

        <div class="playing-wrapper" v-else>
          <div class="top">
            <h6>Playing Facilities</h6>
            <button class="btn btn-primary" @click="manageParks()" v-if="currentIsAdmin == '1'">Manage Parks</button>
          </div>
          <div class="ground-info" v-for="(playingFacility, index) in eventPlayingFacility" :key="index">
            <div class="park-location">
              <div class="park-img">
                <img src="../../assets/images/baseball_field.png" alt="" />
              </div>
              <div class="park-info-sec">
                <h6>{{ playingFacility?.park_name }}</h6>
                <div
                  class="park-info-sec-loc cursor-pointer"
                  @click="openGoogleMapforGamePark(playingFacility)"
                  v-if="playingFacility?.park_name"
                >
                  <img src="../../assets/images/location.png" />
                  <p>
                    <span v-if="playingFacility?.address">{{ playingFacility.address }}</span>
                    <span v-if="playingFacility?.number"> , {{ playingFacility.number }} </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="park-edit-btn d-none">
              <img src="../../assets/images/edit.png" alt="" />
            </div>
          </div>

        </div>

      </div>
      <!-- Add this section inside your template -->
      <div class="col-12 col-md-6 col-xl-4 pr-0 pr-md-4">
        <div class="products-list-shim p-0" v-if="eventHotels.length == 0 && hotelLoader == 1">
          <content-placeholders :rounded="true">
              <content-placeholders-img />
              <content-placeholders-heading />
            </content-placeholders>
            
          </div>
          <div class="sponsor-wrapper" v-else-if="eventHotels.length == 0 && hotelLoader == 0">
            <div class="top">
              <h6>Hotels</h6>
              <button class="btn btn-primary" @click="newHotel()" v-if="currentIsAdmin == '1'">Add Hotel</button>
            </div>
            <div class="row no-gutters">
              <div class="text-center w-100">
                <p class="">No hotel found yet.</p>
              </div>

            </div>
          </div>
        
         
          <div class="hotel-wrapper" v-else>
          <div class="top">
            <h6>Hotels</h6>
            <button class="btn btn-primary" @click="newHotel()" v-if="currentIsAdmin == '1'">Add Hotel</button>
          </div>
          <div class="ground-info" v-for="(hotel, index) in eventHotels" :key="index" :style="{ cursor: hotel.website_url ? 'pointer' : 'default' }">
            <div class="park-location hotel-loc">
              <div class="park-hotel-img" v-if="hotel.image_url">
                  <img :src="hotelURL + hotel.image_url" alt="Hotel Image" />
                </div>
              <div class="park-img hotel-img" v-else>
                <img src="../../assets/images/sponsor.png" alt="" />
              </div>
              <div class="park-info-sec hotel-info-sec">
                <h6>{{hotel.name}}</h6>
                <div class="park-info-sec-loc">
                  <img src="../../assets/images/location.png" alt="" />
                  <p> {{ hotel.street_address }} . {{ hotel.city }} {{ hotel.state }}, {{ hotel.zip }}</p>
                </div>
              </div>
            </div>
            <div class="park-edit-btn" @click="editHotel(hotel)" v-if="currentIsAdmin == '1'">
              <img src="../../assets/images/edit.png" alt="" />
            </div>
            <p class="hotel-content">
            {{hotel.notes}}
          </p>
          </div>
        </div>
   
  </div>

    </div>
  </div>
</template>

<script>
import EditHotel from './Modals/EditHotel.vue';
import EditSponsor from './Modals/EditSponsor.vue';
import ManageParks from './Modals/ManageParks.vue';
import NewHotel from './Modals/NewHotel.vue';
import NewSponsor from './Modals/NewSponsor.vue';
export default {
  components: { NewSponsor, EditSponsor, ManageParks, NewHotel, EditHotel },
  name: "AssociationEventInfo",
  data() {
    return {
      sponserURL: this.$eventSponserStorageURL,
     hotelURL : this.$eventHotelStorageURL
    };
  },
  props: {
    events: {
      type: Object,
    },
    showLoader: {
      type: Number
    },
    eventId: {
      type: Number,
    },
    eventSponsors: {
      type: Array
    },
    eventHotels: {
      type: Array
    },
    sponsorLoader: {
      type: Number
    },
    hotelLoader:{
      type: Number
    },
    currentIsAdmin: {
      type: String,
    },
    eventPlayingFacility: {
      type: Array
    },
    playingFacilityLoader: {
      type: Number
    },
  },
  methods: { 
    openGoogleMapforGamePark(item) {
      const strlatLng = item["lat"] + "," + item["lng"];
      const urlSuffix = strlatLng ? strlatLng : item["address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    openSponsorUrl(websiteUrl) {
        if (websiteUrl) {
            window.open(websiteUrl, '_blank');
        }
    },

    newSponsor() {
      this.$root.$emit("openNewSponsorModal");
    },

    editSponsor(sponsor) {
  // Emit the "openEditSponsorModal" event along with the sponsor data
  this.$root.$emit("openEditSponsorModal", sponsor);
},

    manageParks() {
      this.$root.$emit("openManageParksModal");
    },

    newHotel() {
      this.$root.$emit("openNewHotelModal");
    },

    editHotel(hotel) {
      this.$root.$emit("openEditHotelModal", hotel);
    }
  },
  mounted(){
console.log(this.currentIsAdmin,"admi")
  }

};
</script>

<style scoped>

/* .sponsor-wrapper, .playing-wrapper, .sponsor-wrapper {
  padding: 10px;
  background: #f0f0f0;
  height: 100%;
} */

</style>