<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="row no-gutters">
          <div class="col-12">
            <CoverPhoto />
            <ChangeCover />
          </div>
        </div>
        <div class="row align-items-center cover-bottom">
          <div class="col-12 col-md-6 col-lg-3">
            <div class="profile-photo-section">
              <ProfilePhoto />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-9">
            <div class="profile-name-section">
              <Username />
            </div>
          </div>
        </div>
        <div class="row no-gutters mt-3">
          <div class="col-lg-4 col-md-6 col-sm-12 pr-md-3">
            <Followers />
            <Info />
            <ProfileFriends />
            <ProfilePhotos />
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 pl-md-0">
            <div class="row">
              <div class="col-12">
                <Mew />
              </div>
              <div class="col-12">
                <ProfilePosts class="mt-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import CoverPhoto from "../components/ProfilePage/CoverPhoto.vue";
import ChangeCover from "../components/ProfilePage/ChangeCover.vue";
import ProfilePhoto from "../components/ProfilePage/ProfilePhoto.vue";
import Username from "../components/ProfilePage/Username.vue";
import Followers from "../components/ProfilePage/Followers.vue";
import Info from "../components/ProfilePage/Info.vue";
import ProfileFriends from "../components/ProfilePage/ProfileFriends.vue";
import ProfilePhotos from "../components/ProfilePage/ProfilePhotos.vue";
import ProfilePosts from "../components/ProfilePage/ProfilePosts";
import Mew from "../components/ProfilePage/Mew.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Navbar,
    LeftMenu,
    CoverPhoto,
    ProfilePosts,
    ChangeCover,
    ProfilePhoto,
    Username,
    Banner,
    Followers,
    Info,
    ProfileFriends,
    ProfilePhotos,
    Mew,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getUserData();
  },
};
</script>
<style>
.navbar-expand-lg .navbar-nav .nav-link {
  padding-bottom: 20px;
}
</style>
