<template>
  <div>
    <div>
      <div class="all-people-wrapper" v-if="searchProfiles.length">
        <h6>People</h6>

        <!-- Profiles on All -->
        <div v-if="openTab == 1">
          <div class="people-wrapper" v-for="profile in searchProfiles" :key="profile.id">
             <!-- Create Chat -->
            <DirectMessageModal :type="type" :userId="profile.user_id" />
            <div class="people-img" v-if="user">
              <span v-if="user.user_link == profile.user_link">
                <router-link to="/profile" exact class="mx-0">
                  <img
                    v-if="profile.picture == 'yes'"
                    :src="
                      Storage_URL +
                        profile.user_id +
                        '/profile_picture.jpg?v=' +
                        time
                    "
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/user-default-new.png"
                    alt=""
                   
                  />
                </router-link>
              </span>
              <span v-else>
                <router-link :to="/profile/ + profile.user_link" exact class="mx-0">
                  <img
                    v-if="profile.picture == 'yes'"
                    :src="
                      Storage_URL +
                        profile.user_id +
                        '/profile_picture.jpg?v=' +
                        time
                    "
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/user-default-new.png"
                    alt=""
                  
                  />
                  
                </router-link>
              </span>
            </div>
            <div class="people-info" v-if="user">
              <span v-if="user.user_link == profile.user_link">
                <router-link to="/profile" exact class="mx-0">
                  {{
                  profile.user_name
                  }}
                </router-link>
              </span>
              <span v-else>
                <router-link
                  :to="/profile/ + profile.user_link"
                  exact
                  class="mx-0"
                >{{ profile.user_name }}</router-link>
              </span>
              <p v-if="profile.currentUser == 0 && profile.friend == 3">Friend</p>
              <p v-if="profile.currentUser == 0 && profile.friend == 1">Request Sent</p>
              <p v-if="profile.currentUser == 0 && profile.friend == 2">Request Received</p>
            </div>
            <div
              class="add-friend"
              :id="profile.user_id"
              v-if="profile.currentUser == 0 && profile.friend == 0"
              v-on:click="add(profile.user_id)"
            >
              <img src="../../../assets/images/search-add-friend.png" />
              <span class="tool-tip-text">Add Friend</span>
            </div>
            <div
              class="message-friend"
              v-if="profile.currentUser == 0 && profile.friend == 3"
              data-toggle="modal"
              data-target="#individualModalDirect"
              v-on:click="
                fetchDirectMessageData(profile.user_name, profile.user_id)
              "
            >
              <img src="../../../assets/images/search-message.png" />
              <span class="tool-tip-text">Message</span>
            </div>
          </div>
        </div>
        <!-- HHHHHHHHHHHHHHHHEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE -->
        <div v-if="openTab == 2">
          <div class="people-wrapper" v-for="profile in searchFetchProfile" :key="profile.id">
             <!-- Create Chat -->
            <DirectMessageModal :type="type" :userId="profile.user_id" />
            <div class="people-img">
              <span v-if="user.user_link == profile.user_link">
                <router-link to="/profile" exact class="mx-0">
                  <img
                    v-if="profile.picture == 'yes'"
                    :src="
                      Storage_URL +
                        profile.user_id +
                        '/profile_picture.jpg?v=' +
                        time
                    "
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/user-default-new.png"
                    alt=""
                   
                  />
                  
                </router-link>
              </span>
              <span v-else>
                <router-link :to="/profile/ + profile.user_link" exact class="mx-0">
                  <img
                    v-if="profile.picture == 'yes'"
                    :src="
                      Storage_URL +
                        profile.user_id +
                        '/profile_picture.jpg?v=' +
                        time
                    "
                    alt=""/>
                  <img
                    v-else
                    src="../../../assets/images/user-default-new.png"
                    alt=""
                    
                /></router-link>
              </span>
            </div>
            <div class="people-info" v-if="user">
              <span v-if="user.user_link == profile.user_link">
                <router-link to="/profile" exact class="mx-0">
                  {{
                  profile.user_name
                  }}
                </router-link>
              </span>
              <span v-else>
                <router-link
                  :to="/profile/ + profile.user_link"
                  exact
                  class="mx-0"
                >{{ profile.user_name }}</router-link>
              </span>
              <p v-if="profile.currentUser == 0 && profile.friend == 3">Friend</p>
              <p v-if="profile.currentUser == 0 && profile.friend == 1">Request Sent</p>
              <p v-if="profile.currentUser == 0 && profile.friend == 2">Request Received</p>
            </div>
            <div
              class="add-friend"
              :id="profile.user_id"
              v-if="profile.currentUser == 0 && profile.friend == 0"
              v-on:click="add(profile.user_id)"
            >
              <img src="../../../assets/images/search-add-friend.png" />
              <span class="tool-tip-text">Add Friend</span>
            </div>
            <div
              class="message-friend"
              v-if="profile.currentUser == 0 && profile.friend == 3"
              data-toggle="modal"
              data-target="#individualModalDirect"
              v-on:click="
                fetchDirectMessageData(profile.user_name, profile.user_id)
              "
            >
              <img src="../../../assets/images/search-message.png" />
              <span class="tool-tip-text">Message</span>
            </div>
          </div>
        </div>
        <button v-if="openTab == 1" class="see-all-friends" v-on:click="searchPeople">
          <span>See All</span>
        </button>
      </div>
      <div class="see-all-seen-post-wrapper" v-if="searchProfiles.length == 0">
        <p>No people found.</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import DirectMessageModal from "../../Teams/ChatRight/ChatSettingComponents/DirectMessageModal.vue";
import $ from "jquery";
export default {
  computed: {
    ...mapGetters("auth", ["user"])
  },
  components: {
    DirectMessageModal
  },
  name: "People",
  data() {
    return {
      type: "searchPage",
      isDisabledIndividual: true,
      showLoaderIndividual: 0,
      showIndividual: 1,
      individualMessage: "",
      sentUserName: "",
      sentUserId: "",
      time: this.$time,
      Storage_URL: this.$userProfileStorageURL,
      startRooms: null,
      currentUserName: localStorage.getItem("userName"),
      API_URL: this.$userProfileStorageURL
    };
  },
  props: {
    openTab: {
      type: Number
    },
    searchProfiles: {
      type: Array
    },
    searchFetchProfile: {
      type: Array
    }
  },
  methods: {
    searchPeople() {
      window.scrollTo(0, 0);
      this.$root.$emit("openTabSearchSelection", 2, 0, 0);
    },
    // ---- Send Message ----------------------------------------------------------------------
    fetchDirectMessageData(name, userID) {
      this.$root.$emit("fetchDirectMessageData", name, userID);
    },
    // ------------------------------------------------
    async add(friend_id) {
      $("#" + friend_id).hide();
      var formData = new FormData();
      formData.append("friend_id", friend_id);
      return axios
        .post(process.env.VUE_APP_API_URL + "friends/addFriend", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.statusCode == 200) {
            if (this.openTab == 1) {
              const profile = this.searchProfiles.find(
                profile => profile.id == friend_id
              );
              profile.friend = 1;
              // this.$root.$emit("openTabSearchSelection", 1, 1, 0);
            } else if (this.openTab == 2) {
              // this.$root.$emit("openTabSearchSelection", 2, 1, 0);
              const friendElement = this.searchFetchProfile.find(
                element => element.id === friend_id
              );
              if (friendElement) {
                friendElement.friend = 1;
              }
            }
          } else {
            this.errorMessage = response.data.message;
          }
        });
    }
  }
};
</script>
<style scoped></style>
