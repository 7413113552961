var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AddTournament',{attrs:{"eventId":_vm.eventId,"currentIsAdmin":_vm.currentIsAdmin}}),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.currentIsAdmin == '1')?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Add Tournament ")]):_vm._e()]),(
      _vm.tournamentsData &&
      _vm.tournamentsData.length != 0 &&
      _vm.showLoaderTournament == 0
    )?_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{staticClass:"mt-3 b-datatable cursor-pointer",attrs:{"items":_vm.tournamentsData,"fields":_vm.fields,"hover":"","responsive":""},scopedSlots:_vm._u([{key:"cell(Tournament)",fn:function(data){return [_c('router-link',{staticClass:"stats-link",attrs:{"to":{ name: 'Tournament', params: { id: data.item.guid } }}},[_vm._v(" "+_vm._s(data.item.tournamentName)+" ")])]}},{key:"cell(Date)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.date)+" ")]}},{key:"cell(Teams)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.teamCounts)+" Teams ")]}},(_vm.currentIsAdmin == '1')?{key:"cell(Action)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-end",on:{"click":function($event){return _vm.handleActionClick(data.item)}}},[_c('EditTournamentEllipsis',{attrs:{"tournament":data.item,"eventId":_vm.eventId,"currentIsAdmin":_vm.currentIsAdmin}})],1)]}}:null],null,true)}),(_vm.showLoaderTournament == 1)?_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":false}})],1):_vm._e()],1):_vm._e(),(
      (!_vm.tournamentsData || _vm.tournamentsData.length === 0) &&
      _vm.showLoaderTournament === 0
    )?_c('div',{staticClass:"no-products-in-shop"},[_c('img',{attrs:{"src":require("../../assets/images/no-product.png"),"alt":""}}),_c('p',{staticClass:"header"},[_vm._v("No tournament found.")]),_c('p',{staticClass:"info"},[_vm._v("There are no tournament found to display.")])]):_vm._e(),(_vm.showLoaderTournament == 1)?_c('div',{staticClass:"products-list-shim p-0 mt-3"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-img'),_c('content-placeholders-heading')],1),_c('content-placeholders',{staticClass:"mt-3",attrs:{"rounded":true}},[_c('content-placeholders-img'),_c('content-placeholders-heading')],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }