<template>
  <div>
    <!-- Edit Model -->
    <div
      class="modal fade bd-example-modal-lg"
      id="editProfilebio"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Edit Bio</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalPostEdit"
              @click="closeOldDiv"
            >
              <img src="../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <textarea
                type="text"
                v-model.trim="$v.details.bio.$model"
                :placeholder="'Tell everyone about you, ' + user.name + '?'"
                class="form-control border-0 bio-input"
                :class="{
                  'is-invalid': validationStatus($v.details.bio),
                }"
                v-on:keyup = errorNull()
              ></textarea>
              <div
                v-if="!$v.details.bio.required"
                class="invalid-tooltip"
              >
                Bio is required
              </div>
            </div>
          </div>
            <div class="event-btns-wrapper justify-content-end px-3 pb-3 pt-0">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              @click="updateBio(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "updateBio",
  props: {
    items: {
      type: Object,
    },
  },

  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      details: {
        bio: "",
      },
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },
  validations: {
    details: {
      bio: { required },
    },
  },

  methods: {
    closeOldDiv: function() {
      this.$root.$emit("closeEditProfileModal");
      if (this.user) {
        this.details.bio = this.user.bio;
      }
    },
    bioValues() {
      if (this.user) {
        this.details.bio = this.user.bio;
      }
    },
    validationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    errorNull: function() {
      if(this.$v.$invalid){
        this.isDisabled = true;
      }
      else{
        this.isDisabled = false;
      }
    },
    ...mapActions("auth", ["getUserData"]),
    updateBio: function(index) {
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append("bio", this.details.bio ? this.details.bio : "");

      return axios
        .post(process.env.VUE_APP_API_URL + "user/bioUpdate", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            var elem = this.$refs.closeModalPostEdit;
            elem.click();
            this.getUserData();
            this.$set(this.isLoadingArray, index, false);
          } else {
            setTimeout(() => {
              this.$set(this.isLoadingArray, index, false);
              this.errorMessage = response.data.message;
            }, 500);
          }
        });
    },
  },
  mounted: function() {
    if (this.user) {
      this.details.bio = this.user.bio;
      
    }
    this.$root.$on("updateBio", () => {
      this.bioValues(1);
      this.$v.details.bio.$reset();
    });
  },
};
</script>
