var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"live-stream-content d-none"},[_vm._v(" This will publish the boxscores for live stream and live stream channel for this game will be available for users to view. ")]),_c('div',{staticClass:"d-md-flex align-items-center justify-content-between footer-score"},[_c('div',[(_vm.showShimmer == 1 || _vm.gameActionLoader == 1)?_c('content-placeholders',{staticClass:"post-view mt-3 w-100",attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":4}})],1):_vm._e(),(
        _vm.gameActionLoader == 0 &&
        this.scores.length != 0 &&
        _vm.gameFetchedright.game_delayed != 1 &&
        _vm.gameFetchedright.locked != 1 &&
        (String(_vm.gameFetchedright.scoring_user_id) === String(this.user_id) || _vm.gameFetchedright.scoring_user_id === null) &&
        _vm.gameFetchedright.status != 2
      )?_c('div',{staticClass:"score"},[_c('div',{staticClass:"inning-counter"},[_c('p',[_vm._v("Inning")]),_c('span',[_vm._v(_vm._s(_vm.totalInnings))])]),_c('div',{staticClass:"team-position"},[_c('p',{staticClass:"team-name text-capitalize"},[_vm._v(_vm._s(_vm.currentBattingTeam))]),_c('div',{staticClass:"team-score-hr"},[_c('div',{staticClass:"team-score"},[_c('span',{staticClass:"head"},[_vm._v("Runs")]),_c('div',{staticClass:"product-inc-dec"},[_c('div',{staticClass:"product-decrement d-block",on:{"click":function($event){return _vm.runsDecrement()}}},[_c('img',{attrs:{"src":require("../../../assets/images/minus.png"),"alt":""}})]),_c('div',{staticClass:"product-value pt-1"},[_c('span',[_vm._v(_vm._s(_vm.runs))])]),_c('div',{staticClass:"product-increment",on:{"click":function($event){return _vm.runsIncrement()}}},[_c('img',{attrs:{"src":require("../../../assets/images/plus.png"),"alt":""}})])])]),_c('div',{staticClass:"team-hr"},[_c('span',{staticClass:"head"},[_vm._v("HR")]),_c('div',{staticClass:"product-inc-dec"},[_c('div',{staticClass:"product-decrement d-block",on:{"click":function($event){return _vm.hrDecrement()}}},[_c('img',{attrs:{"src":require("../../../assets/images/minus.png"),"alt":""}})]),_c('div',{staticClass:"product-value pt-1"},[_c('span',[_vm._v(_vm._s(_vm.hr))])]),_c('div',{staticClass:"product-increment",on:{"click":function($event){return _vm.hrIncrement()}}},[_c('img',{attrs:{"src":require("../../../assets/images/plus.png"),"alt":""}})])])])])])]):_vm._e()],1),(
      _vm.gameActionLoader == 0 &&
      this.scores.length != 0 &&
      _vm.gameFetchedright.game_delayed != 1 &&
      _vm.gameFetchedright.locked != 1 &&
      (String(_vm.gameFetchedright.scoring_user_id) === String(this.user_id) || _vm.gameFetchedright.scoring_user_id === null) &&
      _vm.gameFetchedright.status != 2
)?_c('div',[_c('div',{staticClass:"row mt-3 mt-lg-0"},[_c('div',{staticClass:"col-12 col-lg-6 mb-2 px-3 px-lg-0"},[_c('div',{staticClass:"live-stream-tags h-100",on:{"click":_vm.endHalfInning}},[_c('h6',[_vm._v("End Half Inning")]),_c('p',[_vm._v("End this half inning and start the next")])])]),_c('div',{staticClass:"col-12 col-lg-6 pl-lg-2 mb-2"},[_c('div',{staticClass:"live-stream-tags h-100",on:{"click":_vm.swapHomeVisitor}},[_c('h6',[_vm._v("Swap Home / Visitor")]),_c('p',[_vm._v("Swap home / visitor team & reset game")])])]),_c('div',{staticClass:"col-12 col-lg-6 px-lg-0 pb-2 pb-lg-0"},[_c('div',{staticClass:"live-stream-tags h-100",on:{"click":_vm.deleteLastInning}},[_c('h6',[_vm._v("Delete Last Inning")]),_c('p',[_vm._v("Delete the last inning scoring data")])])]),(_vm.gameFetchedright.status != 2 && (String(_vm.gameFetchedright.scoring_user_id) === String(this.user_id) || _vm.gameFetchedright.scoring_user_id === null))?_c('div',{staticClass:"col-12 col-lg-6 pl-lg-2"},[_c('div',{staticClass:"live-stream-tags mr-0 mb-0 live-stream-tags-danger h-100",on:{"click":_vm.endGame}},[_c('h6',[_vm._v("End Game")]),_c('p',[_vm._v("Ends the game and lock the team scores")])])]):_vm._e()])]):( _vm.gameActionLoader == 1)?_c('content-placeholders',{staticClass:"post-view mt-3 pl-5",attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":4}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }