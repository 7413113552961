<template>
  <div>
    <div class="shop-products-img-loader" v-if="showLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
    <div class="cover-photo-wrapper" v-if="user">
      <img
      @load="onImageLoad"
        v-if="user.coverphoto == 'yes'"
        :src="API_URL + user.id + '/cover_photo.jpg?v=' + time"
        alt=""
        class="cursor-pointer"
        @click="OpenCoverPhotoPreview"
      />
      <img
      @load="onImageLoad"
        v-else
        src="../../assets/cover-default.png"
        alt=""
        class="cover-default"
      />
      <div class="cover-photo-modal" v-if="openCoverPhoto">
        <button
          class="close-icon btn btn-outline-danger btn-sm lb-modal-close"
          @click="CloseCoverPhotoPreview"
        >
          <i class="fa fa-times"></i>
        </button>
        <div class="cover-img-full-preview" @click="CloseCoverPhotoPreview">
          <img
            v-if="user.coverphoto == 'yes'"
            :src="API_URL + user.id + '/cover_photo.jpg?v=' + time"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="products-list-shim" v-else>
      <content-placeholders>
        <content-placeholders-img />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CoverPhoto",
  data() {
    return {
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      openCoverPhoto: false,
      showLoader: 1,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    OpenCoverPhotoPreview() {
      this.openCoverPhoto = true;
      // document.querySelector("body").style.overflow = "hidden";
    },
    CloseCoverPhotoPreview() {
      this.openCoverPhoto = false;
      // document.querySelector("body").style.overflow = "visible";
    },
    onImageLoad() {
      this.showLoader = 0
    },
  },
};
</script>
<style></style>
