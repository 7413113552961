<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-md-3 pb-3">
        <div class="row no-gutters">
          <div class="col-12 px-0">
            <div
              class="order-history single-box-score-loader"
              v-if="showLoader == 1"
            >
              <div class="lds-dual-ring-media"></div>
            </div>
            <div v-if="showLoader == 0">
              <!-- <LeftSide /> -->

              <div class="card-event col-12 no-card-event bg-white"
                v-if="singleGame == 'null' || singleGame == null" >
                <div class="img">
                  <img src="../assets/images/games.png" alt="" />
                </div>
                <p class="text">No game found.</p>
              </div>
              
              <Score v-else-if="singleGame != 'null' || singleGame != null"
                :singleGame="singleGame"
                :showShimmer="showShimmer"
                :currentIsAdmin="currentIsAdmin"
                :currentIsUmpire="currentIsUmpire"
                :scores="scores"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import Score from "../components/Tournaments/Scoring/Score.vue";
import axios from "axios";
export default {
  name: "TournamentScores",
  components: {
    Navbar,
    LeftMenu,
    Score,
    Banner,
  },
  data() {
    return {
      singleGame: {},
      scores: [],
      showLoader: 1,
      showShimmer: 0,
      gameIdFromUrl: "",
      currentIsAdmin: "0",
      currentIsUmpire: "0",
    };
  },
  methods: {
    async fetchGameScore() {
      this.showLoader = 1;
      var url = window.location.pathname;
      var segment_array = url.split("/");
      this.gameIdFromUrl = segment_array.pop();
      var formData = new FormData();
      formData.append("tournament_game_guid", this.gameIdFromUrl);
      await axios
        .post(process.env.VUE_APP_API_URL + "tournaments/game/getScores", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.singleGame = response.data.data.game;
          this.showLoader = 0;
          this.scores = response.data.data.game.game_scores;
          this.currentIsAdmin=(response.data.data.user_association_role)?(response.data.data.user_association_role.role_id==1 )?'1':'0':'0';
         this.currentIsUmpire= (response.data.data.game.umpire) ? response.data.data.game.umpire.user_id == localStorage.getItem("userId") ? '1' :'0':'0';

        });
    },
  },
  mounted() {
    this.fetchGameScore();
    this.$root.$on("RefreshTournamentGames",()=>{
      this.fetchGameScore();
    });
    this.$root.$on("showTounamentGameScore", () => {
      this.fetchGameScore();
    });
    this.$root.$on("showSingleBoxScoreTournamentForGameSwitch", () => {
      this.fetchGameScore();
    });
  },
};
</script>

<style></style>
