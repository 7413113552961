<template>
  <div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ active: currentTab === 'game', disabled: isLoaded.game }"
          id="game-tab"
          data-toggle="tab"
          data-target="#game"
          type="button"
          role="tab"
          aria-controls="game"
          aria-selected="true"
          @click="selectTab('game')"
        >
          Games
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ active: currentTab === 'team', disabled: isLoaded.team }"
          id="team-tab"
          data-toggle="tab"
          data-target="#team"
          type="button"
          role="tab"
          aria-controls="team"
          aria-selected="false"
          @click="selectTab('team')"
        >
          Teams
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ active: currentTab === 'score', disabled: isLoaded.score }"
          id="score-tab"
          data-toggle="tab"
          data-target="#score"
          type="button"
          role="tab"
          aria-controls="score"
          aria-selected="false"
          @click="selectTab('score')"
        >
          Box Score
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ active: currentTab === 'bracket', disabled: isLoaded.bracket }"
          id="bracket-tab"
          data-toggle="tab"
          data-target="#bracket"
          type="button"
          role="tab"
          aria-controls="bracket"
          aria-selected="false"
          @click="selectTab('bracket')"
        >
          Brackets
        </button>
      </li>
    </ul>
    <div class="tab-content mt-4" id="myTabContent">
      <div
        class="tab-pane fade show active"
        :class="{ 'show active': currentTab === 'game' }"
        id="game"
        role="tabpanel"
        aria-labelledby="game-tab"
      >
        <TournamentGames v-if="currentTab === 'game'"
            :currentIsAdmin="currentIsAdmin"
            :currentIsUmpire="currentIsUmpire"
            :tournamentData="tournamentData"
            :showLoader="showLoader" />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': currentTab === 'team' }"
        id="team"
        role="tabpanel"
        aria-labelledby="team-tab"
      >
        <TournamentTeams v-if="currentTab === 'team'" :tournamentData="tournamentData" :showLoader="showLoader" :currentIsAdmin="currentIsAdmin"  :currentIsUmpire="currentIsUmpire"/>
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': currentTab === 'score' }"
        id="score"
        role="tabpanel"
        aria-labelledby="score-tab"
      >
        <TournamentBoxScores/>
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': currentTab === 'bracket' }"
        id="bracket"
        role="tabpanel"
        aria-labelledby="bracket-tab"
      >
        <TournamentBrackets v-if="currentTab == 'bracket'"    :currentIsAdmin="currentIsAdmin"  :currentIsUmpire="currentIsUmpire"/>
      </div>
    </div>
  </div>
</template>

<script>
import TournamentBrackets from './TournamentBrackets.vue';
import TournamentBoxScores from './TournamentBoxScores.vue';
import TournamentGames from './TournamentGames.vue';
import TournamentTeams from './TournamentTeams.vue';
export default {
  components: { TournamentGames, TournamentTeams, TournamentBoxScores, TournamentBrackets },
  name: "TournamentTabs",
  data() {
    return {
      // tournamentData: [],
      currentIsAdmin: "0",
      currentIsUmpire:"0",
      isLoaded: {
        game: true,
        team: true,
        score: true,
        bracket: true,
      },
      currentTab: "game",
    };
  },
  props: {
    tournamentData: {
      typeof: Object,
    },
    showLoader: {
      typeof: Number,
      default: 1,
    }
  },
  methods: {
    selectTab(tab) {
      this.currentTab = tab;
      if (tab === "game") {
        this.isLoaded.game = false;
        this.isLoaded.team = true;
        this.isLoaded.score = true;
        this.isLoaded.bracket = true;
        setTimeout(() => {
          this.isLoaded.game = false;
          this.isLoaded.team = false;
          this.isLoaded.score = false;
          this.isLoaded.bracket = false;
        }, 2000);
      }
      if (tab === "team") {
        this.isLoaded.game = true;
        this.isLoaded.team = true;
        this.isLoaded.score = true;
        this.isLoaded.bracket = true;
        setTimeout(() => {
          this.isLoaded.game = false;
          this.isLoaded.team = false;
          this.isLoaded.score = false;
          this.isLoaded.bracket = false;
        }, 2000);
      }
      if (tab === "score") {
        this.$root.$emit("callBoxScore", this.tournamentData);
        this.isLoaded.game = true;
        this.isLoaded.team = true;
        this.isLoaded.score = true;
        this.isLoaded.bracket = true;
        setTimeout(() => {
          this.isLoaded.game = false;
          this.isLoaded.team = false;
          this.isLoaded.score = false;
          this.isLoaded.bracket = false;
        }, 2000);
      }
      if (tab === "bracket") {
        this.isLoaded.game = true;
        this.isLoaded.team = true;
        this.isLoaded.score = true;
        this.isLoaded.bracket = true;
        setTimeout(() => {
          this.isLoaded.game = false;
          this.isLoaded.team = false;
          this.isLoaded.score = false;
          this.isLoaded.bracket = false;
        }, 2000);
      }
    },
  },
  mounted() {
    // Set default tab to "game" and enable all tabs after 2 seconds
    this.isLoaded.game = false;
    setTimeout(() => {
      this.isLoaded.game = false;
      this.isLoaded.team = false;
      this.isLoaded.score = false;
      this.isLoaded.bracket = false;
    }, 2000);

    // this.$root.$on("tournmentSingleGameForTab", (data) => {
    //   this.tournamentData = data;
    //   this.currentIsAdmin=(this.tournamentData.user_association_role)?(this.tournamentData.user_association_role.role_id==1)?'1':'0':'0';
    //   this.currentIsUmpire=(this.tournamentData.user_association_role)?(this.tournamentData.user_association_role.role_id==2)?'1':'0':'0';
    // });
    
  if (this.tournamentData) {
    this.currentIsAdmin = (this.tournamentData.user_association_role) ? (this.tournamentData.user_association_role.role_id == 1) ? '1' : '0' : '0';
    this.currentIsUmpire = (this.tournamentData.user_association_role) ? (this.tournamentData.user_association_role.role_id == 2) ? '1' : '0' : '0';
  }

  // Watch for changes in tournamentData prop and update currentIsAdmin and currentIsUmpire accordingly
  this.$watch('tournamentData', (newValue) => {
    if (newValue) {
      this.currentIsAdmin = (newValue.user_association_role) ? (newValue.user_association_role.role_id == 1) ? '1' : '0' : '0';
      this.currentIsUmpire = (newValue.user_association_role) ? (newValue.user_association_role.role_id == 2) ? '1' : '0' : '0';
    }
  });
  },
};
</script>