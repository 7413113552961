<template>
  <div class="product-img-wrap justify-content-md-around" v-if="productdetails">
    <router-link to="/shop" exact class="back-link d-block d-md-none">
      <img src="../../../assets/images/arrow-left.png" alt="" />
      <span>Back to products</span></router-link
    >

    <div class="" v-if="productProduct && productdetails.gallery.length == 0">
      <div class="product-image-detail">
        <div class="shop-products-img-loader" v-if="showLoader == 1">
            <div class="lds-dual-ring-media"></div>
          </div>
        <img
        @load="onImageLoad"
          v-if="productProduct.products.image != null"
          :src="API_URL_THUMB + productProduct.products.image"
          alt=""
        />
        <img
          class="default-product-img"
          v-else
          src="../../../assets/images/gallery.png"
          alt=""
        />
      </div>
    </div>

    

    <hooper group="group1" v-if="productdetails.gallery.length > 0">
      
      <slide
        class="product-images-main mb-3 mb-md-0"
        v-for="gallery in productdetails.gallery"
        :key="gallery.id"
      >
      <div class="shop-products-img-loader" v-if="showLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
        <div class="image-content">
          <img
            @load="onImageLoad"
            v-if="gallery"
            :src="API_URL + gallery.path"
            alt=""
          />
          <img v-else src="../../../assets/images/gallery.png" alt="" />
        </div>
      </slide>
    </hooper>

    <hooper
      group="group1"
      :itemsToShow="3"
      :centerMode="true"
      v-if="productdetails.gallery.length > 0"
      :settings="hooperSettings"
      class="bottom-slider"
    >
      <slide
        class="product-images"
        v-for="gallery in productdetails.gallery"
        :key="gallery.id"
      >
        <div class="product-detail-slides">
          <div class="shop-products-slider-loader" v-if="showLoader == 1">
            <div class="lds-dual-ring-media"></div>
          </div>
          <div class="image-content">
            <img @load="onImageLoad" v-if="gallery" :src="API_URL + gallery.path" alt="" />
            <img v-else src="../../../assets/images/gallery.png" alt="" />
          </div>
        </div>
      </slide>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>
  </div>
</template>
<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "ProductImage",
  components: { Hooper, Slide, HooperNavigation },
  props: {
    productdetails: {
      type: Object,
    },
    productProduct: {
      type: Object,
    },
  },
  data() {
    return {
      API_URL: this.$galleryImageStorageURL,
      API_URL_THUMB: this.$productImageStorageURL,
      showLoader: 1,
      // settings: {
      //   arrows: true,
      //   focusOnSelect: true,
      // },
      hooperSettings: {
        breakpoints: {
          315: {
            itemsToShow: 2,
          },
          480: {
            itemsToShow: 3,
          },
          576: {
            itemsToShow: 4,
          },
          768: {
            itemsToShow: 2,
          },
          1150: {
            itemsToShow: 3,
          },
          1300: {
            itemsToShow: 4,
          },
        },
      },
    };
  },
  methods: {
    onImageLoad() {
      this.showLoader = 0
    },
  },
};
</script>
