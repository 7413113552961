<template>
  <div>
    <div>
      <button
        class="d-none"
        data-toggle="modal"
        data-target="#GameModal"
        ref="openTournamentListModal"
      ></button>
      <div
        class="modal fade"
        id="GameModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog product-cart-modal" role="document">
          <div class="modal-content d-block">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Switch Tournament</h5>
              <button
                type="button"
                class="close cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body overflow-auto">

              <div v-if="showLoader === 1" class="card-event px-3 py-3 pt-0">
                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                </content-placeholders>

                <br />

                <content-placeholders :rounded="true">
                  <content-placeholders-img />
                  <content-placeholders-heading />
                </content-placeholders>
              </div>

              <div v-else-if="tournamentList.length === 0 && showLoader === 0">
                <div class="no-chat-text">
                  <p>No tournament is scheduled yet</p>
                </div>
              </div>

              <div class="live-league-wrap switch-tournament px-3 pt-0 pb-3" v-if="tournamentList.length > 0 && showLoader === 0">
                <div class="tournament-event" v-for="(tournament, index) in tournamentList" :key="index">
                  <div class="tournament-event-content d-flex justify-content-between align-items-center py-2 cursor-pointer" data-dismiss="modal"
                  @click="callTournament(tournament)">
                    <div>
                      <h1 class="text-capitalize">{{ tournament.tournamentName }}</h1>
                      <div class="event-date modal-date my-2">
                        <img src="../../../assets/images/calendar-event.png" />
                        <div class=""> {{ tournament.exactStartDate }} - {{ tournament.exactEndDate }} </div>
                      </div>
                      <p class="">{{ tournament.teamCounts }} Teams</p>
                    </div>
                    <span><img src="../../../assets/images/arrow-right.png" /></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  name: "EventTournamentListModal",

  data() {
    return {
      tournamentList: [],
      singleEvent: {},
      showLoader: 1,
      page: 1,
      lastPage: 1,
      total: null,
    };
  },
 
  methods: {
    async getTournament() {
      this.showLoader = 1;

      var formData = new FormData();
      formData.append("event_guid", this.singleEvent.event.guid);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/tournamentListForAdminEventsExceptPagination",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.tournamentList =response.data.data.eventTournaments;
          this.lastPage = response.data.data.eventTournaments.last_page;
          this.total = response.data.data.eventTournaments.total;

          this.showLoader = 0;
        });
    },

    callTournament(tournament) {
      var combineUrl = `${tournament.guid}`;
      const currentRoute = this.$router.currentRoute;
      if (currentRoute.name !== 'Tournament' || currentRoute.params.guid !== combineUrl) {
        this.$router.push({
          name: "Tournament",
          params: { id: combineUrl },
        });
        this.$root.$emit("showTournamentForSwitch");

        this.$root.$emit("showTournamentForBoxScore", tournament.id);
      }
    }

  },
  mounted() {
    this.$store.commit("setErrors", {});
    this.$root.$on("openEventTournamentListModal", (tournament) => {
      this.singleEvent = tournament;
      this.tournamentList = [];
      this.getTournament();
      var elem = this.$refs.openTournamentListModal;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
