<template>
  <div class="calendar-modal-wrapper">
    <div class="calendar-modal-image">
      <div class="shop-products-img-loader" v-if="imgLoader == 1">
        <div class="lds-dual-ring-media"></div>
      </div>
      <img
        @load="onImageLoad"
        v-if="this.avatar == '' || this.avatar == null"
        alt=""
        src="../../assets/event-avatar.png"
        style="
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          object-fit: cover;
          border-radius: 5px;
        "
      />
      <img
        @load="onImageLoad"
        v-else
        :src="eventURL + this.avatar"
        style="
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          object-fit: cover;
          border-radius: 5px;
        "
      />
    </div>
    <div class="calendar-modal-info">
      <h3 class="event-name calendar-side">
        {{ singleEvent.eventName }}
      </h3>
      <div
        v-if="showDefualt == 1 && showEventsMember == 0 && showGameList == 0"
        class="event-description"
      >
        <div class="association">
          <span class="heading">Team Name :</span>
          <span class="content">{{ singleEvent.teamName }}</span>
        </div>
        <div
          class="association"
          v-if="
            selectedRoomTeam.sportType == 'Softball (Slow Pitch)' ||
            selectedRoomTeam.sportType == 'Softball (Fast Pitch)' ||
            (selectedRoomTeam.sportType == 'Softball' &&
              singleEvent.association != '' &&
              singleEvent.association != null &&
              singleEvent.association != 'null')
          "
        >
          <span class="heading">Association :</span>
          <span class="content">{{ singleEvent.association }}</span>
        </div>

        <div class="event-type">
          <span class="heading">Event Type :</span>
          <span class="content"
            >{{ singleEvent.eventType }}
            <span v-if="singleEvent.status == 2">(Online)</span></span
          >
        </div>
        <div
          class="event-location"
          v-if="
            (singleEvent.status == 1 &&
              singleEvent.address != null &&
              singleEvent.address != '' &&
              singleEvent.address != 'null') ||
            (singleEvent.city != null &&
              singleEvent.city != '' &&
              singleEvent.city != 'null') ||
            (singleEvent.state != null &&
              singleEvent.state != '' &&
              singleEvent.state != 'null')
          "
        >
          <img
            class="mr-2"
            src="../../assets/images/location.png"
            @click="openGoogleMap(singleEvent)"
          />
          <span class="content" @click="openGoogleMap(singleEvent)"
            >{{ singleEvent.address }} {{ singleEvent.city }} ,
            {{ singleEvent.state }}</span
          >
        </div>
        <div v-if="singleEvent.status == 2" class="event-location">
          <img src="../../assets/images/link.png" class="mr-2" />
          <a :href="singleEvent.Url" class="content" target="_blank">
            {{ singleEvent.Url }}
          </a>
        </div>
        <div class="event-date">
          <span class="heading">Date & Time :</span>
          <div
            class="content"
            v-if="
              singleEvent.exactStartTime != '' &&
              singleEvent.exactEndTime != '' &&
              singleEvent.exactStartDate != '' &&
              singleEvent.exactEndDate != '' &&
              singleEvent.time_zone !== null && // Add null check here
              singleEvent.time_zone !== undefined
            "
          >
            <span
              >{{ singleEvent.exactStartDate }} to
              {{ singleEvent.exactEndDate }}
            </span>
            <span v-if="singleEvent.allDay == 'true'"> All Day.</span>
            <span v-else>
              From {{ singleEvent.exactStartTime }} to
              {{ singleEvent.exactEndTime }}</span
            >
            <span v-if="singleEvent.time_zone !== null">
              {{ singleEvent.time_zone.split(")").pop() }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarEventWrapper",
  data: function () {
    return {
      imgLoader: 1,
      events: [],
      avatar: "",
      eventURL: this.$eventAvatarStorageURL,
      showEventsMember: 0,
      showGameList: 0,
      showDefualt: 1,
      gameList: [],
    };
  },
  props: {
    singleEvent: {
      type: Object,
    },
    selectedRoomTeam: {
      type: Array, Object
    },
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
  },
};
</script>

<style>
</style>