<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#manageParks"
      ref="openModalManageTourSeedars"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="manageParks">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Manage Tournament Seedars</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeManageTourSeedars"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="teammates-lineup-wrapper">
              <div class="available-teammates">
                <h6>Available Seedars</h6>
                <div class="teammates-info">
                  <draggable
                    class="list-group"
                    :list="availableSeedars"
                    group="people"
                    handle=".handle"
                    @change="handleAvailableSeedars"
                    v-if="showLoaderAvailableParks == 0"
                  >
                    <div
                      class="list-group-item"
                      v-for="(element, index) in availableSeedars"
                      :key="index"
                    >
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <div class="d-flex flex-column">
                          <p class="name">{{ element?.criteria_name }}</p>
                        </div>
                      </div>
                      <div class="handle">
                        <img
                          src="../../../assets/images/line-ellipsis.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="no-line-up"
                    v-if="
                      showLoaderAvailableParks == 0 &&
                        availableSeedars.length == 0
                    "
                  >
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No available seedars found.</p>
                  </div>
                  <div
                    class="lds-dual-ring-media"
                    v-if="showLoaderAvailableParks == 1"
                  ></div>
                </div>
              </div>
              <div class="team-lineup">
                <h6>Selected Seedars</h6>
                <div class="teammates-info position-relative">
                  <draggable
                    class="list-group"
                    :list="selectedSeedars"
                    group="people"
                    handle=".handle"
                    @change="handleSelectedSeedars"
                    v-if="showLoaderSelectedParks == 0"
                  >
                    <div
                      class="list-group-item"
                      v-for="(element, index) in selectedSeedars"
                      :key="element?.position_index"
                    >
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <div class="d-flex flex-column">
                          <p class="name">{{ element?.criteria_name }}</p>
                        </div>
                      </div>
                      <div class="handle">
                        <img
                          src="../../../assets/images/line-ellipsis.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="no-line-up"
                    v-if="
                      showLoaderSelectedParks == 0 &&
                        selectedSeedars.length == 0
                    "
                  >
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No selected seedars found.</p>
                  </div>
                  <div
                    class="lds-dual-ring-media"
                    v-if="showLoaderSelectedParks == 1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="manageTournamentSeedars(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import draggable from "vuedraggable";
export default {
  name: "ManageTournamentSeedars",
  components: {
    draggable,
  },
  props: {
    tournament_id: {
      type: Number,
    },
  },
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      selectedSeedars: [],
      showLoaderSelectedParks: 1,
      availableSeedars: [],
      showLoaderAvailableParks: 1,
    };
  },
  watch: {
    selectedSeedars: function(newValue) {
      if (newValue.length > 0 || this.availableSeedars.length > 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    availableSeedars: function(newValue) {
      if (newValue.length > 0 || this.selectedSeedars.length > 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
  },

  methods: {
    async manageTournamentSeedars(index) {
      this.$set(this.isLoadingArray, index, true);
      try {
        const url = `${process.env.VUE_APP_API_URL}tournaments/updateTournamentSeedCriteria`;
        await axios.post(url, {
          tournament_id: this.tournament_id,
          seed_criteria_defaults: this.selectedSeedars,
        });
        var elem = this.$refs.closeManageTourSeedars;
        if (elem) {
          elem.click();
        }
      } catch (error) {
        console.error("Error saving selected Seedars:", error);
      } finally {
        this.$set(this.isLoadingArray, index, false);
      }
    },

    handleAvailableSeedars(evt) {
      if (evt.added) {
        this.selectedSeedars.push(evt.added.element);
        console.log(this.selectedSeedars);
      }
    },
    handleSelectedSeedars(evt) {
      if (evt.removed) {
        const index = this.selectedSeedars.indexOf(evt.removed.element);
        if (index > -1) {
          this.selectedSeedars.splice(index, 1);
        }
      }
    },

    async getSeedarsForAssociation() {
      try {
        this.showLoaderSelectedParks = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}tournaments/getDefaultSeedars?tournament_id=${this.tournament_id}`
        );

        if (response.data.statusCode === 200) {
          console.log(response.data.data);
          this.selectedSeedars = Object.values(response.data.data);
          this.showLoaderSelectedParks = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoaderSelectedParks = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoaderSelectedParks = 0;
      }
    },
    async getTournamentSeedars() {
      try {
        this.showLoaderAvailableParks = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}tournaments/fetchFilteredSeedarsTournament?tournament_id=${this.tournament_id}`
        );

        if (response.data.statusCode === 200) {
          console.log(response.data.data);
          this.availableSeedars = Object.values(response.data.data);
          this.showLoaderAvailableParks = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoaderAvailableParks = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoaderAvailableParks = 0;
      }
    },
  },

  mounted() {
    this.$root.$on("openManageTourSeedarsModal", () => {
      console.log("helloe");
      this.getTournamentSeedars();
      this.getSeedarsForAssociation();
      var elem = this.$refs.openModalManageTourSeedars;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
