var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar-modal-wrapper"},[_c('div',{staticClass:"calendar-modal-image"},[(_vm.imgLoader == 1)?_c('div',{staticClass:"shop-products-img-loader"},[_c('div',{staticClass:"lds-dual-ring-media"})]):_vm._e(),(this.avatar == '' || this.avatar == null)?_c('img',{staticStyle:{"width":"100%","height":"100%","background-position":"center","background-repeat":"no-repeat","background-size":"cover","object-fit":"cover","border-radius":"5px"},attrs:{"alt":"","src":require("../../assets/event-avatar.png")},on:{"load":_vm.onImageLoad}}):_c('img',{staticStyle:{"width":"100%","height":"100%","background-position":"center","background-repeat":"no-repeat","background-size":"cover","object-fit":"cover","border-radius":"5px"},attrs:{"src":_vm.eventURL + this.avatar},on:{"load":_vm.onImageLoad}})]),_c('div',{staticClass:"calendar-modal-info"},[_c('h3',{staticClass:"event-name calendar-side"},[_vm._v(" "+_vm._s(_vm.singleEvent.eventName)+" ")]),(_vm.showDefualt == 1 && _vm.showEventsMember == 0 && _vm.showGameList == 0)?_c('div',{staticClass:"event-description"},[_c('div',{staticClass:"association"},[_c('span',{staticClass:"heading"},[_vm._v("Team Name :")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.singleEvent.teamName))])]),(
          _vm.selectedRoomTeam.sportType == 'Softball (Slow Pitch)' ||
          _vm.selectedRoomTeam.sportType == 'Softball (Fast Pitch)' ||
          (_vm.selectedRoomTeam.sportType == 'Softball' &&
            _vm.singleEvent.association != '' &&
            _vm.singleEvent.association != null &&
            _vm.singleEvent.association != 'null')
        )?_c('div',{staticClass:"association"},[_c('span',{staticClass:"heading"},[_vm._v("Association :")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.singleEvent.association))])]):_vm._e(),_c('div',{staticClass:"event-type"},[_c('span',{staticClass:"heading"},[_vm._v("Event Type :")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.singleEvent.eventType)+" "),(_vm.singleEvent.status == 2)?_c('span',[_vm._v("(Online)")]):_vm._e()])]),(
          (_vm.singleEvent.status == 1 &&
            _vm.singleEvent.address != null &&
            _vm.singleEvent.address != '' &&
            _vm.singleEvent.address != 'null') ||
          (_vm.singleEvent.city != null &&
            _vm.singleEvent.city != '' &&
            _vm.singleEvent.city != 'null') ||
          (_vm.singleEvent.state != null &&
            _vm.singleEvent.state != '' &&
            _vm.singleEvent.state != 'null')
        )?_c('div',{staticClass:"event-location"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../../assets/images/location.png")},on:{"click":function($event){return _vm.openGoogleMap(_vm.singleEvent)}}}),_c('span',{staticClass:"content",on:{"click":function($event){return _vm.openGoogleMap(_vm.singleEvent)}}},[_vm._v(_vm._s(_vm.singleEvent.address)+" "+_vm._s(_vm.singleEvent.city)+" , "+_vm._s(_vm.singleEvent.state))])]):_vm._e(),(_vm.singleEvent.status == 2)?_c('div',{staticClass:"event-location"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../../assets/images/link.png")}}),_c('a',{staticClass:"content",attrs:{"href":_vm.singleEvent.Url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.singleEvent.Url)+" ")])]):_vm._e(),_c('div',{staticClass:"event-date"},[_c('span',{staticClass:"heading"},[_vm._v("Date & Time :")]),(
            _vm.singleEvent.exactStartTime != '' &&
            _vm.singleEvent.exactEndTime != '' &&
            _vm.singleEvent.exactStartDate != '' &&
            _vm.singleEvent.exactEndDate != '' &&
            _vm.singleEvent.time_zone !== null && // Add null check here
            _vm.singleEvent.time_zone !== undefined
          )?_c('div',{staticClass:"content"},[_c('span',[_vm._v(_vm._s(_vm.singleEvent.exactStartDate)+" to "+_vm._s(_vm.singleEvent.exactEndDate)+" ")]),(_vm.singleEvent.allDay == 'true')?_c('span',[_vm._v(" All Day.")]):_c('span',[_vm._v(" From "+_vm._s(_vm.singleEvent.exactStartTime)+" to "+_vm._s(_vm.singleEvent.exactEndTime))]),(_vm.singleEvent.time_zone !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.singleEvent.time_zone.split(")").pop())+" ")]):_vm._e()]):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }