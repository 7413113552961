<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#EventAttendeesModal"
      ref="openModalEvent"
    ></button>
    <div
      class="modal fade"
      id="EventAttendeesModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Event Attendees
            </h5>
            <button
              type="button"
              class="close text-primary"
              data-dismiss="modal"
              aria-label="Close"
              ref="openModalEventClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="showEventMemeberCountShimmer == 1">
              <div class="col-12">
                <div class="friends-requests">
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                </div>
              </div>
            </div>
            <div class="user-counter" v-if="showEventMemeberCountShimmer == 0">
              <ul class="counting">
                <li>
                  <span>{{ totalGoing }}</span>
                  <span>Going</span>
                </li>
                <li>
                  <span>{{ totalNotGoing }}</span>
                  <span>Not Going</span>
                </li>
                <li>
                  <span>{{ totalMayBe }}</span>
                  <span>Maybe</span>
                </li>
                <li>
                  <span>{{ totalNotRespond }}</span>
                  <span>Not Responded</span>
                </li>
              </ul>
            </div>
            <div class="event-actions">
              <ul>
                <li
                  v-bind:class="[selectAttendeeTab == 0 ? 'active' : '']"
                  v-on:click="filterGoing()"
                >
                  Going
                </li>

                <li
                  v-on:click="filterNotGoing()"
                  v-bind:class="[selectAttendeeTab == 1 ? 'active' : '']"
                >
                  Not Going
                </li>

                <li
                  v-on:click="filterMay()"
                  v-bind:class="[selectAttendeeTab == 2 ? 'active' : '']"
                >
                  Maybe
                </li>

                <li
                  v-bind:class="[selectAttendeeTab == 3 ? 'active' : '']"
                  v-on:click="filterNotResponded()"
                >
                  Not Responded
                </li>
              </ul>
            </div>
            <div class="row" v-if="showEventMemeberCountShimmer == 1">
              <div class="col-12">
                <div class="friends-requests">
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                </div>
              </div>
            </div>
            <div class="event-images" v-if="showEventMemeberCountShimmer == 0">
              <div class="delivered-to-wrapper" v-if="showLoader == 1">
                <div class="friends-requests">
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading :img="true" />
                  </content-placeholders>
                </div>
              </div>
              <div
                class="delivered-to-wrapper"
                v-if="showLoader == 0 && membersAttendee.length > 0"
              >
                <div
                  class="message-reader-detail"
                  v-for="user in membersAttendee"
                  :key="user.id"
                >
                  <div class="user-img">
                    <div class="users-notification" v-if="imgLoader == 1">
                      <img src="../../../../assets/loader.gif" alt="" />
                    </div>
                    <img
                      @load="onImageLoad"
                      v-if="user.avatar != ''"
                      :src="user.avatar"
                     onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                      alt=""
                    />
                    <img
                      @load="onImageLoad"
                      v-else
                      src="../../../../assets/images/user-default-new.png"
                      alt=""
                    />
                  </div>
                  <div class="user-name-time w-100">
                    <div class="user-details d-flex justify-content-between" >
                      <div class="d-flex flex-column justify-content-center">
                        <h6>{{ user.username }}</h6>
                      </div>
                    
                      <!-- Add the "Edit Attendance" button conditionally -->
                      <div>
                        <h6 v-if="currentIsAdmin != 0  && user.event_attendee_status != 0"
                          @click="editAttendance(user)" class="text-danger cursor-pointer">
                          Edit Attendance
                        </h6>

                         <h6 v-if="currentIsAdmin != 0 && user.event_attendee_status == 0"
                            @click="markAttendance(user)" class="text-danger cursor-pointer">
                            Mark Attendance
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="delivered-to-wrapper"
                v-if="membersAttendee.length == 0"
              >
                <div class="message-reader-detail">
                  <span>No User found.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditEventAttendessModal
    :selectedRoomTeam="selectedRoomTeam"
    :singleEvent="singleEvent"
    />
  </div>
</template>

<script>
import axios from "axios";
import EditEventAttendessModal from "./EditEventAttendessModal.vue"
export default {
  name: "EventAttendeesModal",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleEvent: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  data() {
    return {
      showLoader: 0,
      selectAttendeeTab: 0,
      membersAttendeeComplete: [],
      showEventMemeberCountShimmer: 0,
      membersAttendee: [],
      totalGoing: 0,
      totalNotGoing: 0,
      totalMayBe: 0,
      totalNotRespond: 0,
      currentUserEventStatus: 0,
      imgLoader: 1,
    };
  },
  components: {
    EditEventAttendessModal,
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    filterGoing: function() {
      this.selectAttendeeTab = 0;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 1 ||
          attendee.event_attendee_status == "1"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    filterNotGoing: function() {
      this.selectAttendeeTab = 1;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 2 ||
          attendee.event_attendee_status == "2"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    filterMay: function() {
      this.selectAttendeeTab = 2;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 3 ||
          attendee.event_attendee_status == "3"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    filterNotResponded: function() {
      this.selectAttendeeTab = 3;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 0 ||
          attendee.event_attendee_status == "0"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    editAttendance: function (user) {
      this.$root.$emit("openEditAttendeesModal", user);
      var elem = this.$refs.openModalEventClose;
      if (elem) {
        elem.click();
      }
    },
    markAttendance: function (user) {
      this.$root.$emit("openEditMarkAttendeesModal", user);
      var elem = this.$refs.openModalEventClose;
      if (elem) {
        elem.click();
      }
    },
    async getAttendee() {
      this.showEventMemeberCountShimmer = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const eventAttendeeFromDB = response.data.data.attendeeListing;
          this.eventInvitedList = response.data.data.inviteListing;
          const tempMembers = this.selectedRoomTeam.users;
          const tempMembersRole = this.selectedRoomTeam.userRole;
          this.eventGoingForpdf = [];

          // Add Uniform ID to Going Members
          for (const memberRole of tempMembers) {
            for (const memberRoles of tempMembersRole) {
              if (memberRoles.id == memberRole.id && memberRoles.fan != "1") {
                for (const attendeeRole of eventAttendeeFromDB) {
                  if (
                    memberRole.c_id == attendeeRole.member_id &&
                    (attendeeRole.member_status == 1 ||
                      attendeeRole.member_status == "1")
                  ) {
                    const foundUser = tempMembersRole.find(
                      (user) => user?.id === memberRole.id
                    );
                    attendeeRole.uniform = foundUser.uniform;
                    this.eventGoingForpdf.push(attendeeRole);
                  }
                }
              }
            }
          }
          // Add Uniform ID to Going Members

          this.membersAttendeeComplete = [];
          var $i = 0;
          this.totalGoing = 0;
          this.totalNotGoing = 0;
          this.totalMayBe = 0;
          this.totalNotRespond = 0;
          for (const member of tempMembers) {
            for (const memberRole of tempMembersRole) {
              if (memberRole.id == member.id && memberRole.fan != "1") {
                this.membersAttendeeComplete.push(member);
                this.membersAttendeeComplete[$i].event_attendee_status = 0;
                for (const attendee of eventAttendeeFromDB) {
                  if (member.c_id == attendee.member_id) {
                    this.membersAttendeeComplete[$i].event_attendee_status =
                      attendee.member_status;
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        1 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "1"
                    ) {
                      this.totalGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        2 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "2"
                    ) {
                      this.totalNotGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        3 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "3"
                    ) {
                      this.totalMayBe++;
                    }
                  }
                }

                if (
                  this.membersAttendeeComplete[$i].event_attendee_status == 0 ||
                  this.membersAttendeeComplete[$i].event_attendee_status == "0"
                ) {
                  this.totalNotRespond++;
                }

                if (member.id == this.currentUserId) {
                  this.currentUserEventStatus = this.membersAttendeeComplete[
                    $i
                  ].event_attendee_status;
                }
                $i++;
              }
            }
          }
          this.showEventMemeberCountShimmer = 0;
          this.showLoader = 1;
          this.filterGoing();
          this.showLoader = 0;
        });
    },
  },
  mounted() {
    this.$root.$on("openEventAttendeesModal", () => {
      var elem = this.$refs.openModalEvent;
      if (elem) {
        elem.click();
        this.getAttendee();
      }
    });
  },
};
</script>
