<template>
  <div class="container-fluid p-0 friend-wrap">
    <div class="row no-gutters" v-if="items.length != 0">
      <div
        class="col-12 col-sm-6 col-lg-4 col-xl-3 p-0 pr-sm-3"
        v-for="value in items"
        :key="value.id"
      >
        <div class="friends-wrapper">
          <router-link
            :to="/profile/ + value.user_link"
            exact
            style="color: black"
            class="m-0"
          >
            <div class="friend-info">
              <div class="friend-image">
                <div class="users-notification" v-if="imgLoader == 1">
                  <!-- <div class="lds-dual-ring-refresh"></div> -->
                  <img src="../../assets/loader.gif" alt="" />
                </div>
                <img
                  @load="onImageLoad"
                  v-if="value.picture == 'yes'"
                  :src="
                    API_URL + value.user_id + '/profile_picture.jpg?v=' + time
                  "
                  alt=""
                />
                <img
                  @load="onImageLoad"
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                />
              </div>
              <div class="friends">
                <h3>{{ value.user_name }}</h3>
                <p></p>
              </div>
            </div>
          </router-link>
          <FriendsEllipsis :items="value" />
        </div>
      </div>
    </div>
    <div class="info-heading friends-list justify-content-center mb-0" v-else-if="loader == 0">No followings found.</div>
    <div class="container-fluid p-0 friends-list" v-if="loader == 1">
      <div class="row no-gutters">
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FriendsEllipsis from "./FriendsEllipsis.vue";
export default {
  name: "following",
  components: {
    FriendsEllipsis,
  },
  props: {
    items: {
      type: Array,
    },
    loader: {
      type: Number,
    },
  },
  data() {
    return {
      friendsShort: "",
      friendCount: "0",
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      imgLoader: 1,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
  },
  mounted() {},
};
</script>
