<template>
  <div>
    <div class="events-wrapper">
      <div class="event-list" v-for="event in events" :key="event.index">
        <div
          class="event-list-details event-info-wrap"
          @click="showDetails(event)"
        >
          <EventWrapper :event="event" :selectedRoomTeam="selectedRoomTeam" />
          <Weather
            v-if="event.weatherData && event.status == 1"
            :eventsForWeather="event"
          />
        </div>
        <UserGoingList
          :selectedRoomTeam="selectedRoomTeam"
          :attendeeListing="event.event_attendee_all"
        />
      </div>

      <div
        class="no-event-content"
        v-if="events.length == 0"
      >
        <div class="no-event-list">
          <img src="../../../../assets/images/calendar-search.png" alt="" />
          <p class="no-event-header">No upnext events</p>
          <p class="no-event-text">
            All upnext events for this team will appear here.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserGoingList from "./UserGoingList.vue";
import Weather from "./Weather.vue";
import EventWrapper from "./EventWrapper.vue";
export default {
  components: { UserGoingList, Weather, EventWrapper },
  name: "UpcomingEvents",
  data() {
    return {
      events: [],
      roomID: "",
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
  },
  methods: {},
  mounted() {
    this.events = this.selectedRoomTeam.upcomingEventsList;
    this.roomID = this.selectedRoomTeam.id;
    this.$root.$on("fetchEventIncoming", (events) => {
      if (this.events.length != events.length) {
        this.$root.$emit("showEventDetailPageBack");
      }
      this.events = events;
    });
  },
};
</script>
