<template>
  <div>
    <div class="product-image">
      <img src="../../../assets/ads.jpg" alt="" />
    </div>
    <div class="cart-overlay"></div>
  </div>
</template>
<script>
export default {
  name: "CartOverlay",
};
</script>