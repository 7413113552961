<template>
  <div>
    <div class="order-history" v-if="showLoader == 0">
      <h1>Invites for Super/Pro User</h1>
      <div class="order-content" v-if="displayedinvitesArray.length != 0">
        <div class="table-responsive-md">
          <table class="table" v-if="displayedinvitesArray">
            <thead>
              <tr>
                <th scope="col">Invited By</th>
                <th scope="col">Package</th>
                <th scope="col">Price</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody v-for="(users, index) in displayedinvitesArray" :key="index">
              <tr>
                <td scope="row">{{ users.user_who_sent?.name }}</td>
                <td>{{ users.package.pack_name }}</td>
                <td>${{ users.payment }}</td>
                <td class="text-capitalize" v-if="users.status == 1">
                  <button
                    class="resend-Invite-btn"
                    @click="getPaymentPage(users)"
                  >
                    Accept
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <nav class="orders-pagination-nav" aria-label="Page navigation example">
          <ul class="pagination m-0">
            <li class="page-item">
              <button
                type="button"
                class="page-link"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </button>
            </li>
            <li class="page-item" v-if="page != 1">
              <button
                type="button"
                class="page-link"
                v-for="pageNumber in displayedPages"
                :key="pageNumber"
                @click="page = pageNumber"
              >
                {{ pageNumber }}
              </button>
            </li>
            <li class="page-item">
              <select
                class="custom-select"
                v-model="page"
                v-if="pagesLength > 15"
              >
                <option
                  v-for="pageNumber in pages"
                  :key="pageNumber"
                  :value="pageNumber"
                >
                  Page {{ pageNumber }}
                </option>
              </select>
            </li>
            <li class="page-item">
              <button
                type="button"
                @click="page++"
                v-if="pagesLength > 15 && page<pages.length"
                class="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div v-else class="media-wrapper">
        <div class="no-content" v-if="showLoader == 0">
          <div class="img">
            <img src="../../assets/images/order-grey.png" alt="" />
          </div>
          <p class="header">No invites yet</p>
          <p class="text">
            There are no invites to show in your <br />
            invites history
          </p>
        </div>
      </div>
    </div>
    <div class="order-history" v-if="showLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "InvitesTable",
  components: {},
  data() {
    return {
      errorMessage: null,
      invitesArray: [],
      showLoader: 0,
      page: 1,
      perPage: 15,
      pages: [],
      pagesLength: 0,
    };
  },
  props: {},
  methods: {
    ...mapActions("auth", ["getFounderPayment"]),
    ...mapActions("auth", ["getSinglePayment"]),
    async getPaymentPage(userPackage) {
      await this.getSinglePayment(userPackage);
      this.$router.push({ name: "FounderUserPayment" });
    },
    async fetchInvites(page = 1) {
      this.showLoader = 1;
      await this.getFounderPayment(page);
      if (this.founderPayment) {
        this.invitesArray = this.founderPayment;
        this.pagesLength = this.founderPayment.total;
        this.showLoader = 0;
        this.setPages();
      } else {
        this.showLoader = 0;
      }
    },
    setPages() {
      let numberOfPages = Math.ceil(this.pagesLength / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(invitesArray) {
      let page = this.page;
      let perPage = this.perPage;
      let from = 0;
      let to = page * perPage;
      const slicedInvites = invitesArray.slice(from, to);
      return slicedInvites;
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("auth", ["founderPayment"]),
    ...mapGetters("auth", ["subscribedInfo"]),
    displayedinvitesArray() {
      return this.paginate(this.invitesArray);
    },
    displayedPages() {
      const currentPage = this.page;
      const totalPageCount = this.pages.length;
      if (totalPageCount <= 5) {
        return this.pages;
      } else {
        const firstPage = Math.max(1, currentPage - 1);
        const lastPage = Math.min(currentPage + 1, totalPageCount);
        return this.pages.slice(firstPage - 1, lastPage);
      }
    },
  },
  mounted() {},
  watch: {
    page(newPage) {
      this.fetchInvites(newPage);
    },
    posts() {
      this.setPages();
    },
  },
  created() {
    this.fetchInvites();
  },
  filters: {
    trimWords(value) {
      return (
        value
          .split(" ")
          .splice(0, 20)
          .join(" ") + "..."
      );
    },
  },
};
</script>

<style>
.order-history th {
  padding: 0.75rem 0 !important;
  text-align: left;
}
.order-history td {
  padding: 0.75rem 0 !important;
  text-align: left;
}
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 14px;
  color: #29b3ed;
  font-weight: 500;
  padding: 7px 11px;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
.order-history .order-content {
  height: calc(100vh - 172px);
  overflow: auto;
}

.order-history .order-content .table-repsonsive-md {
  width: 220%;
}
.payment-status {
  display: flex;
  align-items: center;
  gap: 6px;
}
.order-status-top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.order-status-top img {
  width: 20px;
  height: 20px;
}
.order-status-top p {
  color: #254c72;
}
.order-status-end {
  font-size: 13px;
  color: #557491;
  margin-top: 1.5px;
}
.orders-pagination-nav {
  float: right;
  margin-right: 10px;
}
</style>
