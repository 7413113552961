<template>
  <div>
    <h2 class="main-heading">Interpretation and Definitions</h2>
    <h5 class="sub-heading">Interpretation</h5>
    <p class="main-para">
      The words of which the initial letter is capitalized have meanings defined
      under the following conditions. The following definitions shall have the
      same meaning regardless of whether they appear in singular or in plural.
    </p>
    <h5 class="sub-heading">Definitions</h5>
    <p class="para mb-2">
      For the purposes of this Privacy Policy:
    </p>
    <div class="para-list-items">
      <p>
        <span>Account</span>means a unique account created for You to access our
        Service or parts of our Service
      </p>
      <p>
        <span>Affiliate</span>means an entity that controls, is controlled by or
        is under common control with a party, where "control" means ownership of
        50% or more of the shares, equity interest or other securities entitled
        to vote for election of directors or other managing authority.
      </p>
      <p>
        <span>Application</span>means the software program provided by the
        Company downloaded by You on any electronic device, named WIF
      </p>
      <p>
        <span>Business,</span>for the purpose of the CCPA (California Consumer
        Privacy Act), refers to the Company as the legal entity that collects
        Consumers' personal information and determines the purposes and means of
        the processing of Consumers' personal information, or on behalf of which
        such information is collected and that alone, or jointly with others,
        determines the purposes and means of the processing of consumers'
        personal information, that does business in the State of California.
      </p>
      <p>
        <span>Company</span>(referred to as either "the Company", "We", "Us" or
        "Our" in this Agreement) refers to Who I Follow Corp, 12254 Highland Rd.
      </p>
      <p>
        <span>Consumer,</span>for the purpose of the CCPA (California Consumer
        Privacy Act), means a natural person who is a California resident. A
        resident, as defined in the law, includes (1) every individual who is in
        the USA for other than a temporary or transitory purpose, and (2) every
        individual who is domiciled in the USA who is outside the USA for a
        temporary or transitory purpose.
      </p>
      <p>
        <span>Cookies</span>are small files that are placed on Your computer,
        mobile device or any other device by a website, containing the details
        of Your browsing history on that website among its many uses.
      </p>
      <p>
        <span>Device</span>means any device that can access the Service such as
        a computer, a cellphone or a digital tablet.
      </p>
      <p>
        <span>Do Not Track</span>(DNT) is a concept that has been promoted by US
        regulatory authorities, in particular the U.S. Federal Trade Commission
        (FTC), for the Internet industry to develop and implement a mechanism
        for allowing internet users to control the tracking of their online
        activities across websites.
      </p>
      <p>
        <span>Personal Data</span>is any information that relates to an
        identified or identifiable individual.
      </p>

      <p>
        For the purposes of the CCPA, Personal Data means any information that
        identifies, relates to, describes or is capable of being associated
        with, or could reasonably be linked, directly or indirectly, with You.
      </p>

      <p>
        <span>Sale,</span>the purpose of the CCPA (California Consumer Privacy
        Act), means selling, renting, releasing, disclosing, disseminating,
        making available, transferring, or otherwise communicating orally, in
        writing, or by electronic or other means, a Consumer's personal
        information to another business or a third party for monetary or other
        valuable consideration.
      </p>

      <p><span>Service</span>to the Application or the Website or both.</p>

      <p>
        <span>Service Provider</span>any natural or legal person who processes
        the data on behalf of the Company. It refers to third-party companies or
        individuals employed by the Company to facilitate the Service, to
        provide the Service on behalf of the Company, to perform services
        related to the Service or to assist the Company in analyzing how the
        Service is used.
      </p>

      <p>
        <span>Usage Data</span>to data collected automatically, either generated
        by the use of the Service or from the Service infrastructure itself (for
        example, the duration of a page visit).
      </p>

      <p>
        <span>Website</span>refers to Who I Follow, accessible from
        <router-link to="">http://www.whoifollow.com</router-link>
      </p>

      <p><span>You</span>means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>