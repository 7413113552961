<template>
  <div>
    <InningTournamentModal />

    <div class="friends-options">
      <div class="live-game px-0 row no-gutters ">
        <div class="live-game-heading col-md-6">
          <h2>All Live Games</h2>
          <p v-if="lastUpdateDate">{{ lastUpdateDate | formatDateOfBirth }}</p>
          <div class="date-loader" v-else>
            <content-placeholders  :rounded="true">
              <content-placeholders-text :lines="1" />
            </content-placeholders>
        </div>
        </div>
        <div class="col-md-6 d-flex justify-content-md-end mt-md-0 mt-2">
          <p v-if="lastUpdateDate">Last updated on: {{ formattedLastUpdateDate }}</p>
          <div class="date-loader" v-else>
            <content-placeholders  :rounded="true">
              <content-placeholders-text :lines="1" />
            </content-placeholders>
        </div>
        </div>
      </div>
    </div>

    <!-- loader -->

    <div
      class="products-list-shim mt-3"
      v-if="showLoader === 1"
    >
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>


    <!-- If no live game  -->
    <div v-else-if="gameScores.length === 0 && showLoader === 0">
    <div class="no-chat-text mb-3 bg-white py-3 mt-0 rounded">
        <p class="text-center">No live games at the moment.</p>
    </div>
    </div>

    

    <div>
      <div class="row no-gutters"  v-if="gameScoresOngoing.length >0">
          <div
            class="col-sm-12 col-md-6 col-lg-4 products-column"
            v-for="gameScore in gameScoresOngoing"
            :key="gameScore.id"

          >
            <div class="event-card card-menu-wrapper"  @click="callBoxViewModal(gameScore)" v-if="gameScore.game_status != 2">
              <div class="">
              <div class="inner-sec d-flex justify-content-between">
                <div class="d-flex justify-content-between">
                  <div class="delayed-tag" v-if="gameScore.game_delayed == 1">
                    <span>Delayed</span>
                  </div>
                  <div class="live-tag" v-else>
                    <span>Live</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <!-- <img src="../../assets/images/video.png" alt="" /> -->
                    <span class="ml-1">{{ gameScore.game_name }}</span>
                  </div>
                </div>

                <div class="game_name11 mt-1">
                  <span class="float-right">{{ gameScore.end_inning_flag }}</span>
                </div>
                  
              </div>
              
              <div class="date-date d-flex justify-content-between">
                 <div><span>{{ gameScore.date | formatDateWeather }}</span><span v-if="gameScore.actual_start_time">, {{ gameScore.actual_start_time }} </span>  </div> 
                 <div v-if="gameScore.game_delayed == 1 && gameScore.delayed_reason !== '' && gameScore.delayed_reason !== null">
                    <span>{{ gameScore.delayed_reason }}</span>
                  </div>  
               </div>
              </div>

              
              <div @click="callBoxViewModal(gameScore)" class="">
                <!-- Visiting Team -->
                <div v-for="score in gameScore.score" :key="score.id + 1000">
                  <div
                    class="row no-gutters align-items-center"
                    v-if="score.team_type == 1"
                  >
                    <div class="col-9 p-0">
                      <div class="user-info">
                        <div
                          class="user-img"
                          style="
                            margin: 3px !important;
                            margin-left: 0 !important;
                          "
                        >
                          <div class="users-notification" v-if="imgLoader == 1">
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="score.team.team_avatar"
                            :src="url + score.team.team_avatar"
                            alt=""
                            style="border: 2px solid white"
                          />
                          <img
                            @load="onImageLoad"
                            v-else
                            alt=""
                            src="../../assets/team-avatar.png"
                          />
                        </div>
                        <div class="user-name-pro position-relative">
                          <span class="user-name ml-1" style="color: black">
                            #{{ score.teams.team_sr_no }}: {{ score.team.team_name }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-3 pr-0 pl-2 position-relative"
                      style="border-left: 2px solid #e5f2fe"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        {{ gameScore.visitingRuns }}
                        <span
                          class="arraw-inning"
                          v-if="gameScore.visitingBatting == 1"
                        >
                          <img src="../../assets/images/arrow-left.png" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Home Team -->
                <div v-for="score in gameScore.score" :key="score.id + 2000">
                  <div
                    class="row no-gutters align-items-center"
                    v-if="score.team_type == 2"
                  >
                    <div class="col-9 p-0">
                      <div class="user-info">
                        <div
                          class="user-img"
                          style="
                            margin: 3px !important;
                            margin-left: 0 !important;
                          "
                        >
                          <div class="users-notification" v-if="imgLoader == 1">
                            <img src="../../assets/loader.gif" alt="" />
                          </div>
                          <img
                            @load="onImageLoad"
                            v-if="score.team.team_avatar"
                            :src="url + score.team.team_avatar"
                            alt=""
                            style="border: 2px solid white"
                          />
                          <img
                            @load="onImageLoad"
                            v-else
                            alt=""
                            src="../../assets/team-avatar.png"
                          />
                        </div>
                        <div class="user-name-pro position-relative">
                          <span class="user-name ml-1" style="color: black">
                            #{{ score.teams.team_sr_no }}: {{ score.team.team_name }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-3 pl-2 pr-0 position-relative"
                      style="border-left: 2px solid #e5f2fe"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        {{ gameScore.homeRuns }}
                        <span
                          class="arraw-inning"
                          v-if="gameScore.homeBatting == 1"
                        >
                          <img src="../../assets/images/arrow-left.png" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pt-1">
                    <div class="d-flex">
                      <p class="park-field">
                        <span> {{ gameScore.tournament_name }} </span>
                        <span> -  {{ gameScore.event_name }}</span>
                      </p>
                    </div>
                </div>

                <div>
                    <div class="pt-1" v-if="gameScore.field || gameScore.park">
                      <p class="park-field">
                        <img src="../../assets/images/maximize.png" class="mr-2" />

                        <span v-if=" gameScore.park">
                          {{ gameScore.park.park_name }}
                        </span>

                        <span v-if="gameScore.field"> - {{ gameScore.field.field_name }}</span>
                      </p>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>
  
<script>
import InningTournamentModal from "../Tournaments/InningTournamentModal.vue";
import axios from "axios";
import moment from 'moment';

export default {
  name: "AllTournamentLiveGame",
  components: { InningTournamentModal },

  data() {
    return {
      url: this.$groupAvatarStorageURL,
      showLoader: 1,
      imgLoader: 1,
      gameScores:[],
      gameScoresOngoing:[],
      gameScoresFinal:[],
      event_name:'',
      total_game: '',
      lastUpdateDate: '',
    };
  },
  computed: {
    formattedLastUpdateDate() {
      if (!this.lastUpdateDate) return '';
      // Use Moment.js to format the date
      return moment(this.lastUpdateDate).format('MMM DD, YYYY h:mm A');
    }
  },
  methods: {
    getLiveScore() {
      this.gameScoresOngoing = [];
      this.gameScoresFinal = [];
      axios
        .post(process.env.VUE_APP_API_URL + "tournaments/game/eventsGameListingMutlipleTeams", {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.gameScores = res.data.data.listing;
          this.total_game = res.data.data.total_games;
          this.lastUpdateDate = moment().format();
          this.showLoader = 0;
          if(this.gameScores.length >0)
          {
            this.event_name=this.gameScores[0].event_name;
            this.count = 0;
            this.gameScores.forEach((gameScore) => {
              if (gameScore.game_status != 2) {
                this.gameScoresOngoing.push(gameScore);
                this.count = this.count + 1;
              }
              else
              {
                this.gameScoresFinal.push(gameScore);
              }
            });

          }       
        });
      },

      onImageLoad() {
      this.imgLoader = 0;
    },
    callBoxViewModal(score) {
      this.$root.$emit("openBoxViewScoresModal", score);
      this.$root.$emit("openBoxViewInningModal", score);
    },
  },
  mounted() {
    this.showLoader = 1;
    this.getLiveScore();
    setInterval(() => {
      this.getLiveScore();
    }, 5 * 60 * 1000);

  },
};
</script>
<style scoped>
.live-game{
 display: flex;
 justify-content: space-between;
}
.live-game-heading{
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.live-game-heading h2{
  font-size: 24px;
  color: #188ef5;
}
.game_name11 {
  border-right: none;
  font-size: 17px;
  color: #188ef5 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-align: left;
}

.date-date {
  font-size: 14px;
  text-align: left;
  color: #dc3545 !important;
  font-weight: 400;
}
.event-card {
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #97ccfa;
  transition: all 0.7s;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
  margin-left:2px;
  margin-right:2px;
  padding: 15px;
}

.event-card:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.game_name11 {
  color: #000000 !important;
  font-size: 15px;
}

.arraw-inning {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  margin: 0 0 0 5px;
  text-align: center;
  font-size: 10px;
  width: 20px;
  height: 20px;
  line-height: 16px;
}

.arraw-inning img {
  width: 14px;
  filter: brightness(0) invert(1) !important;
  -webkit-filter: brightness(0) invert(1) !important;
}

.game_name11 span {
  width: 24px;
  height: 24px;
  line-height: 10px;
  font-size: 14px;
  font-weight: bold;
  background: #188ef5;
  border-radius: 100px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.date-loader {
  width: 200px;
  padding: 0px;
  margin: 0px;
}
.park-field{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}
</style>