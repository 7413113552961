<template>
  <div class="order-wrapper">
    <div class="order-info">
      <div class="order-heading">
        <img src="../../../assets/images/order-user.png" alt="" />
        <h6>Who is placing this order ?</h6>
      </div>
      <div class="order-form">
        <form>
          <div class="form-row">
            <div class="form-group col-12">
              <label for="email">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Email"
                v-model="email"
                @keyup="errorNull"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-6">
              <label for="first_name">First Name</label>
              <input
                type="text"
                class="form-control"
                id="first_name"
                placeholder="First Name"
                v-model="fname"
                @keyup="errorNull"
              />
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <label for="last_name">Last Name</label>
              <input
                type="text"
                class="form-control"
                id="last_name"
                placeholder="Last Name"
                v-model="lname"
                @keyup="errorNull"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="btn-wrap event-btns-wrapper">
      <button class="btn btn-primary-disabled" v-if="this.isDisabled == true">
        Next
      </button>

      <button
        class="btn btn-primary"
        v-else-if="this.isDisabled == false"
        @click="proceed()"
      >
        Next
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "OrderPlacement",
  data() {
    return {
      email: "",
      fname: "",
      lname: "",
      isDisabled: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("cart", ["cartItemCount"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    proceed: function() {
      if (this.fname != "" && this.lname != "" && this.email != "") {
        this.$router.push({ name: "Shipping" });
      }
    },

    errorNull: function() {
      if (this.email == "" || this.fname == "" || this.lname == "") {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
  mounted() {
    if (this.cartItemCount <= 0) {
      this.$router.push({ name: "Shop" });
    }
    this.getUserData();

    this.email = this.user.email;
    var username = this.user.name.split(" ");
    this.fname = username[0];
    this.lname = username[1];
    this.errorNull();
  },
};
</script>
