<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="friends-options pt-2 px-0 pb-0">
          <ul class="nav nav-tabs mb-0" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                :class="{ active: isTeamInviteActive }"
                id="events-tab"
                data-toggle="tab"
                data-target="#events"
                type="button"
                role="tab"
                aria-controls="Events"
                aria-selected="false"
                @click="fetchTeamInvites()"
              >
                Team Invites
                <span class="invite-badge px-1 ml-2" v-if="inviteArray.length > 0">{{
                  inviteArray.length
                }}</span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                :class="{ active: isAssociationInviteActive }"
                id="team-tab"
                data-toggle="tab"
                data-target="#team"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                @click="fetchInvites()"
              >
                Association Invites
                <span
                  class="invite-badge px-1 ml-2"
                  v-if="associationinviteArray.length > 0"
                  >{{ associationinviteArray.length }}</span
                >
              </button>
            </li>
          </ul>

          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane"
              :class="{ active: isTeamInviteActive }"
              id="events"
              role="tabpanel"
              aria-labelledby="events-tab"
            >
              <JoinGroup
                :inviteArray="inviteArray"
                :showLoaderOnPage="showLoaderOnPage"
              />
            </div>

            <div
              class="tab-pane"
              :class="{ active: isAssociationInviteActive }"
              id="team"
              role="tabpanel"
              aria-labelledby="team-tab"
            >
              <JoinAssociation
                :associationinviteArray="associationinviteArray"
                :showLoaderOnPage="showLoaderOnPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import JoinGroup from "../components/Teams/JoinGroup.vue";
import JoinAssociation from "../components/Teams/JoinAssociation.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  name: "PendingInvite",
  components: {
    Navbar,
    JoinGroup,
    JoinAssociation,
    LeftMenu,
    Banner,
  },
  data() {
    return {
      inviteArray: [],
      associationinviteArray: [],
      isLoadingArray: [],
      showLoaderOnPage: true,
      isTeamInviteActive: true,
      isAssociationInviteActive: false,
      lastSegment: "",
    };
  },
  mounted() {
    this.getTabActive();
    this.fetchTeamInvites();
    this.fetchInvites();
    this.$root.$on("removeItem", (targetId) => {
      this.inviteArray = this.inviteArray.filter((item) => item.group_id !== targetId);
    });
    this.$root.$on("fetchAssociationInvites", () => {
      this.fetchInvites();
    });
  },
  computed: {
    ...mapGetters("auth", ["group"]),
  },
  methods: {
    ...mapActions("notification", ["getUserNotificationData"]),
    fetchTeamInvites() {
      this.showLoaderOnPage = true;
      // let loader = this.$loading.show({
      //     // Optional parameters
      //     container: this.fullPage ? null : this.$refs.formContainer,

      //     color: "#ffffff",
      //     backgroundColor: "#333",
      //     opacity: 0.6,
      //   });
      return axios
        .post(process.env.VUE_APP_API_URL + "invite/fetchTeamInvites")
        .then((response) => {
          // loader.hide();
          if (response.data.statusCode == 200) {
            localStorage.removeItem("linkUrl");
            localStorage.removeItem("notifyId");
            this.inviteArray = response.data.data;
            this.getUserNotificationData();
            this.showLoaderOnPage = false;
            return;
          } else {
            setTimeout(() => {
              this.errorMessage = response.data.message;
            }, 500);
          }
        });
    },
    getTabActive() {
      var linkUrl = window.location.pathname;
      var segment_array = linkUrl.split("/");
      this.lastSegment = segment_array.pop();

      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,

        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });
      var formData = new FormData();
      if (this.lastSegment !== "pending-invite") {
        formData.append("c_id", this.lastSegment);
      } else if (localStorage.getItem("linkUrlActive") !== "pending-invite") {
        formData.append("c_id", localStorage.getItem("linkUrlActive"));
      }else if (localStorage.getItem("linkUrlActive") == "pending-invite") {
        formData.append("c_id", "");
      } else {
        loader.hide();
        this.isTeamInviteActive = true;
        this.isAssociationInviteActive = false;
      }
      axios
          .post(process.env.VUE_APP_API_URL + "getInviteTab", formData)
          .then((response) => {
            if (response.data.statusCode == 200) {
              loader.hide();
              if (response.data.data.teamInvites == true) {
                this.isTeamInviteActive = true;
                this.isAssociationInviteActive = false;
              } else if (response.data.data.associationInvites == true) {
                this.isTeamInviteActive = false;
                this.isAssociationInviteActive = true;
              } else {
                this.isTeamInviteActive = true;
                this.isAssociationInviteActive = false;
              }
              localStorage.removeItem("linkUrlActive");
            } else {
              loader.hide();
              setTimeout(() => {
                this.errorMessage = response.data.message;
              }, 500);
            }
          })
          .catch((error) => {
            loader.hide();
            console.error("Error fetching association invites:", error);
          });
    },
    fetchInvites() {
      this.showLoaderOnPage = true;
      axios
        .post(process.env.VUE_APP_API_URL + "association/fetchAssociationInvites")
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.associationinviteArray = response.data.data;
            this.getUserNotificationData();
            this.showLoaderOnPage = false;
          } else {
            setTimeout(() => {
              this.errorMessage = response.data.message;
            }, 500);
          }
        })
        .catch((error) => {
          console.error("Error fetching association invites:", error);
        });
    },
  },
};
</script>
<style scoped>
.nav-tabs {
  border-bottom: 2px solid #e5f2fe;
  margin-bottom: 16px;
}
.nav-tabs .nav-link {
  border: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  margin: 0 !important;
  align-items: center;
  position: relative;
  color: #557491 !important;
}
.nav-tabs .nav-link:hover {
  border: none;
  color: #188ef5 !important;
}
.nav-tabs .nav-link.active {
  color: #188ef5 !important;
  border-bottom: 4px solid #188ef5;
  border-radius: 0px;
}
.invite-badge {
  background: red;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}
</style>
