<template>
  <div class="all-event-wrapper">
    <div class="event-top">
      <div class="event-top-content">
        <div class="back-arrow" @click="closeDiv">
          <img src="../../../../assets/images/arrow-left.png" />
        </div>
        <div v-if="showTeams == 0">
          <div class="event-title">
            <div
              class="image-wrapper"
              v-if="selectedRoomTeam.chatType == 'team'"
            >
              <div class="users-notification" v-if="imgLoader == 1">
                <!-- <div class="lds-dual-ring-refresh"></div> -->
                <img src="../../../../assets/loader.gif" alt="" />
              </div>
              <img
                @load="onImageLoad"
                v-if="selectedRoomTeam.teamAvatar == ''"
                src="../../../../assets/images/main-team-avatar.png"
                alt=""
              />
              <img
                @load="onImageLoad"
                v-if="selectedRoomTeam.teamAvatar != ''"
                :src="this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar"
                alt=""
              />
            </div>
            <div class="user-details">
              <p class="team-name" :id="selectedRoomTeam.id">
                {{ selectedRoomTeam.teamName }}
              </p>
              <span> Shared Files </span>
            </div>
          </div>
        </div>
        <div v-if="showIndividual == 0">
          <div class="event-title">
            <div class="image-wrapper">
              <div class="users-notification" v-if="imgLoader == 1">
                <!-- <div class="lds-dual-ring-refresh"></div> -->
                <img src="../../../../assets/loader.gif" alt="" />
              </div>
              <img
                @load="onImageLoad"
                v-if="foundCurrentUser.avatar != ''"
                :src="foundCurrentUser.avatar"
                alt=""
              />
              <img
                @load="onImageLoad"
                v-else
                src="../../../../assets/images/chat-user.png"
                alt=""
              />
            </div>
            <div class="user-details">
              <p class="team-name" :id="selectedRoomTeam.id">
                {{ selectedRoomTeam.roomName }}
              </p>
              <span> Shared Files </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="all-event-category">
      <ul>
        <li :class="'media ' + activeMedia" @click="showChatMediaDiv">Media</li>
        <li :class="'link ' + activeLink" @click="showChatLinksDiv">Links</li>
        <li :class="'doc ' + activeDocument" @click="showChatDocumentsDiv">
          Documents
        </li>
        <li :class="'pin ' + activePinned" @click="showPinnedDiv">Pinned</li>
      </ul>
    </div>
    <div v-if="showLoader == 0">
      <ChatMedia
        v-if="showChatMedia == 0"
        :selectedRoomTeam="selectedRoomTeam"
        :currentUserId="currentUserId"
      />
      <ChatLinks
        v-if="showChatLinks == 0"
        :selectedRoomTeam="selectedRoomTeam"
        :currentUserId="currentUserId"
      />
      <ChatDocuments
        v-if="showChatDocuments == 0"
        :selectedRoomTeam="selectedRoomTeam"
        :currentUserId="currentUserId"
      />
      <ChatPinned v-if="showPinned == 0" :selectedRoomTeam="selectedRoomTeam" :currentUserId="currentUserId" />
    </div>
    <div v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
import ChatPinned from "./SharedFilesComponents/ChatPinned.vue";
import ChatDocuments from "./SharedFilesComponents/ChatDocuments.vue";
import ChatLinks from "./SharedFilesComponents/ChatLinks.vue";
import ChatMedia from "./SharedFilesComponents/ChatMedia.vue";
// import $ from "jquery";

export default {
  components: {
    ChatMedia,
    ChatLinks,
    ChatDocuments,
    ChatPinned,
  },
  name: "SharedFiles",
  data: function () {
    return {
      imgLoader: 1,
      showPinned: 1,
      showChatDocuments: 1,
      showChatLinks: 1,
      showChatMedia: 0,
      activeMedia: "active",
      activeLink: "",
      activeDocument: "",
      activePinned: "",
      showLoader: 0,
      foundCurrentUser: [],
      userId: localStorage.getItem("userId"),
      showTeams: 0,
      showIndividual: 1,
    };
  },

  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },

    closeDiv: function () {
      // $(".chat-right-sidebar")
      //   .slideUp(200)
      //   .delay(200)
      //   .slideDown(200);

      if (localStorage.getItem("openTabChat") == "Individual") {
        this.$root.$emit("IndChatSettings", 0, 0);
        this.$root.$emit("IndMenuSettingsDiv", 0);
      } else {
        this.$root.$emit("ChatSettings", 0);
        this.$root.$emit("fetchEvents");
      }
    },
    showPinnedDiv: function () {
      this.showLoader = 1;
      this.showPinned = 0;
      this.showChatDocuments = 1;
      this.showChatLinks = 1;
      this.showChatMedia = 1;
      this.activeMedia = "";
      this.activeLink = "";
      this.activeDocument = "";
      this.activePinned = "active";
      this.showLoader = 0;
    },
    showChatDocumentsDiv: function () {
      this.showLoader = 1;
      this.showPinned = 1;
      this.showChatDocuments = 0;
      this.showChatLinks = 1;
      this.showChatMedia = 1;
      this.activeMedia = "";
      this.activeLink = "";
      this.activeDocument = "active";
      this.activePinned = "";
      this.showLoader = 0;
    },
    showChatLinksDiv: function () {
      this.showLoader = 1;
      this.showPinned = 1;
      this.showChatDocuments = 1;
      this.showChatLinks = 0;
      this.showChatMedia = 1;
      this.activeMedia = "";
      this.activeLink = "active";
      this.activeDocument = "";
      this.activePinned = "";
      this.showLoader = 0;
    },
    showChatMediaDiv: function () {
      // this.showLoader = 1;
      this.showPinned = 1;
      this.showChatDocuments = 1;
      this.showChatLinks = 1;
      this.showChatMedia = 0;
      this.activeMedia = "active";
      this.activeLink = "";
      this.activeDocument = "";
      this.activePinned = "";
      // this.showLoader = 0;
    },
  },

  mounted() {
    if (localStorage.getItem("openTabChat") == "Individual") {
      this.showIndividual = 0;
      this.showTeams = 1;
    } else {
      this.showIndividual = 1;
      this.showTeams = 0;
    }
    this.selectedRoomTeam.users.forEach((dataU) => {
      if (dataU.c_id != this.userId) {
        this.foundCurrentUser = dataU;
      }
    });
  },
};
</script>
