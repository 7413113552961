import { render, staticRenderFns } from "./TournamentTeamsModal.vue?vue&type=template&id=552dd34e&scoped=true&"
import script from "./TournamentTeamsModal.vue?vue&type=script&lang=js&"
export * from "./TournamentTeamsModal.vue?vue&type=script&lang=js&"
import style0 from "./TournamentTeamsModal.vue?vue&type=style&index=0&id=552dd34e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552dd34e",
  null
  
)

export default component.exports