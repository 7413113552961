<template>
  <div>
    <div class="event-list-image" @click="showDetails(event)">
      <div class="image">
        <div class="shop-products-img-loader" v-if="imgLoader == 1">
          <div class="lds-dual-ring-media"></div>
        </div>
        <img
          @load="onImageLoad"
          v-if="event.avatar == '' || event.avatar == null"
          src="../../../../assets/event-avatar.png"
          alt=""
        />
        <img @load="onImageLoad" v-else :src="eventURL + event.avatar" alt="" />
      </div>
      <div class="content-info">
        <h3 class="event-name">
      {{ event.event_tournaments && event.event_tournaments.length > 0
        ? event.eventName + "/"+ event.event_tournaments[0].tournamentName
        : event.eventName }}
    </h3>

        <div>
          <div class="association" v-if="selectedRoomTeam.sportType == 'Softball (Slow Pitch)' ||
                          selectedRoomTeam.sportType == 'Softball (Fast Pitch)' ||
                          selectedRoomTeam.sportType == 'Softball' && event.association != '' && event.association != null && event.association != 'null'">
            <span class="heading">Association</span>
            <span class="content">{{ event.association }}</span>
          </div>
          <div class="event-type">
            <span class="heading">Event Type</span>
            <span class="content"
              >{{ event.eventType }}
              <span v-if="event.status == 2">(Online)</span></span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="event-list-details event-info-wrap" @click="showDetails(event)">
      <div class="event-date">
        <img src="../../../../assets/images/calendar-event.png" />
        <div
          class="content"
          v-if="
            event.exactStartDate != '' &&
            event.exactStartTime != '' &&
            event.exactEndTime != ''
          "
        >
          <span v-if="event.exactStartDate == event.exactEndDate">
            {{ event.exactStartDate }}
          </span>
          <span v-else
            >{{ event.exactStartDate.split(",")[0] }} to
            {{ event.exactEndDate }}</span
          >
          <span v-if="event.allDay == 'true'"> All Day. </span>
          <span v-else>
            From {{ event.exactStartTime }} to {{ event.exactEndTime }}
          </span>
          <span v-if="event.time_zone !== null">
            {{ event.time_zone.split(")").pop() }}
          </span>
        </div>
      </div>
      <p
        @click="openGoogleMap(event)"
        class="location-content"
        v-if="
          (event.status == 1 &&
            event.address != null &&
            event.address != '' &&
            event.address != 'null') ||
          (event.city != null && event.city != '' && event.city != 'null') ||
          (event.state != null && event.state != '' && event.state != 'null')
        "
      >
        <img src="../../../../assets/images/location.png" />
        <span>{{ event.address }} {{ event.city }}, {{ event.state }}</span>
      </p>
      <span class="location-content" v-if="event.status == 2">
        <img src="../../../../assets/images/link.png" />
        <a :href="event.Url" target="_blank" class="mr-0 font-weight-normal">
          {{ event.Url }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventWrapper",
  data: function () {
    return {
      eventURL: this.$eventAvatarStorageURL,
      imgLoader: 1,
    };
  },
  props: {
    event: {
      type: Object,
    },
    selectedRoomTeam: {
      type: Object,
    }
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    showDetails: function (event) {
      this.$root.$emit("showEventDetailPage", event);
    },
  },
};
</script>

<style>
</style>