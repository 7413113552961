<template>
  <div>
    <Navbar />

    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3 pb-3">
        <GoSubscribe />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import GoSubscribe from "../components/GoPro/GoSubscribe.vue";

export default {
  components: {
    Navbar,
    LeftMenu,
    GoSubscribe,
    Banner
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    if (
      localStorage.getItem("getTab") == "1" &&
      localStorage.getItem("getTab") == 1
    ) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>
