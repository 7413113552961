<template>
  <div>
    <div class="new" v-if="showLoader == 1">
      <div class="friends-requests p-0">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
        </content-placeholders>
      </div>
    </div>

    <div class="main-page-event" v-if="showLoader == 0">
      <div class="top-layer">
        <div class="left-sec">
          <p class="event-tag">Team Record</p>
        </div>
        <div class="right-sec">
          <!-- <img src="../../../../assets/images/info-circle.png" alt="" /> -->
        </div>
      </div>
      <div class="row no-gutters mt-2">
        <div class="col-12 col-sm-4">
          <div class="counter-widget mr-0">
            <h3>Games</h3>
            <span class="counter-value">{{ totalGame }}</span>
          </div>
        </div>
        <div class="col-6 col-sm-4 pl-0 pr-2 px-sm-2 mt-2 mt-sm-0">
          <div class="counter-widget">
            <h3>Won</h3>
            <span class="counter-value">{{ win }}</span>
          </div>
        </div>
        <div class="col-6 col-sm-4 mt-2 mt-sm-0">
          <div class="counter-widget mr-0">
            <h3>Lost</h3>
            <span class="counter-value">{{ loss }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TeamRecord",
  props: {
    showLoader: {
      type: Number,
    },
   totalGame: {
      type: Number,
    },
    win: {
      type: Number,
    },
    loss: {
      type: Number,
    },
  },
};
</script>