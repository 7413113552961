<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#GameModal"
      ref="openGameModal"
    ></button>
    <div
      class="modal fade"
      id="GameModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog product-cart-modal" role="document">
        <div class="modal-content d-block">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Switch Game</h5>
            <button
              type="button"
              class="close cursor-pointer"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body overflow-auto">
            <div v-if="showLoader === 1" class="card-event mt-3 p-3">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
              </content-placeholders>

              <br />

              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-heading />
              </content-placeholders>
            </div>
            <div v-else-if="gameList.length === 0 && showLoader === 0">
              <div class="no-chat-text">
                <p>No game is scheduled yet</p>
              </div>
            </div>
            <div
              v-if="gameList.length > 0 && showLoader === 0"
              class="live-league-wrap p-3"
            >
              <div v-for="(schedule, index) in gameList" :key="index">
                <div
                  class="cursor-pointer"
                  data-dismiss="modal"
                  @click="callSingleBoxModal(schedule)"
                >
                  <div
                    class="live-league position-relative"
                    v-if="schedule.game_live == 1"
                  >
                    <div class="inner-sec">
                      <div class="live-tag">
                        <span>Live</span>
                      </div>
                      <!-- <div class="video-icon">
                        <img src="../../assets/images/video.png" alt="" />
                      </div> -->
                    </div>

                    <div class="league">
                      <span>{{ schedule.name }}</span>
                    </div>
                  </div>

                  <div class="live-league position-relative" v-else>
                    <div class="inner-sec"></div>

                    <div class="league">
                      <span>{{ schedule.name }}</span>
                    </div>
                  </div>

                  <div
                    class="schedule-info"
                    :class="{
                      'last-schedule-info': index === gameList.length - 1,
                    }"
                  >
                    <div>
                      <span class="day-date">{{
                        schedule.exactDateFormat
                      }}</span>
                      <div class="location-info">
                        <img
                          src="../../../assets/images/location.png"
                          @click="openGoogleMap($event, schedule)"
                          alt=""
                        />

                        <div class="content">{{ schedule.park_address }}</div>
                      </div>
                      <div class="opponent-info">
                        <span class="vs">vs</span>
                        <span class="oppo-name">{{
                          schedule.opponent_name
                        }}</span>
                      </div>
                      <div
                        class="oppo-para"
                        v-if="
                          schedule.comment !== null && schedule.comment !== ''
                        "
                      >
                        {{ schedule.comment }}
                      </div>
                    </div>
                    <div class="played-data">
                      <span class="tie" v-if="schedule.game_live == 1"
                        >{{ schedule.team_score }} -
                        {{ schedule.opponent_score }}</span
                      >

                      <span class="tie" v-else-if="schedule.win_status == 0"
                        >{{ schedule.team_score }} -
                        {{ schedule.opponent_score }}</span
                      >
                      <span
                        class="win-data"
                        v-else-if="schedule.win_status == 1"
                        >W{{ schedule.team_score }} -
                        {{ schedule.opponent_score }}</span
                      >
                      <span
                        class="loss-data"
                        v-else-if="schedule.win_status == 2"
                        >L{{ schedule.team_score }} -
                        {{ schedule.opponent_score }}</span
                      >
                      <span class="tie" v-else>
                        {{ schedule.team_score }} -
                        {{ schedule.opponent_score }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
export default {
  components: {},
  name: "GameListModal",

  data() {
    return {
      gameList: [], // Dummy game list
      singleGame: "",
      showLoader: 1,
    };
  },
  methods: {
    async getGames() {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleGame.event.id);
      formData.append("team_id", this.singleGame.team_id);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameList = response.data.data;
          this.showLoader = 0;
        });
    },
    callSingleBoxModal(schedule) {
      var combineUrl = `${schedule.team_id}-${schedule.guid}`;
      this.$router.push({
        name: "SingleBoxScore",
        params: { guid: combineUrl },
      });
      // Emit an event to indicate that the single box score is being shown
      this.$root.$emit("showSingleBoxScoreForGameSwitch");
      this.$root.$emit("HitEmitTeamsHistory", schedule.id);
      this.$root.$emit("HitfetchTeamPositionUponEmit", {
        fieldConfigId: schedule.field_configuration_id,
        scheduleId: schedule.id,
      });
    },
  },
  mounted() {
    $(window).on("popstate", function () {
      $(".modal-backdrop").remove();
      $("body").removeClass("modal-open");
    });
    this.$store.commit("setErrors", {});
    this.$root.$on("openGamesDataModal", (game) => {
      this.singleGame = game;
      this.getGames();
      var elem = this.$refs.openGameModal;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
