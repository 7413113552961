<template>
  <div class="info-wrapper" v-if="dataInfo">
    <div class="info-heading">
      <p>Short Info</p>
    </div>
    <div v-if="dataInfo.bio" class="info-content">
      <p>
        {{ dataInfo.bio }}
      </p>
    </div>
    <div v-else class="info-content">
      <p>
        <span class="text-capitalize">{{ dataInfo.user_name }}</span> hasn't updated the Bio yet.
      </p>
    </div>
    <div class="personal-info">
      <div class="personal-info-heading">
        <span>Date of Birth</span>
        <span>Age</span>
        <span>Gender</span>
      </div>
      <div class="personal-info-content">
        <span v-if="dataInfo.dob">{{ dataInfo.dob }}</span>
        <span v-else>-</span>
        <span v-if="calculatedAge > 0 && dataInfo.dob">{{ calculatedAge }} yrs</span>
        <span v-else>-</span>
        <span v-if="dataInfo.gender">{{ dataInfo.gender }}</span>
        <span v-else>-</span>
      </div>
    </div>
    <div class="location" style="border:none !important">
      <div class="key">
        <span>Address</span>
      </div>
      <div class="value text-capitalize">
        <span v-if="dataInfo.address">{{ dataInfo.address }}</span>
        <span v-else>-</span>
      </div>
    </div>
    <div class="personal-info" style="border:none !important">
      <div class="personal-info-heading">
        <span>City</span>
      </div>
      <div class="personal-info-content">
        <span v-if="dataInfo.city">{{ dataInfo.city }}</span>
        <span v-else>-</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  props: {
    dataInfo: {
      type: Object,
    },
  },
  computed: {
    calculatedAge() {
      if (this.dataInfo.dob) {
        const dob = new Date(this.dataInfo.dob);
        const diffMs = Date.now() - dob.getTime();
        const ageDt = new Date(diffMs);

        return Math.abs(ageDt.getUTCFullYear() - 1970); // Returns the age
      }
      return null;
    },
  },
};
</script>
