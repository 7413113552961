<!-- <template>
  <div class="weather-sec" v-if="eventsForWeather.weatherData">
    <div
      class="weather-content"
      v-for="(dataItem, index) in eventsForWeather.weatherData.slice(0,5)"
      :key="index"
    >
      <div class="weather-date">{{ formatDateWeather(dataItem.day) }}</div>
      <img
        :src="iconUrl + dataItem.icon"
        alt="Weather icon"
      />
      <div class="tempr">
        <p class="high">
          {{ dataItem.temperatureMax }}<sup><span class="small-symbol">&deg;C</span></sup>
        </p>
        <p class="low">
          {{ dataItem.temperatureMin }}<sup><span class="small-symbol">&deg;C</span></sup>
        </p>
      </div>
    </div>
  </div>
</template> -->

<template>
  <div class="weather-sec" v-if="eventsForWeather.weatherData">
    <div
      class="weather-content"
      v-for="(dataItem, index) in eventsForWeather.weatherData.slice(0,5)"
      :key="index"
    >
      <div class="weather-date">
        <div class="date-part">{{ formatDateWeather(dataItem.day).date }}</div>
        <div class="year-part">{{ formatDateWeather(dataItem.day).year }}</div>
      </div>
      <img
        :src="iconUrl + dataItem.icon"
        alt="Weather icon"
      />
      <div class="tempr">
        <p class="high">
          {{ dataItem.temperatureMax }}<sup><span class="small-symbol">&deg;F</span></sup>
        </p>
        <p class="low">
          {{ dataItem.temperatureMin }}<sup><span class="small-symbol">&deg;F</span></sup>
        </p>
      </div>
    </div>
  </div>
</template>

<!-- <script>
export default {
  name: "Weather",
  props: {
    eventsForWeather: Object,
  },
  data() {
    return {
      iconUrl: "https:",
    };
  },
  methods: {
    formatDateWeather(date) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    }
  },
  mounted() {
    console.log(this.eventsForWeather, "Hello");
  }
};
</script> -->

<script>
export default {
  name: "Weather",
  props: {
    eventsForWeather: Object,
  },
  data() {
    return {
      iconUrl: "https:",
    };
  },
  methods: {
    formatDateWeather(date) {
      const options = { month: 'short', day: 'numeric' };
      const datePart = new Date(date).toLocaleDateString(undefined, options);
      const yearPart = new Date(date).getFullYear();
      return { date: datePart, year: yearPart };
    }
  },
  mounted() {
    console.log(this.eventsForWeather, "Hello");
  }
};
</script>