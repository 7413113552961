<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#AddTournament"
      ref="openModalAddTournament"
      @click="dataReset()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="AddTournament">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Add Tournament</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalAddTournament"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="name">Tournament Name</label>
              <input
                id="name"
                class="form-control"
                v-model="tournament_name"
                spellcheck="false"
                autocomplete="off"
                trim
                type="text"
                required
                @input="errorNull"
              />
            </div>
            <div class="row no-gutters">
              <div class="col-sm-12 col-md-6 pr-0 pr-md-2">
                <div class="form-group">
                  <label for="startDateCheck">Start Date</label>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="startDateError != ''"
                  >
                    Please select Start Date.
                  </span>
                  <date-picker
                    :open="open"
                    @close="open = false"
                    :editable="false"
                    :clearable="false"
                    @click.native="handleClickStart"
                    v-model="startDate"
                    valueType="format"
                    format="YYYY-MM-DD"
                    placeholder="yyyy-mm-dd"
                    @change="checkdate;errorNull"
                  >
                    <i slot="icon-calendar" class="cursor-pointer">
                      <img src="../../../assets/images/calendar-input.png" />
                    </i>
                  </date-picker>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 pl-lg-0 mt-2 mt-md-0">
                <div class="form-group">
                  <label for="endDateCheck">End Date</label>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="endDateError != ''"
                  >
                    Please select End Date.
                  </span>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="endDateErrorGreater != ''"
                  >
                    End Date should be greater than start date
                  </span>
                  <date-picker
                    :open="openEnd"
                    @close="openEnd = false"
                    :editable="false"
                    :clearable="false"
                    @click.native="handleClickEnd"
                    v-model="endDate"
                    valueType="format"
                    format="YYYY-MM-DD"
                    placeholder="yyyy-mm-dd"
                    @change="checkdate;errorNull"
                  >
                    <i slot="icon-calendar" class="cursor-pointer">
                      <img src="../../../assets/images/calendar-input.png" />
                    </i>
                  </date-picker>
                </div>
              </div>
            </div>
            <div class="col-12 p-0">
              <div class="form-group">
                  <label for="tournamentFormat">Tournament Format</label>
                  <textarea
                    type="text"
                    class="form-control pt-3 mt-2 add-notes"
                    id="tournamentFormat"
                    rows="4"
                    v-model="tournamentFormat"
                    @keyup="errorNull"
                    required
                  ></textarea>
                </div>
              <!-- <div>
                <label for="summernote">Tournament Format</label>
                <vue-editor v-model="tournamentFormat" :editorToolbar="customToolbar"></vue-editor>
              </div> -->
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="!isDisabled"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="saveTournament(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VueEditor, Quill } from 'vue2-editor';
import axios from "axios";

// Generate code-friendly font names
// function getFontName(font) {
//     return font.toLowerCase().replace(/\s/g, "-");
// }

// // Specify Quill fonts
// const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana', 'sans-serif', 'Serif', 'Monospace'];
// const fontNames = fontList.map(font => getFontName(font));
// const fonts = Quill.import('formats/font');
// fonts.whitelist = fontNames;
// Quill.register(fonts, true);

// // Add fonts to CSS style
// let fontStyles = "";
// fontList.forEach(function(font) {
//     let fontName = getFontName(font);
//     fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
//         "content: '" + font + "';" +
//         "font-family: '" + font + "', sans-serif;" +
//         "}" +
//         ".ql-font-" + fontName + "{" +
//         " font-family: '" + font + "', sans-serif;" +
//         "}";
// });
// const node = document.createElement('style');
// node.innerHTML = fontStyles;
// document.body.appendChild(node);

export default {
  name: "AddTournament",
  components: {
    // VueEditor
  },
  data() {
    return {
      open: false,
      openEnd: false,
      startDate: "",
      endDate: "",
      exactStartDate: "",
      startDateForField: "",
      tournament_name: "",
      tournamentFormat: "",
      endDateForField: "",
      exactEndDate: "",
      startDateError: "",
      endDateError: "",
      endDateErrorGreater: "",
      exactStartDateformat: "",
      exactEndDateformat: "",
      isDisabled: true,
      isLoadingArray: [],
      // customToolbar: [
      //   ["bold", "italic", "underline", "strike"],
      //   [{ list: "ordered" }, { list: "bullet" }],
      //   [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      //   [{ font: fonts.whitelist }],
      //   // [{ font: [] }],
      //   // [{ size: ["small", false, "large", "huge"] }],
      //   [{ align: [] }],
      //   [{ color: [] }, { background: [] }],
      //   // ["link", "image", "video"],
      //   // ["code-block"],
      //   // ["clean"]
      // ],
    };
  },
  props: {
    eventId: {
      type: Number,
    },
  },
  watch: {
    tournament_name: "errorNull",
    startDate: "checkdate",
    endDate: "checkdate",
    tournamentFormat: "errorNull",
  },
  methods: {
    handleClickStart(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    handleClickEnd(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.openEnd = true;
      }
    },
    dataReset() {
      this.tournament_name = "";
      this.startDate = "";
      this.endDate = "";
      this.tournamentFormat = "";
      this.exactStartDate = "";
      this.exactEndDate = "";
      this.startDateError = "";
      this.endDateError = "";
      this.endDateErrorGreater = "";
    },
    errorNull() {
      if (!this.tournament_name || !this.startDate || !this.endDate || !this.tournamentFormat) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    checkdate: function () {
      const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      // Start Date
      if (this.startDate.length == 10) {
        const startDateArray = this.startDate.split("-");
        var startMonth = startDateArray[1];
        var startDay = startDateArray[2];
        var startYear = startDateArray[0];
        var varDate = startYear + "-" + startMonth + "-" + startDay;
        var startDateConvert = new Date(varDate);
        this.exactStartDateformat = startDateConvert;
        this.startDateForField = startDateConvert.toISOString().split('T')[0]; // Added this line
        this.exactStartDate = month[startMonth - 1] + " " + startDay + ", " + startYear;
      }
      // End Date
      if (this.endDate.length == 10) {
        const endDateArray = this.endDate.split("-");
        var endMonth = endDateArray[1];
        var endDay = endDateArray[2];
        var endYear = endDateArray[0];
        var varDatee = endYear + "-" + endMonth + "-" + endDay;
        var endDateConvert = new Date(varDatee);
        if (endDateConvert < startDateConvert) {
          this.endDateError = "";
          this.endDateErrorGreater = "on";
          this.exactEndDate = "";
          this.isDisabledEvent = true;
        } else {
          this.exactEndDateformat = endDateConvert;
          this.endDateError = "";
          this.endDateErrorGreater = "";
          this.endDateForField = endDateConvert.toISOString().split('T')[0]; // Added this line
          this.exactEndDate = month[endMonth - 1] + " " + endDay + ", " + endYear;
        }
      }
      this.checkTimeDate();
    },
    checkTimeDate() {
      if (!this.exactStartDate && this.exactEndDate) {
        this.startDateError = "on";
      } else {
        this.startDateError = "";
      }

      if (!this.exactEndDate && this.exactStartDate) {
        this.endDateError = "on";
      } else {
        this.endDateError = "";
      }

      if (this.exactStartDate && this.exactEndDate) {
        this.errorNull();
      }
    },
    saveTournament(index) {
      const formData = {
        event_id: this.eventId,
        tournamentName: this.tournament_name,
        format: this.tournamentFormat,
        startDateForField: this.startDateForField,
        endDateForField: this.endDateForField,
        startDate: this.exactStartDateformat,
        endDate: this.exactEndDateformat,
        exactStartDate: this.exactStartDate,
        exactEndDate: this.exactEndDate,
      };
      this.$set(this.isLoadingArray, index, true);

      axios.post('/associationEvent/createTournament', formData)
        .then(response => {
          console.log(response);
          this.$root.$emit("fetchTournaments");
          this.$refs.closeModalAddTournament.click();
          this.$set(this.isLoadingArray, index, false);
          this.dataReset();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.$set(this.isLoadingArray, index, false);
        });
    },
  },
  mounted() {
    this.$root.$on("openAddTournamentModal", () => {
      const elem = this.$refs.openModalAddTournament;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
<style scoped>
.event-error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  padding: 3px 8px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
  top: 2px;
}

</style>