<template>
  <div>
    <div class="search">
      <div class="search-heading">
        <h6>
          Search results for <span>'{{ searchText }}'</span>
        </h6>
      </div>
      <ul class="search-list mb-0">
        <li
          class="search-list-item"
          v-bind:class="[openTab == 1 ? 'active' : '']"
          @click="searchAll"
        >
          <img
            v-if="openTab == 1"
            src="../../assets/images/search-blue-all.png"
          />
          <img v-else src="../../assets/images/search-all.png" />
          All
        </li>
        <li
          class="search-list-item"
          v-bind:class="[openTab == 2 ? 'active' : '']"
          @click="searchPeople"
        >
          <img
            v-if="openTab == 2"
            src="../../assets/images/search-people-blue.png"
          />
          <img v-else src="../../assets/images/search-people.png" />
          People
        </li>
        <li
          class="search-list-item"
          v-bind:class="[openTab == 3 ? 'active' : '']"
          @click="searchPost"
        >
          <img v-if="openTab == 3" src="../../assets/images/search-blue.png" />
          <img v-else src="../../assets/images/search-posts.png" />
          Post
        </li>
        <!-- <li
          class="search-list-item"
          v-bind:class="[openTab == 4 ? 'active' : '']"
          @click="searchEvent"
        >
          <img v-if="openTab == 4" src="../../assets/events-blue.png" />
          <img v-else src="../../assets/events-black.png" />
          Events
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Events",
  props: {
    openTab: {
      type: Number,
    },
    searchText: {
      type: String,
    },
  },
  methods: {
    searchAll() {
      window.scrollTo(0, 0);
      this.$root.$emit("openTabSearchSelection", 1, 0, 0);
    },
    searchPeople() {
      window.scrollTo(0, 0);
      this.$root.$emit("openTabSearchSelection", 2, 0, 0);
    },
    searchPost() {
      window.scrollTo(0, 0);
      this.$root.$emit("openTabSearchSelection", 3, 0, 0);
    },
    searchEvent() {
      window.scrollTo(0, 0);
      this.$root.$emit("openTabSearchSelection", 4, 0, 0);
    },
  },
};
</script>
