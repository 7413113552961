var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"main-heading"},[_vm._v("Interpretation and Definitions")]),_c('h5',{staticClass:"sub-heading"},[_vm._v("Interpretation")]),_c('p',{staticClass:"main-para"},[_vm._v(" The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural. ")]),_c('h5',{staticClass:"sub-heading"},[_vm._v("Definitions")]),_c('p',{staticClass:"para mb-2"},[_vm._v(" For the purposes of this Privacy Policy: ")]),_c('div',{staticClass:"para-list-items"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('p',[_vm._v(" For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You. ")]),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_c('p',[_c('span',[_vm._v("Website")]),_vm._v("refers to Who I Follow, accessible from "),_c('router-link',{attrs:{"to":""}},[_vm._v("http://www.whoifollow.com")])],1),_vm._m(14)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Account")]),_vm._v("means a unique account created for You to access our Service or parts of our Service ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Affiliate")]),_vm._v("means an entity that controls, is controlled by or is under common control with a party, where \"control\" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Application")]),_vm._v("means the software program provided by the Company downloaded by You on any electronic device, named WIF ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Business,")]),_vm._v("for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Company")]),_vm._v("(referred to as either \"the Company\", \"We\", \"Us\" or \"Our\" in this Agreement) refers to Who I Follow Corp, 12254 Highland Rd. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Consumer,")]),_vm._v("for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Cookies")]),_vm._v("are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Device")]),_vm._v("means any device that can access the Service such as a computer, a cellphone or a digital tablet. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Do Not Track")]),_vm._v("(DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Personal Data")]),_vm._v("is any information that relates to an identified or identifiable individual. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Sale,")]),_vm._v("the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's personal information to another business or a third party for monetary or other valuable consideration. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Service")]),_vm._v("to the Application or the Website or both.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Service Provider")]),_vm._v("any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Usage Data")]),_vm._v("to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit). ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("You")]),_vm._v("means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.")])
}]

export { render, staticRenderFns }