<template>
  <div class="followers-wrapper">
    <div class="follower-count text-center">
      <span>Followers</span>
      <h3>{{ followerCount }}</h3>
    </div>
    <div class="following-count text-center">
      <span>Following</span>
      <h3>{{ followingCount }}</h3>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "Followers",
  data() {
    return {
      followerCount: "0",
      followingCount: "0",
      user_link: localStorage.getItem("user_link"),
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    async allCount() {
      let allCounts = await axios.get(`allCount?user_link=${this.user_link}`);

      if (allCounts.data.statusCode == 200) {
        this.followerCount = allCounts.data.data.followerCount;
        this.followingCount = allCounts.data.data.followingCount;
        this.showLoader = 0;
      } else {
        this.showLoader = 0;
      }
    },
  },
  mounted() {
    this.allCount();
  },
};
</script>
