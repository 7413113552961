<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3 pb-3">
        <div class="row no-gutters">
          <div class="col-sm-12 col-md-8 px-0">
            <div class="order-left-side">
              <CheckoutHeading />
              <Delivery />
            </div>
          </div>
          <div class="col-sm-12 col-md-4 px-0">
            <ItemHistory />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import CheckoutHeading from "../components/Shop/Order/CheckoutHeading.vue";
import Delivery from "../components/Shop/Shipping/Delivery.vue";
import ItemHistory from "../components/Shop/Order/ItemHistory.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";

export default {
  components: {
    Navbar,
    LeftMenu,
    CheckoutHeading,
    Delivery,
    ItemHistory,
    Banner
  },
  name: "Shipping",
  computed: {
    ...mapGetters("cart", ["cartItemCount"]),
  },
  mounted() {
    if (this.cartItemCount <= 0) {
      this.$router.push({ name: "Shop" });
    }
  },
};
</script>
