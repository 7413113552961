<template>
  <div>
    <div class="order-history single-box-score-loader" v-if="showLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
    <div v-if="showLoader == 0">
      <div class="team-member team-member-attendees-side mt-3">
        <h6>Going</h6>
        <span class="nav-counter-team">{{ totalGoing }}</span>
      </div>
      <div class="team-members">
        <div class="team-info d-block" v-if="membersGoing.length > 0">
          <div
            class="team-details mb-3 d-flex justify-content-between"
            v-for="user in membersGoing"
            :key="user.id"
          >
            <div class="d-flex align-items-center">
              <div class="team-image mr-2 position-relative">
                <div class="users-notification" v-if="imgLoader == 1">
                  <img src="../../assets/loader.gif" alt="" />
                </div>
                <img
                  @load="onImageLoad"
                  v-if="user.avatar != ''"
                  :src="user.avatar"
                 onerror="this.src='https://api.whoifollow.tech/user-default2.png'"

                  alt=""
                />
                <img
                  @load="onImageLoad"
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                />
              </div>
              <div class="team-name-role">
                <h6>{{ user.username }}</h6>
              </div>
            </div>
            <AttendeesOptions
              :currentIsAdmin="currentIsAdmin"
              :user="user"
              :selectedRoomTeam="selectedRoomTeam"
              :singleEvent="singleEvent"
            />
          </div>
        </div>
        <div class="delivered-to-wrapper" v-if="membersGoing.length == 0">
          <div class="message-reader-detail">
            <span>No User found.</span>
          </div>
        </div>
      </div>
      <div class="team-member mt-3">
        <h6>Not Going</h6>
        <span class="nav-counter-team">{{ totalNotGoing }}</span>
      </div>
      <div class="team-members">
        <div class="team-info d-block" v-if="membersNotGoing.length > 0">
          <div
            class="team-details mb-3 d-flex justify-content-between"
            v-for="user in membersNotGoing"
            :key="user.id"
          >
            <div class="d-flex align-items-center">
              <div class="team-image mr-2 position-relative">
                <div class="users-notification" v-if="imgLoader == 1">
                  <img src="../../assets/loader.gif" alt="" />
                </div>
                <img
                  @load="onImageLoad"
                  v-if="user.avatar != ''"
                  :src="user.avatar"
                 onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                  alt=""
                />
                <img
                  @load="onImageLoad"
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                />
              </div>
              <div class="team-name-role">
                <h6>{{ user.username }}</h6>
              </div>
            </div>
            <AttendeesOptions
              :currentIsAdmin="currentIsAdmin"
              :user="user"
              :selectedRoomTeam="selectedRoomTeam"
              :singleEvent="singleEvent"
            />
          </div>
        </div>
        <div class="delivered-to-wrapper" v-if="membersNotGoing.length == 0">
          <div class="message-reader-detail">
            <span>No User found.</span>
          </div>
        </div>
      </div>
      <div class="team-member mt-3">
        <h6>May Be</h6>
        <span class="nav-counter-team">{{ totalMayBe }}</span>
      </div>
      <div class="team-members">
        <div class="team-info d-block" v-if="membersMayBe.length > 0">
          <div
            class="team-details mb-3 d-flex justify-content-between"
            v-for="user in membersMayBe"
            :key="user.id"
          >
            <div class="d-flex align-items-center">
              <div class="team-image mr-2 position-relative">
                <div class="users-notification" v-if="imgLoader == 1">
                  <img src="../../assets/loader.gif" alt="" />
                </div>
                <img
                  @load="onImageLoad"
                  v-if="user.avatar != ''"
                  :src="user.avatar"
                 onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                  alt=""
                />
                <img
                  @load="onImageLoad"
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                />
              </div>
              <div class="team-name-role">
                <h6>{{ user.username }}</h6>
              </div>
            </div>
            <AttendeesOptions
              :currentIsAdmin="currentIsAdmin"
              :user="user"
              :selectedRoomTeam="selectedRoomTeam"
              :singleEvent="singleEvent"
            />
          </div>
        </div>
        <div class="delivered-to-wrapper" v-if="membersMayBe.length == 0">
          <div class="message-reader-detail">
            <span>No User found.</span>
          </div>
        </div>
      </div>
      <div class="team-member mt-3">
        <h6>Not Responded</h6>
        <span class="nav-counter-team">{{ totalNotRespond }}</span>
      </div>
      <div class="team-members">
        <div class="team-info d-block" v-if="membersNotRespond.length > 0">
          <div
            class="team-details mb-3 d-flex justify-content-between"
            v-for="user in membersNotRespond"
            :key="user.id"
          >
            <div class="d-flex align-items-center">
              <div class="team-image mr-2 position-relative">
                <div class="users-notification" v-if="imgLoader == 1">
                  <img src="../../assets/loader.gif" alt="" />
                </div>
                <img
                  @load="onImageLoad"
                  v-if="user.avatar != ''"
                  :src="user.avatar"
                 onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                  alt=""
                />
                <img
                  @load="onImageLoad"
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                />
              </div>
              <div class="team-name-role">
                <h6>{{ user.username }}</h6>
              </div>
            </div>
            <AttendeesOptions
              :currentIsAdmin="currentIsAdmin"
              :user="user"
              :selectedRoomTeam="selectedRoomTeam"
              :singleEvent="singleEvent"
            />
          </div>
        </div>
        <div class="delivered-to-wrapper" v-if="membersNotRespond.length == 0">
          <div class="message-reader-detail">
            <span>No User found.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AttendeesOptions from "./AttendeesOptions.vue";
import axios from "axios";
export default {
  name: "AttendeesInfo",
  components: {
    AttendeesOptions,
  },
  data() {
    return {
      imgLoader: 1,
      showLoader: 1,
      attendingList: [],
      totalGoing: 0,
      totalNotGoing: 0,
      totalMayBe: 0,
      totalNotRespond: 0,
      eventInvitedList: [],
      membersAttendeeComplete: [],
      currentUserEventStatus: 0,
      membersGoing: [],
      membersNotGoing: [],
      membersMayBe: [],
      membersNotRespond: [],
      finalArray: [],
      selectedArray: [],
      room_count: 1,
      adult_count: 1,
      exactRoomEndDate: "",
      note: "",
      exactRoomStartDate: "",
      userMember_id: "",
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleEvent: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    async getAttendee() {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("event_id", this.$route.params.id);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const eventAttendeeFromDB = response.data.data.attendeeListing;
          this.eventInvitedList = response.data.data.inviteListing;
          const tempMembers = this.selectedRoomTeam.users;
          const tempMembersRole = this.selectedRoomTeam.userRole;
          this.eventGoingForpdf = [];

          // Add Uniform ID to Going Members
          for (const memberRole of tempMembers) {
            for (const memberRoles of tempMembersRole) {
              if (memberRoles.id == memberRole.id && memberRoles.fan != "1") {
                for (const attendeeRole of eventAttendeeFromDB) {
                  if (
                    memberRole.c_id == attendeeRole.member_id &&
                    (attendeeRole.member_status == 1 ||
                      attendeeRole.member_status == "1")
                  ) {
                    const foundUser = tempMembersRole.find(
                      (user) => user?.id === memberRole.id
                    );
                    attendeeRole.uniform = foundUser.uniform;
                    this.eventGoingForpdf.push(attendeeRole);
                  }
                }
              }
            }
          }
          // Add Uniform ID to Going Members

          this.membersAttendeeComplete = [];
          var $i = 0;
          this.totalGoing = 0;
          this.totalNotGoing = 0;
          this.totalMayBe = 0;
          this.totalNotRespond = 0;
          for (const member of tempMembers) {
            for (const memberRole of tempMembersRole) {
              if (memberRole.id == member.id && memberRole.fan != "1") {
                this.membersAttendeeComplete.push(member);
                this.membersAttendeeComplete[$i].event_attendee_status = 0;
                for (const attendee of eventAttendeeFromDB) {
                  if (member.c_id == attendee.member_id) {
                    this.membersAttendeeComplete[$i].event_attendee_status =
                      attendee.member_status;
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        1 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "1"
                    ) {
                      this.totalGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        2 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "2"
                    ) {
                      this.totalNotGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        3 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "3"
                    ) {
                      this.totalMayBe++;
                    }
                  }
                }

                if (
                  this.membersAttendeeComplete[$i].event_attendee_status == 0 ||
                  this.membersAttendeeComplete[$i].event_attendee_status == "0"
                ) {
                  this.totalNotRespond++;
                }

                if (member.id == this.currentUserId) {
                  this.currentUserEventStatus =
                    this.membersAttendeeComplete[$i].event_attendee_status;
                }
                $i++;
              }
            }
          }
          this.showLoader = 0;
          this.populateLists();
        });
    },
    populateLists() {
      this.membersGoing = this.membersAttendeeComplete.filter(
        (attendee) => attendee.event_attendee_status == 1
      );
      this.membersNotGoing = this.membersAttendeeComplete.filter(
        (attendee) => attendee.event_attendee_status == 2
      );
      this.membersMayBe = this.membersAttendeeComplete.filter(
        (attendee) => attendee.event_attendee_status == 3
      );
      this.membersNotRespond = this.membersAttendeeComplete.filter(
        (attendee) => attendee.event_attendee_status == 0
      );
    },
    async selectStatusWithServices(status) {
      status = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      formData.append("member_status", status);
      formData.append("services", this.selectedArray);
      formData.append("team_id", this.selectedRoomTeam.roomId);
      formData.append("room_count", this.room_count);
      formData.append("exactStartDate", this.exactRoomStartDate);
      formData.append("exactEndDate", this.exactRoomEndDate);
      formData.append("note", this.note);
      formData.append("adult_count", this.adult_count);
      if (
        this.userMember_id !== null &&
        this.userMember_id !== undefined &&
        this.userMember_id !== ""
      ) {
        formData.append("member_id", this.userMember_id);
      }
      // formData.append("member_id", this.userMember_id);

      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.getAttendee();
        });
    },
  },
  mounted() {
    this.getAttendee();
    this.$root.$on("showAttendeesList", () => {
      this.getAttendee();
    });
    this.$root.$on(
      "attendeesData",
      (
        data,
        user_id,
        room_count,
        adultQuantity,
        note,
        exactRoomStartDate,
        exactRoomEndDate
      ) => {
        this.selectedArray = data;
        this.userMember_id = user_id;
        this.room_count = room_count;
        this.note = note;
        this.adult_count = adultQuantity;
        this.exactRoomEndDate = exactRoomEndDate;
        this.exactRoomStartDate = exactRoomStartDate;
        this.selectStatusWithServices();
      }
    );
  },
};
</script>