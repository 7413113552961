<template>
  <div>
    <TeamLineUpModal :selectedRoomTeam="selectedRoomTeam" />
    <div v-if="games && games.length > 0">
      <div v-for="game in games" :key="game.id">
        <div class="game-detail-wrapper align-items-stretch single-event-side-game p-2 mb-2">

          <div class="game-content">
            <div class="inner-sec"  v-if="game.game_live == 1">
              <div class="live-tag position-relative">
                <span>Live</span>
              </div>
              <!-- <div class="video-icon">
                  <img src="../../assets/images/video.png" alt="" />
                </div> -->
            </div>
            <router-link :to="`/score-game/${game.team_id}-${game.guid}`" class="m-0 date-content">
              <p>{{ getFromDate(game.exactDateFormat) }}</p>
            </router-link>
            <span
              class="game-location cursor-pointer"
              @click="openGoogleMapforGamePark(game)"
              v-if="game.park_name != null || game.park_name != ''"
            >
              <img src="../../assets/images/location.png" class="mr-2" />
              <p>{{ game.park_name }}</p>
            </span>
            <router-link :to="`/score-game/${game.team_id}-${game.guid}`"
              class="m-0 game-location"
              v-if="game.park_address != null || game.park_address != ''"
            >
              <p>{{ game.park_address }}</p>
            </router-link>
            <router-link :to="`/score-game/${game.team_id}-${game.guid}`"
              class="m-0 game-location"
              v-if="game.park_number != null || game.park_number != ''"
            >
              <p>{{ game.park_number }}</p>
          </router-link>
            <router-link :to="`/score-game/${game.team_id}-${game.guid}`" class="m-0 opponent-name">
              <!-- <img src="../../assets/images/teammates.png" alt="" /> -->
              <span>vs</span>
              <h5>{{ game.opponent_name }}</h5>
            </router-link>
            <router-link :to="`/score-game/${game.team_id}-${game.guid}`"
              v-if="
                game.comment != null &&
                game.comment != '' &&
                game.comment != 'null'
              "
              class="m-0 game-description"
            >
              <p>{{ game.comment }}</p>
          </router-link>
          </div>
          <div
            class="d-flex flex-column justify-content-between align-items-end"
          >
            <div>
              <div class="game-name d-flex justify-content-end">
                <router-link
                  class="m-0"
                  :to="`/score-game/${game.team_id}-${game.guid}`"
                >
                  <h6>{{ game.name }}</h6>
                </router-link>
              </div>
              <router-link :to="`/score-game/${game.team_id}-${game.guid}`" class="m-0">
                <div class="played-data text-right">
                  <span class="tie" v-if="game.game_live == 1"
                    >{{ game.team_score }} - {{ game.opponent_score }}</span
                  >

                  <span class="tie" v-else-if="game.win_status == 0"
                    >{{ game.team_score }} - {{ game.opponent_score }}</span
                  >
                  <span class="win-data" v-else-if="game.win_status == 1"
                    >W{{ game.team_score }} - {{ game.opponent_score }}</span
                  >
                  <span class="loss-data" v-else-if="game.win_status == 2"
                    >L{{ game.team_score }} - {{ game.opponent_score }}</span
                  >
                  <span class="tie text-dark" v-else>
                    {{ getTimeFromDateFormat(game.exactDateFormat) }}
                  </span>
                </div>
              </router-link>
            </div>
            <GameOptions
              :singleGame="game"
              :eventAlertOptions="eventAlertOptions"
              v-if="
                currentIsAdmin == '1' &&
                selectedRoomTeam.teamType == 'sports' &&
                currentTeamIsActive == 1
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="user-notification d-flex align-items-center justify-content-center"
      v-if="showGameLoader == true"
    >
      <div class="lds-dual-ring-refresh"></div>
    </div>
  </div>
</template>

<script>
import GameOptions from "./GameOptions.vue";
import TeamLineUpModal from "../SingleBoxScore/SubLeft/TeamLineUpModal.vue";
export default {
  name: "GameInfo",
  components: {
    TeamLineUpModal,
    GameOptions,
  },
  data() {
    return {
      singleGame: {},
    };
  },
  props: {
    games: {
      type: Array,
    },
    showGameLoader: {
      type: Boolean,
    },
    eventAlertOptions: {
      type: Array,
    },
    selectedRoomTeam: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
  },
  methods: {
    getTimeFromDateFormat(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return ''; 
      }
      return date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    },
    getFromDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return '';
      }
      const formattedDate = date.toLocaleDateString([], { month: 'short', day: '2-digit' });
      let formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const amIndex = formattedTime.indexOf('AM');
      if (amIndex !== -1) {
        formattedTime = formattedTime.slice(0, amIndex + 2);
      }
      return `${formattedDate}, ${formattedTime}`;
    },
    // callModal(game) {
    //   var combineUrl = game.team_id + "-" + game.guid;
    //   const urlToOpen = `/score-game/${combineUrl}`;
    //   window.location.href = urlToOpen;
    // },
    openGoogleMapforGamePark(item) {
      const strlatLng = item["park_lat"] + "," + item["park_lng"];
      const urlSuffix = strlatLng ? strlatLng : item["park_address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
  },
};
</script>
<style scoped>
.date-content p,
.game-location p{
  font-size: 14px;
}
.live-tag{
  max-width: fit-content;
}
</style>

