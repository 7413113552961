<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3 pb-3">  
        <div class="row no-gutters">
          <div class="col-12">
            <div class="notification-page-heading">
              <h5>Notification</h5>
            </div>
          </div>
          <div class="col-12 bg-white notification-section">
            <NotificationContent
              :notificationList="notificationList"
              :showLoader="showLoader"
            />
            <div
              v-if="notificationList.length"
              v-observe-visibility="handleScrolledToButtom"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions } from "vuex";
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import NotificationContent from "../components/Notification/NotificationContent.vue";

export default {
  name: "NotificationPage",
  components: {
    Navbar,
    LeftMenu,
    NotificationContent,
    Banner
  
  },
  data() {
    return {
      Storage_URL: this.$userProfileStorageURL,
      errorMessage: null,
      successMessage: null,
      page: 1,
      noNotification: 0,
      showLoader: 0,
      lastPage: 1,
      API_URL: this.$userProfileStorageURL,
      notificationList: [],
    };
  },
  computed: {
  },
  methods: {
    ...mapActions("notification", ["getUserNotificationDataPaginate"]),

    async fetch() {
      this.showLoader = 1;
      let notifications = await axios.get(
        `notification/listing?page=${this.page}`
      );

      if (notifications.data.statusCode == 200) {
        this.notificationList.push(...notifications.data.data.data);
        this.lastPage = notifications.data.data.last_page;
        this.noNotification = notifications.data.data.length > 0 ? 0 : 1;
      } else {
        this.errorMessage = notifications.data.message;
      }
      this.showLoader = 0;
    },

    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.fetch();
    },
  },

  mounted() {
    this.fetch(1);
    this.$root.$on("NotificationPage", () => {
      this.page = 1;
      this.lastPage = 1;
      this.notificationList = [];
      this.fetch(1);
    });
  },
};
</script>
