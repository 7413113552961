<template>
    <div>
      <Navbar />
      <div class="setting-main-wrapper">
        <div class="main-left-menu">
          <LeftMenu />
        </div>
        <Banner />
        <div class="shop-side pr-3">
          <GameTimeTab />
          <AllTeamList />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Navbar from "../components/Header/Navbar.vue";
  import GameTimeTab from "../components/GameTime/GameTimeTabs.vue";
  import LeftMenu from "../components/Home/LeftMenu.vue";
  import Banner from "../components/BannerNotify/BannerPage.vue";
  import AllTeamList from "../components/GameTime/AllTeamList.vue";
  
  export default {
    data() {
      return { };
    },
    components: {
      Navbar,
      LeftMenu,
      Banner,
      AllTeamList,
      GameTimeTab
  
    }
  };
  </script>
  