<template>
  <div>
    <AddTournament
    :eventId="eventId"
    :currentIsAdmin="currentIsAdmin"
     />
    <div class="d-flex justify-content-end" >
      <button class="btn btn-primary" @click="openModal()" v-if="currentIsAdmin == '1'">
        Add Tournament
      </button>
    </div>
    <div
      class="table-responsive mb-0"
      v-if="
        tournamentsData &&
        tournamentsData.length != 0 &&
        showLoaderTournament == 0
      "
    >
      <b-table
        :items="tournamentsData"
        :fields="fields"
        hover
        responsive
        class="mt-3 b-datatable cursor-pointer"
        
      >
        <template v-slot:cell(Tournament)="data" @row-clicked="redirectToTournament">
          <router-link
            :to="{ name: 'Tournament', params: { id: data.item.guid } }"
            class="stats-link"
          >
            {{ data.item.tournamentName }}
          </router-link>
        </template>

        <template v-slot:cell(Date)="data">
          {{ data.item.date }}
        </template>
        <template v-slot:cell(Teams)="data">
          {{ data.item.teamCounts }} Teams
        </template>
        <template v-if="currentIsAdmin == '1'" v-slot:cell(Action)="data">
          <div
            @click="handleActionClick(data.item)"
            class="d-flex justify-content-end"
          >
            <EditTournamentEllipsis :tournament="data.item" :eventId="eventId" :currentIsAdmin="currentIsAdmin" />
          </div>
        </template>
      </b-table>
      <content-placeholders :rounded="true" v-if="showLoaderTournament == 1">
        <content-placeholders-heading :img="false" />
      </content-placeholders>
    </div>
    <div
      v-if="
        (!tournamentsData || tournamentsData.length === 0) &&
        showLoaderTournament === 0
      "
      class="no-products-in-shop"
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No tournament found.</p>
      <p class="info">There are no tournament found to display.</p>
    </div>
    <div class="products-list-shim p-0 mt-3" v-if="showLoaderTournament == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>
  
  <script>
import EditTournamentEllipsis from "./EditTournamentEllipsis.vue";
import AddTournament from "./Modals/AddTournament.vue";
export default {
  components: { AddTournament, EditTournamentEllipsis },
  name: "AssociationTournamentInfo",

  data() {
    return {
      fields: [
        { key: "Tournament", label: "Tournament", sortable: true },
        {
          key: "startDateForField",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
            const startDate = new Date(item.exactStartDate);
            const endDate = new Date(item.exactEndDate);

            const startDateString = startDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });
            const endDateString = endDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });
            const startYearString = startDate.getFullYear();
            const endYearString = endDate.getFullYear();

            if (startYearString === endYearString) {
              return `${startDateString} to ${endDateString}, ${startYearString}`;
            } else {
              return `${startDateString}, ${startYearString} to ${endDateString}, ${endYearString}`;
            }
          },
          sortBy: (item) => {
            // Custom sorting function based on startDateForField
            return new Date(item.startDateForField).getTime(); // Sort by timestamp
          },
        },
        { key: "Teams", label: "Teams", sortable: true },
        { key: "Action", label: "", class: "columnheader" },
      ],
    };
  },
  props: {
    tournamentsData: {
      type: Array,
    },
    eventId: {
      // Define umpires prop to receive the umpire data
      type: Number,
    },
    showLoaderTournament: {
      type: Number,
    },
    page: {
      type: Number,
    },
    currentIsAdmin: {
      type: String,
    },
    lastPage: {
      type: Number,
    },
  },
  methods: {
    redirectToTournament(item) {
      const tournamentId = item.guid;
      this.$router.push({ name: "Tournament", params: { id: tournamentId } });
    },
    openModal: function () {
      this.$root.$emit("openAddTournamentModal");
    },
    handleActionClick() {
      this.$root.$emit("openEditTournamentEllipsis");
    },
  },
};
</script>
