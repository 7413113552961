<template>
  <div class="order-history ml-3">
    <!-- <h2>Going Teammates</h2> -->

    <div class="col-12 pl-1">
      <table class="table table-hover print-table text-left" v-if="users">
        <thead class="text-left">
          <tr style="word-break:break-word;">
            <th scope="col" class="pl-0">Name</th>
            <th scope="col" class="pl-0">Uniform #</th>
            <th scope="col" class="pl-0">Phone/Email</th>
            <th scope="col" class="pl-0">Signature</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style="word-break:break-word;"
            v-for="(user, index) in users"
            :key="index + 100"
            cla
          >
            <td scope="row" style="min-width: 80px; max-width: 80px;">
              {{ user.fname }} {{ user.lname }}
            </td>
            <td
              v-if="user.uniform != 'null'"
              scope="row"
              style="min-width: 80px; max-width: 80px;"
            >
              {{ user.uniform }}
            </td>
            <td
              scope="row"
              style="min-width: 80px; max-width: 80px;"
              v-else
            ></td>
            <td v-if="user.target == undefined">
              <span> {{ user.mob_code }}{{ user.mob }} </span>
              <span>{{ user.email }}</span>
            </td>
            <td v-else>
              <span class="text-capitalize">
                {{ user.target }}
              </span>
            </td>
            
            <td>_________________________</td>
          </tr>
          <!-- <tr
            style="word-break: break-word;"
            v-for="(user, index) in InvitedList"
            :key="index"
          >
            <td scope="row" style="min-width: 80px; max-width: 80px;">
              {{ user.fname }} {{ user.lname }}
            </td>
            <td scope="row" style="min-width: 80px; max-width: 80px;" v-if="user.uniform!='null'">
              {{ user.uniform }}
            </td>
             <td scope="row" style="min-width: 80px; max-width: 80px;" v-else>
            </td>
           
            <td class="text-capitalize">{{ user.target }}</td>
            <td>_________________________</td>
          </tr> -->
        </tbody>
      </table>
      <div class="info-heading" v-if="users.length == 0">
        <p class="text-center" v-if="showLoader == 0">
          No One marked going yet!
        </p>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "pdfHtml",
  props: {
    users: {
      type: Array,
    },
    selectedRoomTeam: {
      type: Object,
    },
    InvitedList: {
      type: Array,
    },
  },
  data() {
    return {
      errorMessage: null,
      orders: [],
      showLoader: 0,
      page: 1,
      perPage: 12,
      pages: [],
      usersPDF: [],
    };
  },

  methods: {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {},
};
</script>

<style>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 14px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}

.table.print-table tbody tr {
  border-bottom: 1px solid #dee2e6;
}

.table.print-table tbody tr:last-child {
  border-bottom: none;
}
</style>
