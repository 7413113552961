<template>
  <div>
    <b-row class="no-gutters" v-if="TeamDetails">
      <div class="col-12 products-column mt-3" @click="on()">
        <div class="event-card mt-0 p-3">
          <div class="table-responsive mb-0">
            <table class="table p-2">
              <thead>
                <tr>
                  <th scope="col" class="pl-0">Event Name</th>
                  <th scope="col" class="pl-0">Association</th>
                  <th scope="col" class="pl-0">Date</th>
                  <th scope="col" class="pl-0">Time</th>
                </tr>
              </thead>
              <tbody v-for="value in TeamDetails" :key="value.id">
                <tr @click="handleEventClick(value)">
                  <td>{{ value.eventName }}</td>
                  <td v-if="value.association != '' && value.association != null && value.association != 'null'">{{ value.association }}</td>
                  <td
                    class="content"
                    v-if="value.exactStartDate == value.exactEndDate"
                  >
                    {{ value.exactStartDate }}
                  </td>
                  <td class="content" v-else>
                    {{ value.exactStartDate.split(",")[0] }} to
                    {{ value.exactEndDate }}
                  </td>
                  <td class="content" v-if="value.allDay == 'true'">
                    &nbsp; All Day.
                  </td>
                  <td v-else class="content">
                    From {{ value.exactStartTime }} to {{ value.exactEndTime }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-row>
    <div
      class="card-event col-12 no-card-event"
      v-if="showLoader == 0 && TeamDetails.length == 0"
    >
      <img src="../../assets/images/calendar-search.png" alt="" />
      <p class="no-event-header">No upnext events</p>
      <p class="no-event-text">
        All upnext events for this team will appear here.
      </p>
    </div>
    <div class="products-list-shim mt-3" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { parseTimestamp } from "../../utils/dates";
import { mapGetters } from "vuex";

export default {
  name: "alleventListView",

  data: function () {
    return {
      firstTimeEventsA: [],
      eventURL: this.$eventAvatarStorageURL,
      eventsDetails: [],
      page: 1,
      lastPage: 1,
      TeamDetail: this.TeamDetails,
      avatar: "",
      eventName: "",
      createdByName: "",
      createdByDate: "",
      association: "",
      eventType: "",
      status: "",
      address: "",
      city: "",
      Url: "",
      exactStartDate: "",
      exactEndDate: "",
      allDay: "",
      exactStartTime: "",
      exactEndTime: "",
      eventTypeA: "",
      showEventMemeberCountShimmer: 0,
      currentUserEventStatus: 1,
      showEventsMember: 0,
      showGameList: 0,
      showDefualt: 1,
      totalGoing: 0,
      totalNotGoing: 0,
      totalMayBe: 0,
      totalNotRespond: 0,
      showGameShimmer: 1,
      UserDetail: [],
      gameList: [],
      selectAttendeeTab: 0,
      membersAttendee: [],
      API_URL: this.$userProfileStorageURL,
      selectedRoomTeam: [],
      selectMainTab: 0,
      teamID: "",
      eventID: "",
      selectVisitEventStatus: [],
      singleEvent: [],
      comingstat: this.comingstatus,
      total: 0,
      eventTo: 0,
    };
  },

  props: {
    TeamDetails: Array,
    UserDetails: Array,
    filters: Object,
    showLoader: Number,
    currentUserId: String
  },
  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("events", ["comingstatus"]),
  },
  methods: {
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    handleEventClick(clickInfo_event) {
      document.getElementById("overlay").style.display = "block";
      this.eventID = clickInfo_event;
      this.selectMainTab = 0;
      this.$root.$emit("gameScoreLoaderShow", 1);
      const formData = new FormData();
      formData.append("teamIDs", this.eventID.id);
      axios
        .post(process.env.VUE_APP_API_URL + "game/boxScores", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const data = res.data.data.listing;
          const event_name = this.eventID.eventName;
          this.$root.$emit("dataItems", data, event_name);
        });

      const getItem = document.querySelector(".box-score-right-side");

      getItem.style.display = "block";
    },
    checkStatus(endDateForField, exactStartTime) {
      var checkDateIfPast = parseTimestamp(
        endDateForField + "&&" + exactStartTime,
        "checkIfPast"
      );
      if (checkDateIfPast == "upComing") {
        return "upComing";
      } else {
        return "past";
      }
    },
    openGoogleMapforGamePark(item) {
      const strlatLng = item["park_lat"] + "," + item["park_lng"];
      const urlSuffix = strlatLng ? strlatLng : item["park_address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    // on: function() {
    //   document.getElementById("overlay").style.display = "block";
    // },
  },

  // watch: {
  // },

  mounted() {
    this.lastPage = 2;
    this.page = 1;
  },
};
</script>

<style scoped>
.content .img-wrapper {
  width: 100%;
  height: 200px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content .img-wrapper img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.card-text {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
}

.created-by,
.date {
  font-size: 14px;
  font-weight: 500;
}

.event-description .created-by {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.event-description .date .content {
  margin-left: 43px;
}

.event-details-wrapper {
  padding-top: 10px;
  overflow-x: hidden;
  z-index: 99;
}

.event-details-top {
  display: flex;
  align-items: center;
}

.event-details-top .back-arrow {
  color: #188ef5;
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
  padding: 0 5px;
}

.event-details-top .back-arrow:hover {
  background-color: #188ef5;
  color: #ffffff;
  border-radius: 5px;
}

.event-details-top h6 {
  margin: 0;
  text-transform: uppercase;
  color: #a7a6a6;
  font-weight: 500;
}

.event-image-name {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.event-image-name .image {
  min-width: 120px;
  max-width: 120px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;
}

.event-image-name .image img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.created-by,
.date {
  font-size: 14px;
  font-weight: 500;
}

.created-by .heading,
.date .heading {
  color: #a7a6a6;
  margin-right: 3px;
  font-weight: 400;
}

.event-label h6 {
  color: #333333;
  font-weight: 400;
  margin: 10px 5px;
  font-size: 16px;
}

.cursor-p {
  cursor: pointer;
}

.cursor-p-null {
  cursor: default !important;
}

.event-label ul li.active {
  background-color: #188ef5;
  color: #ffffff;
}

.event-label ul li.active:before {
  content: "✓";
  position: absolute;
  top: -10px;
  background-color: #ff5b66;
  width: 15px;
  height: 15px;
  font-size: 10px;
  border-radius: 5px;
  text-align: center;
}

.view-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view-details span {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: #188ef5;
  /*cursor: pointer;*/
}

/* .user-counter .counting {
  display: block;
  align-items: center;
  padding-bottom: 8px;
}

.user-counter .counting li span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.user-counter .counting li span:first-child {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 5px;
}

.user-counter .counting li span:last-child {
  font-weight: 400;
  font-size: 13px;
  color: #b4b3b3; */
/* overflow: hidden; */
/* text-overflow: ellipsis; */
/* display: -webkit-box; */
/* -webkit-line-clamp: 1; number of lines to show */
/* -webkit-box-orient: vertical; */
/* } */

.games-section {
  padding: 12px 0;
}

.game-content-section {
  max-width: 350px;
  overflow-x: auto;
}

.game-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game-top .game-heading h6 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.game-top .game-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-content p {
  font-weight: 500;
  font-size: 10px;
  color: #ff5b66;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.actions-content {
  width: 12px;
  position: relative;
}

.game-location {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 7px;
}

.opponent-name h5 {
  font-weight: 500;
  font-size: 13px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.event-card {
  cursor: pointer;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  transition: all 0.7s;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
}

.event-card:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.content .img-wrapper img.default-event-image {
  width: 30px !important;
  height: 30px !important;
  border-radius: 0 0 0 0 !important;
}
.card-event {
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
}
</style>
