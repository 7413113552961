import { render, staticRenderFns } from "./BoxScores.vue?vue&type=template&id=ded9c020&scoped=true&"
import script from "./BoxScores.vue?vue&type=script&lang=js&"
export * from "./BoxScores.vue?vue&type=script&lang=js&"
import style0 from "./BoxScores.vue?vue&type=style&index=0&id=ded9c020&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ded9c020",
  null
  
)

export default component.exports