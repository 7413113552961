<template>
  <div class="profile-friends-wrapper">
    <div class="friends-heading">
      <h6 class="mb-0">
        Friends <span>({{ friendCount }})</span>
      </h6>
      <router-link v-if="friendCount > 0" to="/friends">See All</router-link>
    </div>
    <div class="friends-content" v-if="friendsShort">
      <div class="row no-gutters" v-if="friendsShort.length > 0">
        <div
          class="col-6 col-sm-4 mb-3 text-center"
          v-for="value in friendsShort.slice(0, 6)"
          :key="value.id"
        >
          <router-link :to="/profile/ + value.user_link" exact>
            <div class="friends-content-info">
              <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../assets/loader.gif" alt="">
    </div>
              <img
              @load="onImageLoad"
                v-if="value.picture == 'yes'"
                :src="API_URL + value.id + '/profile_picture.jpg?v=' + time"
                alt=""
              />
              <img @load="onImageLoad" v-else src="../../assets/images/user-default-new.png" alt="" />
            </div>
            <p class="friend-name">{{ value.user_name }}</p>
          </router-link>
        </div>
      </div>
      <div class="info-heading" v-else>
        <p>No friends yet.</p>
      </div>
    </div>
    <content-placeholders :rounded="true" v-else>
      <content-placeholders-heading :img="true" />
    </content-placeholders>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "ProfileFriends",
  data() {
    return {
      friendsShort: "",
      friendCount: "0",
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      imgLoader: 1,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    async friendShort() {
      axios
        .get(`${process.env.VUE_APP_API_URL}friends/friendList`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.friendsShort = response.data.data;
            this.friendCount = response.data.data.length;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
  },
  mounted() {
    this.friendShort();
  },
};
</script>
