<template>
  <div>
    <p class="policy-heading">Terms and Conditions</p>
    <p class="policy-date">Last updated: March 23, 2022</p>
    <p class="policy-description">
      Please read these terms and conditions carefully before using Our Service.

    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>