<template>
  <div>
    <content-placeholders :rounded="true" class="mb-10 content-div" v-if="showApiShimmer == 1">
        <content-placeholders-text
          :lines="1"
          class="content-margin-reaction width-100"
        />
        <content-placeholders-text
          :lines="1"
          class="content-margin-reaction width-100"
        />
    </content-placeholders>

    <div class="event_game" v-if="showApiShimmer == 0 && eventsName || gamesName">
                  {{ eventsName.eventName }}
                  <span
                    v-if="this.eventId != '' || this.gameId != ''"
                    >-</span
                  >
                  {{ gamesName.name }}
                </div>
  </div>
</template>
<script>
import axios from "axios"
export default {
  name: "EventGameName",

  data() {
    return {
      showApiShimmer: 1,
      eventsName: "",
      gamesName: "",
    };
  },
  props: [
    "eventId",
    "gameId"
],
  components: {},
  methods: {

    async getEventAndGameName() {
        
      if (this.eventId != "") {
        await axios
          .post(
            process.env.VUE_APP_API_URL +
              `event/eventFormedia?eventId=${this.eventId}`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.eventsName = response.data.data;
            } else {
              this.errorMessage = response.data.message;
            }
          });
      }
      if (this.gameId != "") {
        await axios
          .post(
            process.env.VUE_APP_API_URL +
              `game/gameFormedia?gameId=${this.gameId}`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.gamesName = response.data.data;
            } else {
              this.errorMessage = response.data.message;
            }
          });
      }
      this.showApiShimmer = 0;
    },
  },
  mounted() {
    this.getEventAndGameName();
  },
};
</script>
<style>
.content-div{
    display: flex;
}
</style>
