module.exports = [
  {name: "Afghanistan", code: "AF", gmt: "(UTC+04:30)"},
{name: "Albania", code: "AL", gmt: "(UTC+01:00)"},
{name: "Algeria", code: "DZ", gmt: "(UTC+01:00)"},
{name: "Argentina", code: "AR", gmt: "(UTC-03:00)"},
{name: "Armenia", code: "AM", gmt: "(UTC+04:00)"},
{name: "Australia", code: "AU", gmt: "(UTC+11:00)"},
{name: "Austria", code: "AT", gmt: "(UTC+01:00)"},
{name: "Azerbaijan", code: "AZ", gmt: "(UTC+04:00)"},
{name: "Bahrain", code: "BH", gmt: "(UTC+03:00)"},
{name: "Bangladesh", code: "BD", gmt: "(UTC+06:00)"},
{name: "Belarus", code: "BY", gmt: "(UTC+03:00)"},
{name: "Belgium", code: "BE", gmt: "(UTC+01:00)"},
{name: "Belize", code: "BZ", gmt: "(UTC-06:00)"},
{name: "Bhutan", code: "BT", gmt: "(UTC+06:00)"},
{name: "Bolivia", code: "BO", gmt: "(UTC-04:00)"},
{name: "Bosnia and Herzegovina", code: "BA", gmt: "(UTC+01:00)"},
{name: "Botswana", code: "BW", gmt: "(UTC+02:00)"},
{name: "Brazil", code: "BR", gmt: "(UTC-02:00)"},
{name: "Brunei", code: "BN", gmt: "(UTC+08:00)"},
{name: "Bulgaria", code: "BG", gmt: "(UTC+02:00)"},
{name: "Cambodia", code: "KH", gmt: "(UTC+07:00)"},
{name: "Cameroon", code: "CM", gmt: "(UTC+01:00)"},
{name: "Canada", code: "CA", gmt: "(UTC-03:30)"},
{name: "Chile", code: "CL", gmt: "(UTC-04:00)"},
{name: "China", code: "CN", gmt: "(UTC+08:00)"},
{name: "Colombia", code: "CO", gmt: "(UTC-05:00)"},
{name: "Congo (DRC)", code: "CD", gmt: "(UTC+01:00)"},
{name: "Costa Rica", code: "CR", gmt: "(UTC-06:00)"},
{name: "Côte d’Ivoire", code: "CI", gmt: "(UTC+00:00)"},
{name: "Croatia", code: "HR", gmt: "(UTC+01:00)"},
{name: "Cuba", code: "CU", gmt: "(UTC-05:00)"},
{name: "Czech Republic", code: "CZ", gmt: "(UTC+01:00)"},
{name: "Denmark", code: "DK", gmt: "(UTC+01:00)"},
{name: "Djibouti", code: "DJ", gmt: "(UTC+03:00)"},
{name: "Dominican Republic", code: "DO", gmt: "(UTC-04:00)"},
{name: "Ecuador", code: "EC", gmt: "(UTC-05:00)"},
{name: "Egypt", code: "EG", gmt: "(UTC+02:00)"},
{name: "El Salvador", code: "SV", gmt: "(UTC-06:00)"},
{name: "Eritrea", code: "ER", gmt: "(UTC+03:00)"},
{name: "Estonia", code: "EE", gmt: "(UTC+02:00)"},
{name: "Ethiopia", code: "ET", gmt: "(UTC+03:00)"},
{name: "Faroe Islands", code: "FO", gmt: "(UTC+00:00)"},
{name: "Finland", code: "FI", gmt: "(UTC+02:00)"},
{name: "France", code: "FR", gmt: "(UTC+01:00)"},
{name: "Georgia", code: "GE", gmt: "(UTC+04:00)"},
{name: "Germany", code: "DE", gmt: "(UTC+01:00)"},
{name: "Greece", code: "GR", gmt: "(UTC+02:00)"},
{name: "Greenland", code: "GL", gmt: "(UTC-03:00)"},
{name: "Guatemala", code: "GT", gmt: "(UTC-06:00)"},
{name: "Haiti", code: "HT", gmt: "(UTC-05:00)"},
{name: "Honduras", code: "HN", gmt: "(UTC-06:00)"},
{name: "Hong Kong SAR", code: "HK", gmt: "(UTC+08:00)"},
{name: "Hungary", code: "HU", gmt: "(UTC+01:00)"},
{name: "Iceland", code: "IS", gmt: "(UTC+00:00)"},
{name: "India", code: "IN", gmt: "(UTC+05:30)"},
{name: "Indonesia", code: "ID", gmt: "(UTC+07:00)"},
{name: "Iran", code: "IR", gmt: "(UTC+03:30)"},
{name: "Iraq", code: "IQ", gmt: "(UTC+03:00)"},
{name: "Ireland", code: "IE", gmt: "(UTC+00:00)"},
{name: "Israel", code: "IL", gmt: "(UTC+02:00)"},
{name: "Italy", code: "IT", gmt: "(UTC+01:00)"},
{name: "Jamaica", code: "JM", gmt: "(UTC-05:00)"},
{name: "Japan", code: "JP", gmt: "(UTC+09:00)"},
{name: "Jordan", code: "JO", gmt: "(UTC+02:00)"},
{name: "Kazakhstan", code: "KZ", gmt: "(UTC+06:00)"},
{name: "Kenya", code: "KE", gmt: "(UTC+03:00)"},
{name: "Korea", code: "KR", gmt: "(UTC+09:00)"},
{name: "Kuwait", code: "KW", gmt: "(UTC+03:00)"},
{name: "Kyrgyzstan", code: "KG", gmt: "(UTC+06:00)"},
{name: "Laos", code: "LA", gmt: "(UTC+07:00)"},
{name: "Latvia", code: "LV", gmt: "(UTC+02:00)"},
{name: "Lebanon", code: "LB", gmt: "(UTC+02:00)"},
{name: "Libya", code: "LY", gmt: "(UTC+02:00)"},
{name: "Liechtenstein", code: "LI", gmt: "(UTC+01:00)"},
{name: "Lithuania", code: "LT", gmt: "(UTC+02:00)"},
{name: "Luxembourg", code: "LU", gmt: "(UTC+01:00)"},
{name: "Macao SAR", code: "MO", gmt: "(UTC+08:00)"},
{name: "Macedonia, FYRO", code: "MK", gmt: "(UTC+01:00)"},
{name: "Malaysia", code: "MY", gmt: "(UTC+08:00)"},
{name: "Maldives", code: "MV", gmt: "(UTC+05:00)"},
{name: "Mali", code: "ML", gmt: "(UTC+00:00)"},
{name: "Malta", code: "MT", gmt: "(UTC+01:00)"},
{name: "Mexico", code: "MX", gmt: "(UTC-06:00)"},
{name: "Moldova", code: "MD", gmt: "(UTC+02:00)"},
{name: "Monaco", code: "MC", gmt: "(UTC+01:00)"},
{name: "Mongolia", code: "MN", gmt: "(UTC+08:00)"},
{name: "Montenegro", code: "ME", gmt: "(UTC+01:00)"},
{name: "Morocco", code: "MA", gmt: "(UTC+00:00)"},
{name: "Myanmar", code: "MM", gmt: "(UTC+06:30)"},
{name: "Nepal", code: "NP", gmt: "(UTC+05:45)"},
{name: "Netherlands", code: "NL", gmt: "(UTC+01:00)"},
{name: "New Zealand", code: "NZ", gmt: "(UTC+12:00)"},
{name: "Nicaragua", code: "NI", gmt: "(UTC-06:00)"},
{name: "Nigeria", code: "NG", gmt: "(UTC+01:00)"},
{name: "Norway", code: "NO", gmt: "(UTC+01:00)"},
{name: "Oman", code: "OM", gmt: "(UTC+04:00)"},
{name: "Pakistan", code: "PK", gmt: "(UTC+05:00)"},
{name: "Panama", code: "PA", gmt: "(UTC-05:00)"},
{name: "Paraguay", code: "PY", gmt: "(UTC-04:00)"},
{name: "Peru", code: "PE", gmt: "(UTC-05:00)"},
{name: "Philippines", code: "PH", gmt: "(UTC+08:00)"},
{name: "Poland", code: "PL", gmt: "(UTC+01:00)"},
{name: "Portugal", code: "PT", gmt: "(UTC+00:00)"},
{name: "Puerto Rico", code: "PR", gmt: "(UTC-04:00)"},
{name: "Qatar", code: "QA", gmt: "(UTC+03:00)"},
{name: "Réunion", code: "RE", gmt: "(UTC+04:00)"},
{name: "Romania", code: "RO", gmt: "(UTC+02:00)"},
{name: "Russia", code: "RU", gmt: "(UTC+02:00)"},
{name: "Rwanda", code: "RW", gmt: "(UTC+02:00)"},
{name: "Saudi Arabia", code: "SA", gmt: "(UTC+03:00)"},
{name: "Senegal", code: "SN", gmt: "(UTC+00:00)"},
{name: "Serbia", code: "RS", gmt: "(UTC+01:00)"},
{name: "Singapore", code: "SG", gmt: "(UTC+08:00)"},
{name: "Slovakia", code: "SK", gmt: "(UTC+01:00)"},
{name: "Slovenia", code: "SI", gmt: "(UTC+01:00)"},
{name: "Somalia", code: "SO", gmt: "(UTC+03:00)"},
{name: "South Africa", code: "ZA", gmt: "(UTC+02:00)"},
{name: "Spain", code: "ES", gmt: "(UTC+01:00)"},
{name: "Sri Lanka", code: "LK", gmt: "(UTC+05:30)"},
{name: "Sweden", code: "SE", gmt: "(UTC+01:00)"},
{name: "Switzerland", code: "CH", gmt: "(UTC+01:00)"},
{name: "Syria", code: "SY", gmt: "(UTC+02:00)"},
{name: "Taiwan", code: "TW", gmt: "(UTC+08:00)"},
{name: "Tajikistan", code: "TJ", gmt: "(UTC+05:00)"},
{name: "Thailand", code: "TH", gmt: "(UTC+07:00)"},
{name: "Trinidad and Tobago", code: "TT", gmt: "(UTC-04:00)"},
{name: "Tunisia", code: "TN", gmt: "(UTC+01:00)"},
{name: "Turkey", code: "TR", gmt: "(UTC+02:00)"},
{name: "Turkmenistan", code: "TM", gmt: "(UTC+05:00)"},
{name: "Ukraine", code: "UA", gmt: "(UTC+02:00)"},
{name: "United Arab Emirates", code: "AE", gmt: "(UTC+04:00)"},
{name: "United Kingdom", code: "GB", gmt: "(UTC+00:00)"},
{name: "United States", code: "US", gmt: "(UTC-05:00)"},
{name: "Uruguay", code: "UY", gmt: "(UTC-03:00)"},
{name: "Uzbekistan", code: "UZ", gmt: "(UTC+05:00)"},
{name: "Venezuela", code: "VE", gmt: "(UTC-04:00)"},
{name: "Vietnam", code: "VN", gmt: "(UTC+07:00)"},
{name: "Yemen", code: "YE", gmt: "(UTC+03:00)"},
{name: "Zimbabwe", code: "ZW", gmt: "(UTC+02:00)"}
];