<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-md-3 pb-3">
        <EventDetail />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import EventDetail from "../components/SingleEventDetail/EventDetail.vue";
export default {
  name: "SingleEventDetail",
  components: {
    Navbar,
    LeftMenu,
    Banner,
    EventDetail,
  },
};
</script>