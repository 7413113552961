<template>
  <div class="profile-photos-wrapper mb-3">
    <div class="photos-heading">
      <h6 class="mb-0">Photos</h6>
      <router-link
        v-if="photos.length > 0"
        :to="/otherPhotos/ + lastSegment"
        exact
        >See All</router-link
      >
    </div>
    <div class="photos-content p-0">
      <div class="post-images my-0" v-if="showLoader == 0">
        <div class="gallery-container" v-if="photos.length > 0">
          <lightbox :items="photos" class="newPostC" />
        </div>
        <div class="info-heading" v-else>
          <p>No photos found.</p>
        </div>
      </div>
      <div v-if="showLoader == 1">
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Lightbox from "../ImageGrid/lightbox.vue";
import "../ImageGrid/lightbox";
import "../ImageGrid/lightbox.css";
export default {
  components: { Lightbox },
  name: "ProfilePhotos",
  data() {
    return {
      errorMessage: null,
      successMessage: null,
      photos: [],
      page: 1,
      noPost: 0,
      showLoader: 0,
      details: {
        post_id: "",
      },
      lastPage: 1,
      readMoreActivated: false,
      API_URL: this.$userProfileStorageURL,
      postOptions: false,
      lastSegment: "",
    };
  },
  methods: {
    async fetchPhotos() {
      this.showLoader = 1;
      let posts = await axios.get(
        `post/otherProfilePictures?userLink=${this.lastSegment}`
      );
      if (posts.data.statusCode == 200) {
        this.photos = posts.data.data.profileImages;
        this.showLoader = 0;
      } else {
        this.errorMessage = posts.data.message;
        this.showLoader = 0;
      }
    },
    getuserLink() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.lastSegment = segment_array.pop();
      if (this.lastSegment !== "profileOther") {
        this.fetchPhotos();
      }
    },
  },
  mounted() {
    this.getuserLink();
    this.$root.$on("ProfilePhotos", () => {
      this.fetchPhotos(1);
    });
  },
};
</script>
