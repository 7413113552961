<template>
  <div class="post-view">
    <div class="post-content" v-if="items">
      <div class="user-details m-0">
        <a v-bind:href="items.link" target="_blank" style="color:black">
            <div class="user-name ml-0"
              > {{ items.title }}</div
            >
            </a>
          </div>
    <p class="m-0 mb-2 p-0">
      <span class="wrappper" v-if="!readMoreActivated">{{
        items.desc.slice(0, 200)
      }}</span>
      <a
        class="text-primary m-0"
        v-if="!readMoreActivated"
        @click="activateReadMore"
      >
        <span v-if="totalcharacter >= 200" class="cursor-pointer ml-1">
          (See More)</span
        >
      </a>
      <span class="wrappper" v-if="readMoreActivated" v-html="items.desc"></span>
      <a
        class="text-primary font-weight-bold cursor-pointer"
        v-if="readMoreActivated"
        @click="activateReadMore"
      >
        <span class="ml-1">(See Less)</span>
      </a>
    </p>
    </div>
      <div>
        <a v-bind:href="items.link" target="_blank">
       <img
       class="adImg"
      v-if="items.image != null"
      :src="API_URL + items.image"
      alt=""
    />
    <img v-else src="../../assets/ads.jpg" alt="" />
    </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdsOnPro",
  props: {
    items: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      readMoreActivated: false,
      totalcharacter: 0,
      API_URL: this.$adBannerStorageURL,
    };
  },

  methods: {
    activateReadMore() {
      this.readMoreActivated = !this.readMoreActivated;
      this.charCount();
    },
    charCount: function () {
      if (this.items.desc) {
        this.totalcharacter = this.items.desc.length;
      }
    },
  },
  mounted() {
    this.charCount(1);
  },
};
</script>
<style scoped>
.adImg{
  width: 100%;
}
.new {
  width: 100%;
  border-radius: 5px;
  height: 120px;
  overflow: hidden;
  margin: 20px 0px;
}

.new a {
  margin: 0;
}
.new img {
  width: 100%;
  background-position: center;
   border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
</style>