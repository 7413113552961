<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="d-block p-3 bg-white">
          <EditMessage
            :messageId="messageId"
            :showLoader1="showLoader1"
            :showReplyLoader="showReplyLoader"
            :showLoader="showLoader"
            :messageData="messageData"
            :replies="replies"
            :currentIsAdmin="currentIsAdmin"
            :currentIsUmpire="currentIsUmpire"
            :page="page"
            :msgLastPage="msgLastPage"
            @toggleReplyLoader="toggleReplyLoader"
            @page-increment="handleRepliesIncrement"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import axios from "axios";
import EditMessage from "../components/Association/EditMessage.vue";

export default {
  name: "MessagesBoard",
  components: { Navbar, LeftMenu, Banner, EditMessage },
  data() {
    return {
      messageId: null,
      messageData: {},
      replies: [],
      currentIsAdmin: "0",
      currentIsUmpire:"0",
      showLoader: 1,
      showLoader1: 1,
      page: 1,
      msgLastPage: 1,
      showReplyLoader: 1,
    };
  },
  props: {
    events: {
      type: Array,
    },
  },
  created() {
 
    this.fetchMessageId();
  },
  methods: {
    handleRepliesIncrement() {
      this.page++; // Update the page prop
      this.fetchReplies();
    },
    async fetchMessageId() {
      try {
        this.messageId = String(this.$route.params.messageId); 
        await this.fetchMessageData();
      } catch (error) {
        console.error("Failed to fetch message ID:", error);
      }
    },
    async fetchMessageData() {
      this.showLoader = 1;
      try {
        const url = `${process.env.VUE_APP_API_URL}associationEvent/viewMessage`;

        const response = await axios.post(url, {
          message_id: this.messageId,
        });

        if (response.data.statusCode === 200) {
          this.messageData = response.data.data;
          this.currentIsAdmin=(response.data.data.user_association_role)?(response.data.data.user_association_role.role_id==1)?'1':'0':'0';
          this.currentIsUmpire=(response.data.data.user_association_role)?(response.data.data.user_association_role.role_id==2)?'1':'0':'0';
         
          this.showLoader = 0;
          this.fetchReplies();
        } else {
          console.error(
            "Failed to fetch message data:",
            response.data.message || "Unknown error occurred"
          );
          this.showLoader = 0;
        }
      } catch (error) {
        this.showLoader = 0;
        console.error("Failed to fetch message data:", error);
      }
    },
    async fetchReplies() {
  this.showLoader1 = 1;
  this.showReplyLoader = 1;
  console.log(this.messageData.id);
  try {
    const url = `${process.env.VUE_APP_API_URL}associationEvent/getReplies`;
    const response = await axios.post(url, {
      parent_id: this.messageData.id,
      page: this.page, // Include the page parameter
    });

    if (response.data.statusCode === 200) {
      const newReplies = response.data.data.data;
      const lastPage = response.data.data.last_page;

      // Check if page > 1
      if (this.page > 1) {
        // Append new replies to existing ones
        this.replies = [...this.replies, ...newReplies];
      } else {
        // Clear the array and replace with new replies
        this.replies = newReplies;
      }

      // Update last page
      this.msgLastPage = lastPage;

      this.showLoader1 = 0;
      this.showReplyLoader = 0;
    } else {
      console.error(
        "Failed to fetch replies:",
        response.data.message || "Unknown error occurred"
      );
      this.showLoader1 = 0;
      this.showReplyLoader = 0;
    }
  } catch (error) {
    this.showLoader1 = 0;
    console.error("Failed to fetch replies:", error);
  }
},
toggleReplyLoader(showLoader){
  this.showReplyLoader = showLoader;
},

  },
  mounted() {
    this.$root.$on("editMessageChange", () => {
      this.fetchMessageData();
    });
    this.$root.$on("fetchRepliesEmit", () => {
      this.fetchReplies();
      // this.fetchMessageData();
    });
    this.$root.$on("replies-increment", () => {
      this.handleRepliesIncrement();
    });
    this.$root.$on("fetchReplyAfterDelation", () => {
      this.fetchReplies();
    });

    // this.fetchMessageData();
  
  },
};
</script>
