<template>
  <div class="innning-total-wrapper">
    <div class="table-innings">
      <table class="table h-100 mb-0">
        <thead>
          <tr>
            <th scope="col" class="pl-0" v-for="n in totalInnings - 1" :key="n + 2000">
               {{ n }}
            </th>
            <th scope="col" class="pl-0">{{ totalInnings }}</th>
          </tr>
        </thead>
        <tbody>
          <!-- VISIT SCORE AFTER END GAME -->
          <tr>
            <td
              v-for="inning in previousInningVisitScore"
              :key="inning.id + 3000"
              class=""
            >
              {{ (inning.score==null)?'-':inning.score }}
            </td>
            <td class="">
              {{ (currentInningVisitScore==null)?'-':currentInningVisitScore }}
            </td>
          </tr>

          <!-- VISIT HR AFTER END GAME -->
          <tr>
            <td
              v-for="inning in previousInningVisitHR"
              :key="inning.id + 4000"
              class=""
            >
              {{ (inning.score==null)?'-':inning.score }}
            </td>
            <td class="">
              {{  }}
              {{ (currentInningVisitHR==null)?'-':currentInningVisitHR }}
            </td>
          </tr>

          <!-- HOME SCORE AFTER END GAME -->
          <tr>
            <td
              v-for="inning in previousInningHomeScore"
              :key="inning.id + 5000"
              class=""
            >
              {{ (inning.score==null)?'-':inning.score }}
            </td>
            <td class="">
              {{ (currentInningHomeScore==null)?'-':currentInningHomeScore }}
            </td>
          </tr>

          <!-- HOME HR AFTER END GAME -->
          <tr>
            <td
              v-for="inning in previousInningHomeHR"
              :key="inning.id + 6000"
              class=""
            >
              {{ (inning.score==null)?'-':inning.score }}
            </td>
            <td class="">
              {{ (currentInningHomeHR==null)?'-':currentInningHomeHR }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Total -->
    <table class="table table-total h-100">
      <thead>
        <tr>
          <th scope="col" class="pl-0">Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="">{{ totalVisitScore }}</td>
        </tr>
        <tr>
          <td class="">{{ totalVisitHR }}</td>
        </tr>
        <tr>
          <td class="">{{ totalHomeScore }}</td>
        </tr>
        <tr>
          <td class="">{{ totalHomeHR }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "InningTournament",
  data: function() {
    return {
      singleScore:[],
      previousInningVisitScore: [],
      previousInningVisitHR: [],
      previousInningHomeScore: [],
      previousInningHomeHR: [],
      currentInningVisitScore: 0,
      currentInningVisitHR: 0,
      currentInningHomeScore: 0,
      currentInningHomeHR: 0,
      homeTeamName: "",
      visitTeamName: "",
      totalHomeHR: 0,
      totalVisitHR: 0,
      totalHomeScore: 0,
      totalVisitScore: 0,
      totalInnings: 1,
      visitScoreId: 0,
      visitHRId: 0,
      homeScoreId: 0,
      homeHRId: 0,
    };
  },
  watch: {
    previousInnings(newValue) {
      this.previousInnings = newValue;
    },
  },
  methods: {},
  mounted() 
  {
  this.$root.$on("openBoxViewInningModal", (score) => {
    this.singleScore = [];
    this.singleScore = score.score;
    this.totalInnings = 1;
    this.previousInningHomeScore = [];
    this.previousInningHomeHR = [];
    this.totalHomeScore = 0;
    this.totalHomeHR = 0;
    this.currentInningHomeScore = 0;
    this.currentInningHomeHR = 0;
    this.homeScoreId = 0;
    this.homeHRId = 0;

    this.previousInningVisitScore = [];
    this.previousInningVisitHR = [];
    this.totalVisitScore = 0;
    this.totalVisitHR = 0;
    this.currentInningVisitScore = 0;
    this.currentInningVisitHR = 0;
    this.visitScoreId = 0;
    this.visitHRId = 0;

    if(this.singleScore!=[])
    {
        const inning = this.singleScore[0].game_innings.length;
        this.totalInnings = inning / 2;
    }

    // Visiting
    this.singleScore.forEach((gameS) => {
      if (gameS.team_type == 1) {
        this.visitTeamName = "";
        this.visitTeamName = gameS.team.team_name;
        gameS.game_innings.forEach((inning) => {
          // Previous Inning Score
                if (inning.inning_type == 2 && inning.inning_status == 1) {
                  this.totalVisitScore =(inning.score!=null)?
                    parseInt(this.totalVisitScore) + parseInt(inning.score):this.totalVisitScore;
                  this.previousInningVisitScore.push(inning);
                }
                // Previous Inning HR
                else if (inning.inning_type == 1 && inning.inning_status == 1) {
                  this.totalVisitHR =(inning.score!=null)?
                    parseInt(this.totalVisitHR) + parseInt(inning.score):this.totalVisitHR;
                  this.previousInningVisitHR.push(inning);
                }
                // Current Inning Score
                if (inning.inning_type == 2 && inning.inning_status == 2) {
                  this.visitScoreId = inning.id;
                  this.currentInningVisitScore = inning.score;
                  this.totalVisitScore =(inning.score!=null)?
                    parseInt(this.totalVisitScore) + parseInt(inning.score):this.totalVisitScore;
                }
                // Current Inning HR
                else if (inning.inning_type == 1 && inning.inning_status == 2) {
                  this.visitHRId = inning.id;
                  this.currentInningVisitHR = inning.score;
                  this.totalVisitHR =(inning.score!=null)?
                    parseInt(this.totalVisitHR) + parseInt(inning.score):this.totalVisitHR;
                }
        });
      }
    });

    // Home
    this.singleScore.forEach((gameS) => {
      if (gameS.team_type == 2) {
        this.homeTeamName = "";
        this.homeTeamName = gameS.team.team_name;
        gameS.game_innings.forEach((inning) => {
          // Previous Inning Score
                if (inning.inning_type == 2 && inning.inning_status == 1) {
                  this.totalHomeScore =(inning.score!=null)?
                    parseInt(this.totalHomeScore) + parseInt(inning.score):this.totalHomeScore;
                  this.previousInningHomeScore.push(inning);
                }
                // Previous Inning HR
                else if (inning.inning_type == 1 && inning.inning_status == 1) {
                  this.totalHomeHR =(inning.score!=null)?
                    parseInt(this.totalHomeHR) + parseInt(inning.score):this.totalHomeHR;
                  this.previousInningHomeHR.push(inning);
                }
                //  Current Inning Score
                if (inning.inning_type == 2 && inning.inning_status == 2) {
                  this.homeScoreId = inning.id;
                  this.currentInningHomeScore = inning.score;
                  this.totalHomeScore =(inning.score!=null)?
                    parseInt(this.totalHomeScore) + parseInt(inning.score):this.totalHomeScore;
                }
                // Current Inning HR
                else if (inning.inning_type == 1 && inning.inning_status == 2) {
                  this.homeHRId = inning.id;
                  this.currentInningHomeHR = inning.score;
                  this.totalHomeHR =(inning.score!=null)?
                    parseInt(this.totalHomeHR) + parseInt(inning.score):this.totalHomeHR;
                }
        });
      }
    });

    });
  },
};
</script>

<style scoped>
.innning-total-wrapper {
  width: calc(100% - 30%);
  display: flex;
  overflow: hidden;
  -webkit-border-radius: 0 11px 11px 0;
  -moz-border-radius: 0 11px 11px 0;
  -ms-border-radius: 0 11px 11px 0;
  -o-border-radius: 0 11px 11px 0;
  border-radius: 0 11px 11px 0;
}

.table-innings {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  width: 70%;
  overflow-x: auto;
}

.table-total {
  width: 30%;
}

.table {
  text-align: center;
  font-size: 13px;
  background-color: #e5f2fe;

}

.table-innings {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
}

.table thead th {
  background-color: #e5f2fe !important;
}

.table-innings::-webkit-scrollbar {
  height: 7.5px;
}

.table-innings::-webkit-scrollbar-track {
  background-color: none;
}

.table-innings::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

@media screen and (max-width: 576px) {
  .table {
    font-size: 8px;
  }

  .table th {
    padding: 0.75rem 0.3rem;
  }

  .table.table-total td {
    background-color: #e5f2fe !important;
  }
  .innning-total-wrapper {
    width: calc(100% - 50%);
  }
}

.table tbody + tbody {
  border: none !important;
}

.table {
  text-align: center;
  font-size: 13px;
  background-color: #e5f2fe;
}

.table thead th,
.table tbody td {
  height: 40px;
}

.table tbody + tbody {
  border: none !important;
}
.table thead th {
    background-color: #e5f2fe !important;
    border-bottom: 0px!important;
}
.game_name11 {
  font-size: 17px;
  color: #188ef5 !important;
}

</style>
