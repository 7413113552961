<template>
  <div>
    <div class="single-event-page bg-white">
      <AssociationEventTop :events="events" :currentIsAdmin="currentIsAdmin" :eventId="eventId" :showLoader="showLoader" />
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{
              active: currentTab === 'detail',
              disabled: isLoaded.detail,
            }"
            id="detail-tab"
            data-toggle="tab"
            data-target="#detail"
            type="button"
            role="tab"
            aria-controls="detail"
            aria-selected="true"
            @click="selectTab('detail')"
          >
            Details
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{ active: currentTab === 'team', disabled: isLoaded.team }"
            id="team-tab"
            data-toggle="tab"
            data-target="#team"
            type="button"
            role="tab"
            aria-controls="team"
            aria-selected="false"
            @click="selectTab('team')"
          >
            Teams
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{
              active: currentTab === 'tournament',
              disabled: isLoaded.tournament,
            }"
            id="tournament-tab"
            data-toggle="tab"
            data-target="#tournament"
            type="button"
            role="tab"
            aria-controls="tournament"
            aria-selected="false"
            @click="selectTab('tournament')"
          >
            Tournaments
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{
              active: currentTab === 'umpire',
              disabled: isLoaded.umpire,
            }"
            id="umpire-tab"
            data-toggle="tab"
            data-target="#umpire"
            type="button"
            role="tab"
            aria-controls="umpire"
            aria-selected="false"
            @click="selectTab('umpire')"
          >
            Umpires
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{
              active: currentTab === 'message',
              disabled: isLoaded.message,
            }"
            id="message-tab"
            data-toggle="tab"
            data-target="#message"
            type="button"
            role="tab"
            aria-controls="message"
            @click="selectTab('message')"
            aria-selected="false"
          >
            Message Board
          </button>
        </li>
      </ul>
      <div class="tab-content mt-4" id="myTabContent">
        <div
          class="tab-pane fade show active"
          :class="{ 'show active': currentTab === 'detail' }"
          id="detail"
          role="tabpanel"
          aria-labelledby="detail-tab"
        >
          <AssociationEventInfo
            :events="events"
            :eventId="eventId"
            :showLoader="showLoader"
            :eventSponsors="eventSponsors"
            :currentIsAdmin="currentIsAdmin"
            :eventHotels="eventHotels"
            :sponsorLoader="sponsorLoader"
            :hotelLoader="hotelLoader"
            :eventPlayingFacility="eventPlayingFacility"
            :playingFacilityLoader="playingFacilityLoader"
            v-if="currentTab === 'detail'"
          />
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'show active': currentTab === 'team' }"
          id="team"
          role="tabpanel"
          aria-labelledby="team-tab"
        >
          <AssociationTeamsInfo
            :eventId="eventId"
            :eventName="eventName"
            :association_id="association_id "
            :showLoaderTeam="showLoaderTeam"
            :currentIsAdmin="currentIsAdmin"
            :page="page"
            :lastPage="lastPage"
            :joinedTeams="joinedTeams"
            v-if="currentTab === 'team'"
          />
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'show active': currentTab === 'tournament' }"
          id="tournament"
          role="tabpanel"
          aria-labelledby="tournamnet-tab"
        >
          <AssocaitionTournamentInfo
            :tournamentsData="tournamentsData"
            :eventId="eventId"
            :currentIsAdmin="currentIsAdmin"
            :showLoaderTournament="showLoaderTournament"
            :page="page"
            :lastPage="lastPage"
            v-if="currentTab === 'tournament'"
          />
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'show active': currentTab === 'umpire' }"
          id="umpire"
          role="tabpanel"
          aria-labelledby="umpire-tab"
        >
          <AssociationUmpiresInfo
            :eventId="eventId"
            :association_id ="association_id"
            :umpireData="umpireData"
            :showLoaderUmpire="showLoaderUmpire"
            :umpirepage="umpirepage"
            :umpirelastPage="umpirelastPage"
            :currentIsAdmin="currentIsAdmin"
            v-if="currentTab === 'umpire'"
          />
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'show active': currentTab === 'message' }"
          id="message"
          role="tabpanel"
          aria-labelledby="message-tab"
        >
          <AsscoaitionMessageBoard
            :eventId="eventId"
            :messageBodyData="messageBodyData"
            :messageTopics="messageTopics"
            :showLoaderMessage="showLoaderMessage"
            :currentIsAdmin="currentIsAdmin"
            :currentIsUmpire="currentIsUmpire"
            :messagepage="messagepage"
            :msgLastPage="msgLastPage"
            v-if="currentTab === 'message'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AsscoaitionMessageBoard from "./AsscoaitionMessageBoard.vue";
import AssocaitionTournamentInfo from "./AssocaitionTournamentInfo.vue";
import AssociationEventInfo from "./AssociationEventInfo.vue";
import AssociationEventTop from "./AssociationEventTop.vue";
import AssociationTeamsInfo from "./AssociationTeamsInfo.vue";
import AssociationUmpiresInfo from "./AssociationUmpiresInfo.vue";
export default {
  components: {
    AssociationTeamsInfo,
    AssociationEventInfo,
    AssocaitionTournamentInfo,
    AssociationUmpiresInfo,
    AsscoaitionMessageBoard,
    AssociationEventTop,
  },
  name: "AssociationSingleEvent",
  data() {
    return {
      sponsorLoader: 1,
      hotelLoader: 1,
      playingFacilityLoader: 1,
      events: {},
      editEventOption: false,
      tournamentsData: [],
      eventSponsors: [],
      eventHotels: [],
      showLoader: 1,
      showLoaderTeam: 1,
      showLoaderTournament: 1,
      showLoaderUmpire: 1,
      showLoaderMessage: 1,
      joinedTeams: [],
      umpireData: [],
      msgLastPage: 1,
      messageBodyData: [],
      association_id : 0,
      currentIsAdmin: "0",
      eventName: "",
      isAdminAssociation: false,
      currentIsUmpire:"0",
      messageTopics: [],
      eventPlayingFacility: [],
      page: 1,
      lastPage: 1,
      eventId: 0,
      currentTab: "detail",
      umpirepage : 1,
      umpirelastPage:1,
      messagepage : 1,
      isLoaded: {
        detail: false,
        team: false,
        tournament: false,
        umpire: false,
        message: false,
      },
    };
  },
  methods: {
    openEditEventOption() {
      this.editEventOption = !this.editEventOption;
    },
    onClickOutside() {
      this.editEventOption = false;
    },
    handleUmpireTabClick() {
      this.$root.$emit("umpire-tab-clicked");
    },
    handleMessageBoardTabClick() {
      this.$root.$emit("message-Board-tab-clicked");
    },
    async EventsDetailData() {
      this.isLoaded.detail = true;
      this.isLoaded.team = true;
      this.isLoaded.tournament = true;
      this.isLoaded.umpire = true;
      this.isLoaded.message = true;
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const eventGuid = segment_array.pop();
      this.showLoader = 1;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}event/tournamentListForAdminEvents?page=${this.page}`,
          { event_guid: eventGuid }
        );

        if (response.data.statusCode === 200) {
          this.events = response.data.data.teamEventData;
          this.association_id = response.data.data?.user_association_role?.association_id;
          // console.log(this.association_id ? this.association_id : "none");
          this.currentIsAdmin=(response.data.data.user_association_role)?(response.data.data.user_association_role.role_id==1)?'1':'0':'0';
          this.currentIsUmpire=(response.data.data.user_association_role)?(response.data.data.user_association_role.role_id==2)?'1':'0':'0';
          this.teamId = response.data.data.teamEventData.team_id;
          this.eventId = this.events.id;
          this.eventName = this.events.eventName;
          this.getPlayingFacility(this.eventId);
          this.getEventSponsor(this.eventId);
          this.getEventHotel(this.eventId);

          this.tournamentsData = response.data.data.eventTournaments.data;

          if (this.tournamentsData.length > 0) {
            const tournamentId = this.tournamentsData[0].id;
            localStorage.setItem("tournament_id", tournamentId);
          }

          this.lastPage = response.data.data.tournamentsData.last_page;

          this.showLoader = 0;
        }
        //  else if (response.data.statusCode === 404) {
        //   this.$router.push({ name: "PageNotFound" });
        //   this.showLoader = 0;
        // }
         else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
      } finally {
        this.showLoader = 0;
      }
    },
    async getEventSponsor(event_id) {
      try {
        this.sponsorLoader = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}associationEvent/getSponsor?event_id=${event_id}`
        );

        if (response.data.statusCode === 200) {
          this.eventSponsors = response.data.data;
          this.sponsorLoader = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.sponsorLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.sponsorLoader = 0;
      }
      this.isLoaded.detail = false;
      this.isLoaded.team = false;
      this.isLoaded.tournament = false;
      this.isLoaded.umpire = false;
      this.isLoaded.message = false;
    },
    async getEventHotel(event_id) {
      try {
        this.hotelLoader = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}associationEvent/getEventHotels?event_id=${event_id}`
        );

        if (response.data.statusCode === 200) {
          this.eventHotels = response.data.data;
          this.hotelLoader = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.hotelLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.hotelLoader = 0;
      }
      this.isLoaded.detail = false;
      this.isLoaded.team = false;
      this.isLoaded.tournament = false;
      this.isLoaded.umpire = false;
      this.isLoaded.message = false;
    },
    async getPlayingFacility(event_id) {
      try {
        this.playingFacilityLoader = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}associationEvent/getEventPlayingFacility?event_id=${event_id}`
        );

        if (response.data.statusCode === 200) {
          this.eventPlayingFacility = response.data.data.parkDetails;
          this.playingFacilityLoader = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.playingFacilityLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.playingFacilityLoader = 0;
      }
    },

    async getJoinTeams() {
      this.isLoaded.detail = true;
      this.isLoaded.team = true;
      this.isLoaded.tournament = true;
      this.isLoaded.umpire = true;
      this.isLoaded.message = true;
      this.showLoaderTeam = 1;
      try {
        const url = `${process.env.VUE_APP_API_URL}associationEvent/getJoinedTeam?page=${this.page}&event_id=${this.eventId}`;
        const response = await axios.get(url);
        if (response.data.statusCode === 200) {
          this.joinedTeams = response.data.data;
          this.showLoaderTeam = 0;
        } else {
          console.error(
            "Failed to fetch umpires:",
            response.data.message || "Unknown error occurred"
          );
          this.showLoaderTeam = 0;
        }
      } catch (error) {
        this.showLoaderTeam = 0;
        console.error("Failed to fetch umpires:", error);
      }
      this.isLoaded.detail = false;
      this.isLoaded.team = false;
      this.isLoaded.tournament = false;
      this.isLoaded.umpire = false;
      this.isLoaded.message = false;
    },

    async TournamentsDetailData() {
      this.isLoaded.detail = true;
      this.isLoaded.team = true;
      this.isLoaded.tournament = true;
      this.isLoaded.umpire = true;
      this.isLoaded.message = true;
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const eventGuid = segment_array.pop();
      this.showLoaderTournament = 1;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}event/tournamentListForAdminEvents?page=${this.page}`,
          { event_guid: eventGuid }
        );

        if (response.data.statusCode === 200) {
          this.tournamentsData = response.data.data.eventTournaments.data;

          if (this.tournamentsData.length > 0) {
            const tournamentId = this.tournamentsData[0].id;
            localStorage.setItem("tournament_id", tournamentId);
          }

          this.lastPage = response.data.data.tournamentsData.last_page;

          this.showLoaderTournament = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoaderTournament = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoaderTournament = 0;
      } finally {
        this.showLoaderTournament = 0;
      }
      this.isLoaded.detail = false;
      this.isLoaded.team = false;
      this.isLoaded.tournament = false;
      this.isLoaded.umpire = false;
      this.isLoaded.message = false;
    },

    async getUmpires() {
      this.isLoaded.detail = true;
      this.isLoaded.team = true;
      this.isLoaded.tournament = true;
      this.isLoaded.umpire = true;
      this.isLoaded.message = true;
      this.showLoaderUmpire = 1;
      try {
        const url = `${process.env.VUE_APP_API_URL}associationEvent/getUmpires?event_id=${this.eventId}&page=${this.umpirepage}`;
        const response = await axios.get(url);
        if (response.data.statusCode === 200) {
          const responseData = response.data.data.umpires.data;
          this.umpirelastPage = response.data.data.umpires.last_page;
          if (this.umpirepage > 1) {
            this.umpireData = [...this.umpireData, ...responseData];
          } else {
            this.umpireData = responseData;
          }
          this.showLoaderUmpire = 0;
        } else {
          console.error(
            "Failed to fetch umpires:",
            response.data.message || "Unknown error occurred"
          );
          this.showLoaderUmpire = 0;
        }
      } catch (error) {
        this.showLoaderUmpire = 0;
        console.error("Failed to fetch umpires:", error);
      }
      this.isLoaded.detail = false;
      this.isLoaded.team = false;
      this.isLoaded.tournament = false;
      this.isLoaded.umpire = false;
      this.isLoaded.message = false;
    },
  


    async getMessageBoards(topicId) {
      this.isLoaded.detail = true;
      this.isLoaded.team = true;
      this.isLoaded.tournament = true;
      this.isLoaded.umpire = true;
      this.isLoaded.message = true;
      this.showLoaderMessage = 1;
      if (topicId !== undefined && topicId !== null) {
            this.messageBodyData = [];
            this.messagepage= 1;
      }
      try {
        let url = `${process.env.VUE_APP_API_URL}associationEvent/getMessageBoards?event_id=${this.eventId}&page=${this.messagepage}`;
        if (topicId !== undefined && topicId !== null) {
          url += `&topic_id=${topicId}`;
        }

        const response = await axios.get(url);
        if (response.data.statusCode === 200) {
          const responseData = response.data.data.messageBoards.data;
          this.msgLastPage = response.data.data.messageBoards.last_page;

          // Clear the existing messageBodyData if a filter is applied
          
          if (this.messagepage > 1) {
            this.messageBodyData = [...this.messageBodyData, ...responseData];
          } else {
            this.messageBodyData = responseData;
          }
          // this.messageBodyData = this.messageBodyData.concat(responseData);
          this.showLoaderMessage = 0;
        } else {
          console.error(
            "Failed to fetch message boards:",
            response.data.message || "Unknown error occurred"
          );
          this.showLoaderMessage = 0;
        }
      } catch (error) {
        this.showLoaderMessage = 0;
        console.error("Failed to fetch message boards:", error);
      }
      this.isLoaded.detail = false;
      this.isLoaded.team = false;
      this.isLoaded.tournament = false;
      this.isLoaded.umpire = false;
      this.isLoaded.message = false;
    },
    async getMessageTopics() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}associationEvent/getMessageTopics`
        );

        if (response.data.statusCode === 200) {
          this.messageTopics = response.data.data.messageTopics;
        } else {
          console.error(
            "Failed to fetch umpires:",
            response.data.message || "Unknown error occurred"
          );
        }
      } catch (error) {
        console.error("Failed to fetch umpires:", error);
      }
    },
    handleMessageBoardPagination() {
      this.messagepage++;
      this.getMessageBoards();
    },
    handleUmpirePagination() {
      this.umpirepage++;
      this.getUmpires();
    },

    selectTab(tab) {
      this.currentTab = tab;
      if (tab === "detail") {
        this.EventsDetailData();
      }
      if (tab === "team") {
        this.getJoinTeams();
      }
      if (tab === "tournament") {
        this.TournamentsDetailData();
      }
      if (tab === "umpire") { 
        this.umpirepage = 1; 
       this.umpireData=[]
        this.getUmpires();
        // this.fetchUserAdminAssociation()
      }
      if (tab === "message") {
        this.messagepage=1;
        this.messageBodyData =[]
        this.getMessageBoards();
        this.getMessageTopics();
      }
    },
  },
  mounted() {
    this.EventsDetailData();
    this.$root.$on("fetchMesagesForTopics", (topic) => {
    this.getMessageBoards(topic);
});
this.$root.$on("sponsorAdded", () => {
  this.getEventSponsor(this.eventId);

});

this.$root.$on("sponsorUpdated", () => {
  this.getEventSponsor(this.eventId);
});
this.$root.$on("hotelAddedEmit",()=>{
  this.getEventHotel(this.eventId);
});
this.$root.$on("selectedUmpireEmit",()=>{
  this.umpireData =[];
  this.getUmpires();
  
});
this.$root.$on("fetchTournaments",()=>{
  this.tournamentsData =[];
  this.TournamentsDetailData()
});
this.$root.$on("selectedTeamEmit",()=>{
  this.joinedTeams =[];
  this.getJoinTeams();
});
this.$root.$on("selectedParkEmit",()=>{
  this.getPlayingFacility(this.eventId);
})
    this.$root.$on("getMessageBoards", () => {
      this.handleMessageBoardPagination();
    });
    this.$root.$on("getUmpires",()=>{
      this.handleUmpirePagination();
    });
    this.$root.$on("fetchMesages", () => {
        this.getMessageBoards();
      });
    this.$root.$on("eventsIdForDetails", (data) => {
      this.events = data;
    });
  },
};
</script>
