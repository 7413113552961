<template>
  <div class="user-name-wrapper" v-if="user">
    <span class="name" v-if="user"
      >{{ user.fname }} {{ user.middle_name }} {{ user.lname }}</span
    >
    <span class="email" v-if="user">@{{ user.user_link }}</span>
  </div>
  <div class="products-list-shim" v-else>
    <content-placeholders>
      <content-placeholders-heading />
      <content-placeholders-heading />
    </content-placeholders>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Username",
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
