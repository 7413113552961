<template>
  <div>
    <div class="shop-left-menu search">
      <div class="search-heading">
        <h6>Shop</h6>
      </div>

      <ul class="setting-menu-list search-list">
        <li
          :class="{ active: isActive }"
          @click="oldData()"
          v-on:click="(page = 1), (allData = [])"
          class="search-list-item"
        >
          All
        </li>
        <li
          class="search-list-item"
          :class="{ active: activeIndex === index }"
          v-for="(cat, index) in catProducts"
          :key="cat.id"
          v-on:click="page = 1"
          @click="getProductByCat(cat.id, index)"
        >
          {{ cat.name }}
        </li>
      </ul>
    </div>
    <div class="shop-page-products mt-3 pl-0 pb-3">
      <div class="search-container shop-search">
        <form class="my-0">
          <input
            v-model="searchValue"
            type="search"
            placeholder="Click to search in shop"
            aria-label="Search"
            autocomplete="off"
            @input="searchProduct()"
          />
          <button @click.prevent="searchProduct()">
            <img
              class="search-image"
              src="../../assets/images/search.png"
              alt
            />
          </button>
        </form>
      </div>
      <div class="shop-products-loader" v-if="showLoader == true">
        <div class="lds-dual-ring-media"></div>
      </div>
      <div class="shop-search" v-if="showLoader == false">
        <div class="no-products-in-shop" v-if="errNotAvai">
          <img src="../../assets/images/no-product.png" alt />
          <p class="header">No product found.</p>
          <p class="info">
            There are no products to display in the selected category.
          </p>
        </div>
        <div
          class="no-products-in-shop"
          v-if="!errNotAvai && products.length == 0 && allData.length == 0"
        >
          <img src="../../assets/images/no-product.png" alt />
          <p class="header">No product found.</p>
          <p class="info">
            There are no products to display in the selected category.
          </p>
        </div>
        <div class="col-sm-12 p-0" v-if="allData && showLoader == false">
          <Products :products="allData" />
        </div>
        <div
          class="col-sm-12 p-0"
          v-if="
            products &&
            allData.length == 0 &&
            errNotAvai == false &&
            showLoader == false
          "
        >
          <Products :products="allData" />
        </div>
      </div>
      <div
        v-observe-visibility="handleScrolledToButtom"
        v-if="products.length"
      ></div>
    </div>
  </div>
</template>
<script>
import Products from "../Shop/Products";
import axios from "axios";
export default {
  name: "ProductsList",

  data: function () {
    return {
      allData: [],
      errNotAvai: false,
      isActive: true,
      activeIndex: undefined,
      searchValue: "",
      errorMessage: "",
      page: 1,
      lastPage: "",
      showLoader: false,
    };
  },

  components: {
    Products,
  },

  props: {
    catProducts: {
      type: Array,
    },
    products: {
      type: Array,
    },
  },

  // computed: {
  //   filteredProduct() {
  //     let tempProds = this.products;

  //     // Process search input
  //     if (this.searchValue != "" && this.searchValue) {
  //       tempProds = tempProds.filter(item => {
  //         return item.name.toUpperCase().includes(
  //           this.searchValue
  //             .toUpperCase()
  //             .trim()
  //             .replace(/\s+/g, " ")
  //         );
  //       });
  //     }
  //     return tempProds;
  //   },
  //   filteredcatProduct() {
  //     let tempProds = this.allData;

  //     // Process search input
  //     if (this.searchValue != "" && this.searchValue) {
  //       tempProds = tempProds.filter(item => {
  //         return item.name.toUpperCase().includes(
  //           this.searchValue
  //             .toUpperCase()
  //             .trim()
  //             .replace(/\s+/g, " ")
  //         );
  //       });
  //     }
  //     return tempProds;
  //   }
  // },

  methods: {
    oldData() {
      this.showLoader = true;
      this.isActive = true;
      this.activeIndex = undefined;
      this.errNotAvai = false;
      //this.allData = this.products;
      this.fetchMoreProducts();
    },
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible && typeof this.activeIndex === "undefined") {
        this.page++;
        this.fetchMoreProducts();
        return;
      }
      if (isVisible && typeof this.activeIndex !== "undefined") {
        let catID = this.catProducts[this.activeIndex].id;
        this.page++;
        this.getProductByCat(catID, this.activeIndex);
        return;
      }
    },
    async fetchMoreProducts() {
      let shopResults = await axios.get(`shop/?page=${this.page}`);
      if (shopResults.data.statusCode == 200) {
        this.showLoader = false;
        this.allData.push(...shopResults.data.data.products.data);
        this.lastPage = shopResults.data.data.products.last_page;
      } else {
        this.errorMessage = shopResults.data.message;
      }
    },
    async getProductByCat(cat, index) {
      this.showLoader = true;
      this.isActive = false;
      this.activeIndex = index;
      let catResults = await axios.get(
        `shop/?category_id=${cat}&page=${this.page}`
      );
      if (catResults.data.statusCode == 200) {
        this.showLoader = false;
        this.lastPage = catResults.data.data.products.last_page;
        this.allData = catResults.data.data.products.data;
        if (this.allData.length == 0) {
          this.errNotAvai = true;
        } else {
          this.errNotAvai = false;
        }
      } else {
        this.errorMessage = catResults.data.message;
      }
    },
    async searchProduct() {
      this.showLoader = true;
      if (typeof this.activeIndex !== "undefined") {
        let catID = this.catProducts[this.activeIndex].id;
        if (this.searchValue == "") {
          this.getProductByCat(catID, this.activeIndex);
          this.showLoader = false;
          return;
        }
        let searchResult = await axios.get(
          `shop/?search=${this.searchValue}&category_id=${catID}`
        );
        this.allData = searchResult.data.data.products.data;
        this.showLoader = false;
        return;
      }
      let searchResult = await axios.get(`shop/?search=${this.searchValue}`);
      this.allData = searchResult.data.data.products.data;
      this.showLoader = false;
    },
  },

  mounted() {
    // this.allData = this.products;
    this.oldData();
  },

  // watch: {
  //   searchValue(){
  //     // this.searchValue = newValue;
  //     this.oldData();
  //   }
  // },
};
</script>
