<template>
  <div>
    <div class="order-history" v-if="showLoader == 0">
      <h1>Order History</h1>
      <div class="order-content" v-if="displayedOrders.length != 0">
        <div class="table-responsive-md">
          <table class="table" v-if="displayedOrders">
            <thead>
              <tr>
                <th scope="col">Order No</th>
                <th scope="col">Date</th>
                <th scope="col">Payment Status</th>
                <th scope="col">Order Status</th>
              </tr>
            </thead>
            <tbody v-for="(order, index) in displayedOrders" :key="index">
              <tr @click="getItemsData(order)">
                <td scope="row">{{ order.pp_invoice_id }}</td>
                <td>{{ order.created_at | formatDateOfBirth }}</td>
                <td>
                  <div class="payment-status">
                  <button class="paid-btn">{{ order.payment_status }}</button>
                   ${{
                    (order.grand_total).toFixed(2)
                  }}
                  </div>
                </td>
                <!-- <td class="text-capitalize">{{ order.status }}</td> -->
                <!-- <td class="text-capitalize" >
                  <div class="order-status-top">
                    <img src="../../assets/images/money-time.png" alt="">
                    <p>Waiting Payment</p>
                  </div>
                  <p class="order-status-end">You have not paid for the order yet</p>
                </td> -->

                <td class="text-capitalize" v-if="order.status == 'confirmed'">
                  <div class="order-status-top">
                    <img src="../../assets/images/box-tick.png" alt="">
                    <p>Confirmed</p>
                  </div>
                  <p class="order-status-end">Your order is getting ready for delivery.</p>
                </td>

                <td class="text-capitalize" v-else-if="order.status == 'dispatch'">
                  <div class="order-status-top">
                    <img src="../../assets/images/truck-fast.png" alt="">
                    <p>On the way</p>
                  </div>
                  <p class="order-status-end">Your order is on the way to deliver.</p>
                </td>
             
                <td class="text-capitalize" v-else-if="order.status == 'delivered'">
                  <div class="order-status-top">
                    <img src="../../assets/images/order-delivered.png" alt="">
                    <p>Delivered</p>
                  </div>
                  <p class="order-status-end">Order delivered on your shipping address.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <nav class="orders-pagination-nav" aria-label="Page navigation example">
          <ul class="pagination m-0">
            <li class="page-item">
              <button
                type="button"
                class="page-link"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </button>
            </li>
            <li class="page-item" v-if="page != 1">
              <button
                type="button"
                class="page-link"
                v-for="pageNumber in pages.slice(page - 1, page + 5)"
                :key="pageNumber"
                @click="page = pageNumber"
              >
                {{ pageNumber }}
              </button>
            </li>
            <li class="page-item">
              <button
                type="button"
                @click="page++"
                v-if="page < pages.length"
                class="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div v-else class="media-wrapper">
        <div class="no-content" v-if="showLoader == 0">
          <div class="img">
            <img src="../../assets/images/order-grey.png" alt="" />
          </div>
          <p class="header">
            No order placed yet
          </p>
          <p class="text">
            There are no orders to show in your <br />
            order history
          </p>
          <router-link to="/shop">
            Visit our shop
          </router-link>
        </div>
      </div>
    </div>
    <div class="order-history" v-if="showLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "OrderHistory",
  data() {
    return {
      errorMessage: null,
      orders: [],
      showLoader: 0,
      page: 1,
      perPage: 12,
      pages: [],
    };
  },

  methods: {
    async fetchOrders(page = 1) {
      this.showLoader = 1;
      var user_id = localStorage.getItem("userId");
      axios
        .get(
          `${process.env.VUE_APP_API_URL}orders/getOrders?page=${page}&user_id=${user_id}`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.orders = response.data.data;
            this.showLoader = 0;
            this.setPages();
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
    setPages() {
      let numberOfPages = Math.ceil(this.orders.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(orders) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return orders.slice(from, to);
    },
    getItemsData(order) {
      this.$root.$emit("OrderedItems", order);
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    displayedOrders() {
      return this.paginate(this.orders);
    },
  },
  mounted() {},
  watch: {
    posts() {
      this.setPages();
    },
  },
  created() {
    this.fetchOrders();
  },
  filters: {
    trimWords(value) {
      return (
        value
          .split(" ")
          .splice(0, 20)
          .join(" ") + "..."
      );
    },
  },
};
</script>

<style>
.order-history th{
  padding: 0.75rem 0 !important;
  text-align: left;
}
.order-history td{
  padding: 0.75rem 0 !important;
  text-align: left;
}
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 14px;
  color: #29b3ed;
  font-weight: 500;
  padding: 7px 11px;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
.order-history .order-content {
  height: calc(100vh - 172px);
  overflow: auto;
}

.order-history .order-content .table-repsonsive-md {
  width: 220%;
}
.payment-status{
  display: flex;
  align-items: center;
  gap: 6px;
}
.order-status-top{
  display: flex;
  align-items: center;
  gap: 10px;
}
.order-status-top img{
  width: 20px;
  height: 20px;
}
.order-status-top p{
  color: #254C72;
}
.order-status-end{
  font-size: 13px;
  color: #557491;
  margin-top: 1.5px;
}
.orders-pagination-nav {
  float: right;
  margin-right: 10px;
}
</style>
