<template>
  <div>
    <div class="all-event-wrapper">
      <div class="event-top">
        <div class="event-top-content">
          <div class="back-arrow" @click="closeDiv">
            <img src="../../../../assets/images/arrow-left.png" />
          </div>
          <div class="event-title">
            <div class="image-wrapper">
              <div class="users-notification" v-if="imgLoader == 1">
                <!-- <div class="lds-dual-ring-refresh"></div> -->
                <img src="../../../../assets/loader.gif" alt="" />
              </div>
              <img
                @load="onImageLoad"
                v-if="foundCurrentUser.avatar != ''"
                :src="foundCurrentUser.avatar"
                alt=""
              />
              <img
                @load="onImageLoad"
                v-else
                src="../../../../assets/images/chat-user.png"
                alt=""
              />
            </div>
            <div class="user-details">
              <p class="team-name" :id="selectedRoomTeam.id">
                {{ selectedRoomTeam.roomName }}
              </p>
              <span>Chat Options</span>
            </div>
          </div>
        </div>
      </div>
      <div class="menu-items">
        <div
          class="member-list member-list-red"
          data-toggle="modal"
          data-target="#reportChat"
          data-backdrop="static"
          data-dismiss="modal"
          data-keyboard="false"
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/dislike.png" alt="" />
            </div>
            <span>Report Chat</span>
          </div>
        </div>
        <div class="member-list member-list-red" v-if="showBlockLoader == 1">
          <div class="member-detail">
            <content-placeholders :rounded="true">
              <content-placeholders-text
                :lines="1"
                class="m-0"
                style="width: 200px"
              />
            </content-placeholders>
          </div>
        </div>

        <div v-else>
          <!-- Block User -->
          <div
            class="member-list member-list-red"
            v-if="userBlockFlag == 0"
            @click="blockUser"
          >
            <div class="member-detail">
              <div class="member-img">
                <i class="fa fa-ban red-color"></i>
              </div>
              <span>Block User</span>
            </div>
          </div>
          <!-- unblock User -->
          <div
            class="member-list member-list-red"
            v-if="userBlockFlag == 1"
            @click="unblockUser"
          >
            <div class="member-detail">
              <div class="member-img">
                <i class="fa fa-unlock red-color"></i>
              </div>
              <span>Unblock User</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Report
      :chatRoomId="roomId"
      :chatUserId="chatUserId"
      :chatRoomType="chatRoomType"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import report from "../../../Report/report.vue";
import $ from "jquery";
export default {
  name: "ChatOptions",
  components: {
    Report: report,
  },

  data() {
    return {
      userId: localStorage.getItem("userId"),
      foundCurrentUser: [],
      chatUserId: "",
      chatRoomType: "",
      roomId: this.selectedRoomTeam.roomId,
      imgLoader: 1,
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    userBlockFlag: {
      type: Number,
    },
    showBlockLoader: {
      type: Number,
    },
  },

  mounted() {
    const thisInstance = this;
    this.$root.$on("closeReportOption", function () {
      thisInstance.closeDiv();
    });
    this.reportIndividual();
    this.$store.commit("setErrors", {});
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["errors"]),
  },

  methods: {
    ...mapActions("auth", ["getUserData"]),
    onImageLoad() {
      this.imgLoader = 0;
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    closeDiv: function () {
      this.$root.$emit("IndChatSettings", 0, 0);
      this.$root.$emit("IndMenuSettingsDiv", 0);
    },
    //  ======================= report chat ===============

    async reportIndividual() {
      if (this.selectedRoomTeam) {
        this.selectedRoomTeam.users.forEach((dataU) => {
          if (dataU.c_id != this.userId) {
            this.foundCurrentUser = dataU;
          }
        });
        this.chatUserId = this.foundCurrentUser.c_id;
        this.chatRoomType = "Individual";
        this.roomId = this.selectedRoomTeam.roomId;
      }
    },
    async blockUser() {
      if (this.selectedRoomTeam) {
        this.$confirm("About to block the User, Continue?", "Block User").then(
          () => {
            var formData = new FormData();
            formData.append("blocked_user_id", this.chatUserId);
            formData.append("type", 1);
            axios
              .post(
                process.env.VUE_APP_API_URL + "block/blockUserAction",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(() => {
                $(".vac-box-footer").hide();
                this.$root.$emit("checkBlockUserFlagOnTeam");
              });
          }
        );
      }
    },

    async unblockUser() {
      if (this.selectedRoomTeam) {
        this.$confirm(
          "About to unblock the User, Continue?",
          "Unblock User"
        ).then(() => {
          var formData = new FormData();
          formData.append("blocked_user_id", this.chatUserId);
          formData.append("type", 2);
          axios
            .post(
              process.env.VUE_APP_API_URL + "block/blockUserAction",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              $(".vac-box-footer").show();
              this.$root.$emit("checkBlockUserFlagOnTeam");
            });
        });
      }
    },
  },
};
</script>