<template>
  <div>
    <servicesModal />
    <!-- loders -->
      <div v-if="!event" class=" col-12 p-0 mt-3  bg-white p-3">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
      </div>
      <br v-if="!event">
      <div v-if="!event" class="col-12 p-0 bg-white p-3">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
      </div>

    <b-row class="no-gutters" v-if="event && event.length > 0">
      <div
        v-for="value in event"
        :key="value.id"
        class="col-12 col-sm-6 col-lg-4 col-xl-3 pr-sm-3 products-column"
      >
        <div class="event-card">
          <div class="content">
            <div class="img-wrapper" @click="handleEventClick(value.id)">
              <div class="shop-products-img-loader" v-if="imgLoader == 1">
                <div class="lds-dual-ring-media"></div>
              </div>
              <img
                @load="onImageLoad"
                v-if="value.avatar == '' || value.avatar == null"
                alt="Image"
                class="default-event-image"
                src="../../assets/cover-default.png"
              />
              <img
                @load="onImageLoad"
                v-else
                :src="eventURL + value.avatar"
                alt="Image"
              />
            </div>

            <div class="p-3" @click="handleEventClick(value.id)">
              <h3 class="event-name calendar-side">{{ value.eventName }}</h3>
              <div class="event-description my-2" style="min-height: 120px">
                <div class="association">
                  <span class="heading">Team Name :</span>
                  <span class="content">{{ value.team_name.team_name }}</span>
                </div>
                <div
                  class="association"
                  v-if="
                  selectedRoomTeam.sportType == 'Softball (Slow Pitch)' ||
                          selectedRoomTeam.sportType == 'Softball (Fast Pitch)' ||
                          selectedRoomTeam.sportType == 'Softball' &&
                    value.association != '' &&
                    value.association != null &&
                    value.association != 'null'
                  "
                >
                  <span class="heading">Association :</span>
                  <span class="content">{{ value.association }}</span>
                </div>
                <div class="event-type">
                  <span class="heading">Event Type :</span>
                  <span class="content"
                    >{{ value.eventType }}
                    <span v-if="value.status == 2">(Online)</span></span
                  >
                </div>
                <div
                  v-if="
                    (value.status == 1 &&
                      value.address != null &&
                      value.address != '' &&
                      value.address != 'null') ||
                    (value.city != null &&
                      value.city != '' &&
                      value.city != 'null') ||
                    (value.state != null &&
                      value.state != '' &&
                      value.state != 'null')
                  "
                  class="event-location"
                >
                  <img src="../../assets/images/location.png" />
                  <span class="content"
                    >{{ value.address }} {{ value.city }} ,
                    {{ value.state }}</span
                  >
                </div>
                <div v-if="value.status == 2" class="event-location">
                  <img src="../../assets/images/link.png" />
                  <a
                    :href="value.Url"
                    class="content card-link"
                    target="_blank"
                  >
                    <span style="word-break: break-all">{{ value.Url }}</span>
                  </a>
                </div>
              </div>
              <div class="event-date">
                <span class="heading">Date & Time :</span>
                <div class="content">

                  <span v-if="value.exactStartDate === value.exactEndDate">
                    {{ value.exactStartDate }}
                  </span>
                  <span v-else-if="value.exactStartDate.split(',')[1].trim() !== value.exactEndDate.split(',')[1].trim()">
                    {{ value.exactStartDate }} to {{ value.exactEndDate }}
                  </span>
                  <span v-else>
                    {{ value.exactStartDate.split(",")[0] }} to {{ value.exactEndDate }}
                  </span>

                  <span v-if="value.allDay == 'true'"> All Day.</span>
                  <span v-else>
                    From {{ value.exactStartTime }} to
                    {{ value.exactEndTime }}</span
                  >
                  <span v-if="value.time_zone !== null">
                    {{ value.time_zone.split(")").pop() }}
                  </span>
                </div>
              </div>
            </div>
            <!-- <div class="event-label border-0">
              <div class="row no-gutters align-items-center mt-3">
                <div class="col-12" v-if="currentIsFan != '1'">
                  <div class="view-details pb-2">
                    <ul class="mb-0">
                      <span
                        v-if="
                          !checkStatus(
                            value.endDateForField,
                            value.exactStartTime
                          )
                        "
                      >
                        <content-placeholders>
                          <content-placeholders-text :lines="2" />
                        </content-placeholders>
                      </span>
                      <span>
                        <li
                          class="cursor-p"
                          v-bind:class="[
                            comingstatus[value.id]
                              ? comingstatus[value.id] == 1
                                ? 'active'
                                : ''
                              : value.event_attendance[0].member_status == 1
                              ? 'active'
                              : '',
                          ]"
                          v-on:click="selectStatus(1, value.id, value.team_id)"
                        >
                          Going
                        </li>
                        <li
                          class="cursor-p"
                          v-bind:class="[
                            comingstatus[value.id]
                              ? comingstatus[value.id] == 2
                                ? 'active'
                                : ''
                              : value.event_attendance[0].member_status == 2
                              ? 'active'
                              : '',
                          ]"
                          v-on:click="selectStatus(2, value.id, value.team_id)"
                        >
                          Not Going
                        </li>
                        <li
                          class="cursor-p"
                          v-bind:class="[
                            comingstatus[value.id]
                              ? comingstatus[value.id] == 3
                                ? 'active'
                                : ''
                              : value.event_attendance[0].member_status == 3
                              ? 'active'
                              : '',
                          ]"
                          v-on:click="selectStatus(3, value.id, value.team_id)"
                        >
                          Maybe
                        </li>
                      </span>
                    </ul>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <b-card-footer style="margin-top: 10px; display: none">
          </b-card-footer>
        </div>
      </div>

      <div
        v-if="!event"
        class="card-event col-12 col-md-6 col-lg-4 col-xl-3 pr-sm-3"
      >
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
      </div>
      <div
        class="card-event col-12 no-card-event"
        v-if="event == 'null' || event == ''"
      >
        <img src="../../assets/images/calendar-search.png" alt="" />
        <p class="no-event-header">No upnext events</p>
        <p class="no-event-text">
          All upnext events for this team will appear here.
        </p>
      </div>

      <div
        v-if="eventTo < total"
        class="col-12 col-md-6 col-lg-4 col-xl-3 p-0 pr-sm-3"
      >
        <div v-if="total > eventTo && showLoader" class="card-event">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
          </content-placeholders>
        </div>
      </div>
    </b-row>

    <div v-if="event && event.length > 0" v-observe-visibility="handleScroll"></div>

    <sweet-modal ref="modal" title="Event Detail">
      <CalendarEventWrapper :singleEvent="singleEvent" :selectedRoomTeam="selectedRoomTeam"/>
      <div class="row no-gutters">
        <div class="col-12 p-0">
          <div class="event-actions">
            <ul>
              <li
                v-bind:class="[selectMainTab == 0 ? 'active' : '']"
                v-on:click="selectAttendee()"
              >
                Attendees
              </li>
              <li
                v-bind:class="[selectMainTab == 1 ? 'active' : '']"
                v-on:click="selectGame()"
              >
                Games
              </li>
            </ul>
          </div>
          <div v-if="showEventMemeberCountShimmer == 0 && selectMainTab == 0">
            <div class="view-details py-0">
              <h6 v-if="currentIsFan != '1'">Are you coming ?</h6>
            </div>

            <div class="event-label pb-0">
              <div class="col-12 px-0" v-if="currentIsFan != '1'">
                <ul class="mb-0">
                  <!-- Upcoming -->
                  <li
                    class="cursor-p"
                    v-bind:class="[
                      currentUserEventStatus == 1 ? 'active disabled ' : '',
                    ]"
                    v-on:click="selectStatus(1)"
                  >
                    Going
                  </li>

                  <li
                    class="cursor-p"
                    v-bind:class="[currentUserEventStatus == 2 ? 'active' : '']"
                    v-on:click="selectStatus(2)"
                  >
                    Not Going
                  </li>

                  <li
                    class="cursor-p"
                    v-bind:class="[currentUserEventStatus == 3 ? 'active' : '']"
                    v-on:click="selectStatus(3)"
                  >
                    Maybe
                  </li>
                </ul>
              </div>

              <div class="col-12 px-0">
                <div class="user-counter">
                  <ul class="counting mb-0">
                    <li>
                      <span>{{ totalGoing }}</span>
                      <span>Going</span>
                    </li>
                    <li>
                      <span>{{ totalNotGoing }}</span>
                      <span>Not Going</span>
                    </li>
                    <li>
                      <span>{{ totalMayBe }}</span>
                      <span>Maybe</span>
                    </li>
                    <li>
                      <span>{{ totalNotRespond }}</span>
                      <span>Not Responded</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--        =================== new  ==================-->

          <div v-if="selectMainTab == 0" class="event-actions">
            <ul>
              <li
                v-bind:class="[selectAttendeeTab == 0 ? 'active' : '']"
                v-on:click="filterGoing()"
              >
                Going
              </li>
              <li
                v-bind:class="[selectAttendeeTab == 1 ? 'active' : '']"
                v-on:click="filterNotGoing()"
              >
                Not Going
              </li>
              <li
                v-bind:class="[selectAttendeeTab == 2 ? 'active' : '']"
                v-on:click="filterMay()"
              >
                Maybe
              </li>
              <li
                v-bind:class="[selectAttendeeTab == 3 ? 'active' : '']"
                v-on:click="filterNotResponded()"
              >
                Not Responded
              </li>
            </ul>
          </div>

          <div v-if="selectMainTab == 0">
            <div v-if="showLoader == 1" class="friends-requests">
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="true" />
              </content-placeholders>
              <br />
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="true" />
              </content-placeholders>
            </div>

            <div
              v-if="showLoader == 0 && membersAttendee.length > 0"
              class="calendar-user-info"
            >
              <div
                v-for="user in membersAttendee"
                :key="user.id"
                class="user-info"
              >
                <div class="image-wrapper">
                  <div class="users-notification" v-if="imgLoader == 1">
                    <!-- <div class="lds-dual-ring-refresh"></div> -->
                    <img src="../../assets/loader.gif" alt="" />
                  </div>
                  <img
                    @load="onImageLoad"
                    v-if="user.c_id != ''"
                    :src="API_URL + user.c_id + '/profile_picture.jpg'"
                    alt=""
                    onerror="this.src='https://api.whoifollow.tech/user-default2.png'"

                  />
                  <img
                    @load="onImageLoad"
                    v-else
                    alt=""
                    src="../../assets/images/user-default-new.png"
                  />
                </div>
                <span class="text-capitalize">{{ user.username }}</span>
              </div>
            </div>
            <div
              v-if="membersAttendee.length == 0 && showLoader == 0"
              class="col-12 p-0"
            >
              <span>No User found.</span>
            </div>
          </div>
        </div>

        <!--------------------------------------- Game List ----------------------------->
        <div v-if="selectMainTab == 1" class="col-12 pl-0">
          <div
            v-if="showGameList == 1 || showDefualt == 1"
            class="games-section"
          >
            <div class="game-top border-0 p-0">
              <div class="game-heading">
                <h6>Games</h6>
              </div>
            </div>
            <div class="container-fluid no-pdd">
              <div
                v-if="showGameShimmer == 0 && gameList.length > 0"
                class="row"
              >
                <div
                  v-for="game in gameList"
                  :key="game.id"
                  class="col-12 col-md-6 pt-3 pr-0 game-wrap"
                >
                  <div class="game-detail-wrapper">
                    <div class="game-content">
                      <div class="game-name">
                        <h6>{{ game.name }}</h6>
                      </div>
                      <div class="date-content">
                        <p>{{ game.exactDateFormat }}</p>
                      </div>

                      <div
                        class="game-location"
                        @click="openGoogleMapforGamePark(game)"
                        v-if="park_name != null || park_name != ''"
                      >
                        <img src="../../assets/images/location.png" />
                        <p>
                          {{ game.park_name }}
                        </p>
                      </div>
                      <div
                        class="game-location"
                        v-if="park_address != null || park_address != ''"
                      >
                        <p>
                          {{ game.park_address }}
                        </p>
                      </div>
                      <div
                        class="game-location"
                        v-if="park_number != null || park_number != ''"
                      >
                        <p>{{ game.park_number }}</p>
                      </div>

                      <div class="opponent-name">
                        <h5>{{ game.opponent_name }}</h5>
                      </div>
                      <div class="game-description">
                        <p>{{ game.comment }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="showGameShimmer == 0 && gameList.length == 0">
                <div class="no-game-section">
                  <div class="no-content">
                    <div class="img">
                      <img src="../../assets/images/games.png" alt="" />
                    </div>
                    <p class="text">
                      There are no games added for this event yet
                    </p>
                  </div>
                </div>
              </div>

              <div v-if="showGameShimmer == 1" class="row">
                <div class="col-12">
                  <div class="friends-requests">
                    <content-placeholders :rounded="true">
                      <content-placeholders-heading :img="true" />
                    </content-placeholders>
                    <br />
                    <content-placeholders :rounded="true">
                      <content-placeholders-heading :img="true" />
                    </content-placeholders>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import axios from "axios";
import { parseTimestamp } from "../../utils/dates";
import { SweetModal } from "sweet-modal-vue";
import { mapActions, mapGetters } from "vuex";
import servicesModal from "../Teams/ChatRight/ChatEvents/servicesModal.vue";
import CalendarEventWrapper from "./CalendarEventWrapper.vue";

export default {
  name: "events",
  components: {
    SweetModal,
    servicesModal,
    CalendarEventWrapper, // SweetModalTab
  },

  data: function () {
    return {
      imgLoader: 1,
      currentIsFan: "0",
      firstTimeEventsA: [],
      eventURL: this.$eventAvatarStorageURL,
      eventsDetails: [],
      page: 1,
      lastPage: 1,
      showLoader: 0,
      TeamDetail: this.TeamDetails,
      avatar: "",
      eventName: "",
      createdByName: "",
      createdByDate: "",
      association: "",
      teamName: "",
      eventType: "",
      status: "",
      address: "",
      city: "",
      Url: "",
      exactStartDate: "",
      exactEndDate: "",
      allDay: "",
      exactStartTime: "",
      time_zone: "",
      exactEndTime: "",
      eventTypeA: "",
      showEventMemeberCountShimmer: 0,
      currentUserEventStatus: 1,
      showEventsMember: 0,
      showGameList: 0,
      showDefualt: 1,
      totalGoing: 0,
      totalNotGoing: 0,
      totalMayBe: 0,
      totalNotRespond: 0,
      showGameShimmer: 1,
      UserDetail: [],
      UserRole: [],
      gameList: [],
      selectAttendeeTab: 0,
      membersAttendee: [],
      API_URL: this.$userProfileStorageURL,
      selectedRoomTeam: [],
      selectMainTab: 0,
      teamID: "",
      eventID: "",
      selectVisitEventStatus: [],
      singleEvent: {},
      comingstat: this.comingstatus,
      total: 0,
      eventTo: 0,
      selectedArray: [],
      room_count: 1,
      adult_count: 1,
      exactRoomEndDate: "",
      note: "",
      exactRoomStartDate: "",
      state: "",
    };
  },

  props: {
    TeamDetails: Array,
    UserDetails: Array,
    currentUserId: String,
  },
  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("events", ["event"]),
    ...mapGetters("events", ["comingstatus"]),
  },

  created() {
    // window.addEventListener("scroll", this.handleScroll);

    window.onscroll = () => {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight - 3;
      if (bottomOfWindow) {
        this.handleScroll();
      }
    };
  },
  methods: {
    ...mapActions("events", ["setEventData"]),
    ...mapActions("events", ["getCurrentComingStatus"]),

    onImageLoad() {
      this.imgLoader = 0;
    },

    async calendarData(TeamDetails) {
      this.eventsDetails = [];
      if (this.event) {
        this.event.forEach((elem) => {
          var exists = this.eventsDetails.some(function (field) {
            return field.id === elem.id;
          });
          if (!exists) {
            this.eventsDetails.push(elem);
          }
        });
      }

      this.showLoader = 1;
      const formData = new FormData();
      formData.append("sportsRoomID", TeamDetails);
      axios
        .post(
          process.env.VUE_APP_API_URL +
            `event/calenderEventlist?page=${this.page}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((res) => {
          this.total = res.data.data.total;
          this.eventTo = res.data.data.to;
          var responseData = res.data.data.data;
          responseData.forEach((elem) => {
            var exists = this.eventsDetails.some(function (field) {
              return field.id === elem.id;
            });
            if (!exists) {
              this.eventsDetails.push(elem);
              this.setEventData(this.eventsDetails);
            }
          });
          this.lastPage = res.data.data.last_page;
          this.noEvent = res.data.data.data.length > 0 ? 0 : 1;
          this.showLoader = 0;
        });
    },

    async handleScroll() {
      if (this.page > this.lastPage) {
        return;
      }

      await this.calendarData(this.TeamDetails);
      this.page++;
    },

    handleEventClick(clickInfo_event_id) {
      this.eventID = clickInfo_event_id;
      this.selectMainTab = 0;
      const formData = new FormData();
      formData.append("id", clickInfo_event_id);
      axios
        .post(
          process.env.VUE_APP_API_URL + "event/calenderEventDetail",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          let data = res.data.data;
          this.singleEvent = res.data.data;
          this.avatar = data.avatar;
          this.eventName = data.eventName;
          this.createdByName = data.createdByName;
          this.teamName = data.teamName;
          this.createdByDate = data.createdByDate;
          this.association = data.association ? data.association : "";
          this.eventType = data.eventType;
          this.status = data.status;
          this.address = data.address;
          this.city = data.city;
          this.Url = data.Url;
          this.exactStartDate = data.exactStartDate;
          this.exactEndDate = data.exactEndDate;
          this.allDay = data.allDay;
          this.exactStartTime = data.exactStartTime;
          this.time_zone = data.time_zone;
          this.exactEndTime = data.exactEndTime;
          this.teamID = data.team_id;
          this.eventID = data.id;

          this.UserDetail = [];
          this.UserRole = [];
          this.UserDetails.forEach((teams) => {
            if (teams.teamID == this.teamID) {
              this.UserDetail = teams.users;
              this.currentIsFan = teams.currentIsFan;
              this.UserRole = teams.userRole;
            }
          });
          this.getAttendee(data.id);

          this.selectedRoomTeam.eventCount = 0;
          this.selectedRoomTeam.eventCount =
            this.selectedRoomTeam.eventCount + 1;
          data.exactStartDate = data.exactStartDate.split(",")[0];
          var checkDateIfPast = parseTimestamp(
            data.endDateForField + "&&" + data.exactStartTime,
            "checkIfPast"
          );
          if (checkDateIfPast == "upComing") {
            this.eventTypeA = "upComing";
          } else {
            this.eventTypeA = "past";
          }
          this.getGames(data.id, data.team_id);

          this.$refs.modal.open();
        });
    },
    async selectStatusWithServices(status, eventID = "", teamID = "") {
      status = 1;
      let Event = eventID !== "" ? eventID : this.eventID;
      let Team = teamID !== "" ? teamID : this.teamID;

      if (Event !== "" && Team !== "" && status !== "") {
        var formData = new FormData();
        formData.append("event_id", Event);
        formData.append("member_status", status);
        formData.append("team_id", Team);
        formData.append("services", this.selectedArray);
        formData.append("room_count", this.room_count);
        formData.append("exactStartDate", this.exactRoomStartDate);
        formData.append("exactEndDate", this.exactRoomEndDate);
        formData.append("note", this.note);
        formData.append("adult_count", this.adult_count);
        await axios
          .post(
            process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(() => {
            this.getAttendee(Event);
            this.$refs.modal.open();
          });
      }
    },
    async selectStatus(status, eventID = "", teamID = "") {
      if (status == 1) {
        this.$root.$emit("openServicesModal");
        this.$refs.modal.close();
      } else {
        let Event = eventID !== "" ? eventID : this.eventID;
        let Team = teamID !== "" ? teamID : this.teamID;

        if (Event !== "" && Team !== "" && status !== "") {
          var formData = new FormData();
          formData.append("event_id", Event);
          formData.append("member_status", status);
          formData.append("team_id", Team);
          await axios
            .post(
              process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              this.getAttendee(Event);
            });
        }
      }
    },

    async getAttendee(Eventid) {
      this.showEventMemeberCountShimmer = 1;
      this.showLoader = 1;

      var formData = new FormData();
      formData.append("event_id", Eventid);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const eventAttendeeFromDB = response.data.data.attendeeListing;
          const tempMembers = this.UserDetail; //.users;
          const tempMembersRole = this.UserRole;
          this.membersAttendeeComplete = [];
          var $i = 0;
          this.totalGoing = 0;
          this.totalNotGoing = 0;
          this.totalMayBe = 0;
          this.totalNotRespond = 0;
          for (const member of tempMembers) {
            for (const memberRole of tempMembersRole) {
              if (memberRole.id == member.id && memberRole.fan != "1") {
                this.membersAttendeeComplete.push(member);
                this.membersAttendeeComplete[$i].event_attendee_status = 0;
                for (const attendee of eventAttendeeFromDB) {
                  if (member.c_id == attendee.member_id) {
                    this.membersAttendeeComplete[$i].event_attendee_status =
                      attendee.member_status;
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        1 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "1"
                    ) {
                      this.totalGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        2 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "2"
                    ) {
                      this.totalNotGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        3 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "3"
                    ) {
                      this.totalMayBe++;
                    }
                  }
                }
                if (
                  this.membersAttendeeComplete[$i].event_attendee_status == 0 ||
                  this.membersAttendeeComplete[$i].event_attendee_status == "0"
                ) {
                  this.totalNotRespond++;
                }
                if (member.id == this.currentUserId) {
                  this.currentUserEventStatus =
                    this.membersAttendeeComplete[$i].event_attendee_status;
                }
                $i++;
              }
            }
          }
          this.showEventMemeberCountShimmer = 0;
          this.filterGoing();
          this.showLoader = 0;
          this.getCurrentStatusupdate();
        });
    },

    filterGoing: function () {
      this.selectAttendeeTab = 0;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 1 ||
          attendee.event_attendee_status == "1"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },

    filterNotGoing: function () {
      this.selectAttendeeTab = 1;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 2 ||
          attendee.event_attendee_status == "2"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    filterMay: function () {
      this.selectAttendeeTab = 2;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 3 ||
          attendee.event_attendee_status == "3"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },

    filterNotResponded: function () {
      this.selectAttendeeTab = 3;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 0 ||
          attendee.event_attendee_status == "0"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },

    selectAttendee: function () {
      this.selectMainTab = 0;
    },
    selectGame: function () {
      this.selectMainTab = 1;
    },

    showEventsMemberDiv: function () {
      this.showDefualt = 0;
      this.showGameList = 0;
      this.showEventsMember = 1;
    },
    showGameListDiv: function () {
      this.showDefualt = 0;
      this.showGameList = 1;
      this.showEventsMember = 0;
    },
    showDefualtDiv: function () {
      this.showDefualt = 1;
      this.showGameList = 0;
      this.showEventsMember = 0;
    },
    async getGames(singleEvent, selectedRoomTeam) {
      this.showGameShimmer = 1;
      var formData = new FormData();
      formData.append("event_id", singleEvent);
      formData.append("team_id", selectedRoomTeam);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameList = response.data.data;
          this.showGameShimmer = 0;
        });
    },
    getCurrentStatusupdate: function () {
      let comingStatusData = {
        teamID: this.TeamDetails,
        usersDetail: this.UserDetails,
        users: this.currentUserId,
      };
      this.getCurrentComingStatus(comingStatusData);
    },
    checkStatus(endDateForField, exactStartTime) {
      // data.exactStartDate = data.exactStartDate.split(",")[0];
      var checkDateIfPast = parseTimestamp(
        endDateForField + "&&" + exactStartTime,
        "checkIfPast"
      );
      if (checkDateIfPast == "upComing") {
        return "upComing";
      } else {
        return "past";
      }
    },
    openGoogleMapforGamePark(item) {
      const strlatLng = item["park_lat"] + "," + item["park_lng"];
      const urlSuffix = strlatLng ? strlatLng : item["park_address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
  },

  // watch: {
  // },

  mounted() {
  this.page = 1;
  this.lastPage = 1;
  this.$root.$on(
    "dataForservice",
    (
      data,
      room_count,
      adultQuantity,
      note,
      exactRoomStartDate,
      exactRoomEndDate
    ) => {
      this.selectedArray = data;
      this.room_count = room_count;
      this.note = note;
      this.adult_count = adultQuantity;
      this.exactRoomEndDate = exactRoomEndDate;
      this.exactRoomStartDate = exactRoomStartDate;
      this.selectStatusWithServices();
    }
  );

},
};
</script>

<style scoped>
.content .img-wrapper {
  width: 100%;
  height: 200px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  background-color: #f1f3f6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content .img-wrapper img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.card-text {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
}

.created-by,
.date {
  font-size: 14px;
  font-weight: 500;
}

.event-description .created-by {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.event-description .date .content {
  margin-left: 43px;
}

.event-details-wrapper {
  padding-top: 10px;
  overflow-x: hidden;
  z-index: 99;
}

.event-details-top {
  display: flex;
  align-items: center;
}

.event-details-top .back-arrow {
  color: #188ef5;
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
  padding: 0 5px;
}

.event-details-top .back-arrow:hover {
  background-color: #188ef5;
  color: #ffffff;
  border-radius: 5px;
}

.event-details-top h6 {
  margin: 0;
  text-transform: uppercase;
  color: #a7a6a6;
  font-weight: 500;
}

.event-image-name {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.event-image-name .image {
  min-width: 120px;
  max-width: 120px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;
}

.event-image-name .image img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.created-by,
.date {
  font-size: 14px;
  font-weight: 500;
}

.created-by .heading,
.date .heading {
  color: #a7a6a6;
  margin-right: 3px;
  font-weight: 400;
}

.event-label h6 {
  color: #333333;
  font-weight: 400;
  margin: 10px 5px;
  font-size: 16px;
}

.cursor-p {
  cursor: pointer;
}

.cursor-p-null {
  cursor: default !important;
}

.event-label ul li.active {
  background-color: #188ef5;
  color: #ffffff;
}

.event-label ul li.active:before {
  content: "✓";
  position: absolute;
  top: -10px;
  background-color: #ff5b66;
  width: 15px;
  height: 15px;
  font-size: 10px;
  border-radius: 5px;
  text-align: center;
}

.view-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view-details span {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: #188ef5;
  /*cursor: pointer;*/
}

/* .user-counter .counting {
  display: block;
  align-items: center;
  padding-bottom: 8px;
}

.user-counter .counting li span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.user-counter .counting li span:first-child {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 5px;
}

.user-counter .counting li span:last-child {
  font-weight: 400;
  font-size: 13px;
  color: #b4b3b3; */
/* overflow: hidden; */
/* text-overflow: ellipsis; */
/* display: -webkit-box; */
/* -webkit-line-clamp: 1; number of lines to show */
/* -webkit-box-orient: vertical; */
/* } */

.games-section {
  padding: 12px 0;
}

.game-content-section {
  max-width: 350px;
  overflow-x: auto;
}

.game-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game-top .game-heading h6 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.game-top .game-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-content p {
  font-weight: 500;
  font-size: 10px;
  color: #ff5b66;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.actions-content {
  width: 12px;
  position: relative;
}

.game-location {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 7px;
}

.game-location p {
  font-weight: 500;
  font-size: 12px;
  color: #188ef5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.opponent-name h5 {
  font-weight: 500;
  font-size: 13px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.event-card {
  cursor: pointer;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  transition: all 0.7s;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
}

.event-card:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.content .img-wrapper img.default-event-image {
  width: 30px !important;
  height: 30px !important;
  border-radius: 0 0 0 0 !important;
}
.card-event {
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
}
</style>
