<template>
  <div class="position-relative">
    <div
      class="friends-ellipsis"
      @click="openFriendEllipsisOptions"
      v-click-outside="onClickOutside"
    >
      <img src="../../assets/images/ellipsis-h.png" alt="" />
    </div>

    <div class="sub-setting-options" v-if="friendEllipsisOptions">
      <ul class="d-block">
        <li @click="unfriend(items.user_id)">Remove Friend</li>
        <li
          data-toggle="modal"
          data-target="#individualModalDirect"
          @click="fetchDirectMessageData(items.user_name, items.user_id)"
        >
          Send Direct Message 
        </li>
      </ul>
    </div>

    <!-- Create Chat -->
    <DirectMessageModal :type="type" :userId="items.user_id" />
    <!-- Create chat modal -->
  </div>
</template>
<script>
import axios from "axios";

import DirectMessageModal from "../Teams/ChatRight/ChatSettingComponents/DirectMessageModal.vue";
export default {
  name: "FriendsEllipsis",
  components: {
    DirectMessageModal,
  },
  data() {
    return {
      friendEllipsisOptions: false,
      errorMessage: "",
      type: "friendsPage",
    };
  },
  props: {
    items: {
      type: Object,
    },
  },
  methods: {
    openFriendEllipsisOptions() {
      this.friendEllipsisOptions = !this.friendEllipsisOptions;
    },
    onClickOutside() {
      this.friendEllipsisOptions = false;
    },
    async unfriend(friend_id) {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("friend_id", friend_id);
      return axios
        .post(process.env.VUE_APP_API_URL + "friends/unFriend", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$root.$emit("FriendsPage");
            this.$root.$emit("FriendsOptions");
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },

    // ---- Send Message ----------------------------------------------------------------------
    fetchDirectMessageData(name, userID) {
      this.$root.$emit("fetchDirectMessageData", name, userID);
      // document.querySelector("body").style.overflow = "hidden";
    },
  },
};
</script>
