<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#PrintRoasterModal"
      ref="openModalPrint"
    ></button>
    <div
      class="modal fade"
      id="PrintRoasterModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Add to Print Roster
            </h5>
            <button
              type="button"
              class="close text-primary"
              data-dismiss="modal"
              aria-label="Close"
              ref="openModalPrintClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h6 class="mx-0">Going Teammates</h6>
            <div class="modal-body" style="padding-left: 0 !important">
              <div class="order-history">
                <!-- <h2>Going Teammates</h2> -->

                <div class="col-12 p-0 table-responsive">
                  <table
                    class="table table-hover print-table text-left"
                    v-if="users"
                  >
                    <thead class="">
                      <tr>
                        <th scope="col" class="pl-0">Name</th>
                        <th scope="col" class="pl-0">Uniform#</th>
                        <th scope="col" class="pl-0">Phone/Email</th>
                        <th scope="col" class="pl-0">Print</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style="word-break: break-word"
                        v-for="(user, index) in users"
                        :key="index + 100"
                      >
                        <td
                          v-if="user.member_status == 1"
                          scope="row"
                          style="min-width: 80px; max-width: 80px"
                          class="pl-0"
                        >
                          {{ user.fname }} {{ user.lname }}
                        </td>
                        <td
                          class="pl-0"
                          v-if="
                            user.member_status == 1 && user.uniform != 'null'
                          "
                          scope="row"
                          style="min-width: 80px; max-width: 80px"
                        >
                          {{ user.uniform }}
                        </td>
                        <td
                          class="pl-0"
                          scope="row"
                          style="min-width: 80px; max-width: 80px"
                          v-else
                        ></td>
                        <td class="pl-0">
                          <span v-if="user.member_status == 1">
                            {{ user.mob_code }}{{ user.mob }}
                          </span>
                          <span v-if="user.member_status == 1">{{
                            user.email
                          }}</span>
                        </td>

                        <td style="text-align: left" class="pl-0">
                          <div class="form-check pl-0">
                            <input
                              class="form-check-input ml-0"
                              type="checkbox"
                              id="blankCheckbox"
                              value="option1"
                              checked
                              aria-label="..."
                              @change="checkMark(user, $event)"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr
                        style="word-break: break-word"
                        v-for="(user, index) in InvitedList"
                        :key="index"
                      >
                        <td
                          scope="row"
                          style="min-width: 80px; max-width: 80px"
                          class="pl-0"
                        >
                          {{ user.fname }} {{ user.lname }}
                        </td>
                        <td
                          scope="row"
                          style="min-width: 80px; max-width: 80px"
                          v-if="user.uniform != 'null'"
                          class="pl-0"
                        >
                          {{ user.uniform }}
                        </td>
                        <td
                          scope="row"
                          style="min-width: 80px; max-width: 80px"
                          v-else
                          class="pl-0"
                        ></td>
                        <!-- <td scope="row" v-if="user.level == 2">Fan</td>
              <td scope="row" v-else>Teammate</td> -->
                        <td class="pl-0">{{ user.target }}</td>

                        <td style="text-align: left" class="pl-0">
                          <div class="form-check pl-0">
                            <input
                              class="form-check-input ml-0"
                              type="checkbox"
                              checked
                              id="blankCheckbox"
                              value="option1"
                              aria-label="..."
                              @change="checkMark(user, $event)"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="info-heading"
                    v-if="users.length == 0 && InvitedList.length == 0"
                  >
                    <p class="text-center" v-if="showLoader == 0">
                      No One marked going yet!
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center w-100 mt-4"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button
            type="button"
            class="btn btn-primary"
            @click="downloadFinalarray()"
            :disabled="finalArray.length === 0">
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ExcelJS from 'exceljs';
export default {
  name: "PrintRoasterModal",
  props: {
    users: {
      type: Array,
    },
    selectedRoomTeam: {
      type: Object,
    },
    fileName: {
      type: String,
    },
    InvitedList: {
      type: Array,
    },
  },
  data() {
    return {
      showLoader: 0,
      finalArray: [],
    };
  },
  methods: {
    checkMark(user, event) {
      if (event.target.checked) {
        this.finalArray.push(user);
      } else {
        var index = this.finalArray.findIndex(function(o) {
          return o.id === user.id;
        });
        if (index !== -1) this.finalArray.splice(index, 1);
      }
    },
    PrintPdf() {
      this.$root.$emit("openprintPdf", this.finalArray);
      var elem = this.$refs.openModalPrintClose;
      if (elem) {
        elem.click();
      }
    },
    downloadFinalarray() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");

      const headers = ["Name", "Uniform#", "Phone/Email", "Signature"];
      worksheet.addRow(headers);
      const headerRow = worksheet.getRow(1);
      headerRow.font = { bold: true }; 

      this.finalArray.forEach((user) => {
        const rowData = [
        user.fname && user.lname ? user.fname + " " + user.lname : "",
        user.uniform !== "null" && user.uniform ? user.uniform : "",
        user.member_status === 1 ? user.mob_code + user.mob : user.email ? user.email : "",
          ];
        worksheet.addRow(rowData);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.fileName+"_Roster.xlsx"; // Set the desired filename here
        link.click();
      });

      var elem = this.$refs.openModalPrintClose;
      if (elem) {
        elem.click();
      }
    },
  },
  mounted() {
    this.$root.$on("openPrintRoasterModal", () => {
      this.finalArray = [];
      this.finalArray = this.users.concat(this.InvitedList);
      $("#blankCheckbox").prop("checked", true);
      var elem = this.$refs.openModalPrint;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>

<style></style>
