var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentIsAdmin == '1' && _vm.currentTeamIsActive == 1)?_c('EditEvent',{attrs:{"singleEvent":_vm.singleEvent,"selectedRoomTeam":_vm.selectedRoomTeam,"currentUserId":_vm.currentUserId,"currentIsAdmin":_vm.currentIsAdmin,"currentTeamIsActive":_vm.currentTeamIsActive,"editEventOption":_vm.editEventOption}}):_vm._e(),(_vm.showLoader == 1)?_c('div',{staticClass:"order-history single-box-score-loader"},[_c('div',{staticClass:"lds-dual-ring-media"})]):_vm._e(),(_vm.showLoader == 0)?_c('div',{staticClass:"single-event-page bg-white p-3"},[_c('div',{staticClass:"sec-top d-flex justify-content-between"},[_c('div',{staticClass:"left-part"},[_c('h3',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.singleEvent.eventName)+" ")]),_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.singleEvent.eventType)+" "),(_vm.singleEvent.association)?_c('span',[_vm._v(" - "+_vm._s(_vm.singleEvent.association)+" ")]):_vm._e()]),_c('div',{staticClass:"event-list-details event-info-wrap"},[_c('div',{staticClass:"event-date"},[_c('img',{attrs:{"src":require("../../assets/images/calendar-event.png")}}),(
                _vm.singleEvent.exactStartDate != '' &&
                _vm.singleEvent.exactStartTime != '' &&
                _vm.singleEvent.exactEndTime != ''
              )?_c('div',{staticClass:"content"},[(_vm.singleEvent.exactStartDate == _vm.singleEvent.exactEndDate)?_c('span',[_vm._v(" "+_vm._s(_vm.singleEvent.exactStartDate)+" ")]):_c('span',[_vm._v(_vm._s(_vm.singleEvent.exactStartDate.split(",")[0])+" to "+_vm._s(_vm.singleEvent.exactEndDate))]),(_vm.singleEvent.allDay == 'true')?_c('span',[_vm._v(" All Day. ")]):_vm._e()]):_vm._e()]),(
              (_vm.singleEvent.status == 1 &&
                _vm.singleEvent.address != null &&
                _vm.singleEvent.address != '' &&
                _vm.singleEvent.address != 'null') ||
              (_vm.singleEvent.city != null &&
                _vm.singleEvent.city != '' &&
                _vm.singleEvent.city != 'null') ||
              (_vm.singleEvent.state != null &&
                _vm.singleEvent.state != '' &&
                _vm.singleEvent.state != 'null')
            )?_c('p',{staticClass:"location-content",on:{"click":function($event){return _vm.openGoogleMap(_vm.singleEvent)}}},[_c('img',{attrs:{"src":require("../../assets/images/location.png")}}),_c('span',[_vm._v(_vm._s(_vm.singleEvent.address)+" "+_vm._s(_vm.singleEvent.city)+", "+_vm._s(_vm.singleEvent.state))])]):_vm._e(),(_vm.singleEvent.status == 2)?_c('span',{staticClass:"location-content"},[_c('img',{attrs:{"src":require("../../assets/images/link.png")}}),_c('a',{staticClass:"mr-0 font-weight-normal",attrs:{"href":_vm.singleEvent.Url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.singleEvent.Url)+" ")])]):_vm._e()])]),_c('div',{staticClass:"right-part"},[_c('router-link',{staticClass:"text-primary text-capitalize text-right mb-4 cursor-pointer",attrs:{"to":`/game-events/${_vm.selectedRoomTeam.id}`}},[_c('h4',[_vm._v(_vm._s(_vm.selectedRoomTeam.teamName))])]),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('SingleEventDetailPlay',{attrs:{"singleEvent":_vm.singleEvent}}),_c('div',{staticClass:"opt mt-0 ml-2 position-relative float-md-right cursor-pointer"},[_c('img',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],attrs:{"src":require("../../assets/images/ellipsis-h.png"),"alt":""},on:{"click":_vm.openEditEventOption}}),(_vm.editEventOption)?_c('div',{staticClass:"sub-setting-options edit-event-options"},[_c('ul',{staticClass:"d-block pb-0"},[(_vm.currentIsAdmin == '1' && _vm.currentTeamIsActive == 1)?_c('li',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.callModal()}}},[_vm._v(" Edit Event ")]):_vm._e()])]):_vm._e()])],1)],1)]),_c('div',{staticClass:"friends-options p-0"},[_c('ul',{staticClass:"options-list"},[_c('li',[_c('a',{staticClass:"m-0",class:{
              active: _vm.isActiveGames,
            },attrs:{"data-tab":"games"},on:{"click":function($event){return _vm.switchTabs('games')}}},[_vm._v(" Games ")])]),_c('li',[_c('a',{staticClass:"m-0",class:{
              active: _vm.isActiveAttendees,
            },attrs:{"data-tab":"attendees"},on:{"click":function($event){return _vm.switchTabs('attendees')}}},[_vm._v("Attendees ")])])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"game-details"},[(
              _vm.currentIsAdmin == '1' &&
              _vm.selectedRoomTeam.teamType == 'sports' &&
              _vm.currentTeamIsActive == 1 && 
              _vm.isActiveGames
            )?_c('NewGame',{attrs:{"selectedRoomTeam":_vm.selectedRoomTeam,"singleEvent":_vm.singleEvent}}):_vm._e()],1)]),(_vm.isActiveGames)?_c('div',{ref:"GamesTab",staticClass:"ui bottom attached tab segment",class:{ active: _vm.isActiveGames },attrs:{"data-tab":"games"}},[_c('GameInfo',{attrs:{"selectedRoomTeam":_vm.selectedRoomTeam,"currentIsAdmin":_vm.currentIsAdmin,"games":_vm.games,"showGameLoader":_vm.showGameLoader,"eventAlertOptions":_vm.eventAlertOptions,"currentTeamIsActive":_vm.currentTeamIsActive}})],1):_vm._e(),(_vm.isActiveAttendees)?_c('div',{staticClass:"ui bottom attached tab segment",class:{ active: _vm.isActiveAttendees },attrs:{"data-tab":"attendees"}},[_c('AttendeesInfo',{attrs:{"selectedRoomTeam":_vm.selectedRoomTeam,"currentUserId":_vm.currentUserId,"currentIsAdmin":_vm.currentIsAdmin,"singleEvent":_vm.singleEvent}})],1):_vm._e()])]):_vm._e(),(_vm.games.length)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.handleScrollToBottom),expression:"handleScrollToBottom"}]}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }