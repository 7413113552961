<template>
  <div>
    <input
      class="d-none"
      data-target="#BoxViewScoresModal"
      data-toggle="modal"
      ref="openBoxViewScoresModal"
    />
    <div
      class="modal fade"
      id="BoxViewScoresModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Box Score</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeKeepScoresModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">
            <!-- Start -->
            <div>
              <div class="game_name11">{{ gameScore.event_name }}</div>
              <div class="container-fluid no-pdd">
                <div class="row no-gutters">
                  <div class="col-12 pt-3">
                    <div class="game-detail-wrapper">
                      <div class="game-content">
                        <div class="date-content">
                          <p>{{ gameScore.date }}</p>
                        </div>
                        <div class="game-name">
                          <h6>{{ gameScore.game_name }}</h6>
                        </div>
                        <div
                          v-if="gameScore.location"
                          @click="openGoogleMapforGamePark(gameScore.location)"
                          class="mb-2 mt-2 cursor-pointer"
                        >
                          <p
                            v-if="
                              gameScore.location.address != '' &&
                                gameScore.location.address != 'null' &&
                                gameScore.location.address != null
                            "
                          >
                            <img
                              src="../../assets/images/location.png"
                              class="mr-2"
                            />{{ gameScore.location.address }}
                          </p>
                          <p v-else>
                            <img
                              src="../../assets/images/location.png"
                              class="mr-2"
                            />{{ gameScore.location.park_name }}
                          </p>
                        </div>
                        <!-- <div class="opponent-name">
                          <h5>{{ gameScore.opponent_name }}</h5>
                        </div> -->
                        <div v-if="gameScore.comment!=null && gameScore.comment!='' && gameScore.comment!='null'" class="game-description">
                          <p>{{ gameScore.comment }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-tables d-flex align-items-centered"
              v-if="gameScore != []"
            >
              <table class="table table-teams table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>Inning</th>
                  </tr>
                </thead>
                <tbody v-for="score in gameScore.score" :key="score.id + 1000">
                  <tr v-if="score.team_type == 1">
                    <th scope="row">
                      <div class="team-name">{{ score.team_name }}</div>
                      <div class="team-type">Visiting Team</div>
                    </th>
                    <th>Runs</th>
                  </tr>
                  <tr v-if="score.team_type == 1">
                    <th scope="row"></th>
                    <th>HR</th>
                  </tr>
                </tbody>
                <tbody v-for="score in gameScore.score" :key="score.id + 2000">
                  <tr v-if="score.team_type == 2">
                    <th scope="row">
                      <div class="team-name">{{ score.team_name }}</div>
                      <div class="team-type">Home Team</div>
                    </th>
                    <th>Runs</th>
                  </tr>
                  <tr v-if="score.team_type == 2">
                    <th scope="row"></th>
                    <th>HR</th>
                  </tr>
                </tbody>
              </table>
              <Inning />
            </div>
            <!-- End -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Inning from "../BoxScores/inning.vue";
export default {
  name: "inningModal",
  components: {
    Inning,
  },
  data: function() {
    return {
      gameScore: [],
    };
  },
  methods: {
    openGoogleMapforGamePark(item) {
      const strlatLng = item["lat"] + "," + item["lng"];
      const urlSuffix = strlatLng ? strlatLng : item["address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
  },
  mounted() {
    this.$root.$on("openBoxViewScoresModal", (score) => {
      this.gameScore = [];
      this.gameScore = score;
      var elem = this.$refs.openBoxViewScoresModal;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>

<style scoped>
.modal-tables {
  width: 100%;
  margin-bottom: 15px;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -ms-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  -o-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
}
.table {
  text-align: center;
  font-size: 13px;
  background-color: #ffffff;
}

.table-teams {
  width: 40%;
}

.table-teams {
  -webkit-border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -ms-border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
}

.table.table-teams tbody tr:nth-child(1) {
  height: 70px !important;
  border-right: 0 !important;
}

.table thead th,
.table tbody td {
  height: 70px;
}

.table tbody + tbody {
  border: none !important;
}
.table.table-teams .team-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.game_name11 {
  border-right: none;
  font-size: 17px;
  color: #188ef5 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-align: left;
}

.date-date {
  font-size: 12px;
  text-align: left;
  color: #dc3545 !important;
  font-weight: 400;
}

@media screen and (max-width: 576px) {
  /* .table {
    font-size: 8px;
  } */

  .table th {
    padding: 0.75rem 0.3rem;
  }

  .table-teams {
    width: 50%;
  }
}

@media screen and (max-width: 380px) {
  .date-date {
    font-size: 9px;
  }

  .table.table-teams thead tr:nth-child(1) {
    height: 70px !important;
  }
  .table.table-teams tbody tr:nth-child(1) {
    height: 70px !important;
  }
  .table.table-teams tbody tr:nth-child(2) {
    height: 70px !important;
  }
}
.table-teams.table-bordered thead tr th:nth-child(1) {
  border-right: 0 !important;
  border-left: 0 !important;
}
.table-teams.table-bordered thead tr th:nth-child(2) {
  border-left: 0 !important;
  border-right: 0 !important;
}

.table-teams.table-bordered tbody tr div:nth-child(1) {
  border-bottom: 0 !important;
}

.date-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-content p {
  font-weight: 500;
  font-size: 10px;
  color: #ff5b66;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}


.game-location {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 7px;
}

.game-location p {
  font-weight: 500;
  font-size: 12px;
  color: #188ef5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.opponent-name h5 {
  font-weight: 500;
  font-size: 13px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
</style>
