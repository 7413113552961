<template>
  <div>
    <Navbar />
    <InviteFriendsModal />
    <FlashMessage />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pb-3 pr-3">
          <div class="row no-gutters post-input p-0">
            <div class=" card-body col-12 col-lg-12">
                <div style="position:relative;padding-bottom:56.25%;overflow:hidden;height:0;max-width:100%;"><iframe id="acba0245-71be-8041-f60a-1d408ced8432" src="https://iframe.dacast.com/live/1d3f3074-8d72-27c4-bb7e-15f047fd1ea1/acba0245-71be-8041-f60a-1d408ced8432" width="100%" height="100%" frameborder="0" scrolling="no" allow="autoplay;encrypted-media" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen style="position:absolute;top:0;left:0;"></iframe></div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import InviteFriendsModal from "../components/Home/InviteFriendsModal.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";

import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    Navbar,
    LeftMenu,
    InviteFriendsModal,
    Banner
  },
  name: "LiveStream",

  data() {
    return {
      showInviteFriend: 0,
      showfriendRequest: 0,
      friendsRequestsCount: 0,
      grpInfo: null,
      linkGetUrl: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth", ["getGrpData"]),
    async sideLeftView() {
      this.showInviteFriend = 0;
      let friendReq = await axios.get(`friends/friendRequestCount`);

      if (friendReq.data.statusCode == 200) {
        this.friendsRequestsCount = friendReq.data.data.count;
        if (this.friendsRequestsCount > 0) {
          this.showFriendRequestList();
        } else {
          this.showInviteButton();
        }
      } else {
        this.showInviteButton();
      }
      this.$root.$emit("FriendRequestshowLoaderOff");
    },

    showInviteButton: function () {
      this.showInviteFriend = 1;
      this.showfriendRequest = 0;
    },

    showFriendRequestList: function () {
      this.showInviteFriend = 0;
      this.showfriendRequest = 1;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.sideLeftView(1);
    this.$root.$on("FriendRequestCountCheck", () => {
      this.sideLeftView(1);
    });
  },
};
</script>
