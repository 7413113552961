<template>
    <div class="delivery-bottom" style="width: 45%">
      <router-link to="/shipping" exact class="page-back">Previous</router-link>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "PaymentMethod",
  computed: {
    ...mapState("cart", ["cart"]),
    ...mapGetters("cart", ["cartTotalPrice"]),
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      errorMessage: "",
      showLoader: 0,
      total: 26,
      firstname: "",
      lastname: "",
      month: "",
      year: "",
      // phone: "",
      saveCard: false,
      address_1: "100 S Orange Ave",
      address_2: "Suite 400",
      address_city: "Orlando",
      address_state: "FL",
      address_zip: "32811",
      address_country: "USA",
      send_receipt: false,
      validate: false,
      StaxPayKey: this.$staxPayKey,
      meta: {
        reference: "invoice-reference-num", // optional - will show up in emailed receipts
        memo: "notes about this transaction", // optional - will show up in emailed receipts
        otherField1: "other-value-1", // optional - we don't care
        otherField2: "other-value-2", // optional - we don't care
        subtotal: 0, // optional - will show up in emailed receipts
        tax: 0, // optional - will show up in emailed receipts, and used for L2 processing. Accepts the tax in dollar amount. To qualify for L2 processing rates, the tax dollar amount must be equivalent to 0.1% and 30% of the transaction's total.
        poNumber: "7649", // customer code used for L2 processing.
        shippingAmount: 0, // the shipping amount for the transaction used for L2 processing
        lineItems: [
          // optional - will show up in emailed receipts
          {
            id: "optional-fm-catalog-item-id",
            item: "Demo Item",
            details: "this is a regular demo item",
            quantity: 0,
            price: 0,
          },
        ],
        invoice_merchant_custom_fields: [
          {
            id: "dc4b-6c74-00dd-fab1-fe00", // Required, must be a unique string.
            name: "External ID", // The name of the custom field that will be displayed to your customers and users; this will appear above the field as a label.
            placeholder: "Ex. #123", // The placeholder for the field; this is the faint text that will appear in the entry box of your custom field to help guide your users before they enter in the value when creating an Invoice.
            required: true, // Determines if this field is required to be filled by the user (not customer) when first creating an Invoice.
            type: "text", // Input type. Only 'text' is supported.
            value: "123456789", // The value that will appear when viewing the Invoice or Invoice Email. For the merchant, this will also appear when viewing the Invoice via the Invoices or Edit Invoice pages.
            visible: true, // This determines if the field is visible when viewing the Invoice or Invoice Email. If false, will only appear in merchant-facing pages such as the Invoices or Edit Invoice pages.
          },
        ],
      },
    };
  },
  methods: {
    payment() {},
    ...mapActions({
      clearCartItems: "cart/clearCartItems",
    }),
    saveCardNotify: function() {
      if (this.saveCard == true) {
        this.saveCard = false;
      } else {
        this.saveCard = true;
      }
    },
  },
  mounted() {
    this.payment();
  },
};
</script>
<style scoped>
.group {
  border-radius: 5px;
}

/* label {
  position: relative;
  color: #8898aa;
  font-weight: 300;
  display: flex;
  align-items: center;
}

label > span {
  width: 80px;
  margin-right: 10px;
} */

.field {
  background: transparent;
  font-weight: 300;
  color: #31325f;
  outline: none;
  flex: 1;
  padding-right: 15px;
  padding-left: 15px;
  cursor: text;
  height: 45px;
}

.field::-webkit-input-placeholder {
  color: #cfd7e0;
}

.field::-moz-placeholder {
  color: #cfd7e0;
}

.outcome {
  margin-top: 15px;
  width: auto;
}

.success {
  background-color: #e2f5fe;
}

.success,
.loader {
  display: none;
  font-size: 13px;
  border-radius: 5px;
  padding: 15px;
}

.success.visible,
.error.visible {
  display: inline;
}

.loader.visible {
  display: block;
  width: 20px;
  height: 20px;
  margin-top: 15px;
}

.error{
  color: #e4584c;
}

.success {
  color: #666ee8;
}

.success .token {
  font-weight: 500;
  font-size: 13px;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #666ee8; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin: 8px;
}

.loader-small {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #666ee8; /* Blue */
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input-box {
  border-radius: 5px;
  border: 1px solid #8898aa;
}
form {
  margin: 20px 0;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
#card-element {
  font-size: 16px;
  flex-grow: 1;
}
.validity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin-top: -4px;
}

@media screen and (max-width: 955px) and (min-width: 320px) {
  label > span {
    margin-right: 0;
  }
  .field {
    margin-top: 10px;
    padding: 12px 10px;
  }
}
</style>
