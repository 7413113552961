var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"event-list-image",on:{"click":function($event){return _vm.showDetails(_vm.event)}}},[_c('div',{staticClass:"image"},[(_vm.imgLoader == 1)?_c('div',{staticClass:"shop-products-img-loader"},[_c('div',{staticClass:"lds-dual-ring-media"})]):_vm._e(),(_vm.event.avatar == '' || _vm.event.avatar == null)?_c('img',{attrs:{"src":require("../../../../assets/event-avatar.png"),"alt":""},on:{"load":_vm.onImageLoad}}):_c('img',{attrs:{"src":_vm.eventURL + _vm.event.avatar,"alt":""},on:{"load":_vm.onImageLoad}})]),_c('div',{staticClass:"content-info"},[_c('h3',{staticClass:"event-name"},[_vm._v(" "+_vm._s(_vm.event.event_tournaments && _vm.event.event_tournaments.length > 0 ? _vm.event.eventName + "/"+ _vm.event.event_tournaments[0].tournamentName : _vm.event.eventName)+" ")]),_c('div',[(_vm.selectedRoomTeam.sportType == 'Softball (Slow Pitch)' ||
                        _vm.selectedRoomTeam.sportType == 'Softball (Fast Pitch)' ||
                        _vm.selectedRoomTeam.sportType == 'Softball' && _vm.event.association != '' && _vm.event.association != null && _vm.event.association != 'null')?_c('div',{staticClass:"association"},[_c('span',{staticClass:"heading"},[_vm._v("Association")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.event.association))])]):_vm._e(),_c('div',{staticClass:"event-type"},[_c('span',{staticClass:"heading"},[_vm._v("Event Type")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.event.eventType)+" "),(_vm.event.status == 2)?_c('span',[_vm._v("(Online)")]):_vm._e()])])])])]),_c('div',{staticClass:"event-list-details event-info-wrap",on:{"click":function($event){return _vm.showDetails(_vm.event)}}},[_c('div',{staticClass:"event-date"},[_c('img',{attrs:{"src":require("../../../../assets/images/calendar-event.png")}}),(
          _vm.event.exactStartDate != '' &&
          _vm.event.exactStartTime != '' &&
          _vm.event.exactEndTime != ''
        )?_c('div',{staticClass:"content"},[(_vm.event.exactStartDate == _vm.event.exactEndDate)?_c('span',[_vm._v(" "+_vm._s(_vm.event.exactStartDate)+" ")]):_c('span',[_vm._v(_vm._s(_vm.event.exactStartDate.split(",")[0])+" to "+_vm._s(_vm.event.exactEndDate))]),(_vm.event.allDay == 'true')?_c('span',[_vm._v(" All Day. ")]):_c('span',[_vm._v(" From "+_vm._s(_vm.event.exactStartTime)+" to "+_vm._s(_vm.event.exactEndTime)+" ")]),(_vm.event.time_zone !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.event.time_zone.split(")").pop())+" ")]):_vm._e()]):_vm._e()]),(
        (_vm.event.status == 1 &&
          _vm.event.address != null &&
          _vm.event.address != '' &&
          _vm.event.address != 'null') ||
        (_vm.event.city != null && _vm.event.city != '' && _vm.event.city != 'null') ||
        (_vm.event.state != null && _vm.event.state != '' && _vm.event.state != 'null')
      )?_c('p',{staticClass:"location-content",on:{"click":function($event){return _vm.openGoogleMap(_vm.event)}}},[_c('img',{attrs:{"src":require("../../../../assets/images/location.png")}}),_c('span',[_vm._v(_vm._s(_vm.event.address)+" "+_vm._s(_vm.event.city)+", "+_vm._s(_vm.event.state))])]):_vm._e(),(_vm.event.status == 2)?_c('span',{staticClass:"location-content"},[_c('img',{attrs:{"src":require("../../../../assets/images/link.png")}}),_c('a',{staticClass:"mr-0 font-weight-normal",attrs:{"href":_vm.event.Url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.event.Url)+" ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }