<template>
  <div>
    <!-- Edit Model -->
    <div
      class="modal fade bd-example-modal-lg"
      id="editProfileContact"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Edit Contact</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalPostEdit"
              @click="closeOldDiv"
            >
              <img src="../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="team-name-type">
                <div class="form-group" style="margin-top: 5px">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required
                    v-model="details.city"
                    v-on:keyup="errorNull()"
                  />
                  <span class="placeholder">Your City</span>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required
                    v-model="details.state"
                    v-on:keyup="errorNull()"
                  />
                  <span class="placeholder">Your State</span>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required
                    v-model="details.address"
                    v-on:keyup="errorNull()"
                  />
                  <span class="placeholder">Address</span>
                </div>
                <div class="container-fluid p-0">
                  <div class="row no-gutters">
                    <div class="form-group col-sm-6 pr-md-2">
                      <input
                        type="text"
                        class="form-control textbox-n"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onfocus="(this.type='date')"
                        onblur="(this.type='text')"
                        name="date"
                        @input="checkPreviuosDOB()"
                        v-model.trim="$v.details.dob.$model"
                        :class="{
                          'is-invalid': validationStatus($v.details.dob),
                        }"
                        v-on:keyup="errorNull()"
                      />
                      <span class="placeholder">Date of Birth</span>
                      <div
                        v-if="!$v.details.dob.required"
                        class="invalid-feedback error-message"
                      >
                        Date of birth is required
                      </div>
                    </div>
                    <div class="form-group col-sm-6">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        required
                        v-model="details.zip_code"
                        v-on:keyup="errorNull()"
                      />
                      <span class="placeholder">Zip Code</span>
                    </div>
                  </div>
                </div>
                <div class="container-fluid p-0">
                  <div class="row no-gutters">
                    <!-- <div class="form-group col-sm-4 pr-md-2">
                      <select
                        class="form-control"
                        id="FormControlSelectcode"
                        v-model="details.mobCode"
                      >
                        <option
                          :value="code.value"
                          v-for="code in codeList"
                          :key="code.code"
                        >
                          {{ code.text }}
                        </option>
                      </select>
                    </div> -->
                    <!-- <div class="form-group col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        required
                        v-model.trim="$v.details.mob.$model"
                        :class="{
                          'is-invalid': validationStatus($v.details.mob),
                        }"
                      />
                      <span class="placeholder">Contact Number</span>
                      <div
                        v-if="!$v.details.mob.integer"
                        class="invalid-feedback error-message"
                      >
                        Mobile number should be numbers.
                      </div>
                      <div
                        v-else-if="!$v.details.mob.minLength"
                        class="invalid-feedback error-message"
                      >
                        Minimum Length Should Be 10.
                      </div>
                    </div> -->
                    <div class="form-group col-sm-12">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        required
                        v-model="details.hometown"
                        v-on:keyup="errorNull()"
                      />
                      <span class="placeholder">Your Hometown</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <!-- <label class="typo__label" for="">Select Gender</label> -->
                  <select
                    class="form-control form-control-lg"
                    id="exampleFormControlSelect1"
                    v-model.trim="$v.details.gender.$model"
                    :class="{
                      'is-invalid': validationStatus($v.details.gender),
                    }"
                    v-on:change="errorNull()"
                  >
                    <option value="" selected disabled hidden
                      >Select Gender</option
                    >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Others</option>
                  </select>
                  <!-- <span class="placeholder">Select Gender</span> -->
                  <div
                    v-if="!$v.details.gender.required"
                    class="invalid-feedback error-message"
                  >
                    Gender is required
                  </div>
                </div>
                <div class="form-group">
                  <!-- <label class="typo__label" for="">Select Country</label> -->
                  <select
                    class="form-control form-control-lg"
                    id="exampleFormControlSelect1"
                    v-model="details.country"
                    v-on:change="errorNull()"
                  >
                    <option value="" selected disabled hidden
                      >Select Country</option
                    >
                    <option
                      :value="country.name"
                      v-for="country in countryList"
                      :key="country.code"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <!-- <button
            class="auth-btn text-white px-3"
            @click="updateContact(0)"
            :disabled="isLoadingArray[0]"
          >
            <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
            <span v-else>Save</span>
          </button> -->
          <div class="event-btns-wrapper justify-content-end p-3">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              @click="updateContact(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
let countries = require("../../assets/js/country");
import { required } from "vuelidate/lib/validators";
export default {
  name: "updateContact",
  props: {
    items: {
      type: Object,
    },
  },

  data() {
    return {
      formatted_date: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/"),
      countryList: countries,
      isLoadingArray: [],
      isDisabled: true,
      details: {
        dob: "",
        city: "",
        country: "",
        hometown: "",
        // mob: "",
        // mobCode: "+1",
        gender: "",
        zip_code: "",
        state: "",
        address: "",
      },
      // codeList: [
      //   { value: "+92", text: "Pak (+92)" },
      //   { value: "+1", text: "USA (+1)" },
      // ],
    };
  },
  validations: {
    details: {
      dob: { required },
      gender: { required },
      // mob: { integer, minLength: minLength(10) },
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    checkPreviuosDOB(){
      const newDob = event.target.value;
      if( newDob !== this.user && this.user.dob){
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    closeOldDiv: function() {
      this.$root.$emit("closeEditProfileModal");
      if (this.user) {
        this.details.dob = this.user.dob;
        this.details.city = this.user.city;
        this.details.hometown = this.user.hometown;
        // this.details.mob = this.user.mob;
        if (this.user.gender != null) {
          this.details.gender = this.user.gender;
        } else {
          this.details.gender = "";
          this.isDisabled = true;
        }
        if (this.user.country != null) {
          this.details.country = this.user.country;
        } else {
          this.details.country = "";
        }
        this.details.zip_code = this.user.zip_code;
        this.details.state = this.user.state;
        this.isDisabled = true;
      }
    },
    errorNull: function() {
      if (
        this.details.dob == "" ||
        this.details.dob == null ||
        this.details.gender == null ||
        this.details.gender == ""||
        this.details.city == "" ||
        this.details.country == "" ||
        this.details.hometown == "" ||
        this.details.zip_code == "" ||
        this.details.state == "" ||
        this.details.address == ""
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    getValues() {
      if (this.user) {
        this.details.dob = this.user.dob;
        this.details.city = this.user.city;
        this.details.hometown = this.user.hometown;
        // this.details.mob = this.user.mob;
        if (this.user.gender != null) {
          this.details.gender = this.user.gender;
        } else {
          this.details.gender = "";
        }
        if (this.user.country != null) {
          this.details.country = this.user.country;
        } else {
          this.details.country = "";
        }
        this.details.zip_code = this.user.zip_code;
        this.details.state = this.user.state;
        this.details.address = this.user.address;
      }
    },
    validationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    ...mapActions("auth", ["getUserData"]),
    updateContact: function(index) {
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append("city", this.details.city ? this.details.city : "");
      formData.append(
        "country",
        this.details.country ? this.details.country : ""
      );
      formData.append(
        "hometown",
        this.details.hometown ? this.details.hometown : ""
      );
      formData.append("gender", this.details.gender ? this.details.gender : "");
      // formData.append("mob", this.details.mob ? this.details.mob : "");
      // formData.append(
      //   "mobCode",
      //   this.details.mobCode ? this.details.mobCode : ""
      // );
      formData.append("dob", this.details.dob ? this.details.dob : "");
      formData.append("state", this.details.state ? this.details.state : "");
      formData.append(
        "address",
        this.details.address ? this.details.address : ""
      );
      formData.append(
        "zip_code",
        this.details.zip_code ? this.details.zip_code : ""
      );
      return axios
        .post(process.env.VUE_APP_API_URL + "user/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            var elem = this.$refs.closeModalPostEdit;
            elem.click();
            this.getUserData();
            this.$set(this.isLoadingArray, index, false);
          } else {
            setTimeout(() => {
              this.$set(this.isLoadingArray, index, false);
              this.errorMessage = response.data.message;
            }, 500);
          }
        });
    },
  },
  mounted: function() {
    if (this.user) {
      this.details.dob = this.user.dob;
      // this.details.mobCode = this.details.mobCode
      //   ? this.details.mobCode
      //   : this.user.mob_code;
      this.details.dob = this.user.dob;
      this.details.city = this.user.city;
      this.details.hometown = this.user.hometown;
      // this.details.mob = this.user.mob;
      if (this.user.gender != null) {
        this.details.gender = this.user.gender;
      } else {
        this.details.gender = "";
        this.isDisabled = true;
      }
      if (this.user.country != null) {
        this.details.country = this.user.country;
      } else {
        this.details.country = "";
      }
      this.details.zip_code = this.user.zip_code;
      this.details.state = this.user.state;
      this.details.address = this.user.address;
    }
    this.$root.$on("updateContact", () => {
      this.getValues(1);
    });
    this.formatted_date = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-");
    document
      .getElementsByName("date")[0]
      .setAttribute("max", this.formatted_date);
  },
};
</script>
<style scoped>
/* Important part */
.modal {
  overflow-y: initial !important;
}
/* .modal-body{
    height: 80vh;
    overflow-y: auto;
} */
#FormControlSelectcode {
  height: calc(2.25rem + 13px);
  font-size: 1rem;
}
</style>
