<template>
  <div>
    <h2 class="main-heading">Collecting and Using Your Personal Data</h2>
    <h6 class="collecting-types">Types of Data Collected</h6>
    <div class="collecting-list-items">
      <div class="personal-data">
        <p class="heading">Personal Data</p>
        <p class="content">
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Mobile Number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Usage Data</li>
        </ul>
      </div>
      <div class="usage-data">
        <p class="heading">Usage Data</p>
        <p class="content">
          Usage Data is collected automatically when using the Service.
        </p>
        <p class="content">
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p class="content">
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p class="content">
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
      </div>
      <div class="info-collected">
        <p class="heading">Information Collected while Using the Application</p>
        <p class="content">
          While using Our Application, in order to provide features of Our
          Application, We may collect, with Your prior permission:
        </p>
        <ul>
          <li>Information regarding your location</li>
          <li>
            Pictures and other information from your Device's camera and photo
            library
          </li>
        </ul>
        <p class="content">
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it may be
          simply stored on Your device.
        </p>

        <p class="content">
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </p>
      </div>
      <div class="tracking-technologies">
        <p class="heading">Tracking Technologies and Cookies</p>
        <p class="content">
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <ul>
          <li>
            <div>
              <span>Cookies or Browser Cookies.</span> A cookie is a small file
              placed on Your Device. You can instruct Your browser to refuse all
              Cookies or to indicate when a Cookie is being sent. However, if
              You do not accept Cookies, You may not be able to use some parts
              of our Service. Unless you have adjusted Your browser setting so
              that it will refuse Cookies, our Service may use Cookies.
            </div>
          </li>
          <li>
            <div>
              <span>Flash Cookies</span> Certain features of our Service may use
              local stored objects (or Flash Cookies) to collect and store
              information about Your preferences or Your activity on our
              Service. Flash Cookies are not managed by the same browser
              settings as those used for Browser Cookies. For more information
              on how You can delete Flash Cookies, please read "Where can I
              change the settings for disabling, or deleting local shared
              objects?" available at
              <router-link to="" class="link-info">
                https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</router-link
              >
            </div>
          </li>
          <li>
            <div>
              <span>Web Beacons.</span> Certain sections of our Service and our
              emails may contain small electronic files known as web beacons
              (also referred to as clear gifs, pixel tags, and single-pixel
              gifs) that permit the Company, for example, to count users who
              have visited those pages or opened an email and for other related
              website statistics (for example, recording the popularity of a
              certain section and verifying system and server integrity).
            </div>
          </li>
        </ul>
        <p class="content">
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. You can learn more about cookies here:
          <router-link to="">Cookies by TermsFeed Generator. </router-link>
        </p>
        <p class="content my-2">
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </p>
        <ul class="cookies-types">
          <li>Necessary / Essential Cookies</li>
          <li><span>Type:</span> Session Cookies</li>
          <li><span>Administrated By:</span> Us</li>
          <li>
            <span>Purpose:</span> Cookies are essential to provide You with
            services available through the Website and to enable You to use some
            of its features. They help to authenticate users and prevent
            fraudulent use of user accounts. Without these Cookies, the services
            that You have asked for cannot be provided, and We only use these
            Cookies to provide You with those services.
          </li>
          <li>Cookies Policy / Notice Acceptance Cookies</li>
          <li><span>Type:</span> Persistent Cookies</li>
          <li><span>Administrated By:</span> Us</li>
          <li>
            <span>Purpose:</span> Cookies identify if users have accepted the
            use of cookies on the Website.
          </li>
          <li>Functionality Cookies</li>
          <li><span>Type:</span> Persistent Cookies</li>
          <li><span>Administrated By:</span> Us</li>
          <li>
            <span>Purpose:</span> These Cookies allow us to remember choices You
            make when You use the Website, such as remembering your login
            details or language preference. The purpose of these Cookies is to
            provide You with a more personal experience and to avoid You having
            to re-enter your preferences every time You use the Website
          </li>
        </ul>
        <p class="content">
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>
      </div>
      <div class="tracking-technologies">
        <p class="heading">Use of Your Personal Data</p>
        <p class="content mb-2">
          The Company may use Personal Data for the following purposes:
        </p>
        <div class="para-list-items">
          <p>
            <span>To provide and maintain our Service,</span>including to
            monitor the usage of our Service.
          </p>
          <p>
            <span>To manage Your Account:</span>to manage Your registration as a
            user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user.
          </p>
          <p>
            <span>For the performance of a contract:</span>the development,
            compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other
            contract with Us through the Service.
          </p>
          <p>
            <span>To contact You:</span>To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application's push notifications regarding updates
            or informative communications related to the functionalities,
            products or contracted services, including the security updates,
            when necessary or reasonable for their implementation.
          </p>
          <p>
            <span>To provide You</span>with news, special offers and general
            information about other goods, services and events which we offer
            that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information.
          </p>
          <p>
            <span>To manage Your requests:</span>attend and manage Your requests
            to Us.
          </p>
          <p>
            <span>For business transfers:</span>We may use Your information to
            evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred.
          </p>
          <p>
            <span>For other purposes:</span>may use Your information for other
            purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </p>
        </div>
        <p class="content">
          We may share Your personal information in the following situations:
        </p>
        <ul>
          <li>
            <div>
              <span>With Service Providers:</span> may share Your personal
              information with Service Providers to monitor and analyze the use
              of our Service, for payment processing, to contact You.
            </div>
          </li>
          <li>
            <div>
              <span>For business transfers:</span> We may share or transfer Your
              personal information in connection with, or during negotiations
              of, any merger, sale of Company assets, financing, or acquisition
              of all or a portion of Our business to another company.
            </div>
          </li>
          <li>
            <div>
              <span>With Affiliates:</span> We may share Your information with
              Our affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </div>
          </li>
          <li>
            <div>
              <span>With business partners:</span> may share Your information
              with Our business partners to offer You certain products, services
              or promotions.
            </div>
          </li>
          <li>
            <div>
              <span>other users:</span> when You share personal information or
              otherwise interact in the public areas with other users, such
              information may be viewed by all users and may be publicly
              distributed outside.
            </div>
          </li>
          <li>
            <div>
              <span>With Your consent:</span> We may disclose Your personal
              information for any other purpose with Your consent.
            </div>
          </li>
        </ul>
        <p class="heading">Retention of Your Personal Data</p>
        <p class="content mb-2">
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p class="content mb-2">
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <p class="heading">Transfer of Your Personal Data</p>
        <p class="content mb-2">
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p class="content mb-2">
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p class="content mb-2">
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <p class="heading">Disclosure of Your Personal Data</p>
        <p class="secondary-heading">Business Transactions</p>
        <p class="content mb-2">
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <p class="secondary-heading">Law enforcement</p>
        <p class="content mb-2">
          if required to do so by law or in response to valid requests by public
          authorities (e.g. a court or a government agency).
        </p>
        <p class="secondary-heading">Other legal requirements</p>
        <p class="content mb-2">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul>
          <li><div>Comply with a legal obligation</div></li>
          <li>
            <div>Protect and defend the rights or property of the Company</div>
          </li>
          <li>
            <div>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </div>
          </li>
          <li>
            <div>
              Protect the personal safety of Users of the Service or the public
            </div>
          </li>
          <li><div>Protect against legal liability</div></li>
        </ul>
        <p class="secondary-heading">Security of Your Personal Data</p>
        <p class="content mb-2">
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
      </div>
    </div>
    <h2 class="main-heading">
      Detailed Information on the Processing of Your Personal Data
    </h2>
    <p class="content mb-2">
      The Service Providers We use may have access to Your Personal Data. These
      third-party vendors collect, store, use, process and transfer information
      about Your activity on Our Service in accordance with their Privacy
      Policies.
    </p>
    <div class="collecting-list-items">
      <p class="heading">Email Marketing</p>
      <p class="content mb-2">
        We may use Your Personal Data to contact You with newsletters, marketing
        or promotional materials and other information that may be of interest
        to You. You may opt-out of receiving any, or all, of these
        communications from Us by following the unsubscribe link or instructions
        provided in any email We send or by contacting Us.
      </p>
      <p class="content mb-2">
        We may use Email Marketing Service Providers to manage and send emails
        to You.
      </p>
      <ul>
        <li class="font-weight-bold">Mailchimp</li>
        <li>
          Mailchimp is an email marketing sending service provided by The Rocket
          Science Group LLC.
        </li>
        <li>
          For more information on the privacy practices of Mailchimp, please
          visit their Privacy policy:
          <router-link to="">https://mailchimp.com/legal/privacy/</router-link>
        </li>
      </ul>
      <p class="heading">Payments</p>
      <p class="content mb-2">
        We may provide paid products and/or services within the Service. In that
        case, we may use third-party services for payment processing (e.g.
        payment processors).
      </p>
      <p class="content mb-2">
        We will not store or collect Your payment card details. That information
        is provided directly to Our third-party payment processors whose use of
        Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </p>
      <p class="font-weight-bold ml-5">Stax</p>
      <p class="content mb-2 ml-5">
        <span>Their Privacy Policy can be viewed at </span>
        <router-link to="" class="link-info"
          >https://www.stax.com/privacypolicy#:~:text=We%20do%20not%20share%20your,search%20warrants%20or%20orders%20by</router-link
        >
      </p>
      <p class="heading">Usage, Performance and Miscellaneous</p>
      <ul>
        <li class="font-weight-bold">Invisible reCAPTCHA</li>
        <li>
          We use an invisible captcha service named reCAPTCHA. reCAPTCHA is
          operated by Google.
        </li>
        <li>
          The reCAPTCHA service may collect information from You and from Your
          Device for security purposes.
        </li>
        <li>
          The information gathered by reCAPTCHA is held in accordance with the
          Privacy Policy of Google:
          <router-link to=""
            >https://www.google.com/intl/en/policies/privacy/</router-link
          >
        </li>
      </ul>
    </div>
    <h2 class="main-heading">CCPA Privacy</h2>
    <div class="collecting-list-items">
      <p class="content mt-2 mb-3">
        This privacy notice section for California residents supplements the
        information contained in Our Privacy Policy and it applies solely to all
        visitors, users, and others who reside in the State of California.
      </p>
      <p class="heading">Categories of Personal Information Collected</p>
      <p class="content mb-2">
        We collect information that identifies, relates to, describes,
        references, is capable of being associated with, or could reasonably be
        linked, directly or indirectly, with a particular Consumer or Device.
        The following is a list of categories of personal information which we
        may collect or may have been collected from California residents within
        the last twelve (12) months.
      </p>
      <p class="content mb-2">
        Please note that the categories and examples provided in the list below
        are those defined in the CCPA. This does not mean that all examples of
        that category of personal information were in fact collected by Us, but
        reflects our good faith belief to the best of our knowledge that some of
        that information from the applicable category may be and may have been
        collected. For example, certain categories of personal information would
        only be collected if You provided such personal information directly to
        Us.
      </p>
      <ul>
        <li class="category"><div>Category A: Identifiers.</div></li>
        <li>
          <div>
            Examples: A real name, alias, postal address, unique personal
            identifier, online identifier, Internet Protocol address, email
            address, account name, driver's license number, passport number, or
            other similar identifiers.
          </div>
        </li>
        <li><div>Collected: Yes.</div></li>
        <li class="category">
          <div>
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e)).
          </div>
        </li>
        <li>
          <div>
            Examples: A name, signature, Social Security number, physical
            characteristics or description, address, telephone number, passport
            number, driver's license or state identification card number,
            insurance policy number, education, employment, employment history,
            bank account number, credit card number, debit card number, or any
            other financial information, medical information, or health
            insurance information. Some personal information included in this
            category may overlap with other categories.
          </div>
        </li>
        <li><div>Collected: Yes.</div></li>
        <li class="category">
          <div>
            Category C: Protected classification characteristics under
            California or federal law.
          </div>
        </li>
        <li>
          <div>
            Examples: Age (40 years or older), race, color, ancestry, national
            origin, citizenship, religion or creed, marital status, medical
            condition, physical or mental disability, sex (including gender,
            gender identity, gender expression, pregnancy or childbirth and
            related medical conditions), sexual orientation, veteran or military
            status, genetic information (including familial genetic
            information).
          </div>
        </li>
        <li><div>Collected: No.</div></li>
        <li class="category"><div>Category D: Commercial information.</div></li>
        <li>
          <div>
            Examples: Records and history of products or services purchased or
            considered.
          </div>
        </li>
        <li><div>Collected: Yes.</div></li>
        <li class="category"><div>Category E: Biometric information.</div></li>
        <li>
          <div>
            Examples: Genetic, physiological, behavioral, and biological
            characteristics, or activity patterns used to extract a template or
            other identifier or identifying information, such as, fingerprints,
            faceprints, and voiceprints, iris or retina scans, keystroke, gait,
            or other physical patterns, and sleep, health, or exercise data.
          </div>
        </li>
        <li><div>Collected: No.</div></li>
        <li class="category">
          <div>Category F: Internet or other similar network activity.</div>
        </li>
        <li>
          <div>Examples: Interaction with our Service or advertisement.</div>
        </li>
        <li><div>Collected: Yes.</div></li>
        <li class="category"><div>Category G: Geolocation data.</div></li>
        <li><div>Examples: Approximate physical location.</div></li>
        <li><div>Collected: Yes.</div></li>
        <li class="category"><div>Category H: Sensory data.</div></li>
        <li>
          <div>
            Examples: Audio, electronic, visual, thermal, olfactory, or similar
            information.
          </div>
        </li>
        <li><div>Collected: No.</div></li>
        <li class="category">
          <div>Category I: Professional or employment-related information.</div>
        </li>
        <li>
          <div>
            Examples: Current or past job history or performance evaluations.
          </div>
        </li>
        <li><div>Collected: No.</div></li>
        <li class="category">
          <div>
            Category J: Non-public education information (per the Family
            Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34
            C.F.R. Part 99)).
          </div>
        </li>
        <li>
          <div>
            Examples: Education records directly related to a student maintained
            by an educational institution or party acting on its behalf, such as
            grades, transcripts, class lists, student schedules, student
            identification codes, student financial information, or student
            disciplinary records.
          </div>
        </li>
        <li><div>Collected: No.</div></li>
        <li class="category">
          <div>
            Category K: Inferences drawn from other personal information.
          </div>
        </li>
        <li>
          <div>
            Examples: Profile reflecting a person's preferences,
            characteristics, psychological trends, predispositions, behavior,
            attitudes, intelligence, abilities, and aptitudes.
          </div>
        </li>
        <li><div>Collected: No.</div></li>
      </ul>
      <p class="content mb-2">
        Under CCPA, personal information does not include:
      </p>
    </div>
    <div class="tracking-technologies">
      <ul>
        <li>
          <div>Publicly available information from government records</div>
        </li>
        <li>
          <div>Deidentified or aggregated consumer information</div>
        </li>
        <li>
          <div>
            Information excluded from the CCPA's scope, such as:
            <li>
              <div>
                Health or medical information covered by the Health Insurance
                Portability and Accountability Act of 1996 (HIPAA) and the
                California Confidentiality of Medical Information Act (CMIA) or
                clinical trial data
              </div>
            </li>
            <li>
              <div>
                Personal Information covered by certain sector-specific privacy
                laws, including the Fair Credit Reporting Act (FRCA), the
                Gramm-Leach-Bliley Act (GLBA) or California Financial
                Information Privacy Act (FIPA), and the Driver's Privacy
                Protection Act of 1994
              </div>
            </li>
          </div>
        </li>
      </ul>
    </div>
    <div class="collecting-list-items">
      <p class="heading">Sources of Personal Information</p>
      <p class="content mb-2">
        We obtain the categories of personal information listed above from the
        following categories of sources:
      </p>
    </div>
    <div class="tracking-technologies">
      <ul>
        <li>
          <div>
            <span>Directly from You.</span>For example, from the forms You
            complete on our Service, preferences You express or provide through
            our Service, or from Your purchases on our Service.
          </div>
        </li>
        <li>
          <div>
            <span>Indirectly from You.</span>For example, from observing Your
            activity on our Service.
          </div>
        </li>
        <li>
          <div>
            <span>Automatically from You.</span>For example, through cookies We
            or our Service Providers set on Your Device as You navigate through
            our Service.
          </div>
        </li>
        <li>
          <div>
            <span>From Service Providers.</span>For example, third-party vendors
            for payment processing, or other third-party vendors that We use to
            provide the Service to You.
          </div>
        </li>
      </ul>
    </div>
    <h2 class="main-heading">
      Use of Personal Information for Business Purposes or Commercial Purposes
    </h2>
    <div class="collecting-list-items">
      <p class="content mb-2">
        We may use or disclose personal information We collect for "business
        purposes" or "commercial purposes" (as defined under the CCPA), which
        may include the following examples:
      </p>
    </div>
    <div class="tracking-technologies">
      <ul>
        <li>
          <div>To operate our Service and provide You with our Service.</div>
        </li>
        <li>
          <div>
            To provide You with support and to respond to Your inquiries,
            including to investigate and address Your concerns and monitor and
            improve our Service.
          </div>
        </li>
        <li>
          <div>
            To fulfill or meet the reason You provided the information. For
            example, if You share Your contact information to ask a question
            about our Service, We will use that personal information to respond
            to Your inquiry. If You provide Your personal information to
            purchase a product or service, We will use that information to
            process Your payment and facilitate delivery.
          </div>
        </li>
        <li>
          <div>
            To respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations.
          </div>
        </li>
        <li>
          <div>
            As described to You when collecting Your personal information or as
            otherwise set forth in the CCPA.
          </div>
        </li>
        <li>
          <div>For internal administrative and auditing purposes</div>
        </li>
        <li>
          <div>
            To detect security incidents and protect against malicious,
            deceptive, fraudulent or illegal activity, including, when
            necessary, to prosecute those responsible for such activities.
          </div>
        </li>
      </ul>
    </div>
    <div class="collecting-list-items">
      <p class="content mb-2">
        Please note that the examples provided above are illustrative and not
        intended to be exhaustive. For more details on how we use this
        information, please refer to the "Use of Your Personal Data" section.
      </p>
      <p class="content mb-2">
        If We decide to collect additional categories of personal information or
        use the personal information We collected for materially different,
        unrelated, or incompatible purposes We will update this Privacy Policy.
      </p>
      <p class="heading">
        Disclosure of Personal Information for Business Purposes or Commercial
        Purposes
      </p>
      <p class="content mb-2">
        We may use or disclose and may have used or disclosed in the last twelve
        (12) months the following categories of personal information for
        business or commercial purposes:
      </p>
    </div>
    <div class="tracking-technologies">
      <ul>
        <li>
          <div>Category A: Identifiers</div>
        </li>
        <li>
          <div>
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e))
          </div>
        </li>
        <li>
          <div>Category D: Commercial information</div>
        </li>
        <li>
          <div>Category F: Internet or other similar network activity</div>
        </li>
        <li>
          <div>Category G: Geolocation data</div>
        </li>
      </ul>
      <p class="content mb-2">
        Please note that the categories listed above are those defined in the
        CCPA. This does not mean that all examples of that category of personal
        information were in fact disclosed, but reflects our good faith belief
        to the best of our knowledge that some of that information from the
        applicable category may be and may have been disclosed.
      </p>
      <p class="content mb-2">
        When We disclose personal information for a business purpose or a
        commercial purpose, We enter a contract that describes the purpose and
        requires the recipient to both keep that personal information
        confidential and not use it for any purpose except performing the
        contract.
      </p>
      <p class="heading">Sale of Personal Information</p>
      <p class="content mb-2">
        As defined in the CCPA, "sell" and "sale" mean selling, renting,
        releasing, disclosing, disseminating, making available, transferring, or
        otherwise communicating orally, in writing, or by electronic or other
        means, a consumer's personal information by the business to a third
        party for valuable consideration. This means that We may have received
        some kind of benefit in return for sharing personal information, but not
        necessarily a monetary benefit.
      </p>
      <p class="content mb-2">
        Please note that the categories listed below are those defined in the
        CCPA. This does not mean that all examples of that category of personal
        information were in fact sold, but reflects our good faith belief to the
        best of our knowledge that some of that information from the applicable
        category may be and may have been shared for value in return.
      </p>
      <p class="content mb-2">
        We may sell and may have sold in the last twelve (12) months the
        following categories of personal information:
      </p>
      <ul>
        <li><div>Category A: Identifiers</div></li>
        <li>
          <div>
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e))
          </div>
        </li>
        <li><div>Category D: Commercial information</div></li>
        <li>
          <div>Category F: Internet or other similar network activity</div>
        </li>
        <li>
          <div>Category G: Geolocation data</div>
        </li>
      </ul>
      <p class="heading">Share of Personal Information</p>
      <p class="content mb-2">
        We may share Your personal information identified in the above
        categories with the following categories of third parties:
      </p>
      <ul>
        <li>
          <div>Service Providers</div>
        </li>
        <li>
          <div>Payment processors</div>
        </li>
        <li>
          <div>Our affiliates</div>
        </li>
        <li>
          <div>Our business partners</div>
        </li>
        <li>
          <div>
            Third party vendors to whom You or Your agents authorize Us to
            disclose Your personal information in connection with products or
            services We provide to You
          </div>
        </li>
      </ul>
      <p class="heading">
        Sale of Personal Information of Minors Under 16 Years of Age
      </p>
      <p class="content mb-2">
        We do not sell the personal information of Consumers We actually know
        are less than 16 years of age, unless We receive affirmative
        authorization (the "right to opt-in") from either the Consumer who is
        between 13 and 16 years of age, or the parent or guardian of a Consumer
        less than 13 years of age. Consumers who opt-in to the sale of personal
        information may opt-out of future sales at any time. To exercise the
        right to opt-out, You (or Your authorized representative) may submit a
        request to Us by contacting Us.
      </p>
      <p class="content mb-2">
        If You have reason to believe that a child under the age of 13 (or 16)
        has provided Us with personal information, please contact Us with
        sufficient detail to enable Us to delete that information.
      </p>
      <p class="heading">Your Rights under the CCPA</p>
      <p class="content mb-2">
        The CCPA provides California residents with specific rights regarding
        their personal information. If You are a resident of California, You
        have the following rights:
      </p>
      <ul>
        <li>
          <div>
            <span>The right to notice.</span>You have the right to be notified
            which categories of Personal Data are being collected and the
            purposes for which the Personal Data is being used.
          </div>
        </li>
        <li>
          <div>
            <span>The right to request.</span>Under CCPA, You have the right to
            request that We disclose information to You about Our collection,
            use, sale, disclosure for business purposes and share of personal
            information. Once We receive and confirm Your request, We will
            disclose to You:
            <li>
              <div>
                The categories of personal information We collected about You
              </div>
            </li>
            <li>
              <div>
                The categories of sources for the personal information We
                collected about You
              </div>
            </li>
            <li>
              <div>
                Our business or commercial purpose for collecting or selling
                that personal information
              </div>
            </li>
            <li>
              <div>
                The categories of third parties with whom We share that personal
                information
              </div>
            </li>
            <li>
              <div>
                The specific pieces of personal information We collected about
                You
              </div>
            </li>
            <li>
              <div>
                If we sold Your personal information or disclosed Your personal
                information for a business purpose, We will disclose to You:
                <li>
                  <div>
                    The categories of personal information categories sold
                  </div>
                </li>
                <li>
                  <div>
                    The categories of personal information categories disclosed
                  </div>
                </li>
              </div>
            </li>
          </div>
        </li>
        <li>
          <div>
            <span
              >The right to say no to the sale of Personal Data (opt-out).</span
            >You have the right to direct Us to not sell Your personal
            information. To submit an opt-out request please contact Us.
          </div>
        </li>
        <li>
          <div>
            <span>The right to delete Personal Data.</span>You have the right to
            request the deletion of Your Personal Data, subject to certain
            exceptions. Once We receive and confirm Your request, We will delete
            (and direct Our Service Providers to delete) Your personal
            information from our records, unless an exception applies. We may
            deny Your deletion request if retaining the information is necessary
            for Us or Our Service Providers to:
            <li>
              <div>
                Complete the transaction for which We collected the personal
                information, provide a good or service that You requested, take
                actions reasonably anticipated within the context of our ongoing
                business relationship with You, or otherwise perform our
                contract with You.
              </div>
            </li>
            <li>
              <div>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity, or prosecute those responsible
                for such activities.
              </div>
            </li>
            <li>
              <div>
                Debug products to identify and repair errors that impair
                existing intended functionality.
              </div>
            </li>
            <li>
              <div>
                Exercise free speech, ensure the right of another consumer to
                exercise their free speech rights, or exercise another right
                provided for by law.
              </div>
            </li>
            <li>
              <div>
                Comply with the California Electronic Communications Privacy Act
                (Cal. Penal Code § 1546 et. seq.).
              </div>
            </li>
            <li>
              <div>
                Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when the information's
                deletion may likely render impossible or seriously impair the
                research's achievement, if You previously provided informed
                consent.
              </div>
            </li>
            <li>
              <div>
                Enable solely internal uses that are reasonably aligned with
                consumer expectations based on Your relationship with Us.
              </div>
            </li>
            <li>
              <div>Comply with a legal obligation.</div>
            </li>
            <li>
              <div>
                Make other internal and lawful uses of that information that are
                compatible with the context in which You provided it.
              </div>
            </li>
          </div>
        </li>
        <li>
          <div>
            <span>The right not to be discriminated against.</span>You have the
            right not to be discriminated against for exercising any of Your
            consumer's rights, including by:
            <li>
              <div>Denying goods or services to You</div>
            </li>
            <li>
              <div>
                Charging different prices or rates for goods or services,
                including the use of discounts or other benefits or imposing
                penalties
              </div>
            </li>
            <li>
              <div>
                Providing a different level or quality of goods or services to
                You
              </div>
            </li>
            <li>
              <div>
                Suggesting that You will receive a different price or rate for
                goods or services or a different level or quality of goods or
                services
              </div>
            </li>
          </div>
        </li>
      </ul>
      <p class="heading">Exercising Your CCPA Data Protection Rights</p>
      <p class="content mb-2">
        In order to exercise any of Your rights under the CCPA, and if You are a
        California resident, You can contact Us:
      </p>
      <ul>
        <li>
          <div>
            By visiting this page on our website:
            <router-link to="">http://www.whoifollow.com/privacy </router-link>
          </div>
        </li>
      </ul>
      <p class="content mb-2">
        Only You, or a person registered with the California Secretary of State
        that You authorize to act on Your behalf, may make a verifiable request
        related to Your personal information.
      </p>
      <p class="content mb-2">Your request to Us must:</p>
      <ul>
        <li>
          <div>
            Provide sufficient information that allows Us to reasonably verify
            You are the person about whom We collected personal information or
            an authorized representative
          </div>
        </li>
        <li>
          <div>
            Describe Your request with sufficient detail that allows Us to
            properly understand, evaluate, and respond to it
          </div>
        </li>
      </ul>
      <p class="content mb-2">
        We cannot respond to Your request or provide You with the required
        information if We cannot:
      </p>
      <ul>
        <li>
          <div>Verify Your identity or authority to make the request</div>
        </li>
        <li>
          <div>And confirm that the personal information relates to You</div>
        </li>
      </ul>
      <p class="content mb-2">
        We will disclose and deliver the required information free of charge
        within 45 days of receiving Your verifiable request. The time period to
        provide the required information may be extended once by an additional
        45 days when reasonably necessary and with prior notice.
      </p>
      <p class="content mb-2">
        Any disclosures We provide will only cover the 12-month period preceding
        the verifiable request's receipt.
      </p>
      <p class="content mb-2">
        For data portability requests, We will select a format to provide Your
        personal information that is readily usable and should allow You to
        transmit the information from one entity to another entity without
        hindrance.
      </p>
      <p class="heading">Do Not Sell My Personal Information</p>
      <p class="content mb-2">
        You have the right to opt-out of the sale of Your personal information.
        Once We receive and confirm a verifiable consumer request from You, we
        will stop selling Your personal information. To exercise Your right to
        opt-out, please contact Us.
      </p>
      <h2 class="main-heading">
        "Do Not Track" Policy as Required by California Online Privacy
        Protection Act (CalOPPA)
      </h2>
      <p class="content mb-2">
        Our Service does not respond to Do Not Track signals.
      </p>
      <p class="content mb-2">
        However, some third party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want
        to be tracked. You can enable or disable DNT by visiting the preferences
        or settings page of Your web browser.
      </p>
      <h2 class="main-heading">Children's Privacy</h2>
      <p class="content mb-2">
        The Service may contain content appropriate for children under the age
        of 13. As a parent, you should know that through the Service children
        under the age of 13 may participate in activities that involve the
        collection or use of personal information. We use reasonable efforts to
        ensure that before we collect any personal information from a child, the
        child's parent receives notice of and consents to our personal
        information practices.
      </p>
      <p class="content mb-2">
        We also may limit how We collect, use, and store some of the information
        of Users between 13 and 18 years old. In some cases, this means We will
        be unable to provide certain functionality of the Service to these
        Users. If We need to rely on consent as a legal basis for processing
        Your information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information.
      </p>
      <p class="content mb-2">
        We may ask a User to verify its date of birth before collecting any
        personal information from them. If the User is under the age of 13, the
        Service will be either blocked or redirected to a parental consent
        process.
      </p>
      <p class="heading">
        Information Collected from Children Under the Age of 13
      </p>
      <p class="content mb-2">
        The Company may collect and store persistent identifiers such as cookies
        or IP addresses from Children without parental consent for the purpose
        of supporting the internal operation of the Service.
      </p>
      <p class="content mb-2">
        We may collect and store other personal information about children if
        this information is submitted by a child with prior parent consent or by
        the parent or guardian of the child.
      </p>
      <p class="content mb-2">
        The Company may collect and store the following types of personal
        information about a child when submitted by a child with prior parental
        consent or by the parent or guardian of the child:
      </p>
      <ul>
        <li>
          <div>First and/or last name</div>
        </li>
        <li><div>Date of birth</div></li>
        <li>
          <div>Gender</div>
        </li>
        <li>
          <div>Grade level</div>
        </li>
        <li>
          <div>Email address</div>
        </li>
        <li>
          <div>Telephone number</div>
        </li>
        <li>
          <div>Parent's or guardian's name</div>
        </li>
        <li>
          <div>Parent's or guardian's name</div>
        </li>
        <li>
          <div>Parent's or guardian's email address</div>
        </li>
      </ul>
      <p class="content mb-2">
        For further details on the information We might collect, You can refer
        to the "Types of Data Collected" section of this Privacy Policy. We
        follow our standard Privacy Policy for the disclosure of personal
        information collected from and about children
      </p>
      <p class="heading">Parental Access</p>
      <p class="content mb-2">A parent who has already given the Company permission to collect and use his child personal information can, at any time:</p>
      <ul>
        <li>
          <div>Review, correct or delete the child's personal information</div>
        </li>
        <li>
          <div>Discontinue further collection or use of the child's personal information</div>
        </li>
      </ul>
      <p class="content mb-2">To make such a request, You can write to Us using the contact information provided in this Privacy Policy.</p>
      <h2 class="main-heading">Your California Privacy Rights (California's Shine the Light law)</h2>
      <p class="content mb-2">Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.</p>
      <p class="content mb-2">If you'd like to request more information under the California Shine the Light law, and if You are a California resident, You can contact Us using the contact information provided below.</p>
      <h2 class="main-heading">California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</h2>
      <p class="content mb-2">California Business and Professions Code Section 22581 allows California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.
</p>
      <p class="content mb-2">To request removal of such data, and if You are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account.</p>
      <p class="content mb-2">Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.</p>
      <h2 class="main-heading">Links to Other Websites</h2>
      <p class="content mb-2">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
      <p class="content mb-2">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
      <h2 class="main-heading">Changes to this Privacy Policy</h2>
      <p class="content mb-2">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
      <p class="content mb-2">We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
      <p class="content mb-2">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
</p>
<h2 class="main-heading">Contact Us</h2>
<p class="content mb-2">If you have any questions about this Privacy Policy, You can contact us:</p>
<ul>
  <li>
    <div>By visiting this page on our website: <router-link to="">http://www.whoifollow.com/privacy</router-link></div>
  </li>
</ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>