<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pb-3 pr-3">
        <div class="row no-gutters">
           <div class="col-12 px-0">
            <CalendarTop :listWhite="listWhite" :calenderBlue="calenderBlue" :tableBlue="tableBlue" />
          </div>
        </div>

        <div class="event-card">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="event-list"
              role="tabpanel"
              aria-labelledby="event-list-tab"
            >
              <events
                v-bind:TeamDetails="TeamDetails"
                v-bind:UserDetails="UserDetails"
                :currentUserId="currentUserId"
              />
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import events from "../components/Calendar/events.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import * as firestoreService from "@/database/firestore";
import { mapActions } from "vuex";
import CalendarTop from "../components/Calendar/CalenderTop.vue";

export default {
  components: {
    Navbar,
    LeftMenu,
    events,
    Banner,
    CalendarTop
  },

  data: function () {
    return {
      TeamDetails: [],
      TeamA: [],
      UserDetails: [],
      totalTeams: "",
      teamUsers: "",
      currentIsFan: "0",
      currentUserId: "",
      calenderImage: require("../assets/images/calendar-white.png"),
      listImage: require("../assets/images/list-black.png"),
      calenderBlue: require("../assets/images/calendar-blue.png"),
      listWhite: require("../assets/images/list-blue.png"),
      tableBlue: require("../assets/images/line-ellipsis.png"),
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("events", ["setEventListData"]),
    ...mapActions("events", ["getCurrentComingStatus"]),

    async firebasedata(user_id) {
      this.TeamDetails = [];
      this.Team = [];
      const querySport = firestoreService.roomsSportQuery(user_id);
      const { data, docs } = await firestoreService.getRooms(querySport);
      this.totalTeams = docs[docs.length - 1];
      data.forEach((teams) => {
        this.TeamDetails.push(teams.id);
        this.TeamA.push(teams);
        //  Make Array for all Users on Team Start -----------
        const rawUsers = [];
        teams.users.forEach((userId) => {
          const promise = firestoreService.getUser(userId);
          rawUsers.push(promise);
        });
        this.teamUsers = "";
        var currentIsFan = "0";
        Promise.all(rawUsers).then((values) => {
          for (const role of teams.userRole) {
            for (const member of teams.users) {
              if (role.id == member) {
                if (member == this.currentUserId && role.fan == "1") {
                  currentIsFan = "1";
                }
                if (member == this.currentUserId && role.fan != "1") {
                  currentIsFan = "0";
                }
              }
            }
          }
          let teamDeta = {
            teamID: teams.id,
            users: values,
            userRole: teams.userRole,
            currentIsFan: currentIsFan,
          };

          this.UserDetails.push(teamDeta);
        });
        //  Make Array for all Users on Team Start -----------
      });

      this.setEventListData(this.TeamDetails);
      let comingStatus = {
        teamID: this.TeamDetails,
        usersDetail: this.UserDetails,
        users: user_id,
      };
      this.getCurrentComingStatus(comingStatus);
    },
    async getUserChatIdWithRetry() {
    if (this.user) {
      const promiseUser = await firestoreService.getUserCid(this.user.id.toString());
      this.currentUserId = promiseUser.data[0].id;
      this.firebasedata(this.currentUserId);
    } else {
      setTimeout(() => {
        this.getUserChatIdWithRetry();
      }, 1000); 
    }
  },
  async getUserChatId() {
    await this.getUserChatIdWithRetry();
  }
  },

  mounted() {
    this.getUserChatIdWithRetry();
    // if(this.user)
    // {
    //      if (this.user.pro_status == null) {
    //      this.$router.push({ name: "GoPro" });
    //      }
    // }
  },
};
</script>

<style scoped>
.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  background-color: white;
  padding-top: 95px;
  flex-grow: 1;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 3em;
}

.fc-col-header-cell-cushion,
.fc-theme-standard .fc-list-day-cushion a {
  color: black !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.fc .fc-daygrid-day-number {
  color: black !important;
  font-size: 15px;
  font-size: 500 !important;
}

.fc .fc-button-primary {
  background-color: #e5f2fe !important;
  color: #188ef5 !important;
  border: 1px solid #e5f2fe !important;
  text-transform: capitalize !important;
}

.fc .fc-timegrid-axis-cushion {
  text-transform: capitalize;
}

.fc-button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.fc-button:hover {
  background-color: #188ef5 !important;
  border: 1px solid #188ef5 !important;
  color: #ffffff !important;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #188ef5 !important;
  border: 1px solid #188ef5 !important;
  color: #ffffff !important;
  cursor: not-allowed;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active:hover {
  color: #ffffff !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #b9c6d3 !important;
}

.fc-daygrid-event {
  margin: 0 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  margin-right: 0 !important;
}

.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-left: 0 !important;
}

@media screen and (max-width: 768px) {
  .demo-app-main {
    /* height: 100vh; */
    overflow: auto;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .fc-col-header-cell-cushion,
  .fc-theme-standard .fc-list-day-cushion a {
    font-size: 10px !important;
    font-weight: normal !important;
  }

  /*.fc-list-event {*/
  /*  font-size: 10px !important;*/
  /*}*/
  .fc-view-harness {
    min-height: 276px !important;
  }

  .fc-toolbar-chunk {
    margin-bottom: 10px;
  }

  .fc .fc-toolbar-title {
    font-size: 1rem;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 5px;
  }

  .fc .fc-toolbar {
    flex-direction: column;
  }

  .fc .fc-daygrid-day-bottom {
    margin-top: -10px !important;
  }

  .fc .fc-daygrid-day-frame {
    min-height: 75px;
  }

  .sweet-modal.is-mobile-fullscreen {
    max-height: 100% !important;
    height: (100vh -58px) !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 460px) {
  .fc .fc-daygrid-day-bottom {
    font-size: 0.6rem;
    padding: 2px 0 !important;
  }
  .fc-col-header-cell-cushion,
  .fc-theme-standard .fc-list-day-cushion a {
    font-size: 8.5px !important;
    font-weight: normal !important;
  }
}

/*===============TOGGLE ==========*/
.event-tab22 ul {
  list-style-type: none;
  display: flex;
  margin-bottom: 0;
}

.event-tab22 ul li {
  color: #c4c4c4;
  cursor: pointer;
  background-color: #e5f2fe;
  text-align: center;
}

.event-tab22 ul li:first-child {
  border-radius: 5px 0 0 5px !important;
}

.event-tab22 ul li:last-child {
  border-radius: 0 5px 5px 0 !important;
}

.event-tab22 .nav-tabs {
  border-bottom: 0 !important;
}

.event-tab22 .nav-tabs .nav-item.show .nav-link,
.event-tab22 .nav-tabs .nav-link.active {
  background-color: #188ef5 !important;
  border-radius: inherit !important;
}

.event-tab22 .nav-tabs .nav-link img {
  width: 20px;
}

.event-tab22 .nav-tabs .nav-link {
  border: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*==========togel button =============*/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .card-wrapper {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.event-actions ul li.active {
  color: #188ef5;
  border-bottom: 3px solid #188ef5;
  padding-bottom: 2px;
}
</style>
