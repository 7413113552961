<template>
  <div>
    <Navbar />
    <div class="banner-wrapper">
      <Banner />
    </div>
    <div class="main-wrapper pb-3">
      <div class="container-fluid no-pdd">
        <div class="row no-gutters">
          <div class="col-3 pr-0 overflow-hidden">
            <CartOverlay />
          </div>
          <div class="col-12 col-lg-6 no-pdd bg-white">
            <div class="row no-gutters">
              <div class="col-12 no-pdd">
                <BackStore />
              </div>
              <div class="col-12 no-pdd">
                <CartHeading />
              </div>
              <div class="col-12 no-pdd">
                <ItemsHeading />
              </div>
            </div>
          </div>
          <div class="col-3 no-pdd">
            <Summary />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import CartOverlay from "../components/Shop/ShoppingCart/CartOverlay.vue";
import BackStore from "../components/Shop/ShoppingCart/BackStore.vue";
import CartHeading from "../components/Shop/ShoppingCart/CartHeading.vue";
import ItemsHeading from "../components/Shop/ShoppingCart/ItemsHeading.vue";
import Summary from "../components/Shop/ShoppingCart/Summary.vue";
import Banner from "../components/BannerNotify/BannerPage.vue"

export default {
  components: {
    Navbar,
    CartOverlay,
    BackStore,
    CartHeading,
    ItemsHeading,
    Summary,
    Banner
  },
  name: "Home",
  data(){
    return{
     
    }
  },
  methods:{
    
  },
  mounted(){

  }
};
</script>
