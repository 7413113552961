<template>
  <div v-if="dataProfile" class="profile-photo-wrapper">
    <div class="shop-products-img-loader" v-if="showLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
    <div class="profile-img">
      <img
        @load="onImageLoad"
        v-if="dataProfile.picture == 'yes'"
        :src="API_URL + dataProfile.user_id + '/profile_picture.jpg?v=' + time"
        alt=""
        class="cursor-pointer"
        @click="OpenProfilePhotoPreview"
      />
      <img
        @load="onImageLoad"
        v-else
        src="../../assets/images/user-default-new.png"
        alt=""
      />
    </div>

    <div class="profile-photo-modal" v-if="openProfilePhoto">
      <button
        class="close-icon btn btn-outline-danger btn-sm lb-modal-close"
        @click="CloseProfilePhotoPreview"
      >
        <i class="fa fa-times"></i>
      </button>
      <div class="profile-img-full-preview" @click="CloseProfilePhotoPreview">
        <img
          v-if="dataProfile.picture == 'yes'"
          :src="
            API_URL + dataProfile.user_id + '/profile_picture.jpg?v=' + time
          "
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    dataProfile: {
      type: Object,
    },
  },
  name: "ProfilePhoto",
  data() {
    return {
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      openProfilePhoto: false,
      showLoader: 1,
    };
  },
  methods: {
    OpenProfilePhotoPreview() {
      this.openProfilePhoto = true;
    },
    CloseProfilePhotoPreview() {
      this.openProfilePhoto = false;
    },
    onImageLoad() {
      this.showLoader = 0;
    },
  },
};
</script>
<style></style>
