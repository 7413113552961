<template>
  <div>
    <button class="d-none" data-toggle="modal" data-target="#Teams" ref="openModalTeams"></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="Teams">
      <div class="modal-dialog modal-dialog-centered teammates-lineup-modal" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0 text-capitalize">
              Manage Teams - {{ tournamentData?.tournamentName }}
            </h6>
            <button type="button" class="close" data-dismiss="modal" @click="clearErrorMessage()" aria-label="Close" ref="closeTournamentLineUpModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="teammates-lineup-wrapper">
              <div class="available-teammates">
                <h6>Available Teams</h6>
                <div class="teammates-info overflow-hidden manage-teams">
                  <div class="search-container border-0 mx-2">
                    <form>
                      <button type="submit">
                        <img src="../../../assets/images/search.png" alt="" />
                      </button>
                      <input v-model="searchValue" id="search-input" type="text" placeholder="Search by team Name"
                        name="search" autocomplete="new-password" v-on:keydown.enter.prevent />
                    </form>
                  </div>
                  <draggable class="list-group" :list="filteredAvailableTeams" group="umpires" handle=".handle"
                    @change="handleAvailableTeams" v-if="showLoaderAvailableTeams == 0">
                    <div class="list-group-item cursor-none" v-for="(element, index) in filteredAvailableTeams" :key="index">
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <div class="d-flex flex-column">
                          <p class="name">{{ element.team.team_name }}</p>
                          <small class="pt-1 age-group-content text-capitalize">
                              {{ element?.team?.age_group?.name ? element.team.age_group.name + '-' : '' }}
                              {{ element?.team?.gender ? element.team.gender + '-' : '' }}
                              {{ element?.team?.ratings?.rate }}
                          </small>
                        </div>
                      </div>
                      <div class="handle cursor-pointer">
                        <img src="../../../assets/images/line-ellipsis.png" alt="" title="Click an icon to drag and drop the option."/>
                      </div>
                    </div>
                  </draggable>
                  <div class="no-line-up" v-if="showLoaderAvailableTeams == 0 && filteredAvailableTeams.length == 0">
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No available Teams found.</p>
                  </div>
                  <div class="lds-dual-ring-media" v-if="showLoaderAvailableTeams == 1"></div>
                </div>
              </div>
              <div class="team-lineup">
                  <h6>Teams Selected</h6>
                <div class="teammates-info position-relative teams-selected">

                  <draggable class="list-group" :list="selectedTeams" group="umpires" handle=".handle"
                    @change="handleSelectedTeams" v-if="showLoaderSelectedTeams == 0">
                    <div class="list-group-item cursor-none" v-for="(element, index) in selectedTeams"
                      :key="element?.position_index">
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <div class="d-flex flex-column">
                          <p class="name">{{ element.team.team_name }}</p>
                          <!-- <small class="pt-1 age-group-content">{{ element?.team?.age_group?.name }}-{{
                            element?.team?.ratings?.rate }}</small> -->
                            <small class="pt-1 age-group-content text-capitalize">
                              {{ element?.team?.age_group?.name ? element.team.age_group.name + '-' : '' }}
                              {{ element?.team?.gender ? element.team.gender + '-' : '' }}
                              {{ element?.team?.ratings?.rate }}
                          </small>
                        </div>
                      </div>
                      <div class="handle cursor-pointer">
                        <img src="../../../assets/images/line-ellipsis.png" alt="" title="Click an icon to drag and drop the option."/>
                      </div>
                    </div>
                  </draggable>
                  <div class="no-line-up" v-if="showLoaderSelectedTeams == 0 && selectedTeams.length == 0">
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No selected Teams found.</p>
                  </div>
                  <div class="no-line-up" v-else-if="errorMessage">
                    <div class="img">
                      <img src="../../../assets/images/teammates-red.png" alt="" />
                    </div>
                    <p class="text-danger pl-2">{{ errorMessage }}</p>
                  </div>
                  <div class="lds-dual-ring-media" v-if="showLoaderSelectedTeams == 1"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button v-if="!isDisabled" type="button" class="btn btn-primary w-25" @click="manageTournamentTeam(0)"
              :disabled="isLoadingArray[0]">
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button v-else type="button" class="btn btn-primary-disabled w-25" :disabled="isLoadingArray[0]">
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
export default {
  name: "TournamentTeamsModal",
  data: function () {
    return {
      tournamentData: [],
      availableTeams: [],
      selectedTeams: [],
      isDisabled: true,
      isLoadingArray: [],
      showLoaderSelectedTeams: 1,
      showLoaderAvailableTeams: 1,
      searchValue: "",
      errorMessage: "", 
    };
  },
  components: {
    draggable,
  },
  watch: {
    selectedTeams(newValue) {
      if (newValue.length > 0 || this.availableTeams.length > 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
      
      // Clear the error message if there are two or more selected teams
      if (newValue.length >= 2) {
        this.errorMessage = "";
      }
    },
    availableTeams(newValue) {
      if (newValue.length > 0 || this.selectedTeams.length > 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
  },
  computed: {
    filteredAvailableTeams() {
      const lowercasedSearchValue = this.searchValue.toLowerCase();
      return this.availableTeams.filter((team) => {
        const isSelected = this.selectedTeams.some(selected => selected.team.id === team.team.id);
        // const ageGroupName = team.team.age_group.name.toLowerCase();
        const teamName = team.team.team_name.toLowerCase();
        return !isSelected && (teamName.includes(lowercasedSearchValue));
      });
    },
  },
  methods: {
    async manageTournamentTeam(index) {
      if (this.selectedTeams.length === 1) {
        this.errorMessage = "Please select at least two teams.";
        return;
      }
    this.$set(this.isLoadingArray, index, true);

    const { event_id, id: tournament_id, guid: tournament_guid } = this.tournamentData;

    try {
      const url = `${process.env.VUE_APP_API_URL}tournaments/teams/addTeamsToTournament`;
      const response = await axios.post(url, {
        event_id,
        selectedTeams: this.selectedTeams,
        tournament_id,
        tournament_guid,
      });

      if (response.data.statusCode === 200) {
        if(response.data.associatedTeam == 1){
            this.$alert(response.data.message);
          }
        this.$root.$emit("selectedTournamentTeamEmit");
        const elem = this.$refs.closeTournamentLineUpModal;
        if (elem) {
          elem.click();
        }
      } else {
        console.error("Failed to add teams. Status:", response.status);
      }
    } catch (error) {
      console.error("Error saving selected teams:", error);
    } finally {
      this.$set(this.isLoadingArray, index, false);
    }
  },
    handleAvailableTeams(evt) {
      if (evt.added) {
        const addedTeam = evt.added.element;
        this.selectedTeams.push(addedTeam);
        this.availableTeams = this.availableTeams.filter(team => team.team.id !== addedTeam.team.id);
      }
    },
    handleSelectedTeams(evt) {
      if (evt.removed) {
        const removedTeam = evt.removed.element;
        this.availableTeams.push(removedTeam);
        this.selectedTeams = this.selectedTeams.filter(team => team.team.id !== removedTeam.team.id);
      }
    },
    async getTeamsForTournament() {
      try {
        this.showLoaderAvailableTeams = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}associationEvent/getJoinedTeamForTournament?event_id=${this.tournamentData?.event_id}&tournament_id=${this.tournamentData?.id}`);

        if (response.data.statusCode === 200) {
          this.availableTeams = response.data.data;

          // Inline sorting logic
          this.availableTeams.sort((a, b) => {
            const extractNumber = (str) => parseInt(str.match(/\d+/), 10);
            const isOlder = (str) => str.toLowerCase().includes("older");
            const ageA = a?.team?.age_group?.name || "";
            const ageB = b?.team?.age_group?.name || "";

            const numA = extractNumber(ageA);
            const numB = extractNumber(ageB);

            if (!isOlder(ageA) && !isOlder(ageB)) {
              // Both are U categories
              return numA - numB;
            } else if (isOlder(ageA) && isOlder(ageB)) {
              // Both are Older categories
              return numA - numB;
            } else if (!isOlder(ageA) && isOlder(ageB)) {
              // U category should come before Older category
              return -1;
            } else {
              // Older category should come after U category
              return 1;
            }
          });

          this.showLoaderAvailableTeams = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoaderAvailableTeams = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoaderAvailableTeams = 0;
      }
    },
    async getSelectedTeamsForTournament() {
      this.showLoaderSelectedTeams = 1;
      try {
        const url = `${process.env.VUE_APP_API_URL}tournaments/teams/getSelectedTournamentTeams?tournament_guid=${this.tournamentData?.guid}`;
        const response = await axios.get(url);
        if (response.data.statusCode === 200) {
          this.selectedTeams = response.data.data;

          // Inline sorting logic
          this.selectedTeams.sort((a, b) => {
            const extractNumber = (str) => parseInt(str.match(/\d+/), 10);
            const isOlder = (str) => str.toLowerCase().includes("older");

            const ageA = a?.team?.age_group?.name || "";
            const ageB = b?.team?.age_group?.name || "";

            const numA = extractNumber(ageA);
            const numB = extractNumber(ageB);

            if (!isOlder(ageA) && !isOlder(ageB)) {
              // Both are U categories
              return numA - numB;
            } else if (isOlder(ageA) && isOlder(ageB)) {
              // Both are Older categories
              return numA - numB;
            } else if (!isOlder(ageA) && isOlder(ageB)) {
              // U category should come before Older category
              return -1;
            } else {
              // Older category should come after U category
              return 1;
            }
          });

          this.showLoaderSelectedTeams = 0;
        } else {
          console.error(
            "Failed to fetch teams:",
            response.data.message || "Unknown error occurred"
          );
          this.showLoaderSelectedTeams = 0;
        }
      } catch (error) {
        this.showLoaderSelectedTeams = 0;
        console.error("Failed to fetch teams:", error);
      }
    },
    clearErrorMessage() {
      this.errorMessage = "";
    }
  },
  mounted() {
    this.$root.$on("openTournamentTeamsModal", (data) => {
      this.tournamentData = data;
      this.getSelectedTeamsForTournament();
      this.getTeamsForTournament();
      this.filteredAvailableTeams = [];
      var elem = this.$refs.openModalTeams;
      this.clearErrorMessage;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
<style scoped>
.manage-teams{
  height: 464px !important;
}
</style>