<template>
  <div>
    <div class="events-wrapper">
      <div class="event-list" v-for="event in events" :key="event.index">
        <div
          class="event-list-details event-info-wrap"
        >
        <EventWrapper :event="event" :selectedRoomTeam="selectedRoomTeam"/>
        </div>
        <UserGoingList
          :selectedRoomTeam="selectedRoomTeam"
          :attendeeListing="event.event_attendee_all"
        />
      </div>

      <div
        class="no-event-content"
        v-if="events.length == 0"
      >
        <div class="no-event-list">
          <img src="../../../../assets/images/calendar-search.png" alt="" />
          <p class="no-event-header">No past events</p>
          <p class="no-event-text">
            All past events for this team will appear here.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventWrapper from './EventWrapper.vue';
import UserGoingList from "./UserGoingList.vue";
export default {
  components: { UserGoingList, EventWrapper },
  name: "PastEvents",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
  },
  data() {
    return {
      events: [],
      roomID: "",
      eventURL: this.$eventAvatarStorageURL,
      imgLoader: 1,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    showDetails: function (event) {
      this.$root.$emit("showEventDetailPage", event);
    },
  },
  mounted() {
    this.events = this.selectedRoomTeam.pastEventsList;
    this.roomID = this.selectedRoomTeam.roomID;
    this.$root.$on("fetchEventPast", (events) => {
      if (this.events.length != events.length) {
        this.$root.$emit("showEventDetailPageBack");
      }
      this.events = events;
    });
  },
};
</script>
