<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div v-if="isLoadingArray[0]" class="lds-dual-ring-team"></div>
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="team-side">
        <div class="chat-side p-0">
          <div class="chat-system-options p-0 bg-white">
            <ChatSystem
              :currentUserId="currentUserId"
              :allUsersRef="allUsersRef"
              :teamsChatCounter="teamsChatCounter"
              :individualChatCounter="individualChatCounter"
              :rooms="rooms"
            />
            <NoChat
              v-if="
                rooms.length == 0 &&
                  !loadingShimmers &&
                  noChatIcon &&
                  tapArchive == 1
              "
            />
            <NoArchives
              v-if="rooms.length == 0 && !loadingShimmers && tapArchive == 2"
            />
          </div>
          <chat-window
            v-if="!loadingShimmers"
            :show-footer="showFooter"
            :current-user-id="currentUserId"
            :room-id="roomId"
            :textMessages="locale"
            :rooms="loadedRooms"
            :loading-rooms="loadingRooms"
            :messages="messages"
            :message-actions="messageActions"
            :messages-loaded="messagesLoaded"
            :load-first-room="loadFirstRoom"
            :rooms-loaded="roomsLoaded"
            :room-message="roomMessage"
            :link-options="{
              disabled: false,
              target: '_blank',
              rel: null,
            }"
            @fetch-more-rooms="fetchMoreRooms"
            @fetch-messages="fetchMessages"
            @send-message="sendMessage"
            @delete-message="deleteMessage"
            @message-action-handler="messageActionHandler"
            @open-file="openFile"
            @add-room="addRoom"
            @menu-action-handler="menuActionHandler"
            :menu-actions="menuActions"
          />
          <div class="col-md-4 col-12 bg-white p-0">
            <chat-shimmers v-if="loadingShimmers" />
          </div>
        </div>
        <div class="chat-right">
          <ChatSettings
            v-if="selectedRoomType == 'team' && !loadingShimmers"
            :selectedRoomTeam="selectedRoomTeam"
            :currentUserId="currentUserId"
            :currentIsAdmin="currentIsAdmin"
            :currentIsFan="currentIsFan"
            :currentTeamIsActive="currentTeamIsActive"
            :showLoader="showLoaderGameRecord"
            :totalGame="totalGame"
            :win="win"
            :loss="loss"
          />
          <chatRightSims v-if="loadingShimmers" />
          <div
            v-if="
              selectedRoomType == 'individual' &&
                !loadingShimmers &&
                deleteUserFound == 0
            "
          >
            <IndChatSetting
              :selectedRoomTeam="selectedRoomTeam"
              :currentUserId="currentUserId"
              :userBlockFlag="userBlockFlag"
              :showBlockLoader="showBlockLoader"
            />
          </div>
          <div
            class="rooms-empty"
            v-if="selectedRoomTeam.length == 0 && !loadingShimmers"
          >
            No data to display.
          </div>
          <div class="rooms-empty" v-if="deleteUserFound == 1">
            No data to display.
          </div>
        </div>
      </div>
    </div>

    <FlashMessage />

    <div
      data-toggle="modal"
      data-target="#messageInfo"
      class="d-none"
      ref="openMessageInfoModal"
    ></div>

    <!-- Message Info -->
    <div
      class="modal fade"
      id="messageInfo"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header align-items-center border-bottom-0">
            <h6 class="modal-title" id="exampleModalLabel">Message Info</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <div class="message-content">
              <p v-if="selectedMessage.content">
                {{ selectedMessage.content }}
              </p>
              <p
                v-if="
                  selectedMessage.files &&
                    selectedMessage.files.length == 1 &&
                    selectedMessage.files[0].extension != 'audio/mp3'
                "
                class="font-style-italic"
              >
                {{ selectedMessage.files[0].name }}.{{
                  selectedMessage.files[0].extension
                }}
              </p>
              <p
                v-else-if="
                  selectedMessage.files && selectedMessage.files.length > 1
                "
                class="font-style-italic"
              >
                Files
              </p>
              <p
                v-else-if="
                  selectedMessage.files &&
                    selectedMessage.files.length == 1 &&
                    selectedMessage.files[0].extension == 'audio/mp3'
                "
                class="font-style-italic"
              >
                Audio File
              </p>
              <div class="time">{{ messageDateTime }}</div>
            </div>
            <div class="message-read-sent">
              <div class="read-by">
                <h6>Read By</h6>
              </div>
              <div class="read-by-wrapper" v-if="messageSeen.length > 0">
                <div
                  class="message-reader-detail"
                  v-for="user in messageSeen"
                  :key="user.name"
                >
                  <div class="user-img">
                    <img
                      v-if="user.avatar != ''"
                      :src="user.avatar"
                      onerror="this.src='https://whoifollow.tech/api/public/user-default-new.png'"
                      alt=""
                    />
                  </div>
                  <div class="user-name-time">
                    <h6>{{ user.name }}</h6>
                    <span>{{ user.date }}</span>
                  </div>
                </div>
              </div>
              <div v-else class="read-by">---</div>
              <div class="delivered-to" v-if="messageDelivered.length > 0">
                <h6>Delivered to</h6>
              </div>
              <div
                class="delivered-to-wrapper"
                v-if="messageDelivered.length > 0"
              >
                <div
                  class="message-reader-detail"
                  v-for="user in messageDelivered"
                  :key="user.name"
                >
                  <div class="user-img">
                    <img
                      v-if="user.avatar != ''"
                      :src="user.avatar"
                      onerror="this.src='https://whoifollow.tech/api/public/user-default-new.png'"
                      alt=""
                    />
                  </div>
                  <div class="user-name-time">
                    <h6>{{ user.name }}</h6>
                    <span>{{ user.date }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Message Info -->
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import ChatWindow from "vue-advanced-chat";
import ChatSettings from "../components/Teams/ChatRight/ChatSettings";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import * as firestoreService from "../database/firestore";
import * as storageService from "../database/storage";
import * as firebaseService from "../database/firebase";
import { parseTimestamp, formatTimestamp } from "../utils/dates";
import chatShimmers from "../components/Teams/chatShimmers";
import chatRightSims from "../components/Teams/chatRightSims";
import IndChatSetting from "../components/Teams/ChatRight/IndChatSetting";

import ChatSystem from "../components/Teams/ChatSystem.vue";
import NoChat from "../components/Teams/NoChat.vue";
import NoArchives from "../components/Teams/NoArchives.vue";
import axios from "axios";
import $ from "jquery";
import Banner from "../components/BannerNotify/BannerPage.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    Navbar,
    LeftMenu,
    ChatWindow,
    ChatSystem,
    NoChat,
    NoArchives,
    ChatSettings,
    chatShimmers,
    chatRightSims,
    IndChatSetting,
    Banner,
  },
  name: "Teams",

  data() {
    return {
      showLoaderGameRecord: 1,
      totalGame: 0,
      win: 0,
      loss: 0,
      showFooter: false,
      isLoadingArray: [],
      currentUserId: "",
      deleteUserFound: 0,
      foundCurrentUser: [],
      teamCurrentUserArray: [],
      tapArchive: 1,
      userId: localStorage.getItem("userId"),
      userName: localStorage.getItem("userName"),
      roomsPerPage: 15,
      rooms: [],
      locale: null,
      messageSeen: [],
      loadFirstRoom: false,
      messageDelivered: [],
      messageDateTime: "",
      selectedMessage: "",
      roomDataCount: 0,
      selectedRoomType: "",
      teamsChatCounter: 0,
      individualChatCounter: 0,
      selectedRoomTeam: [],
      chatTypeCheck: "team",
      roomId: "",
      allUsersRef: [],
      roomAvatar: [],
      startRooms: null,
      endRooms: null,
      roomsLoaded: false,
      loadingRooms: true,
      allUsers: [],
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: false,
      selectedRoom: null,
      messagesPerPage: 100,
      openRoomID: 0,
      firstChat: [],
      messages: [],
      base64data: [],
      messageActions: [
        {
          name: "deleteMessage",
          title: "Delete",
          onlyMe: true,
        },
        {
          name: "infoMessage",
          title: "Info",
          onlyMe: true,
        },
      ],
      messagesLoaded: false,
      roomMessage: "",
      lastLoadedMessage: null,
      previousLastLoadedMessage: null,
      roomsListeners: [],
      listeners: [],
      disableForm: false,
      addNewRoom: null,
      addRoomUsername: "",
      inviteRoomId: null,
      invitedUsername: "",
      teamUserUncount: [],
      removeRoomId: null,
      removeUserId: "",
      removeUsers: [],
      currentIsAdmin: "0",
      currentIsFan: "0",
      currentTeamIsActive: 1,
      time: this.$time,
      loadingShimmers: true,
      noChatIcon: false,
      formattedRooms: [],
      neww: "",
      menuActions: [{ name: "Info", title: "Info" }],
      isLoaded: false,
      isLoading: false,
      userBlockFlag: 0,
      teamsFilters: {},
      isFetchingRooms: false,
      lastSwitchTimestamp: null,
      cooldownDuration: 500,
      showBlockLoader: 1,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    loadedRooms() {
      return this.rooms.slice(0, this.roomsLoadedCount);
    },
    screenHeight() {
      return this.isDevice ? window.innerHeight + "px" : "calc(100vh - 80px)";
    },
  },

  methods: {
    // toggleClicked(){
    //   if (window.innerWidth <= 768) {
    //     this.$root.$emit('showChatbot', true);
    //   }
    // },
    newLists() {
      this.rooms = [];
      this.roomDataCount = 0;
      this.allUsersRef = [];
      this.startRooms = null;
      this.endRooms = null;
      this.roomsLoaded = false;
      this.loadingRooms = true;
      this.allUsers = [];
      this.loadingLastMessageByRoom = 0;
      this.roomsLoadedCount = false;
      this.messages = [];
    },
    //   Reset Teams ---------------------------------------------------------
    resetRooms() {
      this.loadingRooms = true;
      this.loadingLastMessageByRoom = 0;
      this.roomsLoadedCount = 0;
      this.rooms = [];
      this.roomsLoaded = true;
      this.startRooms = null;
      this.endRooms = null;
      this.roomsListeners.forEach((listener) => listener());
      this.roomsListeners = [];
      this.resetMessages();
    },

    //   Reset Message Field --------------------------------------------------
    resetMessages() {
      this.messages = [];
      this.messagesLoaded = false;
      this.lastLoadedMessage = null;
      this.previousLastLoadedMessage = null;
      this.listeners.forEach((listener) => listener());
      this.listeners = [];
      this.roomId = "";
      this.selectedRoomType = "";
      this.selectedRoomTeam = [];
      this.selectedRoom = null;
    },

    //   Fetch Teams -----------------------------------------------------------
    async fetchRooms() {
      this.newLists();
      this.openRoomID = 0;
      if (localStorage.getItem("openTabChat") == "Individual") {
        this.chatTypeCheck = "individual";
      } else {
        this.chatTypeCheck = "team";
      }

      // Find User id from firebase ---------------------------
      if (this.currentUserId == "") {
        const promiseUser = await firestoreService.getUserCid(this.userId);
        this.foundCurrentUser = promiseUser;
        if (this.foundCurrentUser.data[0].id != undefined) {
          this.currentUserId = this.foundCurrentUser.data[0].id;
        } else {
          this.fetchRooms();
        }
        this.$root.$emit("refreshNavbarTeamNotification");
      }

      firebaseService.updateUserOnlineStatus(this.currentUserId);
      this.resetRooms();
      await this.fetchMoreRooms();
      setTimeout(() => {
        this.$root.$emit("enableChatSystemButton");
      }, 1500);
    },

    async countTotalRooms() {
      const countRooms = await firestoreService.countRoomsForUser(
        this.currentUserId,
        this.chatTypeCheck
      );
      this.roomDataCount = countRooms;
    },

    // --------------------------------------------------------------------------

    async makeUserArray(data) {
      const roomUserIds = [];
      data.forEach((room) => {
        room.users.forEach((userId) => {
          const foundUser = this.allUsers.find((user) => user?._id === userId);
          if (!foundUser && roomUserIds.indexOf(userId) === -1) {
            roomUserIds.push(userId);
          }
        });
      });

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },
    // --------------------------------------------------------------------------

    async fetchMoreRooms() {
      var vacCircleElem = document.querySelector(".vac-loader-wrapper.vac-container-top #vac-circle");
      if(vacCircleElem) {
        vacCircleElem.style.display = "block";
      }
      if (this.isFetchingRooms) {
        return;
      }
      this.isFetchingRooms = true;
      try {
        // Log to track if this condition is met
        if (this.endRooms && !this.startRooms) {
          this.roomsLoaded = true;
          return;
        }

        // Log to track if currentUserId is empty or not
        if (this.currentUserId != "") {
          const query = firestoreService.roomsQuery(
            this.currentUserId,
            this.roomsPerPage,
            this.startRooms,
            "",
            this.chatTypeCheck
          );

          const { data, docs } = await firestoreService.getRooms(query);

          this.roomsLoaded =
            data.length === 0 || data.length < this.roomsPerPage;

          // Log to track the startRooms and endRooms values
          if (this.startRooms) this.endRooms = this.startRooms;
          this.startRooms = docs[docs.length - 1];

          await this.makeUserArray(data);
          const roomList = {};
          data.forEach((room) => {
            roomList[room.id] = { ...room, users: [] };
            room.users.forEach((userId) => {
              const foundUser = this.allUsers.find(
                (user) => user?._id === userId
              );
              if (foundUser) {
                var chechUrl;
                var url =
                  this.$userProfileStorageURL +
                  foundUser.c_id +
                  "/profile_picture.jpg?v=" +
                  this.time;
                this.urlExist(url, (exists) => {
                  if (exists) {
                    chechUrl = "yes";
                    foundUser.avatar = url;
                    console.log(chechUrl);
                  } else {
                    chechUrl = "no";
                    foundUser.avatar = require("../assets/images/user-default-new.png");
                  }
                });
                roomList[room.id].users.push(foundUser);
              }
            });
          });

          const formattedRooms = [];
          this.formattedRooms = [];

          Object.keys(roomList).forEach((key) => {
            const room = roomList[key];

            const roomContacts = room.users.filter(
              (user) => user._id !== this.currentUserId
            );

            const roomUnreadCount = room.userCount.filter(
              (user) => user.id == this.currentUserId
            );

            if (room.chatType == "team") {
              room.roomName = room.teamName;
              this.roomAvatar =
                room.teamAvatar !== ""
                  ? this.$groupAvatarStorageURL + room.teamAvatar
                  : require("../assets/images/team-avatar.png");
            } else {
              room.roomName =
                roomContacts.map((user) => user.username).join(", ") ||
                "WIF USER";
              room.userStatus =
                roomContacts.map((user) => user.username).join(", ") ||
                "1122334";
              this.roomAvatar =
                roomContacts.length === 1 && roomContacts[0].avatar
                  ? roomContacts[0].avatar
                  : require("../assets/images/user-default-new.png");
            }

            if (room.chatType == this.chatTypeCheck) {
              // Find if team archive
              this.teamCurrentUserArray = [];
              if (room.chatType == "team") {
                this.teamCurrentUserArray = room.userRole.filter(
                  (user) => user.id == this.currentUserId
                );
              }

              if (
                (this.teamCurrentUserArray.length > 0 &&
                  this.tapArchive == this.teamCurrentUserArray[0].status) ||
                room.chatType != "team"
              ) {
                formattedRooms.push({
                  ...room,
                  roomId: key,
                  unreadCount:
                    roomUnreadCount[0] === undefined
                      ? 0
                      : roomUnreadCount[0].count == "0" ||
                        roomUnreadCount[0].count == 0
                      ? ""
                      : roomUnreadCount[0].count,
                  avatar: this.roomAvatar,
                  index: room.lastUpdated.seconds,
                  lastMessage: {
                    content: "",
                    timestamp: formatTimestamp(
                      new Date(room.lastUpdated.seconds * 1000),
                      room.lastUpdated
                    ),
                  },
                });
              }
            }
          });

          this.rooms = this.rooms.concat(formattedRooms);
          // Avoid Duplication
          const filtered_array = [];
          var itemsFound = {};
          for (var i = 0, l = this.rooms.length; i < l; i++) {
            var stringified = JSON.stringify(this.rooms[i]);
            if (itemsFound[stringified]) {
              continue;
            }
            filtered_array.push(this.rooms[i]);
            itemsFound[stringified] = true;
          }
          // Avoid Duplication
          formattedRooms.forEach((room) => this.listenLastMessage(room));

          // Open First Chat
          if (this.rooms.length > 0 && this.rooms.length <= 15) {
            if (!window.matchMedia("(max-width: 991.98px)").matches) {
              setTimeout(() => {
                if (this.rooms[0] != undefined) {
                  if (this.teamsFilters.length == 0) {
                    document.getElementById(this.rooms[0].roomId).click();
                  }
                }
              }, 2000);
            }
          }

          if (!this.rooms.length) {
            this.loadingRooms = false;
            this.roomsLoadedCount = 0;
            this.noChatIcon = true;
          } else {
            this.noChatIcon = false;
          }

          await this.countTotalRooms();
          this.listenRooms(query);
          this.formattedRooms = formattedRooms;
          this.listenUsersOnlineStatus(this.formattedRooms);
        } else {
          this.loadingRooms = false;
          this.roomsLoadedCount = 0;
        }
        this.loadingShimmers = false;
        this.isFetchingRooms = false;
      } catch (error) {
        console.error("Error fetching rooms:", error); // Log any errors that occur
        this.loadingShimmers = false;
        this.loadingRooms = false;
      } finally {
        this.isFetchingRooms = false;
      }
    },

    // URL exist ----------------------------------------------------------------

    // --------------------------------------------------------------------------
    async listenRooms(query) {
      const listener = await firestoreService.listenRooms(
        query,
        async (rooms) => {
          const oldCount = this.roomDataCount;
          await this.countTotalRooms();
          await this.makeUserArray(rooms);
          // Create Chat on listen -start
          if (oldCount != this.roomDataCount) {
            //  function if that chat not found on current list and vice versa
            this.addedOrRemovedFromChat(rooms, oldCount);
          }
          // Create Chat on listen -end
          rooms.forEach((room) => {
            // Notification Counter --start
            const userIndex = room.userCount.findIndex(
              (c) => c.id === this.currentUserId
            );
            var counter =
              room.userCount[userIndex] === undefined
                ? 0
                : room.userCount[userIndex].count;

            // Notification Counter --end
            const foundRoom = this.rooms.find((r) => r.roomId === room.id);
            if (foundRoom) {
              // Notification Count on chat --start
              const roomIndex = this.rooms.findIndex(
                (r) => r.roomId === room.id
              );
              if (counter > 9) {
                counter = "9+";
              }

              this.rooms[roomIndex].unreadCount =
                counter == "0" || counter == 0 ? "" : counter;
              this.rooms[roomIndex].userCount = room.userCount;

              // Update Users and their roles
              if (this.rooms[roomIndex].chatType == "team") {
                // If user come from invite, it will refresh the list.
                if (room.id == this.selectedRoomTeam.roomId) {
                  if (this.selectedRoomTeam.users.length != room.users.length) {
                    this.$root.$emit("HitInviteTeamList");
                  }
                }
                this.rooms[roomIndex].roomName = room.teamName;
                this.rooms[roomIndex].teamName = room.teamName;
                this.rooms[roomIndex].avatar =
                  room.teamAvatar !== ""
                    ? this.$groupAvatarStorageURL + room.teamAvatar
                    : require("../assets/images/team-avatar.png");

                this.rooms[roomIndex].teamAvatar = room.teamAvatar;
                this.rooms[roomIndex].teamType = room.teamType;
                this.rooms[roomIndex].sportType = room.sportType;
                this.rooms[roomIndex].ageGroup = room.ageGroup;
                this.rooms[roomIndex].gender = room.gender;
                const oldRole = this.rooms[roomIndex].userRole;
                this.rooms[roomIndex].userRole = room.userRole;
                this.rooms[roomIndex].users = [];

                room.users.forEach((userId) => {
                  const foundUser = this.allUsers.find(
                    (user) => user?._id === userId
                  );
                  if (foundUser) {
                    var chechUrl;
                    var url =
                      this.$userProfileStorageURL +
                      foundUser.c_id +
                      "/profile_picture.jpg?v=" +
                      this.time;

                    this.urlExist(url, (exists) => {
                      if (exists) {
                        chechUrl = "yes";
                        foundUser.avatar = url;
                        console.log(chechUrl);
                      } else {
                        chechUrl = "no";
                        foundUser.avatar = require("../assets/images/user-default-new.png");
                      }
                    });

                    this.rooms[roomIndex].users.push(foundUser);
                  }
                });

                if (
                  this.rooms[roomIndex].roomId == this.selectedRoomTeam.roomId
                ) {
                  var i = 0;
                  for (const userRole of this.rooms[roomIndex].userRole) {
                    if (userRole.id == this.currentUserId) {
                      if (
                        oldRole[i].admin !=
                        this.rooms[roomIndex].userRole[i].admin
                      ) {
                        this.$root.$emit("ChatSettings", 0);
                      }
                      i++;
                    }
                    this.selectedRoomTeam = this.rooms[roomIndex];

                    let tempMembers = this.selectedRoomTeam.users;
                    let roles = this.selectedRoomTeam.userRole;

                    // Set Users Role and Find if current User is admin
                    for (const role of roles) {
                      for (const member of tempMembers) {
                        if (role.id == member.id) {
                          member.adminRole = role.admin;
                          member.fanRole = role.fan;
                          member.roleStatus = role.status;
                          member.uniform = role.uniform;
                          member.bat_size = role.bat_size;
                          member.bat_details = role.bat_details;
                          member.hat_size = role.hat_size;
                          member.shirt_size = role.shirt_size;
                          member.pant_size = role.pant_size;
                        }

                        // assign admin role
                        if (
                          member.id == this.currentUserId &&
                          member.adminRole == "1"
                        ) {
                          this.currentIsAdmin = "1";
                        }
                        if (
                          member.id == this.currentUserId &&
                          member.adminRole == "0"
                        ) {
                          this.currentIsAdmin = "0";
                        }
                        if (
                          member.id == this.currentUserId &&
                          member.fanRole == "1"
                        ) {
                          this.currentIsFan = "1";
                        }
                        if (
                          member.id == this.currentUserId &&
                          member.fanRole != "1"
                        ) {
                          this.currentIsFan = "0";
                        }

                        // assign current user team status
                        if (
                          member.id == this.currentUserId &&
                          member.roleStatus == 1
                        ) {
                          this.currentTeamIsActive = 1;
                        }
                        if (
                          member.id == this.currentUserId &&
                          member.roleStatus == 2
                        ) {
                          this.currentTeamIsActive = 2;
                        }
                      }
                    }

                    // Hide messages if user is fan
                    if (this.currentIsFan == "1") {
                      // $(".vac-messages-container").hide();
                      this.showFooter = false;
                    } else {
                      // $(".vac-messages-container").show();
                      this.showFooter = true;
                    }

                    // events date format || upComingEvents
                    if (room.eventCount == undefined) {
                      firestoreService.updateRoom(
                        this.selectedRoomTeam.roomId,
                        {
                          eventCount: this.selectedRoomTeam.eventCount,
                        }
                      );

                      room.eventCount = this.selectedRoomTeam.eventCount;
                    }

                    if (this.selectedRoomTeam.eventCount != room.eventCount) {
                      this.fetchEvents(this.selectedRoomTeam.roomId);
                    }
                    // ----------------------------------------------------
                    // }
                  }
                }
              }
              this.rooms = [...this.rooms];
              foundRoom.typingUsers = room.typingUsers;
              foundRoom.index = room.lastUpdated.seconds;
            }
          });
        }
      );
      this.roomsListeners.push(listener);
    },
    urlExist(url, callback) {
      const img = new Image();
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },
    //
    async addedOrRemovedFromChat(rooms, oldCount) {
      if (oldCount < this.roomDataCount) {
        rooms.forEach((room, index) => {
          const foundRoom = this.rooms.find((r) => r.roomId === room.id);
          if (!foundRoom) {
            const formattedRooms = [];
            this.formattedRooms = [];
            const roomList = {};
            roomList[rooms[index].id] = { ...rooms[index], users: [] };
            rooms[index].users.forEach((userId) => {
              const foundUser = this.allUsers.find(
                (user) => user?._id === userId
              );
              if (foundUser) {
                var chechUrl;
                var url =
                  this.$userProfileStorageURL +
                  foundUser.c_id +
                  "/profile_picture.jpg?v=" +
                  this.time;
                this.urlExist(url, (exists) => {
                  if (exists) {
                    chechUrl = "yes";
                    foundUser.avatar = url;
                    console.log(chechUrl);
                  } else {
                    chechUrl = "no";
                    foundUser.avatar = require("../assets/images/user-default-new.png");
                  }
                });
                roomList[rooms[index].id].users.push(foundUser);
              }
            });
            Object.keys(roomList).forEach((key) => {
              const room = roomList[key];
              const roomContacts = room.users.filter(
                (user) => user._id !== this.currentUserId
              );

              const roomUnreadCount = room.userCount.filter(
                (user) => user.id == this.currentUserId
              );

              if (room.chatType == "team") {
                room.roomName = room.teamName;
                this.roomAvatar =
                  room.teamAvatar !== ""
                    ? this.$groupAvatarStorageURL + room.teamAvatar
                    : require("../assets/images/team-avatar.png");
              } else {
                room.roomName =
                  roomContacts.map((user) => user.username).join(", ") ||
                  "WIF USER";
                this.roomAvatar =
                  roomContacts.length === 1 && roomContacts[0].avatar
                    ? roomContacts[0].avatar
                    : require("../assets/images/user-default-new.png");
              }

              if (room.chatType == this.chatTypeCheck) {
                // Find if team archive
                this.teamCurrentUserArray = [];
                if (room.chatType == "team") {
                  this.teamCurrentUserArray = room.userRole.filter(
                    (user) => user.id == this.currentUserId
                  );
                }
                if (
                  (this.teamCurrentUserArray.length > 0 &&
                    this.tapArchive == this.teamCurrentUserArray[0].status) ||
                  room.chatType != "team"
                ) {
                  formattedRooms.push({
                    ...room,
                    roomId: key,
                    unreadCount:
                      roomUnreadCount[0] === undefined
                        ? 0
                        : roomUnreadCount[0].count == "0" ||
                          roomUnreadCount[0].count == 0
                        ? ""
                        : roomUnreadCount[0].count,
                    avatar: this.roomAvatar,
                    index: room.lastUpdated.seconds,
                    lastMessage: {
                      content: "",
                      timestamp: formatTimestamp(
                        new Date(room.lastUpdated.seconds * 1000),
                        room.lastUpdated
                      ),
                    },
                  });
                }
              }
            });

            this.rooms[this.rooms.length] = formattedRooms[0];
            this.rooms = [...this.rooms];
            formattedRooms.forEach((room) => this.listenLastMessage(room));
            this.formattedRooms.push(formattedRooms[0]);
            this.listenUsersOnlineStatus(this.formattedRooms);
          }
        });
      } else {
        this.rooms = this.rooms.filter((room) => {
          const foundRoom = rooms.find((r) => r.id === room.roomId);
          if (!foundRoom) {
            if (this.selectedRoom == room.id) {
              this.resetMessages();
            }
            return false;
          }
          return true;
        });
      }
    },

    // --------------------------------------------------------------------------
    listenLastMessage(room) {
      const listener = firestoreService.listenLastMessage(
        room.roomId,
        (messages) => {
          messages.forEach((message) => {
            const lastMessage = this.formatLastMessage(message, room);
            const roomIndex = this.rooms.findIndex(
              (r) => room.roomId === r.roomId
            );
            this.rooms[roomIndex].lastMessage = lastMessage;
            this.rooms = [...this.rooms];
          });

          if (this.loadingLastMessageByRoom < this.rooms.length) {
            this.loadingLastMessageByRoom++;

            if (this.loadingLastMessageByRoom === this.rooms.length) {
              this.loadingRooms = false;
              this.roomsLoadedCount = this.rooms.length;
            }
          }
        }
      );
      this.roomsListeners.push(listener);
    },

    // --------------------------------------------------------------------------
    formatLastMessage(message, room) {
      if (!message.timestamp) return;

      let content = message.content;
      if (message.files?.length) {
        const file = message.files[0];
        content = `${file.name}.${file.extension || file.type}`;
      }

      const username =
        message.sender_id !== this.currentUserId
          ? room.users.find((user) => message.sender_id === user._id)?.username
          : "";

      return {
        ...message,
        ...{
          content,
          timestamp: formatTimestamp(
            new Date(message.timestamp.seconds * 1000),
            message.timestamp
          ),
          username: username,
          new:
            message.sender_id !== this.currentUserId &&
            (!message.seen || !message.seen[this.currentUserId]),
        },
      };
    },

    // --------------------------------------------------------------------------
    async fetchMessages({ room, index = 0, options = {} }) {
      this.$emit("show-demo-options", false);
      if (options.reset) {
        this.resetMessages();
        this.roomId = room.roomId;
      }

      if (this.previousLastLoadedMessage && !this.lastLoadedMessage) {
        this.messagesLoaded = true;
        this.$set(this.isLoadingArray, index, false);
        return;
      }

      this.selectedRoom = room.roomId;
      this.selectedRoomType = room.chatType;

      //  ------------ For chat settings ------------------------------------------------------
      this.selectedRoomTeam = room;
      this.$root.$emit("text-messages");

      // Set Users Role and Find if current User is admin
      if (this.selectedRoomType == "team") {
        let tempMembers = room.users;
        let roles = room.userRole;
        for (const role of roles) {
          for (const member of tempMembers) {
            if (role.id == member.id) {
              member.adminRole = role.admin;
              member.fanRole = role.fan;
              member.roleStatus = role.status;
              member.uniform = role.uniform;
              member.bat_size = role.bat_size;
              member.bat_details = role.bat_details;
              member.hat_size = role.hat_size;
              member.shirt_size = role.shirt_size;
              member.pant_size = role.pant_size;
            }

            // assign admin role
            if (member.id == this.currentUserId && member.adminRole == "1") {
              this.currentIsAdmin = "1";
            }
            if (member.id == this.currentUserId && member.adminRole == "0") {
              this.currentIsAdmin = "0";
            }

            if (member.id == this.currentUserId && member.fanRole == "1") {
              this.currentIsFan = "1";
            }
            if (member.id == this.currentUserId && member.fanRole != "1") {
              this.currentIsFan = "0";
            }
            // assign current user team status
            if (member.id == this.currentUserId && member.roleStatus == 1) {
              this.currentTeamIsActive = 1;
            }
            if (member.id == this.currentUserId && member.roleStatus == 2) {
              this.currentTeamIsActive = 2;
            }
          }
        }

        // Hide messages if user is fan or
        if (this.currentIsFan == "1" || this.currentTeamIsActive == 2) {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }

        // Hide message send option if archive
        if (this.currentIsFan == "1") {
          // $(".vac-messages-container").hide();
        } else {
          // $(".vac-messages-container").show();
        }
        // events date format || upComingEvents
        if (this.openRoomID != this.selectedRoomTeam.roomId) {
          this.$root.$emit("ChatSettings", 0);
          this.$root.$emit("showLoaderOnEvents", 1);
          this.$root.$emit("showEventShimmerCounter", 1);

          this.fetchEvents(this.selectedRoomTeam.roomId);
        }
        this.openRoomID = this.selectedRoomTeam.roomId;
      } else {
        if (this.openRoomID != this.selectedRoomTeam.roomId) {
          this.$root.$emit("updateIndividualRightSide", room);
          if (room.userStatus == "1122334") {
            this.deleteUserFound = 1;
            this.showFooter = false;
          } else {
            this.deleteUserFound = 0;
            this.checkBlockUser();
          }
        }
        this.openRoomID = this.selectedRoomTeam.roomId;
      }

      // -----------------------------------------------------------------
      this.$set(this.isLoadingArray, index, true);
      firestoreService
        .getMessages(room.roomId, this.messagesPerPage, this.lastLoadedMessage)
        .then(({ data, docs }) => {
          if (this.selectedRoom !== room.roomId) return;

          if (data.length === 0 || data.length < this.messagesPerPage) {
            setTimeout(() => (this.messagesLoaded = true), 0);
          }

          if (options.reset) this.messages = [];

          data.forEach((message) => {
            const formattedMessage = this.formatMessage(room, message);
            this.messages.unshift(formattedMessage);
          });

          if (this.lastLoadedMessage) {
            this.previousLastLoadedMessage = this.lastLoadedMessage;
          }
          this.lastLoadedMessage = docs[docs.length - 1];

          this.listenMessages(room);
          this.$set(this.isLoadingArray, index, false);
        });
    },

    // ---------------------------------------------------------------------------------------------

    async checkBlockUser() {
      (this.showBlockLoader = 1), (this.userBlockFlag = 0);
      this.selectedRoomTeam.users.forEach((dataU) => {
        if (dataU.c_id != this.userId) {
          this.foundCurrentUser = dataU;
        }
      });

      const chatUserId = this.foundCurrentUser.c_id;
      var formData = new FormData();
      formData.append("blocked_user_id", chatUserId);
      axios
        .post(process.env.VUE_APP_API_URL + "block/checkBlockUser", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const user_blocked = response.data.data.user_blocked;
          if (user_blocked != 0) {
            this.showFooter = false;
          } else {
            this.showFooter = true;
          }
          this.userBlockFlag = user_blocked;
          this.showBlockLoader = 0;
        });
    },

    // ----------------------------------------------------------------------------------------------------

    async fetchEvents(teamId) {
      this.selectedRoomTeam.upcomingEventsList = [];
      this.selectedRoomTeam.pastEventsList = [];
      this.selectedRoomTeam.events = [];
      this.selectedRoomTeam.eventCount = 0;
      let searchResults = await axios.post(
        `event/eventListing?team_id=${teamId}`
      );
      if (searchResults.data.statusCode == 200) {
        this.selectedRoomTeam.upcomingEventsList = [];
        this.selectedRoomTeam.pastEventsList = [];
        this.selectedRoomTeam.pastEvents = [];
        this.selectedRoomTeam.events = [];
        this.selectedRoomTeam.eventCount = 0;
        const events = searchResults.data.data;

        if (events.length > 0) {
          events.forEach((event) => {
            this.selectedRoomTeam.eventCount =
              this.selectedRoomTeam.eventCount + 1;
            var checkDateIfPast = parseTimestamp(
              event.endDateForField + "&&" + event.exactStartTime,
              "checkIfPast"
            );
            if (checkDateIfPast == "upComing") {
              event.eventTypeA = "upComing";
              this.selectedRoomTeam.upcomingEventsList.push(event);
            } else {
              event.eventTypeA = "past";
              this.selectedRoomTeam.pastEventsList.push(event);
            }
            this.selectedRoomTeam.events.push(event);
          });

          firestoreService.updateRoom(this.selectedRoomTeam.roomId, {
            eventCount: this.selectedRoomTeam.eventCount,
          });
        }
        this.$root.$emit("showEventShimmerCounter", 0);
        this.$root.$emit("showLoaderOnEvents", 0);
        if (
          this.selectedRoomTeam.teamType == "sports" ||
          this.selectedRoomTeam.teamType == "Sports"
        ) {
          this.teamRecord();
        }

        this.$root.$emit(
          "fetchEventIncoming",
          this.selectedRoomTeam.upcomingEventsList
        );
        this.$root.$emit(
          "fetchEventPast",
          this.selectedRoomTeam.pastEventsList
        );
        this.$root.$emit(
          "updateCounterEvent",
          this.selectedRoomTeam.upcomingEventsList.length,
          this.selectedRoomTeam.pastEventsList.length
        );
      }
    },

    async teamRecord() {
      this.showLoaderGameRecord = 1;
      const formData = new FormData();
      formData.append("team_id", this.selectedRoomTeam.roomId);
      let report = await axios.post("game/stats", formData);

      if (report.data.statusCode == 200) {
        this.totalGame = report.data.data.total_games;
        this.win = report.data.data.win;
        this.loss = report.data.data.lost;
        this.showLoaderGameRecord = 0;
      } else {
        this.showLoaderGameRecord = 0;
      }
    },

    // --------------------------------------------------------------------------
    async listenMessages(room) {
      const listener = firestoreService.listenMessages(
        room.roomId,
        this.lastLoadedMessage,
        this.previousLastLoadedMessage,
        (messages) => {
          if ($(".vac-col-messages").is(":visible")) {
            //  Update Count
            this.teamUserUncount = [];
            room.userCount.forEach((user) => {
              if (user.id != this.currentUserId) {
                this.teamUserUncount.push(user);
              } else {
                const checkCount = {
                  id: user.id,
                  count: 0,
                };
                this.teamUserUncount.push(checkCount);
              }
            });

            firestoreService.updateRoom(room.roomId, {
              userCount: this.teamUserUncount,
            });
            // Update Count End
          }

          messages.forEach((message) => {
            const formattedMessage = this.formatMessage(room, message);
            const messageIndex = this.messages.findIndex(
              (m) => m._id === message.id
            );

            if (messageIndex === -1) {
              this.messages = this.messages.concat([formattedMessage]);
            } else {
              this.messages[messageIndex] = formattedMessage;
              this.messages = [...this.messages];
            }

            this.markMessagesSeen(room, message);
          });
        }
      );
      this.listeners.push(listener);
      this.listenUsersOnlineStatus(this.formattedRooms);

      var formData = new FormData();
      formData.append("group_id", this.selectedRoomTeam.roomId);
      // if (window.innerWidth <= 768) {
      //   this.$root.$emit('showChatbot', false);
      // }
      return axios
        .post(
          process.env.VUE_APP_API_URL + "smsNotification/messagesChecked",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {});
    },

    // --------------------------------------------------------------------------
    markMessagesSeen(room, message) {
      if (window.location.pathname == "/teams") {
        if (
          message.sender_id !== this.currentUserId &&
          (!message.seen || !message.seen[this.currentUserId])
        ) {
          firestoreService.updateMessage(room.roomId, message.id, {
            [`seen.${this.currentUserId}`]: new Date(),
          });
        }
      }
    },

    // --------------------------------------------------------------------------
    formatMessage(room, message) {
      // get seen user id -----------------
      const arrayMessage = message.seen;
      const seenUser = [];
      for (var i in arrayMessage) {
        seenUser.push(i);
      }
      // ------------------------------------

      // get room user id -------------------
      const roomUser = [];
      room.users.forEach((user) => {
        if (user.id !== this.currentUserId) roomUser.push(user.id);
      });
      // ------------------------------------

      // get non seen user -------------------
      const notSeenUser = roomUser.filter(function(obj) {
        return seenUser.indexOf(obj) == -1;
      });
      // --------------------------------------

      const formattedMessage = {
        ...message,
        ...{
          senderId: message.sender_id,
          _id: message.id,
          seconds: message.timestamp.seconds,
          timestamp: parseTimestamp(message.timestamp, "HH:mm"),
          date: parseTimestamp(message.timestamp, "messageFormat"),
          username: this.allUsers.find(
            (user) => user?._id === message.sender_id
          )?.username,
          avatar:
            localStorage.getItem("openTabChat") != "Individual" &&
            message.sender_id != this.currentUserId
              ? room.users.find((user) => message.sender_id === user._id)
                  ?.avatar != undefined
                ? room.users.find((user) => message.sender_id === user._id)
                    ?.avatar
                : require("../assets/images/user-default-new.png")
              : "",
          // avatar:

          // message.sender_id != this.currentUserId
          //   ? require("../assets/user-default.png")
          //   : "",
          // seen: message.sender_id === this.currentUserId ? message.seen : null,
          seen: notSeenUser.length == 0 ? message.seen : false,
          distributed: true,
          messageSeen: message.seen,
          messageDelivered: notSeenUser,
        },
      };

      if (message.replyMessage) {
        formattedMessage.replyMessage = {
          ...message.replyMessage,
          ...{
            senderId: message.replyMessage.sender_id,
          },
        };
      }
      return formattedMessage;
    },

    validURL: function(textMessage) {
      var links = textMessage.match(/https?:\/\/[^\s]+/g);

      if (links != null && links.length >= 2) {
        this.$root.$emit("ChatLinks");
        return;
      }
      if (links != null && links.length == 1) {
        this.$root.$emit("ChatLinks");
        return;
      }
    },
    async checkTimeDiscrepancy() {
      try {
        const response = await fetch('https://worldtimeapi.org/api/ip');
        if (!response.ok) {
          throw new Error('Failed to fetch current time');
        }
        const data = await response.json();
        const timestampFromAPI = new Date(data.utc_datetime);
        const localTimestamp = new Date();

        const timeDifference = Math.abs(timestampFromAPI.getTime() - localTimestamp.getTime());
        const fiveMinutesInMilliseconds = 5 * 60 * 1000;

        if (timeDifference > fiveMinutesInMilliseconds) {
          this.showFooter = false;
          this.$alert("Your device's time and date settings appear to be incorrect. Please ensure your device's date and time settings are correct, then refresh the page.");
        } else {
          this.showFooter = true;
        }
      } catch (error) {
        console.error('Error fetching current time:', error);
      }
  },
    // --------------------------------------------------------------------------
    async sendMessage({ content, roomId, files, replyMessage }) {
  
    try {
       

        let message = {
            sender_id: this.currentUserId,
            content,
            timestamp: new Date(),
            pinFile: false,
            hasFile: false,
        };

        if (files) {
            files.forEach(async (file) => {
                const fileFile = [];
                fileFile.push(file);
                message = {
                    sender_id: this.currentUserId,
                    content,
                    timestamp: new Date(),
                    pinFile: false,
                    hasFile: true,
                    tagsDetails: {
                        name: "",
                        tags: {},
                        eventId: "",
                        gameId: "",
                    },
                };
                message.files = this.formattedFiles(fileFile);
                this.validURL(content);
                const { id } = await firestoreService.addMessage(roomId, message);
                var last_message_sent =
                    files[files.length - 1].name +
                    "." +
                    files[files.length - 1].extension;
                await this.counterQueryandSMSSend(
                    roomId,
                    files.length,
                    last_message_sent
                );
                for (let index = 0; index < fileFile.length; index++) {
                    await this.uploadFile({
                        fileIndex: index,
                        file: fileFile[index],
                        messageId: id,
                        roomId,
                    });
                }
            });

            this.$root.$emit("ChatMedia");
            this.$root.$emit("ChatDocuments");
        } else {
            if (replyMessage) {
                message.replyMessage = {
                    _id: replyMessage._id,
                    content: replyMessage.content,
                    sender_id: replyMessage.senderId,
                };

                if (replyMessage.files) {
                    message.replyMessage.files = replyMessage.files;
                }
            }

            this.validURL(content);
            await firestoreService.addMessage(roomId, message);
            var last_message_sent = message.content.trim();
            await this.counterQueryandSMSSend(roomId, 1, last_message_sent);
            this.updateSMSCounter(content, files);
        }
    } catch (error) {
        console.error("Error:", error.message);
    }
},


    async counterQueryandSMSSend(roomId, counterSum, last_message_sent) {
      const foundRoom = this.rooms.find((r) => r.roomId === roomId);
      //  Update Count
      this.teamUserUncount = [];
      foundRoom.userCount.forEach((user) => {
        if (user.id != this.currentUserId) {
          var oldcount = user.count === undefined ? 0 : user.count;
          var count = parseInt(oldcount) + counterSum;
          const checkCount = {
            id: user.id,
            count: count,
          };
          this.teamUserUncount.push(checkCount);
        } else {
          const checkCount = {
            id: user.id,
            count: 0,
          };
          this.teamUserUncount.push(checkCount);
        }
      });

      // Update Count End
      await firestoreService.updateRoom(roomId, {
        userCount: this.teamUserUncount,
        last_message_sent: last_message_sent,
        last_sender_id: this.currentUserId,
        lastUpdated: new Date(),
      });
    },

    async updateSMSCounter(content, files) {
      var messageReceived = content;
      if (files) {
        if (files.length > 1) {
          messageReceived = "sent attachments.";
        } else {
          messageReceived = "sent an attachment.";
        }
      }
      let tempMembers = this.selectedRoomTeam.users;
      let tempMembersRoles = this.selectedRoomTeam.userRole;

      // ===== SMS Sending ========
      for (const member of tempMembers) {
        if (member.id != this.currentUserId) {
          var group_name = "";
          var notify = "on";
          var notifyPush = "on";
          var heading = "";

          if (this.selectedRoomType == "team") {
            for (const tempMember of tempMembersRoles) {
              if (tempMember.id == member.id) {
                if (tempMember.notification == "off") {
                  notify = "off";
                } else {
                  notify = "on";
                }
                if (tempMember.pushNotification == "off") {
                  notifyPush = "off";
                } else {
                  notifyPush = "on";
                }
              }
            }
            group_name = this.selectedRoomTeam.roomName;
            heading = this.selectedRoomTeam.roomName;
          } else {
            group_name = "no-group";
            heading = "";
          }

          const foundUser = this.allUsers.find(
            (user) => user?._id === member.id
          );

          if (
            messageReceived != "((left_group_undefined))" &&
            messageReceived != "((update_group_name))" &&
            messageReceived != "((make_admin_group))" &&
            messageReceived != "((remove_admin_group))" &&
            messageReceived != "((removed_group_undefined))" &&
            messageReceived != "((joined_group_undefined))" &&
            messageReceived != "((joined_group_undefined))" &&
            messageReceived != "((added_group_undefined))"
          ) {
            // Script for sending desktop
            // messageReceived

            // Script for sending desktop

            var formData = new FormData();
            formData.append("user_id", foundUser.c_id);
            formData.append("group_id", this.selectedRoomTeam.roomId);
            formData.append("group_name", group_name);
            formData.append("messageReceived", messageReceived);
            formData.append("heading", heading);
            formData.append("notify", notify);
            formData.append("pushNotification", notifyPush);
            axios
              .post(
                process.env.VUE_APP_API_URL + "smsNotification/messageCheck",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(() => {});
          }
        }
      }
      // ====== SMS Sending =======
    },

    // --------------------------------------------------------------------------
    async uploadFile({ fileIndex, file, messageId, roomId }) {
      return new Promise((resolve) => {
        let type = file.extension || file.type;
        if (type === "svg" || type === "pdf") {
          type = file.type;
        }

        storageService.listenUploadImageProgress(
          this.currentUserId,
          messageId,
          file,
          type,
          async (progress) => {
            this.updateFileProgress(messageId, file.localUrl, progress);
            const message = await firestoreService.getMessage(
              roomId,
              messageId
            );

            // Added s3 Customised code start
            const fileBlob = this.blobToFile(file.blob, "filename");
            if (fileBlob) {
              var formData = new FormData();
              formData.append("image", fileBlob);
              axios
                .post(
                  process.env.VUE_APP_API_URL + "chat/storeMedia",
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((response) => {
                  message.files[fileIndex].url = response.data.data;
                  firestoreService.updateMessage(roomId, messageId, {
                    files: message.files,
                  });
                  resolve(true);
                });
            }
            //  Added s3 Customised code end
          }
        );
      });
    },

    // --------------------------------------------------------------------------
    updateFileProgress(messageId, fileUrl, progress) {
      const message = this.messages.find(
        (message) => message._id === messageId
      );

      if (!message || !message.files) return;

      message.files.find((file) => file.url === fileUrl).progress = progress;
      this.messages = [...this.messages];
    },

    // --------------------------------------------------------------------------
    formattedFiles(files) {
      const formattedFiles = [];
      this.fileedata = 0;
      files.forEach((file) => {
        const messageFile = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.localUrl,
        };

        if (file.audio) {
          messageFile.audio = true;
          messageFile.duration = file.duration;
        }
        formattedFiles.push(messageFile);
      });
      return formattedFiles;
    },

    // --------------------------------------------------------------------------
    openFile({ file }) {
      window.open(file.file.url, "_blank");
    },
    blobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, {
        lastModified: new Date().getTime(),
        type: theBlob.type,
      });
    },

    messageActionHandler({ action, message }) {
      switch (action.name) {
        case "infoMessage": {
          var elem = this.$refs.openMessageInfoModal;
          elem.click();

          //  Seen Users -------------------------
          this.messageSeen = [];
          const arrayMessage = message.messageSeen;
          this.selectedMessage = message;
          this.messageDateTime = message.date + ", " + message.timestamp;
          for (var i in arrayMessage) {
            // Format Time and date
            var first = arrayMessage[i];
            var time = parseTimestamp(first, "HH:mm");
            var date = parseTimestamp(first, "messageFormat");

            // Get User data
            const foundUser = this.allUsers.find((user) => user?._id === i);
            const finalArray = {
              name: foundUser.username,
              avatar: foundUser.avatar,
              date: date + ", " + time,
            };
            this.messageSeen.push(finalArray);
          }
          //  Seen Users -------------------------

          //  Deliverd Users -------------------------
          this.messageDelivered = [];
          const arrayMessageD = message.messageDelivered;
          for (var iD in arrayMessageD) {
            // Get User data
            const foundUserD = this.allUsers.find(
              (user) => user?._id === arrayMessageD[iD]
            );
            const finalArrayD = {
              name: foundUserD.username,
              avatar: foundUserD.avatar,
              date: this.messageDateTime,
            };
            this.messageDelivered.push(finalArrayD);
          }
          //  Deliverd Users -------------------------
        }
      }
    },

    // --------------------------------------------------------------------------
    async deleteMessage({ message, roomId }) {
      await this.$confirm(
        "Are you sure you want to delete this message, Continue?",
        "Delete Message"
      ).then(() => {
        firestoreService.updateMessage(roomId, message._id, {
          deleted: new Date(),
        });

        if (this.selectedRoomTeam.lastMessage.id == message._id) {
          firestoreService.updateRoom(roomId, { last_message_sent: "" });
        }

        var deleteChatFiles = true;

        var deleteChatLinks = true;

        const { files } = message;

        if (files) {
          files.forEach((file) => {
            if (
              file.type == "image/jpeg" ||
              file.type == "audio/mpeg" ||
              file.type == "video/mp4"
            ) {
              deleteChatFiles = true;
            }

            if (
              file.type == "application/pdf" ||
              file.extension == "docx" ||
              file.type == "text/plain" ||
              file.extension == "sql"
            ) {
              deleteChatFiles = false;
            }
            var formData = new FormData();
            formData.append("files", file.url);
            axios
              .post(
                process.env.VUE_APP_API_URL + "chat/deleteMedia",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(() => {});
          });
          if (deleteChatFiles == true) {
            this.$root.$emit("ChatMedia");
            this.$root.$emit("ChatPinned");
          } else if (deleteChatFiles == false) {
            this.$root.$emit("ChatDocuments");
            this.$root.$emit("ChatPinned");
          }
        }
        if (deleteChatLinks == true) {
          if (this.validURL(message.content) == true)
            this.$root.$emit("ChatLinks");
          this.$root.$emit("ChatPinned");
        }
      });
    },

    // --------------------------------------------------------------------------
    listenUsersOnlineStatus(rooms) {
      rooms.forEach((room) => {
        if (room != undefined) {
          room.users.forEach((user) => {
            const listener = firebaseService.firebaseListener(
              firebaseService.userStatusRef(user._id),
              (snapshot) => {
                if (!snapshot || !snapshot.val()) return;
                const lastChanged = formatTimestamp(
                  new Date(snapshot.val().lastChanged),
                  new Date(snapshot.val().lastChanged)
                );

                user.status = { ...snapshot.val(), lastChanged };
                if (user.username.trim() == room.roomName.trim()) {
                  if (user.status.state == "online") {
                    var roomIDCheck = room.roomId;
                    this.setOnlineStatus(roomIDCheck);
                  } else {
                    $("#" + room.roomId).removeClass("avatar-online");
                  }
                }
              }
            );
            this.roomsListeners.push(listener);
          });
        }
      });
    },

    setOnlineStatus(roomIDCheck) {
      var element = document.getElementById(roomIDCheck);
      if (element != null) {
        $("#" + roomIDCheck).addClass("avatar-online");
      } else {
        setTimeout(() => {
          this.setOnlineStatus(roomIDCheck);
        }, 5000);
      }
    },
    // --------------------------------------------------------------------------
    addRoom() {
      this.resetForms();
      this.addNewRoom = true;
    },

    // --------------------------------------------------------------------------
    resetForms() {
      this.disableForm = false;
      this.addNewRoom = null;
      this.addRoomUsername = "";
      this.inviteRoomId = null;
      this.invitedUsername = "";
      this.removeRoomId = null;
      this.removeUserId = "";
    },

    menuActionHandler({ action }) {
      let window_width = window.innerWidth;
      switch (action.name) {
        case "Info":
          if (window_width <= 991.98) {
            $(".chat-right").show();
            $(".vac-col-messages").hide();
          } else if (window_width >= 901) {
            $(".chat-right").show();
            $(".vac-col-messages").show();
          }
      }
    },
    // ------- Database modification---------------------------------------------------------------------------
    async updateCreatedAtToTimestamp() {
      const rawRooms = [];
      const promise = await firestoreService.getAllRooms();
      rawRooms.push(promise);
      const rooms = await Promise.all(rawRooms);
      rooms.forEach(async (room) => {
        room.data.forEach(async (room1) => {
          const inputDate = room1.createdAt;
          var timestamp = await this.dateFormatTimestamp(inputDate);
          // Update Room
          await firestoreService.updateRoom(room1.id, {
            createdAtTimestamp: timestamp,
          });
        });
      });
    },

    async updateUserRoleOfSpecificTeam() {
      var teamDetail = "";
      const rawRoom = [];

      // Get Team data
      const promise = await firestoreService.getRoomById(
        "uoz4srUcaWQ7n3nzZoGX"
      );
      rawRoom.push(promise);
      console.log(promise);

      const foundRoom = await Promise.all(rawRoom);
      Object.keys(foundRoom).forEach((key) => {
        teamDetail = foundRoom[key];
      });
      if (teamDetail.users != undefined) {
        const teamSUserRole = [];
        const users = teamDetail.users;
        for (const userRole of users) {
          if (userRole == "KF0FQqBYH9CQblDT3ITR") {
            const arrayAdminUser = {
              id: "KF0FQqBYH9CQblDT3ITR",
              admin: "1",
              fan: "0",
              notification: "on",
              status: 1,
            };
            teamSUserRole.push(arrayAdminUser);
          } else {
            const arrayAdminUserS = {
              id: userRole,
              admin: "0",
              fan: "0",
              notification: "on",
              status: 1,
            };
            teamSUserRole.push(arrayAdminUserS);
          }
        }
        firestoreService.updateRoom("uoz4srUcaWQ7n3nzZoGX", {
          userRole: teamSUserRole,
        });
      }
    },

    async updateLastSentMessage() {
      const rawRooms = [];
      const promise = await firestoreService.getAllRooms();
      rawRooms.push(promise);
      const rooms = await Promise.all(rawRooms);
      rooms.forEach(async (room) => {
        room.data.forEach(async (roomSingle) => {
          await firestoreService.listenLastMessage(
            roomSingle.id,
            (messageFetch) => {
              const message = messageFetch[0];
              if (message) {
                var content = "";
                if (!message.deleted) {
                  if (message.files) {
                    if (message.files.length > 0) {
                      const files = message.files;
                      const lastFileSent = files[files.length - 1];
                      if (lastFileSent.name) {
                        content =
                          lastFileSent.extension == "audio/mp3"
                            ? lastFileSent.name
                            : lastFileSent.name + "." + lastFileSent.extension;
                      }
                    }
                  } else {
                    content = message.content.trim();
                  }
                }
                // Update Room
                firestoreService.updateRoom(roomSingle.id, {
                  last_message_sent: content,
                  last_sender_id: message.sender_id,
                });
              }
            }
          );
        });
      });
    },

    dateFormatTimestamp(inputDate) {
      // Split the input date into day, month, and year
      const [day, month, year] = inputDate.split("/");
      // Create a new Date object
      const dateObj = new Date(`${year}-${month}-${day}T00:00:00Z`);
      return dateObj;
    },
    async updateHasFileindex() {
      const rawRooms = [];
      const promise = await firestoreService.getAllRooms();
      rawRooms.push(promise);
      const rooms = await Promise.all(rawRooms);
      rooms.forEach(async (room) => {
        room.data.forEach(async (roomSingle) => {
          await firestoreService
            .getMessagesToUpdate(roomSingle.id)
            .then(({ data }) => {
              data.forEach(async (message) => {
                if (message) {
                  if (message.hasFile == undefined) {
                    if (message.files) {
                      if (message.files.length > 0) {
                        // Update message hasFile = true
                        await firestoreService.updateMessage(
                          roomSingle.id,
                          message.id,
                          {
                            hasFile: true,
                          }
                        );
                      }
                    } else {
                      await firestoreService.updateMessage(
                        roomSingle.id,
                        message.id,
                        {
                          hasFile: false,
                        }
                      );
                    }
                  }
                }
              });
            });
        });
      });
    },
  },

  mounted() {
    this.checkTimeDiscrepancy();
    this.fetchMoreRooms();
    if (localStorage.getItem("openTabChat") == "Individual") {
      this.teamsFilters = {};
    } else {
      this.teamsFilters = {};
      //  this.teamsFilters = {
      //     chat_year: new Date().getFullYear(),
      //   };
    }
    this.locale = {
      ROOMS_EMPTY: "No chat found",
      ROOM_EMPTY: "No chat selected",
      NEW_MESSAGES: "New Messages",
      MESSAGE_DELETED: "Deleted",
      MESSAGES_EMPTY: "No messages",
      CONVERSATION_STARTED: "Conversation started on:",
      SEARCH: "Search in conversations",
      IS_ONLINE: "is online",
      LAST_SEEN: "last seen ",
      IS_TYPING: "is writing...",
      CANCEL_SELECT_MESSAGE: "Cancel",
    };
    this.fetchRooms();

    this.$root.$on("ChatSystem", () => {
      this.fetchRooms();
    });

    this.$root.$on("fetchEvents", () => {
      // this.$root.$emit("showEventShimmerCounter", 1);
      this.fetchEvents(this.selectedRoomTeam.roomId);
    });

    this.$root.$on("checkBlockUserFlagOnTeam", () => {
      this.checkBlockUser();
    });

    this.$root.$on("showEventList", () => {
      this.$root.$emit("showLoaderOnEvents", 1);
    });

    this.$root.$on("showTeamList", () => {
      localStorage.setItem("openTabChat", "Team");
      this.tapArchive = 1;
      this.noChatIcon = false;
      this.teamsFilters = {};
      // this.teamsFilters = {
      //   chat_year: new Date().getFullYear(),
      // };
      this.fetchRooms();
    });
    this.$root.$on("showIndividualList", () => {
      localStorage.setItem("openTabChat", "Individual");
      this.tapArchive = 1;
      this.noChatIcon = false;
      this.teamsFilters = {};
      this.fetchRooms();
    });

    this.$root.$on("showTeamArchiveList", () => {
      localStorage.setItem("openTabChat", "Team");
      this.tapArchive = 2;
      this.loadingShimmers = true;
      this.noChatIcon = false;
      this.teamsFilters = [];
      this.fetchRooms();
    });
    this.$root.$on("fetchTeamsByfilters", (filters) => {
      this.teamsFilters = filters;
      this.noChatIcon = false;
      this.fetchRooms();
    });
    this.$root.$on("text-messages", () => {
      var room_name = this.selectedRoomTeam.roomName
        ? this.selectedRoomTeam.roomName.replace(" ( Online )", "").trim()
        : "";
      this.locale = {
        ROOMS_EMPTY: "No chat found",
        ROOM_EMPTY: "No chat selected",
        NEW_MESSAGES: "New Messages",
        MESSAGE_DELETED: "Deleted",
        MESSAGES_EMPTY: "No messages",
        CONVERSATION_STARTED: "Conversation started on:",
        TYPE_MESSAGE: "Type your message for " + room_name,
        SEARCH: "Search in conversations",
        IS_ONLINE: "is online",
        LAST_SEEN: "last seen ",
        IS_TYPING: "is writing...",
        CANCEL_SELECT_MESSAGE: "Cancel",
      };
    });
  },
};
</script>

<style>
/* Add margin or padding as needed to place the banner below the Navbar */
/* .banner-wrapper {
  margin-top: 
} */

.main-wrapper-team {
  padding-top: 68px;
  height: 100vh;
  display: flex;
  overflow: hidden !important;
}
.mar-right {
  margin-right: -20px;
}
.vac-room-header {
  display: none;
}

.vac-badge-counter {
  height: 15px !important;
  width: 15px !important;
}
.vac-col-messages .vac-messages-count {
  background-color: #c70625 !important;
}

.vac-message-file-container .vac-video-container {
  width: 250px !important;
  margin: 0 !important;
  height: 250px;
}

.vac-message-file-container .vac-video-container video {
  object-fit: cover;
}

.vac-message-files-container .vac-file-wrapper {
  overflow: hidden;
  width: auto;
}

.vac-audio-player .vac-svg-button {
  margin-left: 0;
}

.vac-player-bar {
  max-width: calc(100% - 34px);
  margin-right: 0;
  margin-left: 15px;
}

.vac-message-wrapper .vac-progress-time {
  margin: 0 0 0 10px;
}

@media screen and (max-width: 991.98px) {
  .vac-room-header .vac-room-info {
    display: none;
  }
  .vac-message-files-container .vac-file-container svg {
    margin-top: 6px;
  }
  .vac-room-header {
    display: block;
    z-index: 5;
    height: 65px;
    background-color: #f3f7fc;
  }
  .vac-room-header .vac-toggle-button {
    margin-top: 20px;
  }
  .vac-svg-button {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .vac-col-messages .vac-icon-textarea-left {
    height: auto;
  }

  .vac-col-messages .vac-send-disabled {
    min-height: 30px !important;
    padding: 10px 0;
  }
}
.vac-card-window .vac-rooms-container {
  height: calc(100vh - 129px);
  overflow: auto;
  min-width: calc(100vw - 72vw);
  padding-top: 16px;
}

@media screen and (max-width: 991px) {
  .vac-card-window .vac-rooms-container {
    height: calc(100vh - 192px);
  }
}

.vac-room-container .vac-text-date {
  color: #777676;
  text-transform: uppercase;
}

.vac-box-search {
  height: 40px;
  padding: 0 10px;
}
.vac-box-search .vac-input {
  background: #dae6f1;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 14px;
}
.vac-box-search .vac-svg-button {
  display: none;
}

.vac-rooms-container .vac-room-list {
  margin-top: 10px;
  border-top: 1px solid rgb(230, 229, 229);
  padding-left: 0;
  padding-right: 0;
}
.vac-rooms-container .vac-room-item {
  border-top: 1px solid #d1d0d0;
  border-radius: 0;
  margin-bottom: 0;
}
.vac-rooms-container .vac-room-item:hover {
  background-color: #e5f2fe !important;
}
.vac-rooms-container .vac-room-selected {
  border-left: 4px solid #188ef5;
  background-color: #e5f2fe !important;
}

.vac-rooms-container .vac-room-selected:hover {
  background-color: #e5f2fe !important;
}

.vac-rooms-container .vac-room-selected .vac-room-container .vac-room-name {
  color: #188ef5;
}
.vac-title-container .vac-room-name {
  color: black !important;
  text-transform: capitalize;
}

.vac-col-messages .vac-container-scroll {
  background-color: transparent !important;
  margin-top: 0;
  overflow: auto;
  width: 100%;
  position: absolute;
  bottom: 64px;
  /* max-height: calc(100vh - 194px); */
  max-height: calc(100% - 64px);
}

.vac-card-window {
  border: 0;
  border-radius: none;
  box-shadow: 0;
  -webkit-tap-highlight-color: transparent;
}

.vac-col-messages {
  background-color: #e6eff7;
  height: calc(100vh - 68px);
  margin-top: -59px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .vac-col-messages {
    height: calc(100vh - 120px);
  }
}
@media screen and (max-width: 767.98px) {
  .vac-col-messages {
    height: calc(100% - 70px);
    position: fixed;
    /* top: 140px; */
    left: 0;
    right: 0;
    bottom: 0;
  }
  .avatar-online:before {
    top: 36px !important;
  }
}
#infinite-loader-messages .vac-loader-wrapper #vac-circle {
  display: none !important;
}

.vac-loader-wrapper.vac-container-top #vac-circle {
  display: none;
}

@media screen and (max-width: 900px) {
  .main-wrapper-team {
    padding-top: 68px;
  }
}

.team-left-menu .left-menu-wrapper {
  min-width: 7.999999%;
}

.vac-col-messages .vac-room-footer {
  z-index: 5;
}
.vac-message-files-container .vac-file-container {
  height: 80px !important;
  width: 100% !important;
}
.chat-system-top .vac-message-files-container .vac-file-container {
  height: 80px !important;
  width: 100% !important;
}
.vac-icon-check {
  /* display: none; */
  margin-right: 3px !important;
}

.vac-room-container .vac-room-badge {
  background-color: #ff5b66 !important;
  padding: 9px 9px;
  height: 17px;
  font-size: 10px;
  font-weight: 400 !important;
}

.vac-message-wrapper .vac-message-card {
  background: #188ef5 !important;
  font-weight: 400 !important;
  border-radius: 0 8px 8px 8px;
  color: #ffffff !important;
}

.vac-message-files-container {
  background: #cce6ff !important;
  font-weight: 400 !important;
  color: black !important;
  padding-top: 23px !important;
  border-radius: inherit;
}

.vac-message-wrapper .vac-message-current {
  background: #cce6ff !important;
  font-weight: 400 !important;
  color: black !important;
}

.vac-format-message-wrapper span {
  font-style: normal !important;
}

.vac-message-deleted {
  font-weight: 500 !important;
}

.vac-emoji-reaction {
  display: none;
}

.vac-message-actions-wrapper .vac-options-me {
  background-color: transparent !important;
}

.vac-message-actions-wrapper .vac-blur-container {
  display: none !important;
}

.vac-message-wrapper .vac-text-timestamp {
  text-align: left !important;
  font-weight: 600 !important;
}
.vac-message-image .vac-image-buttons .vac-button-download svg path {
  fill: white !important;
}
.vac-box-search .vac-input {
  border-radius: 5px !important;
}

.chat-option-archive {
  background: #e2f5fe;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-options img {
  width: 20px;
}

.vac-col-messages .vac-box-footer {
  background-color: #f3f7fc;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.vac-message-wrapper .vac-message-current {
  border-radius: 5px 5px 0 5px;
}
.vac-message-wrapper .vac-message-card {
  display: contents;
}
.vac-format-message-wrapper {
  background-color: inherit;
  border-radius: inherit;
  padding: 11px 10px;
  margin-bottom: 4.5px;
}

/* .vac-room-container .vac-state-online {
  display: none;
} */
.vac-room-container .vac-state-circle {
  display: none;
}
.vac-message-wrapper .vac-avatar {
  margin-bottom: 15px;
}

.vac-avatar {
  background-image: url("https://whoifollow.tech/api/public/user-default-new.png");
}

.vac-avatar .vac-avatar-current {
  display: none !important;
}
.vac-text-last .vac-format-message-wrapper {
  margin: 0;
  padding: 0;
}
.vac-text-last .vac-format-message-wrapper {
  font-size: inherit;
  font-weight: inherit;
}
.vac-format-message-wrapper span {
  padding: 2px;
  font-size: 14px;
  font-weight: 400;
}
.vac-room-name.vac-text-ellipsis {
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  word-break: break-all !important;
  width: 95%;
  margin-right: 10px;
}

.vac-text-timestamp span {
  color: var(--chat-message-color-started);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px !important;
}
.vac-message-wrapper .vac-offset-current .vac-text-timestamp span {
  float: right;
}
.vac-col-messages .vac-textarea {
  border-radius: 0 !important;
  border: none !important;
  background-color: #e2e1e1;
  max-height: calc(100vh - 162px) !important;
  overflow-y: auto;
  font-size: 14px !important;
}
@media screen and (max-width: 768px) {
  .vac-col-messages .vac-textarea {
    padding: 11.5px 16px !important;
    overflow-y: auto;
  }
}
.vac-col-messages .vac-icon-textarea {
  margin-left: 0;
}
.vac-col-messages .vac-icon-textarea-left {
  margin-right: 0;
}
.vac-col-messages .vac-icon-textarea-left {
  background-color: #e2e1e1;
  border-radius: 5px 0 0 5px;
}

.vac-col-messages .vac-icon-textarea {
  background-color: #e2e1e1;
  border-radius: 0 5px 5px 0;
}

.vac-col-messages .vac-send-disabled {
  margin-left: 5px;
  background-color: #188ef5;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.vac-col-messages .vac-send-disabled svg {
  width: 25px;
  margin-top: 3px;
}
.vac-col-messages .vac-messages-container,
.vac-loader-wrapper.vac-container-top {
  padding: 0 !important;
}

@media screen and (max-width: 991.98px) {
  .vac-col-messages .vac-messages-container {
    height: auto;
  }
  .vac-col-messages .vac-send-disabled svg {
    margin-top: 3.5px !important;
  }
  .vac-col-messages .vac-container-scroll {
    max-height: calc(100% - 130px);
  }
}
@media screen and (max-width: 900px) {
  .vac-col-messages .vac-send-disabled svg {
    margin-top: 3.5px !important;
    max-width: 16px !important;
  }
}
#vac-icon-send-disabled {
  fill: #fff;
}

.vac-message-wrapper .vac-message-container {
  margin: 5px 0;
}

.vac-rooms-container {
  margin-top: 0 !important;
}
.vac-card-window .vac-chat-container input[type="search"] {
  background-color: #f9f9f9;
  border-radius: 5px !important;
  height: inherit;
}
.vac-box-search .vac-icon-search {
  background-image: url("../assets/images/search.png");
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#vac-icon-search {
  display: none;
}
.vac-box-search .vac-input {
  padding-left: 50px;
}

.vac-emoji-wrapper {
  border-right: 1px solid #bdbbbb;
}

.vac-text-started {
  margin: 5px 0 16px 0 !important;
}

.vac-message-wrapper .vac-avatar {
  height: 34px;
  min-width: 34px;
  max-width: 34px;
  background-image: url("../assets/images/team-avatar.png");
  margin-bottom: 34px;
  margin-left: 12px;
}

.vac-text-tag {
  cursor: auto !important;
  font-weight: 600 !important;
}

.vac-text-tag span {
  cursor: auto !important;
  font-weight: 600 !important;
}

.vac-rooms-container .vac-room-list {
  border-top: none;
}

.vac-format-message-wrapper span {
  padding: 0 !important;
}

.vac-app-border-r {
  border-right: none !important;
}
.chat-side .vac-card-window {
  border: none !important;
  box-shadow: none !important;
  height: calc(100vh - 121px) !important;
}

.no-right-room {
  font-size: 14px;
  color: #9ca6af;
  font-style: italic;
  text-align: center;
  margin: 200px 0;
  line-height: 20px;
  white-space: pre-line;
}

@media screen and (max-width: 900px) {
  .chat-side .vac-card-window {
    height: calc(100vh - 71px) !important;
  }
}

.vac-text-ellipsis {
  color: var(--chat-room-color-message) !important;
}

.vac-message-wrapper .vac-message-box {
  max-width: 80%;
}

.vac-message-wrapper .vac-offset-current {
  margin-left: 20%;
}

/* background-color: rgb(194, 197, 195); */
/* .vac-room-container .active:before {*/

/* .vac-room-item:before { */
.avatar-online:before {
  content: "";
  position: absolute;
  top: 46px;
  margin-left: 30px;
  background-color: rgb(123, 216, 99);
  width: 10px;
  height: 10px;
  font-size: 10px;
  border-radius: 50px;
  text-align: center;
}

.vac-message-wrapper .vac-message-deleted {
  color: #b0bac4 !important;
  font-style: normal !important;
}

.vac-message-actions-wrapper .vac-message-options svg {
  width: 25px;
  height: 25px;
}

.vac-card-window a {
  color: inherit !important;
  margin: 0 !important;
}

.vac-text-timestamp span {
  float: right;
}

@media screen and (max-width: 991.98px) {
  .vac-room-header .vac-toggle-button {
    margin-top: 0;
  }
}

#messageInfo .modal-header h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #254c72;
}

#messageInfo .close {
  color: #188ef5;
  font-weight: normal;
}
#messageInfo .modal-body .message-content {
  height: 120px;
  background: #fafafa;
  overflow-y: auto;
  overflow-x: hidden;
}

.message-content p {
  border-radius: 5px 5px 0 5px;
  background-color: #cce6ff !important;
  font-weight: 500 !important;
  position: relative;
  padding: 16px;
  align-items: end;
  min-width: 100px;
  box-sizing: content-box;
  text-align: end;
  margin-bottom: 16px;
  font-size: 16px !important;
  float: right;
  word-break: break-all;
}
.font-style-italic {
  font-style: italic !important;
}
.message-content .time {
  background-color: inherit !important;
  border: none !important;
  font-weight: 600 !important;
  position: relative;
  padding: 0 !important;
  align-items: end;
  box-sizing: content-box;
  margin: 14px;
  margin-top: -10px;
  text-align: end;
  width: 95% !important;
  font-size: 12px !important;
  float: left !important;
}

#messageInfo .read-by h6,
#messageInfo .delivered-to h6 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #111115;
  margin: 16px 0;
}

.read-by-wrapper,
.delivered-to-wrapper {
  max-height: 210px;
  overflow-y: auto;
  padding-right: 16px;
}

.message-reader-detail {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 0;
  border-bottom: 1px solid #cfd7e0;
}

.message-reader-detail:first-child {
  padding-top: 0px;
}

.message-reader-detail:last-child {
  border-bottom: none !important;
  padding-bottom: 0px;
}

.message-reader-detail .user-name-time {
  display: flex;
  flex-direction: column;
}

.message-reader-detail .user-name-time h6 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #111115;
  text-transform: capitalize;
}
#messageInfo .message-read-sent .message-reader-detail .user-name-time span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #868585;
}

.rooms-empty {
  font-size: 14px;
  color: #9ca6af;
  font-style: italic;
  text-align: center;
  /* line-height: 20px; */
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.vac-room-header .vac-room-options {
  display: none;
}

.vac-room-header .vac-room-options svg {
  display: none;
}

@media screen and (max-width: 991.98px) {
  .vac-room-header .vac-room-options {
    display: block;
    position: relative;
  }

  .vac-room-header .vac-room-options::before {
    content: url("../assets/images/info.png");
    right: 0;
    position: absolute;
    top: -7.5px;
  }
}

.vac-emoji-wrapper .vac-emoji-picker {
  position: absolute;
  bottom: 35px !important;
  right: -50px !important;
}
@media screen and (max-width: 390px) {
  .vac-emoji-wrapper .vac-emoji-picker {
    right: -75px !important;
  }
}

.vac-room-files-container {
  position: absolute;
  bottom: 64px !important;
  width: 100%;
}

.loading {
  background: transparent url("../assets/loader.gif") center no-repeat;
  height: 400px;
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vac-message-actions-wrapper .vac-menu-options {
  top: 22px !important;
}

.vac-tags-container {
  position: absolute;
  bottom: 64px !important;
}
</style>
