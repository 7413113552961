<template>
  <div class="order-history-wrap pl-0 pt-0 pb-0">
    <div>
      <div class="history-heading">
        <h6>
          Cart
          <div class="cart-items" v-if="cartItemCount == 1">
            <span
              >{{ cartItemCount }} Item{{ cartItemCount > 1 ? "S" : "" }}</span
            >
          </div>
        </h6>
        <EditCart />
      </div>
    </div>

    <div class="history-wrapper-detail">
      <div class="history-wrapper-content history-wrapper-content-last">
        <div
          class="history-content"
          v-for="item in cart"
          :key="item.product.id"
        >
          <div class="history-info">
            <div class="item-info-wrapper">
              <div class="item-image">
                <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../../assets/loader.gif" alt="">
    </div>
                <img
                @load="onImageLoad"
                  v-if="item.product.products.image != null"
                  :src="API_URL + item.product.products.image"
                  alt=""
                />
                <img @load="onImageLoad" v-else src="../../../assets/images/gallery.png" alt="" />
              </div>
              <div class="item-name">
                <span class="category">{{ item.product.products.name }}</span>
                <div class="item-quantity">
                  <span>{{ item.quantity }}x</span>
                  <span>${{ (item.product.products.price).toFixed(2) }} / Piece</span>
                </div>
              </div> 
            </div>
            <span>${{ (item.quantity * item.product.products.price).toFixed(2) }}</span>
          </div>
        </div>
      </div>
      <div class="history-payment">
        <div class="promo-code">
          <h6>Promo & Gift Codes</h6>
          <p>
            You can redeem your promo gifts codes here to get a discount on this
            order
          </p>
          <div class="code-input">
            <input
              type="text"
              v-model="promo_code"
              placeholder="Enter your code here"
            />
            <button @click="showCode" id="applyButton" class="apply-btn">
              Apply
            </button>
          </div>
          <div class="remove-code" v-if="codeShow">
            <span class="code-text">{{ promo_code }}</span>
            <button @click="removeCode">Remove Code</button>
          </div>
        </div>
        <div class="history-total">
          <div class="total-heading">
            <div>Subtotal</div>
            <div>
              Tax
              <span class="tax">({{ shopConfigData.tax_percentage }}%)</span>
            </div>
            <div>
              Shipping
              <span class="tax">({{ shopConfigData.shipping_cost }}%)</span>
            </div>
            <div>
              Discount <span class="tax">({{ discount_percentage }}%)</span>
            </div>
            <div>Total Payable</div>
          </div>
          <div class="total-content">
            <div>${{ (cartTotalPrice).toFixed(2) }}</div>
            <div>
              ${{
                (
                  (cartTotalPrice / 100) *
                  shopConfigData.tax_percentage
                ).toFixed(2)
              }}
            </div>
            <div>
              ${{
                ((cartTotalPrice / 100) * shopConfigData.shipping_cost).toFixed(
                  2
                )
              }}
            </div>
            <div>
              ${{ ((cartTotalPrice / 100) * discount_percentage).toFixed(2) }}
            </div>
            <div>
              ${{
                (
                  cartTotalPrice -
                  (cartTotalPrice / 100) * discount_percentage +
                  (cartTotalPrice / 100) * shopConfigData.tax_percentage +
                  (cartTotalPrice / 100) * shopConfigData.shipping_cost
                ).toFixed(2)
              }}
            </div>
          </div>
        </div>
        <div class="form-check pl-0 d-flex my-2">
          <input type="checkbox" id="gridCheck" />
          <label class="form-check-label pl-2" for="gridCheck"
            >I accept
            <router-link exact to="/our-terms-and-conditions"
              >terms and conditions</router-link
            >
            for this order</label
          >
        </div>
      </div>
    </div>
    <FlashMessage
      :position="'right bottom'"
      style="z-index: 99999999 !important"
    ></FlashMessage>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import EditCart from "../Order/EditCart.vue";
import axios from "axios";
import $ from "jquery";
export default {
  components: { EditCart },
  name: "OrderItemHistory",
  computed: {
    ...mapState("cart", ["cart"]),
    ...mapGetters("cart", ["cartTotalPrice"]),
    ...mapGetters("cart", ["cartItemCount"]),
    ...mapGetters("cart", ["shopConfigData"]),
  },

  data() {
    return {
      API_URL: this.$productImageStorageURL,
      codeShow: false,
      promo_code: "",
      discount_percentage: 0,
      imgLoader: 1,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    showCode: function() {
      if (this.promo_code == "" || this.promo_code == null) {
        $("#applyButton").addAttr("disabled");
        // $("#applyButton").addClass("btn-disabled");
      } else if (this.promo_code != "" || this.promo_code != null) {
        $("#applyButton").removeAttr("disabled");
        // $("#applyButton").removeClass("btn-disabled");
      }
      var formData = new FormData();
      let loader = this.$loading.show({
        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });
      formData.append("promo_name", this.promo_code);
      return axios
        .post(process.env.VUE_APP_API_URL + "shop/findPromoCode", formData, {})
        .then((response) => {
          if (response.data.statusCode == 200) {
            loader.hide();
            this.discount_percentage = response.data.data.discount_percentage;
            this.promo = response.data.data;
            this.codeShow = true;
            this.$root.$emit(
              "getDiscountFromPromo",
              this.discount_percentage,
              this.promo
            );
          } else {
            loader.hide();
            this.flashMessage.error({
              title: "Error" || "Error",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
          }
        });
    },
    removeCode() {
      this.codeShow = false;
      this.discount_percentage = 0;
      this.promo_code = "";
      this.$root.$emit("getDiscountFromPromo", 0, null);
    },
  },
  mounted() {
    $(window).ready(function(){
    $(window).scrollTop(0);
});
  },
};
</script>
