<template>
  <div>
    <Navbar />
    <div class="main-left-menu">
        <LeftMenu />
      </div>
    <div class="banner-wrapper">
      <Banner />
    </div>
    <div class="main-wrapper-thankyou">
      <div class="thanks-content">
        <header class="site-header" id="header">
          <h2 class="site-header__title" data-lead-id="site-header-title">
            THANK YOU !
          </h2>
        </header>

        <div class="main-content">
          <i class="fa fa-check main-content__checkmark" id="checkmark"></i>
          <p class="main-content__body" data-lead-id="main-content-body">
            Thanks for billing .
          </p>
          <router-link to="/shop" class="nav-link m-0" exact>
            Continue Shopping
          </router-link>
        </div>

        <footer class="site-footer" id="footer">
          <!-- <p class="site-footer__fineprint" id="fineprint">Copyright ©2014 | All Rights Reserved</p> -->
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";

export default {
  components: {
    Navbar,
    Banner,
    LeftMenu
  },
  name: "Order",
  computed: {
    ...mapGetters("cart", ["cartItemCount"]),
  },
  mounted() {
    //  if(this.cartItemCount<=0){
    //      this.$router.push({ name: "Shop" });
    // }
  },
};
</script>
