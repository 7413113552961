<template>
  <div>
    <h2 class="main-heading">Interpretation and Definitions</h2>
    <p class="sub-heading">Interpretation</p>
    <p class="main-para">
      The words of which the initial letter is capitalized have meanings defined
      under the following conditions. The following definitions shall have the
      same meaning regardless of whether they appear in singular or in plural
    </p>
    <p class="sub-heading">Definitions</p>
    <p class="main-para mb-2">For the purposes of this Cookies Policy:</p>
    <div class="tracking-technologies">
      <ul>
        <li>
          <div>
            <span>Company</span> (referred to as either "the Company", "We",
            "Us" or "Our" in this Cookies Policy) refers to Who I Follow Corp,
            12254 Highland Rd.
          </div>
        </li>
        <li>
          <div>
            <span>Cookies</span> means small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            details of your browsing history on that website among its many
            uses.
          </div>
        </li>
        <li>
          <div>
            <span>Website</span> refers to Who I Follow, accessible from
            <router-link to="">http://www.whoifollow.com</router-link>
          </div>
        </li>
        <li>
          <div>
            <span>You</span> means the individual accessing or using the
            Website, or a company, or any legal entity on behalf of which such
            individual is accessing or using the Website, as applicable.
          </div>
        </li>
      </ul>
    </div>
    <h2 class="main-heading mb-3">The use of the Cookies</h2>
    <p class="sub-heading">Type of Cookies We Use</p>
    <p class="main-para mb-2">
      Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
      remain on your personal computer or mobile device when You go offline,
      while Session Cookies are deleted as soon as You close your web browser.
    </p>
    <p class="main-para mb-2">
      We use both session and persistent Cookies for the purposes set out below:
    </p>
    <div class="tracking-technologies">
      <ul class="cookies-types">
        <li>Necessary / Essential Cookies</li>
        <li>Type: Session Cookies</li>
        <li>Administered by: Us</li>
        <li>
          Purpose: These Cookies identify if users have accepted the use of
          cookies on the Website.
        </li>
        <li>Functionality Cookies</li>
        <li>Type: Persistent Cookies</li>
        <li>Administered by: Us</li>
        <li>
          Purpose: These Cookies allow us to remember choices You make when You
          use the Website, such as remembering your login details or language
          preference. The purpose of these Cookies is to provide You with a more
          personal experience and to avoid You having to re-enter your
          preferences every time You use the Website.
        </li>
        <li>Tracking and Performance Cookies</li>
        <li>Type: Persistent Cookies</li>
        <li>Administered by: Third-Parties</li>
        <li>
          Purpose: These Cookies are used to track information about traffic to
          the Website and how users use the Website. The information gathered
          via these Cookies may directly or indirectly identify you as an
          individual visitor. This is because the information collected is
          typically linked to a pseudonymous identifier associated with the
          device you use to access the Website. We may also use these Cookies to
          test new pages, features or new functionality of the Website to see
          how our users react to them.
        </li>
      </ul>
    </div>
    <p class="sub-heading">Your Choices Regarding Cookies</p>
    <p class="main-para mb-2">
      If You prefer to avoid the use of Cookies on the Website, first You must
      disable the use of Cookies in your browser and then delete the Cookies
      saved in your browser associated with this website. You may use this
      option for preventing the use of Cookies at any time.
    </p>
    <p class="main-para mb-2">
      If You do not accept Our Cookies, You may experience some inconvenience in
      your use of the Website and some features may not function properly.
    </p>
    <p class="main-para mb-2">
      If You'd like to delete Cookies or instruct your web browser to delete or
      refuse Cookies, please visit the help pages of your web browser.
    </p>
    <div class="tracking-technologies">
      <ul class="cookies-types">
        <li class="font-weight-normal">
          <div>
            For the Chrome web browser, please visit this page from Google:
            <br /><router-link to=""
              >https://support.google.com/accounts/answer/32050</router-link
            >
          </div>
        </li>
        <li>
          <div>
            For the Internet Explorer web browser, please visit this page from
            Microsoft:
            <br /><router-link to=""
              >http://support.microsoft.com/kb/278835</router-link
            >
          </div>
        </li>
        <li>
          <div>
            For the Firefox web browser, please visit this page from Mozilla:
            <br /><router-link to=""
              >
              https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
            </router-link>
          </div>
        </li>
        <li>
          <div>
            For the Safari web browser, please visit this page from Apple:
            <br /><router-link to=""
              >https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</router-link
            >
          </div>
        </li>
      </ul>
      <p class="main-para mb-2">
        For any other web browser, please visit your web browser's official web
        pages
      </p>
      <p class="sub-heading">More Information about Cookies</p>
      <p class="main-para mb-2">
        You can learn more about cookies here:
        <router-link to="">All About Cookies by TermsFeed.</router-link>
      </p>
      <p class="sub-heading">Contact Us</p>
      <p class="main-para mb-2">
        If you have any questions about this Cookies Policy, You can contact us:
      </p>
      <div class="tracking-technologies">
        <ul class="cookies-types">
          <li>
            <div class="font-weight-normal">By email: legal@whoifollow.com</div>
          </li>
          <li>
            <div>
              By visiting this page on our website:
              <router-link to="">: http://whoifollow.com/cookies</router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>