<template>
  <div>
    <p class="policy-heading">Privacy Policy</p>
    <p class="policy-date">Last updated: March 23, 2022</p>
    <p class="policy-description">
      This Privacy Policy describes Our policies and procedures on the
      collection, use and disclosure of Your information when You use the
      Service and tells You about Your privacy rights and how the law protects
      You.
    </p>
    <p class="policy-alert">We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
</p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>