<template>
  <div>
    <InviteToTeamModal :currentUserId="currentUserId" :selectedRoomTeam="selectedRoomTeam" />
    <div class="all-event-wrapper">
      <div class="event-top">
        <div class="event-top-content">
          <div class="back-arrow" @click="closeDiv">
            <img src="../../../../assets/images/arrow-left.png" />
          </div>
          <div class="event-title">
            <div class="image-wrapper">
              <div class="users-notification" v-if="imgLoader == 1">
                <img src="../../../../assets/loader.gif" alt="" />
              </div>
              <img
                @load="onImageLoad"
                v-if="selectedRoomTeam.teamAvatar == ''"
                src="../../../../assets/images/main-team-avatar.png"
                alt=""
              />
              <img
                @load="onImageLoad"
                v-if="selectedRoomTeam.teamAvatar != ''"
                :src="this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar"
                alt=""
              />
            </div>
            <div class="user-details">
              <p class="team-name" :id="selectedRoomTeam.id">
                {{ selectedRoomTeam.teamName }}
              </p>
              <span> Teammates </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="menu-items"
        v-if="currentIsAdmin == '1' && currentTeamIsActive == 1"
      >
        <div
          class="member-list"
          v-if="isLoadingPrint == false"
          @click="downloadExcel()"
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/printer.png" />
            </div>
            <span>Print Team Info</span>
          </div>
          
        </div>
      
        <div class="friends-requests pb-0 px-0" v-if="isLoadingPrint == true">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>

        <div class="member-list" @click="inviteToTeamModalCall()">
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/add-teammates.png" alt="" />
            </div>
            <span>Invite to Team</span>
          </div>
        </div>
      </div>
      <div v-else class="p-0"></div>

      <MembersInfos
        :selectedRoomTeam="selectedRoomTeam"
        :currentUserId="currentUserId"
        :currentIsAdmin="currentIsAdmin"
        :currentIsFan="currentIsFan"
        :currentTeamIsActive="currentTeamIsActive"
      />

      <TeamInviteList
        :selectedRoomTeam="selectedRoomTeam"
        :currentIsAdmin="currentIsAdmin"
      />

      <!-- <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="mobilePreview"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        :manual-pagination="false"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        ref="teamPdf"
      >
        <section slot="pdf-content" class="py-2">
          <div
            class="ml-3 d-flex align-items-center team-info order-history pt-4"
          >
            <div class="image-wrapper">
              <img
                v-if="selectedRoomTeam.teamAvatar == ''"
                src="../../../../assets/team-avatar.png"
                alt=""
              />
              <img
                v-if="selectedRoomTeam.teamAvatar != ''"
                :src="this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar"
                alt=""
              />
            </div>
            <div class="user-details">
              <p class="team-name" :id="selectedRoomTeam.id">
                {{ selectedRoomTeam.teamName }}
              </p>
              <p
                class="m-0 p-0"
                v-if="
                  selectedRoomTeam.teamType == 'sports' &&
                  selectedRoomTeam.sportType != undefined
                "
              >
                Type . <span class="type">{{ selectedRoomTeam.teamType }}</span>
                <span class="type">({{ selectedRoomTeam.sportType }})</span>
              </p>
              <p class="m-0 p-0 mb-2" v-else>
                Type . <span class="type">{{ selectedRoomTeam.teamType }}</span>
              </p>
              <p
                class="m-0 p-0"
                v-if="
                  selectedRoomTeam.teamType == 'sports' &&
                  selectedRoomTeam.sportType != undefined
                "
              >
                Age . <span class="type">{{ selectedRoomTeam.ageGroup }}</span>
              </p>
              <p
                class="m-0 p-0 mb-2"
                v-if="
                  selectedRoomTeam.teamType == 'sports' &&
                  selectedRoomTeam.sportType != undefined
                "
              >
                Gender . <span class="type">{{ selectedRoomTeam.gender }}</span>
              </p>

              <p class="m-0 p-0">
                Created By .
                <span class="created-by">{{ selectedRoomTeam.createdBy }}</span>
              </p>
              <p class="m-0 p-0">
                Date .
                <span class="date">{{
                  selectedRoomTeam.createdAt | formatDateChat
                }}</span>
              </p>
            </div>
          </div>

          <div class="order-history ml-2">
            <table class="table text-left">
              <thead class="text-left">
                <tr style="word-break: break-word">
                  <th scope="col" class="pl-0">Name</th>
                  <th scope="col" class="pl-2">Email</th>
                  <th scope="col" class="pl-2">Phone</th>
                  <th scope="col" class="pl-2">Address</th>
                  <th scope="col" class="pl-2">Uniform No</th>
                  <th scope="col" class="pl-2">Bat Size</th>
                  <th scope="col" class="pl-2">Bat Load</th>
                  <th scope="col" class="pl-2">Hat Size</th>
                  <th scope="col" class="pl-2">Shirt Size</th>
                  <th scope="col" class="pl-2">Pant</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="userInfo in printTeamInfoList"
                  :key="userInfo.id"
                  style="border-bottom: 1px solid #d3d5d7"
                >
                  <td
                    v-if="userInfo.fanRole != '1'"
                    scope="row"
                    class="text-capitalize"
                    style="min-width: 75px; max-width: 75px"
                  >
                    {{ userInfo.username }}
                  </td>
                  <td
                    v-if="userInfo.fanRole != '1'"
                    scope="row"
                    style="
                      min-width: 100px;
                      max-width: 100px;
                      word-break: break-word;
                    "
                  >
                    {{ userInfo.email }}
                  </td>
                  <td
                    v-if="userInfo.fanRole != '1'"
                    scope="row"
                    style="
                      min-width: 75px;
                      max-width: 75px;
                      word-break: break-word;
                    "
                  >
                    {{ userInfo.phone }}
                  </td>
                  <td
                    v-if="userInfo.fanRole != '1'"
                    scope="row"
                    style="
                      min-width: 80px;
                      max-width: 80px;
                      word-break: break-word;
                    "
                  >
                    {{ userInfo.address }} {{ userInfo.state }}
                    {{ userInfo.city }} {{ userInfo.zip_code }}
                  </td>
                  <td
                    v-if="userInfo.fanRole != '1'"
                    scope="row"
                    style="
                      min-width: 80px;
                      max-width: 80px;
                      word-break: break-word;
                    "
                  >
                    {{ userInfo.uniform }}
                  </td>
                  <td
                    v-if="userInfo.fanRole != '1'"
                    scope="row"
                    style="
                      min-width: 80px;
                      max-width: 80px;
                      word-break: break-word;
                    "
                  >
                    {{ userInfo.bat_size }}
                  </td>
                  <td
                    v-if="userInfo.fanRole != '1'"
                    scope="row"
                    style="
                      min-width: 80px;
                      max-width: 80px;
                      word-break: break-word;
                    "
                  >
                    {{ userInfo.bat_details }}
                  </td>
                  <td
                    v-if="userInfo.fanRole != '1'"
                    scope="row"
                    style="
                      min-width: 80px;
                      max-width: 80px;
                      word-break: break-word;
                    "
                  >
                    {{ userInfo.hat_size }}
                  </td>
                  <td
                    v-if="userInfo.fanRole != '1'"
                    scope="row"
                    style="
                      min-width: 80px;
                      max-width: 80px;
                      word-break: break-word;
                    "
                  >
                    {{ userInfo.shirt_size }}
                  </td>
                  <td
                    v-if="userInfo.fanRole != '1'"
                    scope="row"
                    style="
                      min-width: 80px;
                      max-width: 80px;
                      word-break: break-word;
                    "
                  >
                    {{ userInfo.pant_size }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </VueHtml2pdf> -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ExcelJS from 'exceljs';
import { mapGetters, mapActions } from "vuex";
import TeamInviteList from "../ChatSettingComponents/TeamInviteList.vue";
// import VueHtml2pdf from "vue-html2pdf";
import InviteToTeamModal from "./InviteToTeamModal.vue";
import MembersInfos from "./MembersInfos.vue";
export default {
  name: "Members",
  props: {
    currentUserId: {
      type: String,
    },
    selectedRoomTeam: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
  },
  components: {
    TeamInviteList,
    // VueHtml2pdf,
    InviteToTeamModal,
    MembersInfos,
  },
  data() {
    return {
      imgLoader: 1,
      searchMember: false,
      showLoaderMember: 0,
      showLoaderSend: 0,
      showLinkLoader: 0,
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      isDisabled: true,
      isDisabledMmeber: true,
      errorMessage: null,
      urlLinkCopy: "",
      successMessage: null,
      isLoadingArray: [],
      isLoadingArrayMember: [],
      addMultipleUsers: [],
      roomId: this.selectedRoomTeam.roomId,
      selectedUsers: [],
      isLoading: false,
      isLoadingPrint: false,
      teamSelectedUser: [],
      teamUserUncount: [],
      teamSelectedUserRole: [],
      allUsersRef: [],
      printTeamInfoList: [],
      linkTobe: false,
      mobilePreview: true,
      roleType: "0",
      selectedCode: "+1",
      showConfirmButton: false,
      newArray: [],
      details: {
        code: "+1",
        mob: "",
        email: "",
        role: "0",
        grpID: this.selectedRoomTeam.roomId,
        grpImage: this.selectedRoomTeam.teamAvatar,
        grpName: this.selectedRoomTeam.teamName,
      },
      codeList: [
        { value: "+92", text: "Pak (+92)" },
        { value: "+1", text: "USA (+1)" },
      ],
      roleList: [
        { value: "0", text: "Teammate" },
        { value: "2", text: "Fan" },
      ],
    };
  },

  computed: {
    htmlToPdfOptions() {
      return {
        filename: this.selectedRoomTeam.teamName,
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          // useCORS: true,
          proxy: this.$groupAvatarStorageURL + this.selectedRoomTeam.teamAvatar,
        },
        jsPDF: { orientation: "landscape" },
      };
    },
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
    this.MobileHidePdfContent();
    // this.isDisabledMmeber = true;
  },

  methods: {
    downloadExcel() {
      this.isLoadingPrint = true;
      axios
      .post(
        `${process.env.VUE_APP_API_URL}user/usersListData`,
        this.selectedRoomTeam.users
      )
      .then((response) => {
        if (response.data && response.data.data) {
          this.printTeamInfoList = response.data.data;

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Team Information');

          // Add headers to the worksheet
          const headers = [
            'Name',
            'Email',
            'Phone',
            'Address',
            'Uniform No',
            'Bat Size',
            'Bat Load',
            'Hat Size',
            'Shirt Size',
            'Pant'
          ];
          worksheet.addRow(headers).eachCell(cell => {
            cell.font = { bold: true };
          });

          // Add data to the worksheet
          this.printTeamInfoList.forEach(userInfo => {
            const dataRow = [
              userInfo.username || '', 
              userInfo.email || '',
              userInfo.phone || '',
              `${userInfo.address || ''} ${userInfo.city || ''} ${userInfo.state || ''} ${userInfo.zip_code || ''}`,
              userInfo.uniform || '',
              userInfo.bat_size || '',
              userInfo.bat_details || '',
              userInfo.hat_size || '',
              userInfo.shirt_size || '',
              userInfo.pant_size || ''
            ];
            worksheet.addRow(dataRow);
          });

          // Create a Blob from the workbook and download it
          workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Include today's date in the file name
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
            const year = today.getFullYear();

            const formattedDate = `${day}-${month}-${year}`;
            const filename = `${this.selectedRoomTeam.teamName}_EquipmentInfo_${formattedDate}.xlsx`;

            // Create a download link and trigger the download
            if (navigator.msSaveBlob) {
              // For IE
              navigator.msSaveBlob(blob, filename);
            } else {
              // For other browsers
              const link = document.createElement('a');
              const url = URL.createObjectURL(blob);
              link.href = url;
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            }
            this.isLoadingPrint = false;
          });
        } else {
          console.error('Invalid response from the server');
          this.isLoadingPrint = false;
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.isLoadingPrint = false;        
      });
  },

    inviteToTeamModalCall() {
      this.$root.$emit("openInviteToTeamModal");
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    MobileHidePdfContent: function () {
      let w = innerWidth;
      if (w < 768) {
        this.mobilePreview = false;
      }
    },
    addNewMemberModal() {
      this.selectedUsers = [];
      this.showLoaderMember = 0;
      this.isDisabledMmeber = true;
      this.asyncFind();
    },
    copyUrl() {
      let testingCodeToCopy = document.querySelector("#link");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        console.log(successful);
        this.$refs.btnToggle.innerText = this.show ? "Copy" : "Copied";
      } catch (err) {
        alert("Oops, unable to copy");
      }
    },
    ...mapActions("auth", ["sendRegisterRequest"]),

    showMemberSearchBar: function () {
      this.searchMember = !this.searchMember;
    },

    closeDiv: function () {
      // $(".chat-right-sidebar")
      //   .slideUp(200)
      //   .delay(200)
      //   .slideDown(200);
      this.$root.$emit("fetchEvents");
      this.$root.$emit("ChatSettings", 0);
    },

    // printTeamInfo() {
    //   this.isLoadingPrint = true;
    //   axios
    //     .post(
    //       `${process.env.VUE_APP_API_URL}user/usersListData`,
    //       this.selectedRoomTeam.users
    //     )
    //     .then((response) => {
    //       this.printTeamInfoList = response.data.data;
    //       this.$refs.teamPdf.generatePdf();
    //       this.isLoadingPrint = false;
    //     });
    // },

    // ---- Add Members ----------------------------------------------------------------------

    errorNull: function () {
      this.errorMessage = null;
      this.successMessage = null;
      if (
        (this.details.mob == "" && this.details.email == "") ||
        this.$v.$invalid
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
};
</script>
<style scoped>
.member-wrapper {
  padding-top: 10px;
  /* animation: mymove 0.5s ease-in-out 0s 1; */
  position: relative;
}
.animate {
  animation: moveback 1s linear 0s 1;
}
.mr-3 {
  margin-right: 30px;
}
.team-top {
  display: flex;
  align-items: center;
}
.team-top .back-arrow {
  margin-right: 15px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-top .back-arrow:hover {
  background-color: #e2f5fe;
  border-radius: 5px;
}

.team-top .back-arrow img {
  width: 20px;
}
.team-search {
  margin-left: auto;
  background-color: #e2f5fe;
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.team-member-actions .team-add-new:hover,
.team-member-actions .team-invite-link:hover {
  background-color: #e5f2fe;
  border-radius: 5px;
}

.team-member-actions .team-add-new h6,
.team-member-actions .team-invite-link h6 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #254c72;
}
.copy-link-btn {
  background-color: #188ef5;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  min-height: 50px;
  margin-left: 10px;
}
.copy-link-btn:hover {
  color: #ffffff;
}
@media screen and (max-width: 420px) {
  .copy-link-btn {
    margin-top: 8px !important;
    width: 100%;
    margin-left: 0;
  }
}
.add-new img,
.invite-link img {
  width: 20px;
}

.team-info .image-wrapper {
  min-width: 100px;
  max-width: 100px;
  overflow: hidden;
  border: 2px solid #e5f2fe;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  margin-right: 10px;
}
.team-info .image-wrapper img {
  width: 100%;
  border-radius: 100px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.team-info .user-details .team-name {
  font-size: 16px;
  font-weight: 500;
  color: #333333 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.team-info .user-details p {
  font-size: 14px;
  font-weight: 400;
}

.team-info .user-details .type {
  color: #188ef5;
}
.team-info .user-details .created-by,
.team-info .user-details .date {
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

.modal__btn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.loader_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
