<template>
  <div class="box-right-side">
    <div class="box-detail-wrapper" v-if="showLoader == 0">
      <span @click="closeRightSide()">
        <img src="../../assets/images/cross.png" alt=""
      /></span>

      <MyBoxScores
        v-bind:gameScores="gameScores"
        :event_name="event_name"
        class=""
      />
    </div>

    <div class="" v-if="showLoader == 1">
      <br />
      <div class="container-fluid">
        <content-placeholders :rounded="true">
          <content-placeholders-heading />
        </content-placeholders>
        <br />
        <br />
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
        <br />
        <br />
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
        <br />
        <br />
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import MyBoxScores from "../BoxScores/MyBoxScores.vue";
export default {
  name: "OrderDetailRightside",
  components: {
    MyBoxScores,
  },
  data() {
    return {
      time: this.$time,
      Storage_URL: this.$userProfileStorageURL,
      errorMessage: null,
      successMessage: null,
      datas: [],
      items: [],
      page: 1,
      gameScores: [],
      event_name: "",
      showLoader: 1,
      noPost: 0,
      API_URL: this.$productImageStorageURL,
    };
  },
  methods: {
    closeRightSide() {
      const getItem = document.querySelector(".box-score-right-side");
      getItem.style.display = "none";
      this.gameScores = [];
      document.getElementById("overlay").style.display = "none";
      //   document
      //     .querySelector(".order-history-left-side")
      //     .setAttribute("style", "width:100vw");
    },
  },
  mounted() {
    this.$root.$on("dataItems", (data, event_name) => {
      this.gameScores = data;
      this.event_name = event_name;
      this.showLoader = 0;
      var count = 0;
      data.forEach((gameScore) => {
        if (gameScore.game_status != "2") {
          count = count + 1;
        }
      });
      this.$root.$emit("selectTapBoxScore", count);
    });
    this.$root.$on("gameScoreLoaderShow", (loader) => {
      this.showLoader = loader;
    });
  },
};
</script>

<style scoped>
.box-detail-wrapper h1 span {
  color: #188ef5;
}

.order-history-right-side .box-right-side {
  height: 100vh;
  display: block;
}

.box-detail-wrapper {
  border-bottom: none;
  position: relative;
}

.box-detail-wrapper span {
  position: absolute;
  top: -5px;
  right: 5px;
}
</style>
