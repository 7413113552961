<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side p-3">
        <div class="row no-gutters">
          <div class="col-12" v-if="otherUserDetail">
            <CoverPhoto :dataCover="otherUserDetail" />
          </div>
        </div>
        <div class="row align-items-center cover-bottom">
          <div class="col-12 col-md-6 col-lg-3">
            <div class="profile-photo-section" v-if="otherUserDetail">
              <ProfilePhoto :dataProfile="otherUserDetail" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-9">
            <div class="profile-name-section" v-if="otherUserDetail">
              <Username :dataName="otherUserDetail" />
            </div>
          </div>
          <!-- <div
              class="col-lg-2 col-md-4 col-sm-6 mt-md-5 mt-sm-0 pt-md-5 pt-sm-0"
            >
              <div class="profile-edit-section" v-if="otherUserDetail">
                <FriendRequest :dataRequest="otherUserDetail" />
              </div>
            </div> -->
        </div>
        <div class="row no-gutters">
          <div class="col-12">
            <!-- <FriendsTop /> -->
            <FriendsOptions
              :friendList="friendsList"
              :followingListCount="followingListCount"
              :followerListCount="followerListCount"
              :showLoader="showLoader"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
// import CoverPhoto from "../components/OtherProfile/CoverPhoto.vue";
// import ProfilePhoto from "../components/OtherProfile/ProfilePhoto.vue";
// import Username from "../components/OtherProfile/Username.vue";
// import FriendsTop from "../components/OthersFriends/FriendsTop.vue";
import FriendsOptions from "../components/OthersFriends/FriendsOptions.vue";
import { mapGetters, mapActions } from "vuex";
import CoverPhoto from "../components/OtherProfile/CoverPhoto.vue";
import ProfilePhoto from "../components/OtherProfile/ProfilePhoto.vue";
import Username from "../components/OtherProfile/Username.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import axios from "axios";

export default {
  name: "FriendsPage",
  components: {
    Navbar,
    LeftMenu,
    // CoverPhoto,
    // ProfilePhoto,
    // Username,
    // FriendsTop,
    FriendsOptions,
    ProfilePhoto,
    Username,
    CoverPhoto,
    Banner
  },
  data() {
    return {
      friendsList: null,
      followerListCount: 0,
      followingListCount: 0,
      user_link: "",
      otherUserDetail: null,
      showLoader: 0,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    async allOthersCounts() {
      this.showLoader = 1;
      let allCounts = await axios.get(
        `allOtherCount?user_link=${this.user_link}`
      );

      if (allCounts.data.statusCode == 200) {
        this.followerListCount = allCounts.data.data.followerCount;
        this.followingListCount = allCounts.data.data.followingCount;
        this.showLoader = 0;
      } else {
        this.showLoader = 0;
      }
    },
    async friendShort() {
      this.showLoader = 1;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}friends/friendOtherList?userLink=${this.user_link}`
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.friendsList = response.data.data;
            this.$root.$emit("enableOtherFriendsButton");
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
    async fetchOtherProfile() {
      let otherUser = await axios.get(
        `user/getOtherUserData?userLink=${this.user_link}`
      );
      if (otherUser.data.statusCode == 200) {
        this.otherUserDetail = otherUser.data.data.userData;
      } else {
        this.errorMessage = otherUser.data.message;
      }
    },
    getuserLink() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.user_link = segment_array.pop();
      if (this.user_link !== "otherFriends") {
        this.friendShort();
        this.fetchOtherProfile();
        this.allOthersCounts();
      }
    },
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getuserLink();
  },
};
</script>
<style>
.navbar-expand-lg .navbar-nav .nav-link {
  padding-bottom: 20px;
}
</style>
