<template>
  <div>
    <EditEvent
      :singleEvent="singleEvent"
      :selectedRoomTeam="selectedRoomTeam"
      v-if="currentIsAdmin == '1' && currentTeamIsActive == 1"
      :currentUserId="currentUserId"
      :currentIsAdmin="currentIsAdmin"
      :currentTeamIsActive="currentTeamIsActive"
      :editEventOption="editEventOption"
    />
    <div class="order-history single-box-score-loader" v-if="showLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
    <div class="single-event-page bg-white p-3" v-if="showLoader == 0">
      <div class="sec-top d-flex justify-content-between">
        <div class="left-part">
          <h3 class="text-capitalize">
            {{ singleEvent.eventName }}
          </h3>
          <p class="mt-2">
            {{ singleEvent.eventType }} <span v-if="singleEvent.association">
              - {{ singleEvent.association }}
            </span>
          </p>
          <div class="event-list-details event-info-wrap">
            <div class="event-date">
              <img src="../../assets/images/calendar-event.png" />
              <div
                class="content"
                v-if="
                  singleEvent.exactStartDate != '' &&
                  singleEvent.exactStartTime != '' &&
                  singleEvent.exactEndTime != ''
                "
              >
                <span
                  v-if="singleEvent.exactStartDate == singleEvent.exactEndDate"
                >
                  {{ singleEvent.exactStartDate }}
                </span>
                <span v-else
                  >{{ singleEvent.exactStartDate.split(",")[0] }} to
                  {{ singleEvent.exactEndDate }}</span
                >
                <span v-if="singleEvent.allDay == 'true'"> All Day. </span>
                <!-- <span v-else>
                  From {{ singleEvent.exactStartTime }} to
                  {{ singleEvent.exactEndTime }}
                </span>
                <span v-if="singleEvent.time_zone !== null">
                  {{ singleEvent.time_zone.split(")").pop() }}
                </span> -->
              </div>
            </div>
            <p
              @click="openGoogleMap(singleEvent)"
              class="location-content"
              v-if="
                (singleEvent.status == 1 &&
                  singleEvent.address != null &&
                  singleEvent.address != '' &&
                  singleEvent.address != 'null') ||
                (singleEvent.city != null &&
                  singleEvent.city != '' &&
                  singleEvent.city != 'null') ||
                (singleEvent.state != null &&
                  singleEvent.state != '' &&
                  singleEvent.state != 'null')
              "
            >
              <img src="../../assets/images/location.png" />
              <span
                >{{ singleEvent.address }} {{ singleEvent.city }},
                {{ singleEvent.state }}</span
              >
            </p>
            <span class="location-content" v-if="singleEvent.status == 2">
              <img src="../../assets/images/link.png" />
              <a
                :href="singleEvent.Url"
                target="_blank"
                class="mr-0 font-weight-normal"
              >
                {{ singleEvent.Url }}
              </a>
            </span>
          </div>
        </div>
        <div class="right-part">
          <router-link :to="`/game-events/${selectedRoomTeam.id}`" class="text-primary text-capitalize text-right mb-4 cursor-pointer">
            <h4>{{ selectedRoomTeam.teamName }}</h4>
          </router-link>
          <div class="d-flex align-items-center justify-content-end">
            <SingleEventDetailPlay :singleEvent="singleEvent" />
            <div
              class="opt mt-0 ml-2 position-relative float-md-right cursor-pointer"
            >
              <img
                src="../../assets/images/ellipsis-h.png"
                alt=""
                @click="openEditEventOption"
                v-click-outside="onClickOutside"
              />
              <div
                class="sub-setting-options edit-event-options"
                v-if="editEventOption"
              >
                <ul class="d-block pb-0">
                  <li
                    v-if="currentIsAdmin == '1' && currentTeamIsActive == 1"
                    @click="callModal()"
                    class="cursor-pointer"
                  >
                    Edit Event
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="friends-options p-0">
        <ul class="options-list">
          <li>
            <a
              :class="{
                active: isActiveGames,
              }"
              @click="switchTabs('games')"
              data-tab="games"
              class="m-0"
            >
              Games
            </a>
          </li>
          <li>
            <a
              :class="{
                active: isActiveAttendees,
              }"
              @click="switchTabs('attendees')"
              data-tab="attendees"
              class="m-0"
              >Attendees
            </a>
          </li>
        </ul>

        <div class="d-flex justify-content-end">
          <div class="game-details">
            <NewGame
              v-if="
                currentIsAdmin == '1' &&
                selectedRoomTeam.teamType == 'sports' &&
                currentTeamIsActive == 1 && 
                isActiveGames
              "
              :selectedRoomTeam="selectedRoomTeam"
              :singleEvent="singleEvent"
            />
          </div>
        </div>

        <div
          class="ui bottom attached tab segment"
          data-tab="games"
          :class="{ active: isActiveGames }"
          v-if="isActiveGames"
          ref="GamesTab"
        >
          <GameInfo
            :selectedRoomTeam="selectedRoomTeam"
            :currentIsAdmin="currentIsAdmin"
            :games="games"
            :showGameLoader="showGameLoader"
            :eventAlertOptions="eventAlertOptions"
            :currentTeamIsActive="currentTeamIsActive"
          />
        </div>
        <div
          class="ui bottom attached tab segment"
          data-tab="attendees"
          :class="{ active: isActiveAttendees }"
          v-if="isActiveAttendees"
        >
          <AttendeesInfo
            :selectedRoomTeam="selectedRoomTeam"
            :currentUserId="currentUserId"
            :currentIsAdmin="currentIsAdmin"
            :singleEvent="singleEvent"
          />
        </div>
      </div>
    </div>
    <div v-observe-visibility="handleScrollToBottom" v-if="games.length"></div>
  </div>
</template>

<script>
import axios from "axios";
import AttendeesInfo from "./AttendeesInfo.vue";
import GameInfo from "./GameInfo.vue";
import EditEvent from "../Teams/ChatRight/ChatEvents/EditEvent.vue";
import NewGame from "../Teams/Games/NewGame.vue";
import SingleEventDetailPlay from "./SingleEventDetailPlay.vue";
import * as firestoreService from "../../database/firestore";
export default {
  components: { AttendeesInfo, EditEvent, GameInfo, SingleEventDetailPlay, NewGame },
  name: "EventDetail",
  data() {
    return {
      isActiveGames: true,
      isActiveAttendees: false,
      teamId: "",
      currentIsAdmin: "0",
      currentIsFan: "0",
      currentTeamIsActive: 1,
      selectedRoomTeam: {},
      currentUserId: "",
      userId: localStorage.getItem("userId"),
      allUsers: [],
      games: [],
      page: 1,
      lastPage: 1,
      size: 5,
      showLoader: 1,
      singleEvent: {},
      showGameLoader: false,
      loadingMoreGames: false,
      eventAlertOptions: [],
      editEventOption: false,
    };
  },
  methods: {
    callModal: function () {
      this.$root.$emit("openEditEvent");
    },
    // showSchedule(teamId) {
    //   this.$router.push(`/game-events/${teamId}`);
    // },
    openEditEventOption() {
      this.editEventOption = !this.editEventOption;
    },
    onClickOutside() {
      this.editEventOption = false;
    },
    getEventAlert() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getEventAlert`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.eventAlertOptions = response.data.data.alerts;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    switchTabs(tab) {
      this.isActiveGames = tab === "games";
      this.isActiveAttendees = tab === "attendees";
      this.page = 1;
      this.lastPage = 1;
      if (tab === "games") {
        this.isActiveGames = true;
        this.isActiveAttendees = false;
      }
    },

    async fetchData() {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("event_id", this.$route.params.id);
      try {
        await axios
          .post(
            process.env.VUE_APP_API_URL +
              `event/details?page=${this.page}&size=${this.size}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.singleEvent = response.data.data.details;
              this.games = response.data.data.games.data;
              if (this.games.length > 0) {
                this.getEventAlert();
              }
              this.lastPage = response.data.data.games.last_page;
              this.teamId = response.data.data.details.team_id;
              if (this.teamId) {
                this.getCurrentChatId();
                this.getTeamData();
              }
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.showLoader = 0;
    },

    async fetchMoreGamesData() {
      this.showGameLoader = true;
      var formData = new FormData();
      formData.append("event_id", this.$route.params.id);
      try {
        await axios
          .post(
            process.env.VUE_APP_API_URL +
              `event/details?page=${this.page}&size=${this.size}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.games = this.games.concat(response.data.data.games.data);
              this.lastPage = response.data.data.games.last_page;
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.showGameLoader = false;
    },
    handleScrollToBottom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.fetchMoreGamesData();
    },
    async getCurrentChatId() {
      if (this.currentUserId == "") {
        const promiseUser = await firestoreService.getUserCid(this.userId);
        this.foundCurrentUser = promiseUser;
        if (this.foundCurrentUser.data[0].id != undefined) {
          this.currentUserId = this.foundCurrentUser.data[0].id;
        } else {
          this.getCurrentChatId();
        }
      }
    },
    async getTeamData() {
      const rawRoom = [];
      const promise = await firestoreService.getRoomById(this.teamId);
      rawRoom.push(promise);

      const foundRoom = await Promise.all(rawRoom);
      let usersData = {};
      Object.keys(foundRoom).forEach((key) => {
        const teamData = foundRoom[key];
        usersData = teamData.users;
        teamData.users = {};
        this.selectedRoomTeam = teamData;
      });

      // check role if team exits or user is member
      let roles = this.selectedRoomTeam.userRole;
      if (usersData != undefined) {
        var admincount = 0;
        for (const userRole of roles) {
          if (userRole.id == this.currentUserId) {
            admincount++;
          }
        }
        if (admincount == 0) {
          this.$router.push({ name: "PageNotFound" });
        }
      } else {
        this.$router.push({ name: "PageNotFound" });
      }

      await this.makeUserArray(usersData);
      const users = [];
      usersData.forEach((userId) => {
        const foundUser = this.allUsers.find((user) => user?._id === userId);
        if (foundUser) {
          var chechUrl;
          var url =
            this.$userProfileStorageURL +
            foundUser.c_id +
            "/profile_picture.jpg?v=" +
            this.time;

          this.urlExist(url, (exists) => {
            if (exists) {
              chechUrl = "yes";
              foundUser.avatar = url;
              console.log(chechUrl);
            } else {
              chechUrl = "no";
              foundUser.avatar = require("../../assets/images/user-default-new.png");
            }
          });
          users.push(foundUser);
        }
      });

      this.selectedRoomTeam.users = users;

      // assign admin role
      let tempMembers = this.selectedRoomTeam.users;

      // Set Users Role and Find if current User is admin
      for (const role of roles) {
        for (const member of tempMembers) {
          if (role.id == member.id) {
            member.adminRole = role.admin;
            member.fanRole = role.fan;
            member.roleStatus = role.status;
            member.uniform = role.uniform;
            member.bat_size = role.bat_size;
            member.bat_details = role.bat_details;
            member.hat_size = role.hat_size;
            member.shirt_size = role.shirt_size;
            member.pant_size = role.pant_size;
            var chechUrl;
            var url =
              this.$userProfileStorageURL +
              member.c_id +
              "/profile_picture.jpg?v=" +
              this.time;
            this.urlExist(url, (exists) => {
              if (exists) {
                chechUrl = "yes";
                member.avatar = url;
                console.log(chechUrl);
              } else {
                chechUrl = "no";
                member.avatar = require("../../assets/images/user-default-new.png");
              }
            });
          }
          if (member.id == this.currentUserId && member.adminRole == "1") {
            this.currentIsAdmin = "1";
          }
          if (member.id == this.currentUserId && member.adminRole == "0") {
            this.currentIsAdmin = "0";
          }
          if (member.id == this.currentUserId && member.fanRole == "1") {
            this.currentIsFan = "1";
          }
          if (member.id == this.currentUserId && member.fanRole != "1") {
            this.currentIsFan = "0";
          }

          // assign current user team status
          if (member.id == this.currentUserId && member.roleStatus == 1) {
            this.currentTeamIsActive = 1;
          }
          if (member.id == this.currentUserId && member.roleStatus == 2) {
            this.currentTeamIsActive = 2;
          }
        }
      }

      this.selectedRoomTeam.roomId = this.selectedRoomTeam.id;
    },
    async makeUserArray(users) {
      const roomUserIds = users;

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },

    // URL exist ----------------------------------------------------------------
    urlExist(url, callback) {
      const img = new Image();
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },
  },
  mounted() {
    this.fetchData();
    this.$root.$on("showEventPageGames", () => {
      this.page = 1;
      this.lastPage = 1;
      this.fetchData();
    });
  },
};
</script>
<style scoped>
.left-part h3 {
  font-size: 24px;
}
</style>