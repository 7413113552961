<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#pinModalDirect"
      ref="openModalPin"
    ></button>
    <div class="modal fade" id="pinModalDirect" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Pin Media</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalPin"
            >
              <img src="../../../../../assets/close.png" alt="" />
            </button>
          </div>
          <div class="modal-body">
            <div class="pin-modal">
              <div class="media-image-sec">
                <div class="img">
                  <img
                    v-if="
                      file_extension == 'jpeg' ||
                      file_extension == 'png' ||
                      file_extension == 'jpg'
                    "
                    :src="file_url"
                    alt=""
                    @load="onImageLoad"
                  />
                  <img
                    v-if="
                      file_extension == 'pdf' ||
                      file_extension == 'sql' ||
                      file_extension == 'docx' ||
                      file_extension == 'txt'
                    "
                    src="../../../../../assets/images/pinned-document.png"
                    alt=""
                    @load="onImageLoad"
                  />
                  <img
                    v-if="
                      file_extension != 'pdf' &&
                      file_extension != 'jpeg' &&
                      file_extension != 'png' &&
                      file_extension != 'jpg' &&
                      file_extension != 'mp4' &&
                      file_extension != 'mp3' &&
                      file_extension != 'sql' &&
                      file_extension != 'docx' &&
                      file_extension != 'txt'
                    "
                    src="../../../../../assets/images/pinned-link.png"
                    alt=""
                    @load="onImageLoad"
                  />
                  <audio
                    v-if="file_extension == 'mp3'"
                    controls
                    @loadedmetadata="onImageLoad"
                    :src="file_url"
                  ></audio>
                  <video
                    v-if="file_extension == 'mp4'"
                    controls
                    @loadeddata="onImageLoad"
                    :src="file_url"
                  ></video>
                  <p class="text-center mt-2">
                    {{ file_name }}.{{ file_extension }}
                  </p>
                </div>
              </div>
              <div class="media-info-sec">
                <div class="form-group">
                  <label for="name">Name</label>
                  <div class="input-wrapper">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      :class="{
                        'is-invalid': validationStatus($v.name),
                      }"
                      v-model.trim="$v.name.$model"
                      placeholder="Enter name"
                      required
                      @keyup="errorNull"
                    />
                    <div v-if="!$v.name.required" class="invalid-tooltip">
                      Name is required
                    </div>
                  </div>
                </div>
                <div class="mt-2" v-if="teamId.chatType == 'team'">
                  <label>Event</label>
                  <v-select
                    v-model="selectedEvent"
                    :options="eventsAll"
                    label="eventName"
                    :placeholder="eventPlaceholder"
                    @input="setSelectedEvent"
                  />
                </div>
                <div class="mt-2" v-if="teamId.chatType == 'team'">
                  <label>Game</label>
                  <v-select
                    v-model="selectedGame"
                    :options="gamesAll"
                    label="name"
                    :placeholder="gamePlaceholder"
                  />
                </div>
                <div class="mt-2">
                  <label>Tags</label>
                  <vue-tags-input
                    v-model="tag"
                    :tags="tags"
                    :max-tags="10"
                    @tags-changed="(newTags) => (tags = newTags)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabledPin == false"
              type="button"
              class="btn btn-primary w-25"
              block
              v-on:click="pinMedia(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabledPin == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import * as firestoreService from "../../../../../database/firestore";
import VueTagsInput from "@johmun/vue-tags-input";
import axios from "axios";
export default {
  name: "PinModal",
  data() {
    return {
      name: "",
      tag: "",
      tags: [],
      isDisabledPin: false,
      isLoadingArray: [],
      file_name: "",
      file_extension: "",
      file_url: "",
      showLoader: 1,
      eventsAll: [],
      eventPlaceholder: "Select event",
      selectedEvent: "",
      teamId: "",
      gamesAll: [],
      gamePlaceholder: "Select game",
      selectedGame: "",
      messageId: "",
    };
  },
  validations: {
    name: {
      required,
    },
  },
  components: {
    VueTagsInput,
  },
  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    errorNull: function () {
      if (this.$v.$invalid) {
        this.isDisabledPin = true;
      } else {
        this.isDisabledPin = false;
      }
    },

    pinMedia: function (index) {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      const tagsTextArray = this.tags.map((tag) => tag.text);
      const messageData = {
        pinFile: true,
        tagsDetails: {
          name: this.name || "",
          tags: tagsTextArray || "",
          eventId: this.selectedEvent.id || "",
          gameId: this.selectedGame.id || "",
        },
      };
      this.updateOrAddMessageIndex(this.messageId, messageData);
      this.$set(this.isLoadingArray, index, true);
      setTimeout(() => {
        var elem = this.$refs.closeModalPin;
        elem.click();
        this.$set(this.isLoadingArray, index, false);
      }, 1000);
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    async eventListing() {
      let searchResults = await axios.post(
        `event/eventListingFormedia?team_id=${this.teamId.roomId}`
      );
      if (searchResults.data.statusCode == 200) {
        this.eventsAll = searchResults.data.data;
      }
    },
    setSelectedEvent(value) {
      this.gameListing(value.id);
    },
    async gameListing(id) {
      var formData = new FormData();
      formData.append("event_id", id);
      formData.append("team_id", this.teamId.roomId);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "game/gameListingForMedia",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.selectedGame = "";
          this.gamesAll = response.data.data;
        });
    },
    async updateOrAddMessageIndex(messageId, messageData) {
      firestoreService.updateMessageMedia(
        this.teamId.roomId,
        messageId,
        messageData
      );
      this.$root.$emit("ChatMedia");
      this.$root.$emit("ChatLinks");
      this.$root.$emit("ChatDocuments");
    },
  },
  mounted() {
    this.$root.$on(
      "openPinModal",
      (file_name, file_extension, file_url, roomId, messageId) => {
        var elem = this.$refs.openModalPin;
        if (elem) {
          elem.click();
        }
        this.selectedEvent = "";
        this.selectedGame = "";
        this.name = "";
        this.tag = "";
        this.tags = [];
        this.file_name = file_name;
        this.file_url = file_url;
        this.file_extension = file_extension;
        this.teamId = roomId;
        this.messageId = messageId;
        this.eventListing();
        this.$v.name.$reset();
        this.isDisabledPin = true;
      }
    );
  },
};
</script>

<style></style>
