<template>
  <div class="container-fluid p-0 friend-wrap">
    <div class="row no-gutters" v-if="items.length != 0">
      <div
        class="col-12 col-sm-6 col-lg-4 col-xl-3 p-0 pr-sm-3"
        v-for="value in items"
        :key="value.id"
      >
        <div class="friends-wrapper">
          <router-link
            :to="/profile/ + value.user_link"
            exact
            style="color: black"
            class="m-0"
          >
            <div class="friend-info">
              <div class="friend-image">
                <div class="users-notification" v-if="imgLoader == 1">
                  <!-- <div class="lds-dual-ring-refresh"></div> -->
                  <img src="../../assets/loader.gif" alt="" />
                </div>
                <img
                  @load="onImageLoad"
                  v-if="value.picture == 'yes'"
                  :src="
                    API_URL + value.user_id + '/profile_picture.jpg?v=' + time
                  "
                  alt=""
                />
                <img
                  @load="onImageLoad"
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                />
              </div>
              <div class="friends">
                <h3>{{ value.name }}</h3>
              </div>
            </div>
          </router-link>
          <div class="delete d-flex align-items-center ml-auto">
            <div
              class="delete-icon"
              @click="cancelRequest(value.user_id)"
              :id="'cancel' + value.user_id"
            >
              <img
                class="find-delete"
                src="../../assets/images/trash.png"
                alt=""
              />
            </div>
            <div
              class="tick-icon"
              @click="acceptRequest(value.user_id)"
              :id="'accept' + value.user_id"
            >
              <img
                class="find-delete"
                src="../../assets/images/profile-tick.png"
                alt=""
              />
            </div>
          </div>
          <!-- <br />
          <br /> -->
        </div>
      </div>
    </div>
    <div class="info-heading friends-list justify-content-center mb-0" v-else-if="loader == 0">No friend request.</div>
    <div class="container-fluid p-0 friends-list" v-if="loader == 1">
      <div class="row no-gutters">
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "friendRequest",
  props: {
    items: {
      type: Array,
    },
    showLoader: {
      type: Number,
    },
    loader: {
      type: Number,
    }
  },
  data() {
    return {
      friendsShort: "",
      friendCount: "0",
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      imgLoader: 1,
      dataLoader: this.showLoader,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    async cancelRequest(friend_id) {
      $("#cancel" + friend_id).hide();
      $("#accept" + friend_id).hide();
      this.dataLoader = 1;
      var formData = new FormData();
      formData.append("friend_id", friend_id);
      return axios
        .post(process.env.VUE_APP_API_URL + "friends/cancelRequest", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$root.$emit("FriendsOptions");
            this.$root.$emit("FriendsPage");
            var elem = this.$refs.closeModalPost;
            elem.click();
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    async acceptRequest(friend_id) {
      $("#cancel" + friend_id).hide();
      $("#accept" + friend_id).hide();
      this.dataLoader = 1;
      var formData = new FormData();
      formData.append("friend_id", friend_id);
      return axios
        .post(process.env.VUE_APP_API_URL + "friends/acceptRequest", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$root.$emit("FriendsOptions");
            this.$root.$emit("FriendsPage");
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.find-delete {
  width: 20px !important;
}
</style>
