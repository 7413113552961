<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3 pb-3">
        <div class="row no-gutters">
          <div class="col-12 col-md-6 col-lg-5 p-0 pr-md-3">
            <ProductImage :productdetails="productDetail" :productProduct="productDetail"  />
          </div>
          <div class="col-12 col-md-6 col-lg-7 p-0 mt-3 mt-md-0">
            <ProductDescription :productProduct="productDetail" :productdetails="productDetail" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import ProductImage from "../components/Shop/ProductsDetails/ProductImage.vue";
import ProductDescription from "../components/Shop/ProductsDetails/ProductDescription.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import axios from "axios";

export default {
  components: {
    Navbar,
    LeftMenu,
    ProductImage,
    ProductDescription,
    Banner
  },
  data() {
    return {
      productDetail: null,
    };
  },
  methods: {
    async fetchDetail() {
      let loader = this.$loading.show({
        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });
      let detailResults = await axios.get(
        `shop/viewProduct?product_guid=${this.lastSegment}`
      );
      if (detailResults.data.statusCode == 200) {
        loader.hide();
      if (detailResults.data.data.length == 0) {
        this.$router.push({ name: "Shop" });
            }
            else{
              this.productDetail = detailResults.data.data;
            }
      } else {
        alert("notfoun");
      }
    },
    getLinkUrl() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.lastSegment = segment_array.pop();
      if (this.lastSegment !== "product-detail") {
        this.fetchDetail();
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getLinkUrl();
    document.getElementById("shop-nav").style.color = "#188ef5";
        document.querySelector(".nav-link-shop").style.borderBottom =
          "4px solid #188ef5";
  },
};
</script>

<style scoped>
.loaderSign {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
