<template>
  <div>
    <p class="policy-heading">Return and Refund Policy</p>
    <p class="policy-date">Last updated: March 23, 2022</p>
    <p class="policy-description">
      Thank you for shopping at Who I Follow website and WIF app.
    </p>
    <p class="policy-description">
      If, for any reason, You are not completely satisfied with a purchase We
      invite You to review our policy on refunds and returns.
    </p>
    <p class="policy-alert">
      The following terms are applicable for any products that You purchased
      with Us.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>