<template>
  <div class="photos-top-wrapper">
    <div class="photos-left">
      <router-link to="/profile" class="m-0">
        <img src="../../assets/images/arrow-left.png" alt="" />
      </router-link>
      <h3>Photos</h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "PhotosTop",
};
</script>
<style lang="">
</style>