<template>
    <div class="toggle-content d-none mb-3">
      <span class="toggle-text live-stream-toggle"
        >Enable live stream scoring</span
      >
      <toggle-button
        class="toggle"
        :value="false"
        :sync="true"
        :width="34"
        ref="toggleBtn"
      />
    </div>
</template>

<script>
export default {
    name: "StreamingToggle"
}
</script>